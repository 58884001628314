export default {
    methods: {
        formatValue(val, min, max) {
            try {
                return val.toLocaleString('pt-BR', {
                    minimumFractionDigits: min,
                    maximumFractionDigits: max
                })
            } catch (e) {
                return 0;
            }
        },
        formatPercent(value) {
            return (value * 100).toFixed(2);
        },
        formatDecimals(val, digits = 2, decimalFormat = true) {
            let formattedVal = val.toFixed(digits).replace(/[.]00$/, "")
            return decimalFormat ? formattedVal.replace('.', ',') : formattedVal
        }
    }
}


