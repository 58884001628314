<template>
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__head kt-portlet__head--lg no-border-radius">
                <div class="kt-portlet__head-label">
                    <div class="row center">
                        <Dropdown v-model="selectedOrg" :options="organizations" optionLabel="display_name"
                            :placeholder="$t('AppAlertsCoaAlertsConfig.Organização')" class="auto-center" :dataKey="'id'" :filter="true"
                            @input="saveChosenOptionsToLocalStorage" style="width: 250px">
                            <template #option="slotProps" style="width: 500px">
                                <div v-if="loadingOrgs"> {{ $t('AppAlertsCoaAlertsConfig.Carregando, aguarde') }}</div>
                                <div style="padding-right: 20px" v-else-if="slotProps.option.name === 'ND'">
                                    -
                                </div>
                                <div style="padding-right: 20px" v-else>
                                    {{ slotProps.option.display_name }}
                                </div>
                            </template>
                        </Dropdown>
                        <app-button type="secondary"
                                    class="auto-center"
                                    style="background-color: #0D89EC; margin-left: 10px;"
                                    @handleClick="exportCsv">
                            <i class="pi pi-external-link" />
                            {{$t('AppAlertsCoaAlertsConfig.Exportar')}}
                        </app-button>
                    </div>
                </div>
            </div>

            <div class="kt-portlet__body no-padding" id="tableDiv">
                <div class="card data-table-width">
                    <DataTable :value="gridData" :paginator="true" :rows="50" :rowHover="true" :loading="loading"
                        filterDisplay="row" :filters.sync="filters" :sortOrder="1" sortField="code_alert" stripedRows
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        :rowsPerPageOptions="[10, 25, 50]" responsiveLayout="scroll"
                        :currentPageReportTemplate="$t('AppAlertsCoaAlertsConfig.Mostrando {first} até {last} de {totalRecords} Alertas')"
                        class="p-datatable-striped">

                        <template #header>
                            <div style="display: flex; justify-content: space-between;">
                                <div style="font-size: 20px; font-weight: 500;">{{ $t('AppAlertsCoaAlertsConfig.Alertas') }}</div>
                                <Button :label="$t('AppAlertsCoaAlertsConfig.Adicionar')" icon="pi pi-plus" @click="handleNew" />
                            </div>
                        </template>
                        <template #empty>
                            {{ $t('AppAlertsCoaAlertsConfig.Sem dados para exibir') }}
                        </template>

                        <Column :header="$t('AppAlertsCoaAlertsConfig.Código do Alerta')" field="code_alert" headerClass="headerClass"  :showFilterMenu="false"
                            :showClearButton="false" bodyClass="bodyClass" :sortable="true">
                            <template #body="slotProps">
                                <span>{{ slotProps.data.code_alert }}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column :header="$t('AppAlertsCoaAlertsConfig.Parâmetro')" field="parameter" headerClass="headerClass"  :showFilterMenu="false"
                            :showClearButton="false" bodyClass="bodyClass" :sortable="true" :styles="{'max-width': '9rem'}">
                            <template #body="slotProps">
                                <span>{{ slotProps.data.parameter }}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <MultiSelect v-model="filterModel.value" @input="filterCallback()" :options="optionsParameter"
                                            :placeholder="$t('AppAlertsCoaAlertsConfig.Selecione')" appendTo="tableDiv"
                                            class="p-column-filter width-multiselect" :showClear="true" optionLabel="name" optionValue="name" />
                            </template>
                        </Column>
                        <Column :header="$t('AppAlertsCoaAlertsConfig.Tipo')" field="vehicle_type" headerClass="headerClass"  :showFilterMenu="false"
                            :showClearButton="false" bodyClass="bodyClass" :sortable="true" :styles="{'max-width': '9rem'}">
                            <template #body="slotProps">
                                <span>{{ slotProps.data.vehicle_type }}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <MultiSelect v-model="filterModel.value" @input="filterCallback()" :options="optionsVehicleType"
                                            :placeholder="$t('AppAlertsCoaAlertsConfig.Selecione')" appendTo="tableDiv"
                                            class="p-column-filter width-multiselect" :showClear="true" optionLabel="name" optionValue="name" />
                            </template>
                        </Column>
                        <Column :header="$t('AppAlertsCoaAlertsConfig.Modelo')" field="vehicle_name" headerClass="headerClass"  :showFilterMenu="false"
                            :showClearButton="false" bodyClass="bodyClass" :sortable="true" :styles="{'max-width': '8rem'}">
                            <template #body="slotProps">
                                <span>{{ slotProps.data.vehicle_name }}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <MultiSelect v-model="filterModel.value" @input="filterCallback()" :options="optionsVehicleName"
                                            :placeholder="$t('AppAlertsCoaAlertsConfig.Selecione')" appendTo="tableDiv"
                                            class="p-column-filter width-multiselect" :showClear="true" optionLabel="name" optionValue="name" />
                            </template>
                        </Column>
                        <Column :header="$t('AppAlertsCoaAlertsConfig.Criticidade')" field="criticality_level" headerClass="headerClass"  :showFilterMenu="false"
                            :showClearButton="false" bodyClass="bodyClass" :sortable="true" :styles="{'max-width': '9rem'}">
                            <template #body="slotProps">
                                <span :class="'badge criticalityLevel-' + slotProps.data.criticality_level">
                                    {{ getCriticalityLevel(slotProps.data.criticality_level) }}
                                </span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <MultiSelect v-model="filterModel.value" @input="filterCallback()" :options="optionsCriticalityLevel"
                                        :placeholder="$t('AppAlertsCoaAlertsConfig.Selecione')" appendTo="tableDiv"
                                        class="p-column-filter width-multiselect" :showClear="true" optionLabel="code" optionValue="code">
                                    <template #value="slotProps">
                                        <span v-for="singleValue in slotProps.value" :class="'badge criticalityLevel-' + singleValue" style="margin-right: 5px;">
                                            {{ getCriticalityLevel(singleValue) }}
                                        </span>
                                    </template>
                                    <template #option="slotProps">
                                        <span :class="'badge criticalityLevel-' + slotProps.option.code">
                                            {{ getCriticalityLevel(slotProps.option.code) }}
                                        </span>
                                    </template>
                                </MultiSelect>
                            </template>
                        </Column>
                        <Column :header="$t('AppAlertsCoaAlertsConfig.Referência Gráfico Min')" field="reference_value_chart_min" headerClass="headerClass"  :showFilterMenu="false"
                            :showClearButton="false" bodyClass="bodyClass" :sortable="true">
                            <template #body="slotProps">
                                <span>{{ slotProps.data.reference_value_chart_min }}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column :header="$t('AppAlertsCoaAlertsConfig.Referência Gráfico Máx')" field="reference_value_chart_max" headerClass="headerClass"  :showFilterMenu="false"
                            :showClearButton="false" bodyClass="bodyClass" :sortable="true">
                            <template #body="slotProps">
                                <span>{{ slotProps.data.reference_value_chart_max }}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column :header="$t('AppAlertsCoaAlertsConfig.Alerta Mínimo Atenção')" field="alert_value_min_attention" headerClass="headerClass"  :showFilterMenu="false"
                            :showClearButton="false" bodyClass="bodyClass" :sortable="true">
                            <template #body="slotProps">
                                <span style="margin-right: 4px;">&lt;</span>
                                <span>{{ slotProps.data.alert_value_min_attention }}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column :header="$t('AppAlertsCoaAlertsConfig.Alerta Mínimo Crítico')" field="alert_value_min_critical" headerClass="headerClass"  :showFilterMenu="false"
                            :showClearButton="false" bodyClass="bodyClass" :sortable="true">
                            <template #body="slotProps">
                                <span style="margin-right: 4px;">&lt;=</span>
                                <span>{{ slotProps.data.alert_value_min_critical }}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column :header="$t('AppAlertsCoaAlertsConfig.Alerta Mínimo Tempo')" field="alert_value_min_time" headerClass="headerClass"  :showFilterMenu="false"
                            :showClearButton="false" bodyClass="bodyClass" :sortable="true">
                            <template #body="slotProps">
                                <span style="margin-right: 4px;">&gt;</span>
                                <span>{{ slotProps.data.alert_value_min_time }}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column :header="$t('AppAlertsCoaAlertsConfig.Alerta Máximo Atenção')" field="alert_value_max_attention" headerClass="headerClass"  :showFilterMenu="false"
                            :showClearButton="false" bodyClass="bodyClass" :sortable="true">
                            <template #body="slotProps">
                                <span style="margin-right: 4px;">&gt;</span>
                                <span>{{ slotProps.data.alert_value_max_attention }}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column :header="$t('AppAlertsCoaAlertsConfig.Alerta Máximo Crítico')" field="alert_value_max_critical" headerClass="headerClass"  :showFilterMenu="false"
                            :showClearButton="false" bodyClass="bodyClass" :sortable="true">
                            <template #body="slotProps">
                                <span style="margin-right: 4px;">&gt;=</span>
                                <span>{{ slotProps.data.alert_value_max_critical }}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column :header="$t('AppAlertsCoaAlertsConfig.Alerta Máximo Tempo')" field="alert_value_max_time" headerClass="headerClass"  :showFilterMenu="false"
                            :showClearButton="false" bodyClass="bodyClass" :sortable="true">
                            <template #body="slotProps">
                                <span style="margin-right: 4px;">&gt;</span>
                                <span>{{ slotProps.data.alert_value_max_time }}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column :header="$t('AppAlertsCoaAlertsConfig.Mensagem de Alerta Mínimo Atenção')" field="alert_message_min_attention" headerClass="headerClass"  :showFilterMenu="false"
                            :showClearButton="false" bodyClass="bodyClass" :sortable="true">
                            <template #body="slotProps">
                                <div v-if="slotProps.data.alert_message_min_attention != null && slotProps.data.alert_message_min_attention.length > 20" v-tooltip.top="`${slotProps.data.alert_message_min_attention}`">
                                    {{ slotProps.data.alert_message_min_attention.slice(0, 20) + ' ...' }}
                                </div>
                                <div v-else>
                                    {{ slotProps.data.alert_message_min_attention }}
                                </div>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column :header="$t('AppAlertsCoaAlertsConfig.Mensagem de Alerta Mínimo Crítico')" field="alert_message_min_critical" headerClass="headerClass"  :showFilterMenu="false"
                            :showClearButton="false" bodyClass="bodyClass" :sortable="true">
                            <template #body="slotProps">
                                <div v-if="slotProps.data.alert_message_min_critical != null && slotProps.data.alert_message_min_critical.length > 20" v-tooltip.top="`${slotProps.data.alert_message_min_critical}`">
                                    {{ slotProps.data.alert_message_min_critical.slice(0, 20) + ' ...' }}
                                </div>
                                <div v-else>
                                    {{ slotProps.data.alert_message_min_critical }}
                                </div>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column :header="$t('AppAlertsCoaAlertsConfig.Mensagem de Alerta Máximo Atenção')" field="alert_message_max_attention" headerClass="headerClass"  :showFilterMenu="false"
                            :showClearButton="false" bodyClass="bodyClass" :sortable="true">
                            <template #body="slotProps">
                                <div v-if="slotProps.data.alert_message_max_attention != null && slotProps.data.alert_message_max_attention.length > 20" v-tooltip.top="`${slotProps.data.alert_message_max_attention}`">
                                    {{ slotProps.data.alert_message_max_attention.slice(0, 20) + ' ...' }}
                                </div>
                                <div v-else>
                                    {{ slotProps.data.alert_message_max_attention }}
                                </div>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column :header="$t('AppAlertsCoaAlertsConfig.Mensagem de Alerta Máximo Crítico')" field="alert_message_max_critical" headerClass="headerClass"  :showFilterMenu="false"
                            :showClearButton="false" bodyClass="bodyClass" :sortable="true">
                            <template #body="slotProps">
                                <div v-if="slotProps.data.alert_message_max_critical != null && slotProps.data.alert_message_max_critical.length > 20" v-tooltip.top="`${slotProps.data.alert_message_max_critical}`">
                                    {{ slotProps.data.alert_message_max_critical.slice(0, 20) + ' ...' }}
                                </div>
                                <div v-else>
                                    {{ slotProps.data.alert_message_max_critical }}
                                </div>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column :header="$t('AppAlertsCoaAlertsConfig.Status')" field="status" headerClass="headerClass"  :showFilterMenu="false"
                            :showClearButton="false" bodyClass="bodyClass" :sortable="true" :styles="{'max-width': '9rem'}">
                            <template #body="slotProps">
                                <span :class="'badge statusAlert-' + slotProps.data.status">
                                    {{ getStatus(slotProps.data.status) }}
                                </span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <MultiSelect v-model="filterModel.value" @input="filterCallback()" :options="optionsStatus"
                                        :placeholder="$t('AppAlertsCoaAlertsConfig.Selecione')" appendTo="tableDiv"
                                        class="p-column-filter width-multiselect" :showClear="true" optionLabel="code" optionValue="code">
                                    <template #value="slotProps">
                                        <span v-for="singleValue in slotProps.value" :class="'badge statusAlert-' + singleValue" style="margin-right: 5px;">
                                            {{ getStatus(singleValue) }}
                                        </span>
                                    </template>
                                    <template #option="slotProps">
                                        <span :class="'badge statusAlert-' + slotProps.option.code">
                                            {{ getStatus(slotProps.option.code) }}
                                        </span>
                                    </template>
                                </MultiSelect>
                            </template>
                        </Column>
                        <Column bodyClass="bodyClass" headerClass="headerClass" :styles="{'min-width': '6rem', 'max-width': '6rem'}">
                            <template #body="slotProps">
                                <Button icon="pi pi-pencil" class="p-button-rounded"
                                    :class="'p-button-success'"
                                    style="margin-right: 10px" @click="handleEdit(slotProps.data)" />

                                <Button icon="pi pi-times" class="p-button-rounded"
                                    :class="'p-button-danger'" @click="handleDelete(slotProps.data)" />
                            </template>
                        </Column>
                    </DataTable>
                </div>

                <Dialog :visible.sync="alertDialog" :header="isNewAlert ? $t('AppAlertsCoaAlertsConfig.Cadastrar Alerta') : $t('AppAlertsCoaAlertsConfig.Editar Alerta')" :modal="true"
                        :contentStyle="{ 'max-width': '90vw', 'width': '886px', 'overflow': 'visible'}">
                    <app-alerts-coa-alerts-config-form
                            @saveAlertConfig="saveAlertConfig"
                            :optionsSensors="optionsSensors"
                            :optionsManufacturers="optionsManufacturers"
                            ref="alertForm" />
                    <template #footer>
                        <Button :label="$t('AppAlertsCoaAlertsConfig.Cancelar')" icon="pi pi-times" class="p-button-text p-button-danger" @click="alertDialog = false"/>
                        <Button :label="$t('AppAlertsCoaAlertsConfig.Confirmar')" icon="pi pi-check" class="p-button-text" @click="$refs.alertForm.validate()"/>
                    </template>
                </Dialog>

                <Dialog :visible.sync="deleteAlertDialog" :contentStyle="{ 'max-width': '90vw', 'width': '500px' }" :header="$t('AppAlertsCoaAlertsConfig.Excluir Alerta')" :modal="true">
                    <div class="confirmation-content">
                        <div>
                            {{ $t('AppAlertsCoaAlertsConfig.Tem certeza que deseja excluir o alerta?') }}
                        </div>
                    </div>
                    <template #footer>
                        <Button :label="$t('AppAlertsCoaAlertsConfig.Cancelar')" icon="pi pi-times" class="p-button-text p-button-danger" @click="deleteAlertDialog = false"/>
                        <Button :label="$t('AppAlertsCoaAlertsConfig.Confirmar')" icon="pi pi-check" class="p-button-text" @click="deleteAlert"/>
                    </template>
                </Dialog>
            </div>
        </div>
    </div>
</template>
<script>
import AppDateTimeControlBar from "../../../common/AppDateTimeControlBar";
import AppAlertsCoaAlertsConfigForm from './AppAlertsCoaAlertsConfigForm.vue';
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button/Button';
import Dialog from 'primevue/dialog/Dialog';
import AppButton from '../../../common/AppButton.vue';
import OrganizationsService from "@/services/OrganizationsService";
import VehiclesManufacturersService from '../../../../services/VehiclesManufacturersService';
import AlertsCoaService from '@/services/AlertsCoaService';
import exportJsonToCsv from '@/mixins/JsonToCsvMixin';
import { CRITICALY_LEVELS, STATUSES_ALERT} from '../AppOptionsCoa';
import orderCustomFieldByNaturalOrder from "@/mixins/ArrayMixin";
import {FilterMatchMode} from 'primevue/api';
import Tooltip from 'primevue/tooltip';

export default {
    mixins: [orderCustomFieldByNaturalOrder, exportJsonToCsv],
    created() {
        this.organizationsService = new OrganizationsService();
        this.vehiclesManufacturersService = new VehiclesManufacturersService();
        this.alertsCoaService = new AlertsCoaService();
    },
    mounted() {
        this.getOrganizations();
        this.getManufacturers();
    },
    data() {
        return {
            loading: false,
            organizationsService: null,
            vehiclesManufacturersService: null,
            alertsCoaService: null,
            loadingOrgs: false,
            selectedOrg: null,
            organizations: [],
            storedChosenOptions: {},
            gridData: [],
            optionsVehicleName: [],
            optionsParameter: [],
            optionsVehicleType: [],
            optionsSensors: [],
            optionsCriticalityLevel: CRITICALY_LEVELS,
            optionsStatus: STATUSES_ALERT,
            deleteAlertDialog: false,
            itemToDelete: null,
            alertDialog: false,
            alertForm: false,
            isNewAlert: false,
            optionsManufacturers: [],
            filters: {
                'code_alert': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'parameter': {value: null, matchMode: FilterMatchMode.IN},
                'unit': {value: null, matchMode: FilterMatchMode.IN},
                'vehicle_type': {value: null, matchMode: FilterMatchMode.IN},
                'vehicle_name': {value: null, matchMode: FilterMatchMode.IN},
                'criticality_level': {value: null, matchMode: FilterMatchMode.IN},
                'reference_value_chart_min': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'reference_value_chart_max': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'alert_value_min_attention': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'alert_value_min_critical': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'alert_value_min_time': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'alert_value_max_attention': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'alert_value_max_critical': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'alert_value_max_time': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'alert_message_min_attention': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'alert_message_min_critical': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'alert_message_max_attention': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'alert_message_max_critical': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'status': {value: null, matchMode: FilterMatchMode.IN}
            }
        }
    },
    methods: {
        exportCsv(){
            let csvData = [];
            JSON.parse(JSON.stringify(this.gridData)).forEach(data => {
                data.criticality_level = this.getCriticalityLevel(data.criticality_level)
                data.status = this.getStatus(data.status)
                csvData.push(data)
            });

            const CSV_FIELDS = [
                'code_alert', 'parameter', 'unit', 'vehicle_type', 'vehicle_name', 'criticality_level', 'reference_value_chart_min', 'reference_value_chart_max',
                'alert_value_min_attention', 'alert_value_min_critical', 'alert_value_min_time', 'alert_value_max_attention', 'alert_value_max_critical',
                'alert_value_max_time', 'alert_message_min_attention', 'alert_message_min_critical', 'alert_message_max_attention', 'alert_message_max_critical', 'status'
            ]
            const CSV_LABELS = [
                this.$t('AppAlertsCoaAlertsConfig.Código do Alerta'),
                this.$t('AppAlertsCoaAlertsConfig.Parâmetro'),
                this.$t('AppAlertsCoaAlertsConfig.Unidade'),
                this.$t('AppAlertsCoaAlertsConfig.Tipo'),
                this.$t('AppAlertsCoaAlertsConfig.Modelo'),
                this.$t('AppAlertsCoaAlertsConfig.Criticidade'),
                this.$t('AppAlertsCoaAlertsConfig.Referência Gráfico Min'),
                this.$t('AppAlertsCoaAlertsConfig.Referência Gráfico Máx'),
                this.$t('AppAlertsCoaAlertsConfig.Alerta Mínimo Atenção'),
                this.$t('AppAlertsCoaAlertsConfig.Alerta Mínimo Crítico'),
                this.$t('AppAlertsCoaAlertsConfig.Alerta Mínimo Tempo'),
                this.$t('AppAlertsCoaAlertsConfig.Alerta Máximo Atenção'),
                this.$t('AppAlertsCoaAlertsConfig.Alerta Máximo Crítico'),
                this.$t('AppAlertsCoaAlertsConfig.Alerta Máximo Tempo'),
                this.$t('AppAlertsCoaAlertsConfig.Mensagem de Alerta Mínimo Atenção'),
                this.$t('AppAlertsCoaAlertsConfig.Mensagem de Alerta Mínimo Crítico'),
                this.$t('AppAlertsCoaAlertsConfig.Mensagem de Alerta Máximo Atenção'),
                this.$t('AppAlertsCoaAlertsConfig.Mensagem de Alerta Máximo Crítico'),
                this.$t('AppAlertsCoaAlertsConfig.Status')
            ]

            this.exportJsonToCsv(CSV_FIELDS, CSV_LABELS, csvData, "sensores");
        },
        handleNew(){
            this.isNewAlert = true
            this.alertDialog = true
            let interval = setInterval(() => {
                if (this.$refs.alertForm) {
                    clearInterval(interval);
                    this.$refs.alertForm.cleanForm();
                }
            }, 50);
        },
        handleEdit(val){
            this.isNewAlert = false
            this.alertDialog = true
            let interval = setInterval(() => {
                if (this.$refs.alertForm) {
                    clearInterval(interval);
                    this.$refs.alertForm.editForm(val);
                }
            }, 50);
        },
        handleDelete(val){
            this.deleteAlertDialog = true
            this.itemToDelete = val
        },
        deleteAlert(){
            this.deleteAlertDialog = false
            this.alertsCoaService.deleteAlert(this.selectedOrg.id, this.itemToDelete.id).then((data) => {
                    this.$toast.add({
                        severity: 'success',
                        summary: this.$t('AppAlertsCoaAlertsConfig.Excluido com sucesso'),
                        life: 5000
                    });
                    this.getFailures(this.selectedOrg)
                }).catch((error) => {
                    if (!error.response || error.response.status !== 403) {
                        this.$toast.add({
                            severity: 'error',
                            summary: this.$t('AppAlertsCoaAlertsConfig.Operação falhou'),
                            detail: this.$t('AppAlertsCoaAlertsConfig.Não foi possível excluir o sensor'),
                            life: 5000
                        });
                        return;
                    }
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppAlertsCoaAlertsConfig.Você não tem acesso a esta funcionalidade'),
                        life: 5000
                    });
                })
        },
        saveAlertConfig(form, isNewForm){
            this.alertDialog = false
            let id = form.id
            delete form.id
            form.source_is_mandatory = form.source != null ? true : false

            if(isNewForm){
                this.createAlert(form)
                return
            }

            this.editAlert(form, id)
        },
        createAlert(form) {
            this.alertsCoaService.createAlert(this.selectedOrg.id, form).then((data) => {
                    this.$toast.add({
                        severity: 'success',
                        summary: this.$t('AppAlertsCoaAlertsConfig.Criado com sucesso'),
                        life: 5000
                    });
                    this.getFailures(this.selectedOrg)
                }).catch((error) => {
                    if (!error.response || error.response.status !== 403) {
                        this.$toast.add({
                            severity: 'error',
                            summary: this.$t('AppAlertsCoaAlertsConfig.Operação falhou'),
                            detail: this.$t('AppAlertsCoaAlertsConfig.Não foi possível criar o alerta'),
                            life: 5000
                        });
                        return;
                    }
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppAlertsCoaAlertsConfig.Você não tem acesso a esta funcionalidade'),
                        life: 5000
                    });
                })
        },
        editAlert(form, id) {
            this.alertsCoaService.editAlert(this.selectedOrg.id, form, id).then((data) => {
                    this.$toast.add({
                        severity: 'success',
                        summary: this.$t('AppAlertsCoaAlertsConfig.Editado com sucesso'),
                        life: 5000
                    });
                    this.getFailures(this.selectedOrg)
                }).catch((error) => {
                    if (!error.response || error.response.status !== 403) {
                        this.$toast.add({
                            severity: 'error',
                            summary: this.$t('AppAlertsCoaAlertsConfig.Operação falhou'),
                            detail: this.$t('AppAlertsCoaAlertsConfig.Não foi possível editar o alerta'),
                            life: 5000
                        });
                        return;
                    }
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppAlertsCoaAlertsConfig.Você não tem acesso a esta funcionalidade'),
                        life: 5000
                    });
                })
        },
        getOrganizations() {
            let vm = this;
            this.loadingOrgs = true;
            this.organizationsService.getAllV3('VEHICLES').then((results) => {
                this.populateOrgsList(results);
                this.loadFilteredOptions();
            }).catch((error) => {
                vm.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppAlertsCoaAlertsConfig.Operação falhou'),
                    detail: this.$t('AppAlertsCoaAlertsConfig.Não foi possível buscar organizações'),
                    life: 5000
                });
                console.log(error)
            }).finally(() => {
                this.loadingOrgs = false;
            })
        },
        populateOrgsList(orgs) {
            this.organizations = this.orderCustomFieldByNaturalOrder(orgs, "name");
        },
        loadFilteredOptions() {
            this.storedChosenOptions = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_BAR_OPTIONS));

            if (!this.storedChosenOptions || !this.storedChosenOptions.clients || this.storedChosenOptions.clients.length === 0) {
                this.storedChosenOptions = {};
                return;
            }

            this.selectedOrg = this.storedChosenOptions['clients'].filter(a => this.organizations.some(b => a.id === b.id))[0];
        },
        saveChosenOptionsToLocalStorage() {
            this.storedChosenOptions['clients'] = [this.selectedOrg];
            this.storedChosenOptions['vehicles'] = [];
            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_BAR_OPTIONS, JSON.stringify(this.storedChosenOptions));
        },
        getStatus(status) {
            let result = this.optionsStatus.find(({code}) => code === status);
            if (result) {
                return result.name;
            }
        },
        getCriticalityLevel(status) {
            let result = this.optionsCriticalityLevel.find(({code}) => code === status);
            if (result) {
                return result.name;
            }
        },
        uniquePush(array, name) {
            if (name != null && name != undefined && array.length === 0) {
                array.push({ code: array.length, name });
                return
            }

            if ((name != null && name != undefined) && !array.some(item => item.name === name)) {
                array.push({ code: array.length, name });
            }
        },
        getFailures(val){
            this.loading = true
            this.alertsCoaService.getAlerts(val.id).then((data) => {
                data.forEach(element => {
                    element.parameter = element.parameter + " (" + element.unit + ")"
                    this.uniquePush(this.optionsParameter, element.parameter);
                    this.uniquePush(this.optionsVehicleType, element.vehicle_type);
                    this.uniquePush(this.optionsVehicleName, element.vehicle_name);
                });
                this.gridData = data
            }).catch((error) => {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppAlertsCoaAlertsConfig.Operação falhou'),
                    detail: this.$t('AppAlertsCoaAlertsConfig.Não foi possível buscar os alertas'),
                    life: 5000
                });
                console.log(error)
            }).finally(() => {
                this.loading = false;
            })
            this.displayMaximizable = false;
        },
        getManufacturers(){
            this.vehiclesManufacturersService.getManufacturers().then((data) => {
                this.optionsManufacturers = data
            }).catch((error) => {
                if (!error.response || error.response.status !== 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppAlertsCoaAlertsConfig.Operação falhou'),
                        detail: this.$t('AppAlertsCoaAlertsConfig.Não foi possível buscar os fabricantes'),
                        life: 5000
                    });
                    return;
                }
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppAlertsCoaAlertsConfig.Você não tem acesso a esta funcionalidade'),
                    life: 5000
                });
            })
        }
    },
    watch: {
        selectedOrg: function (val) {
            this.getFailures(val)

            this.alertsCoaService.getSensorsByOrg(val.id).then((data) => {
                this.optionsSensors = data
            }).catch((error) => {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppAlertsCoaAlertsConfig.Operação falhou'),
                    detail: this.$t('AppAlertsCoaAlertsConfig.Não foi possível buscar os sensores'),
                    life: 5000
                });
                console.log(error)
            })
        }
    },
    components: {
        Dropdown,
        MultiSelect,
        DataTable,
        Column,
        InputText,
        Button,
        Dialog,
        AppButton,
        Tooltip,
        AppDateTimeControlBar,
        AppAlertsCoaAlertsConfigForm
    },
    directives: {
        tooltip: Tooltip
    }
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/primevue";
@import "src/components/views/alerts-coa/style";

@media (max-width: 1024px){
    .data-table-width{
        width: 100vw;
    }
}

@media (min-width: 1025px){
    .data-table-width{
        max-width: calc(100vw - 140px);
    }
}
</style>
