<template>
    <div class="operations-list">
        <div class="row no-gutters sub-header">
            <div class="col-12">
                <div v-if="selected_field" class="title">
                    {{ $t('AppOperationsList.Analisar Trabalhos de') }} {{ selected_field.name }}
                </div>
                <div class="sub-title">
                    <Button :label="$t('AppOperationsList.Todos')" style="font-size: 10px;" @click="allClicked"
                            class="mr-2 p-1 p-button-sm p-button-rounded"
                            :class="{'p-button-outlined': isCurrentOptionActive('all')}"/>
                    <Button :label="$t('AppOperationsList.Agendados')" style="font-size: 10px" @click="scheduledClicked"
                            class="mr-2 p-1 p-button-sm p-button-rounded"
                            :class="{'p-button-outlined': isCurrentOptionActive('scheduled')}"/>
                    <Button :label="$t('AppOperationsList.Completos')" style="font-size: 10px" @click="doneClicked"
                            class="mr-2 p-1 p-button-sm p-button-rounded"
                            :class="{'p-button-outlined': isCurrentOptionActive('done')}"/>
                </div>
            </div>
        </div>
        <perfect-scrollbar class="mt-4">
            <div v-if="sortedOperations.length > 0">
                <div v-for="operation in sortedOperations">
                    <div v-if="operation.status === operationsToShow || operationsToShow === 'all'"
                         class="row no-gutters operations" :id="operation.id"
                         v-tooltip.top="operationStatusTooltip(operation.status)" @click="operationClicked(operation)"
                         @mouseleave="leaveOperation(operation)" @mouseover="hoverOperation(operation)">
                        <div class="operation-body pt-2 pb-2" style="width: 100%">
                            <div class="operation-body-date">
                                {{ dateFormat(operation.start.valueOf() / 1000, 'GMT') }} {{ $t('AppOperationsList.a') }}
                                {{ dateFormat(operation.end.valueOf() / 1000, 'GMT') }}
                            </div>
                            <div class="row no-gutters mt-1" style="width: 100%" id="operation-body-content">
                                <div class="col operation-body-title">
                                    {{ operation.name }}
                                    <span :class="getStatusIcon(operation.status)"
                                          style="margin-left: 10px; font-size: 18px"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else style="font-size: 16px">
                {{operationsToShow === 'scheduled' ? $t('AppOperationsList.Não há trabalhos agendados para este talhão') : $t('AppOperationsList.Não há trabalhos para este talhão') }}
            </div>
        </perfect-scrollbar>
    </div>
</template>

<script>

import Button from 'primevue/button'
import Tooltip from "primevue/tooltip";

import dateFormat from "@/mixins/DateMixin";

export default {
    mixins: [dateFormat],
    data() {
        return {
            operationsToShow: 'all'
        }
    },
    props: {
        operations: {
            type: Array,
        },
        selected_field: {
            type: Object,
        },
    },
    methods: {
        getStatusIcon(status) {
            return status === 'done' ? 'pi pi-check-circle' : 'pi pi-calendar-times';
        },
        operationStatusTooltip(status) {
            return status === 'done' ? this.$t('AppOperationsList.Finalizado') : this.$t('AppOperationsList.Agendado');
        },
        hoverOperation(operation) {
            this.$emit('hoverOperation', operation);
        },
        leaveOperation(operation) {
            this.$emit('leaveOperation', operation);
        },
        operationClicked(operation) {
            this.$emit('operationClickedFromList', operation);
        },
        timelineHover(item) {
            let elementById = document.getElementById(item);
            if (elementById) {
                elementById.classList.add('tl-hover');
            }
        },
        timelineHoverOut(item) {
            let elementById = document.getElementById(item);
            if (elementById) {
                elementById.classList.remove('tl-hover');
            }
        },
        allClicked() {
            this.operationsToShow = 'all';
            this.$emit('allClicked');
        },
        scheduledClicked() {
            this.operationsToShow = 'scheduled';
            this.$emit('scheduledClicked');
        },
        doneClicked() {
            this.operationsToShow = 'done';
            this.$emit('doneClicked');
        },
        isCurrentOptionActive(option) {
            return this.operationsToShow !== option;
        }
    },
    computed: {
        sortedOperations: function () {
            let vm = this;
            return vm.operations.sort((a, b) => b.end - a.end);
        }
    },
    components: {
        Button, Tooltip
    },
    directives: {
        tooltip: Tooltip
    }
}
</script>

<style scoped lang="scss">

.operations-list {
    margin-left: 40px;
    margin-right: 40px;
}

.title {
    padding-top: 30px;
    font-size: 22px;
    font-weight: bold;
    color: black;
}

.sub-title {
    margin-top: 10px;
}

.sub-header {
    height: 120px;
    border-bottom: 1px solid #d6d6d6;
}

.operation-body-date {
    font-size: 12px;
    color: #a2a2a2;
    font-weight: 700;
}

.operation-body-title {
    font-size: 18px;
    font-weight: 900;
    color: black;
}

.operation-body:hover {
    background-color: #addaff;
    cursor: pointer;
    border-radius: 5px;
}

.tl-hover {
    background-color: #addaff;
}

</style>
