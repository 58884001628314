<template>
    <section>
        <VueHtml2pdf class="pdf-operation" :show-layout="false" :float-layout="true" :enable-download="true"
                     :preview-modal="true" :paginate-elements-by-height="1100"
                     :filename="fileName"
                     :pdf-quality="2" :manual-pagination="false" pdf-format="a4" pdf-orientation="portrait"
                     pdf-content-width="800px" ref="html2Pdf">
            <section slot="pdf-content">
                <section class="pdf-item">
                    <div class="brand-background">
                        <img alt="logo" style="height: 80px; width: 150px" :src="headerImage"/>
                    </div>
                    <div class="pdf-title mt-2">
                        <h4 v-if="operation">
                            {{ operation.name }} - {{ dateFormat(operation.start.getTime() / 1000, 'GMT') }} {{ $t('AppOperationsReport.até') }}
                            {{ dateFormat(operation.end.getTime() / 1000, 'GMT') }}
                        </h4>
                    </div>
                </section>

                <section class="pdf-item mt-4" style="padding-left: 75px; padding-right: 75px">
                    <div class="d-flex" style="position: relative">
                        <slot>
                            <div id="google-maps-operation-report"></div>
                        </slot>
                        <div class="scaled">
                            <app-operations-work-options :rate_steps="rate_steps" :map-type="map_type" :report="true"/>
                        </div>
                    </div>
                    <p v-if="map_type" style="text-align: end">{{ map_type.title }}</p>
                </section>

                <section class="pdf-item d-flex flex-column" style="padding-left: 50px; padding-right: 50px">
                    <div class="row justify-content-around">
                        <div class="card-border text-center mb-4 mt-3 col-5" v-if="stats.TOTAL">
                            <div class="mt-1 mb-2"><b>{{ stats.TOTAL.name }}</b></div>
                            <div v-for="item in stats.TOTAL.stats" class="ml-1 text-left">
                                <b>{{ item.title }}:</b> {{ item.value }} {{ item.symbol }}
                            </div>
                        </div>
                        <div class="card-border text-center mb-4 mt-3 col-5" v-if="stats.PERFORMANCE">
                            <div class="mt-1 mb-2"><b>{{ stats.PERFORMANCE.name }}</b></div>
                            <div v-for="item in stats.PERFORMANCE.stats" class="ml-1 text-left">
                                <b>{{ item.title }}:</b> {{ item.value }} {{ item.symbol }}
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-around">
                        <div class="card-border-list text-center col-3">
                            <div class="mt-1 mb-2"><b>{{ $t('AppOperationsReport.Operadores') }}</b></div>
                            <div class="ml-1 text-left">
                                <div v-if="operators.length === 0">
                                    ---
                                </div>
                                <div v-else v-for="operator in operators">
                                    {{ operator }}
                                </div>
                            </div>
                        </div>
                        <div class="card-border-list text-center col-3">
                            <div class="mt-1 mb-2"><b>{{ $t('AppOperationsReport.Veículos') }}</b></div>
                            <div class="ml-1 text-left">
                                <div v-if="vehicles.length === 0">
                                    ---
                                </div>
                                <div v-else v-for="vehicle in vehicles">
                                    {{ vehicle.display_name }}
                                </div>
                            </div>
                        </div>
                        <div v-if="showList('varieties')" class="card-border-list text-center col-3">
                            <div class="mt-1 mb-2"><b>{{ $t('AppOperationsReport.Variedades') }}</b></div>
                            <div class="ml-1 text-left">
                                <div v-if="varieties.length === 0">
                                    ---
                                </div>
                                <div v-else v-for="variety in varieties">
                                    {{ variety }}
                                </div>
                            </div>
                        </div>
                        <div v-if="showList('products')" class="card-border-list text-center col-3">
                            <div class="mt-1 mb-2"><b>{{ $t('AppOperationsReport.Produtos') }}</b></div>
                            <div class="ml-1 text-left">
                                <div v-if="products.length === 0">
                                    ---
                                </div>
                                <div v-else v-for="product in products">
                                    {{ product }}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </VueHtml2pdf>
    </section>
</template>

<script>

import VueHtml2pdf from 'vue-html2pdf'
import dateHourFormat from "@/mixins/DateMixin";
import AppOperationsWorkOptions from "@/components/views/agricultureV2/operations/AppOperationsWorkOptions.vue";

export default {
    mixins: [dateHourFormat],
    data() {
        return {
            headerImage: require(`@/assets/images/brands/${process.env.VUE_APP_THEME_IMAGE_HEADER}.png`)
        }
    },
    methods: {
        formatFileDate(date) {
            let formattedDate = this.dateFormat(date.getTime() / 1000, 'GMT')
            return formattedDate.replaceAll('/', '')
        },
        generateReport() {
            this.$refs.html2Pdf.generatePdf();
        },
        showList(value) {
            switch (this.operation.operation_type) {
                case 'DRY_APPLICATION':
                case 'LIQUID_APPLICATION':
                    return value === 'products';
                default:
                    return value === 'varieties';
            }
        }
    },
    computed: {
        fileName: function () {
            if (!this.operation) {
                return this.$t('AppOperationsReport.Relatório')
            }
            return `${this.$t('AppOperationsReport.Relatório')}_${this.operation.name}_${this.formatFileDate(this.operation.start)}_${this.formatFileDate(this.operation.end)}`
        }
    },
    props: {
        operation: {
            type: Object
        },
        map_type: {
            type: Object
        },
        stats: {
            type: Object
        },
        rate_steps: {
            type: Array
        },
        operators: {
            type: Array
        },
        vehicles: {
            type: Array
        },
        varieties: {
            type: Array
        },
        products: {
            type: Array
        }
    },
    components: {
        AppOperationsWorkOptions,
        VueHtml2pdf
    }
}

</script>

<style scoped lang="scss">
@import "src/assets/styles/constants";

.pdf-operation {
    width: 100%;
    margin-left: -10px;
    background-color: #f4f4f4
}

.brand-background {
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    width: 102%;
    background-color: $aside-menu-item-active;
}

.pdf-title {
    display: flex;
    justify-content: center;
}

.card-border {
    border: 1px solid;
    border-radius: 5px;
    padding-bottom: 1rem;
    margin-right: 1rem;
    margin-left: 1rem;
}

.card-border-list {
    border: 1px solid;
    border-radius: 5px;
    padding-bottom: 1rem;
}

.scaled {
    transform: scale(0.85);
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
}
</style>
