import axios from '../axios/axios-auth'

export default class MachineReportService {
    getInfo(type) {
        return axios.get(`/api/v3/homologatedModels/${type}`).then(response => response.data)
    }

    getInfoById(modelId, lang) {
        return axios.get(`/api/v3/homologatedModels/model/${modelId}/`, {params: {lang: lang}}).then(response => response.data)
    }
}
