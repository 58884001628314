import formatDecimals from "@/mixins/ValuesFormat";
import getTimeFromSecondsDurationFormatMixin from "@/mixins/DateMixin";

export default {
    mixins: [formatDecimals, getTimeFromSecondsDurationFormatMixin],
    methods: {
        buildStatistics(statistics, operationType) {
            let stats = {
                startDate: statistics.start_date,
                endDate: statistics.end_date,
                area: this.formatDecimals(statistics.area, 3),
                distance: statistics.distance,
                time: statistics.time,
                fuel: this.formatDecimals(statistics.fuel),
                fuel_rate_by_area: this.formatDecimals(statistics.fuel_rate_by_area),
                fuel_rate_by_time: this.formatDecimals(statistics.fuel_rate_by_time),
                productivity: this.formatDecimals(statistics.productivity),
                speed: this.formatDecimals(statistics.speed)
            }

            let tmpStatistics = {}
            let tmpOperators = []
            let tmpVehicles = []
            let tmpVarieties = []
            let tmpProducts = []

            tmpOperators = statistics.operators
            tmpVehicles = statistics.vehicles

            tmpStatistics.TOTAL = {
                name: this.$t('OperationsStatisticsBuilder.Totais do Trabalho'),
                stats: [
                    {
                        title: this.$t('OperationsStatisticsBuilder.Área trabalhada'),
                        value: stats.area,
                        symbol: 'ha'
                    }
                ]
            }

            tmpStatistics.PERFORMANCE = {
                name: this.$t('OperationsStatisticsBuilder.Desempenho'),
                stats: [
                    {
                        title: this.$t('OperationsStatisticsBuilder.Velocidade média'),
                        value: stats.speed,
                        symbol: 'km/h'
                    },
                    {
                        title: this.$t('OperationsStatisticsBuilder.Duração'),
                        value: this.getTimeFromSecondsDurationFormatMixin(stats.time * 3600),
                        symbol: ''
                    },
                    {
                        title: this.$t('OperationsStatisticsBuilder.Eficiência operacional'),
                        value: stats.productivity,
                        symbol: 'ha/h'
                    }
                ]
            }

            switch (operationType) {
                case 'SEEDING':
                    tmpStatistics.TOTAL.stats.push({
                            title: this.$t('OperationsStatisticsBuilder.Plantio total'),
                            value: this.formatDecimals(statistics.seeding_total),
                            symbol: 'sementes'
                        },
                        {
                            title: this.$t('OperationsStatisticsBuilder.Taxa de plantio'),
                            value: this.formatDecimals(statistics.seeding_rate),
                            symbol: 'sementes/ha'
                        },
                        {
                            title: this.$t('OperationsStatisticsBuilder.Plantio alvo total'),
                            value: this.formatDecimals(statistics.target_total),
                            symbol: 'sementes'
                        },
                        {
                            title: this.$t('OperationsStatisticsBuilder.Taxa alvo de plantio'),
                            value: this.formatDecimals(statistics.target_rate),
                            symbol: 'sementes/ha'
                        })

                    tmpVarieties = statistics.varieties
                    break
                case 'HARVEST':
                    tmpStatistics.TOTAL.stats.push({
                            title: this.$t('OperationsStatisticsBuilder.Produtividade'),
                            value: this.formatDecimals(statistics.yield),
                            symbol: 't/ha'
                        },
                        {
                            title: this.$t('OperationsStatisticsBuilder.Total colhido'),
                            value: this.formatDecimals(statistics.total_yield),
                            symbol: 't'
                        },
                        {
                            title: this.$t('OperationsStatisticsBuilder.Umidade'),
                            value: this.formatDecimals(statistics.moisture),
                            symbol: '%'
                        },
                        {
                            title: this.$t('OperationsStatisticsBuilder.Rendimento bruto'),
                            value: this.formatDecimals(statistics.wet_weight),
                            symbol: 't/ha'
                        },
                        {
                            title: this.$t('OperationsStatisticsBuilder.Peso bruto'),
                            value: this.formatDecimals(statistics.total_wet_weight),
                            symbol: 't'
                        })

                    tmpStatistics.PERFORMANCE.stats.push({
                            title: this.$t('OperationsStatisticsBuilder.Rendimento (Seco)'),
                            value: this.formatDecimals(statistics.throughput_dry),
                            symbol: 't/h'
                        },
                        {
                            title: this.$t('OperationsStatisticsBuilder.Taxa de colheita'),
                            value: this.formatDecimals(statistics.throughput_wet),
                            symbol: 't/h'
                        })

                    tmpVarieties = statistics.varieties
                    break
                case 'MOWING':
                    tmpVarieties = statistics.varieties
                    break
                case 'DRY_APPLICATION':
                    tmpStatistics.TOTAL.stats.push({
                            title: this.$t('OperationsStatisticsBuilder.Taxa de aplicação'),
                            value: this.formatDecimals(statistics.application_rate),
                            symbol: 'kg/ha'
                        },
                        {
                            title: this.$t('OperationsStatisticsBuilder.Aplicação total'),
                            value: this.formatDecimals(statistics.applied_total),
                            symbol: 'kg'
                        },
                        {
                            title: this.$t('OperationsStatisticsBuilder.Taxa alvo de aplicação'),
                            value: this.formatDecimals(statistics.target_rate),
                            symbol: 'kg/ha'
                        },
                        {
                            title: this.$t('OperationsStatisticsBuilder.Aplicação alvo total'),
                            value: this.formatDecimals(statistics.target_total),
                            symbol: 'kg'
                        },
                        {
                            title: this.$t('OperationsStatisticsBuilder.Pressão'),
                            value: this.formatDecimals(statistics.actual_pressure),
                            symbol: 'kPa'
                        },
                        {
                            title: this.$t('OperationsStatisticsBuilder.Pressão alvo'),
                            value: this.formatDecimals(statistics.target_pressure),
                            symbol: 'kPa'
                        })

                    tmpProducts = statistics.products
                    break
                case 'LIQUID_APPLICATION':
                    tmpStatistics.TOTAL.stats.push({
                            title: this.$t('OperationsStatisticsBuilder.Taxa de aplicação'),
                            value: this.formatDecimals(statistics.application_rate),
                            symbol: 'L/ha'
                        },
                        {
                            title: this.$t('OperationsStatisticsBuilder.Aplicação total'),
                            value: this.formatDecimals(statistics.applied_total),
                            symbol: 'L'
                        },
                        {
                            title: this.$t('OperationsStatisticsBuilder.Taxa alvo de aplicação'),
                            value: this.formatDecimals(statistics.target_rate),
                            symbol: 'L/ha'
                        },
                        {
                            title: this.$t('OperationsStatisticsBuilder.Aplicação alvo total'),
                            value: this.formatDecimals(statistics.target_total),
                            symbol: 'L'
                        },
                        {
                            title: this.$t('OperationsStatisticsBuilder.Pressão'),
                            value: this.formatDecimals(statistics.actual_pressure),
                            symbol: 'kPa'
                        },
                        {
                            title: this.$t('OperationsStatisticsBuilder.Pressão alvo'),
                            value: this.formatDecimals(statistics.target_pressure),
                            symbol: 'kPa'
                        })
                    tmpProducts = statistics.products
                    break
            }

            tmpStatistics.PERFORMANCE.stats.push({
                    title: this.$t('OperationsStatisticsBuilder.Total de combustível'),
                    value: stats.fuel,
                    symbol: 'L'
                },
                {
                    title: this.$t('OperationsStatisticsBuilder.Taxa de combustível (área)'),
                    value: stats.fuel_rate_by_area,
                    symbol: 'L/ha'
                },
                {
                    title: this.$t('OperationsStatisticsBuilder.Taxa de combustível (tempo)'),
                    value: stats.fuel_rate_by_time,
                    symbol: 'L/h'
                })

            return {
                statistics: tmpStatistics,
                operators: tmpOperators,
                vehicles: tmpVehicles,
                varieties: tmpVarieties,
                products: tmpProducts
            }
        }
    }
}
