import axiosAuth from "../../axios/axios-auth"
import jwt_decode from "jwt-decode";

const state = {
    user: {
        access_token: null,
        refresh_token: null,
        expires_in: null,
        username: null,
        roles: null,
        id: null,
        expiration: null,
        language: null,
        name: null,
        user_tenant: null,
        demo: null,
    },
    refreshIntervalId: null,
    loginSSO: false,
    organization_feature_flags: []
};

const getters = {
    user(state) {
        return state.user;
    },
    isAuthenticated(state) {
        return state.user !== null && state.user.access_token != null && state.user.refresh_token != null;
    },
    getUserEmail() {
        if (state.user && state.user.username) {
            return state.user.username;
        } else {
            return null;
        }
    },
    hasAdminRoles() {
        if (!state.user || !state.user.roles || state.user.roles.length === 0) {
            return false;
        }
        let roles = state.user.roles;
        for (let i = 0; i < roles.length; i++) {
            if ('ROLE_SUPER_ADMIN' === roles[i] || 'ROLE_ADMIN' === roles[i]) {
                return true;
            }
        }
        return false;
    },
    isUserDemo() {
        return state.user.demo;
    },

    getOrganizationFeatureFlags() {
        if (!state.organization_feature_flags) {
            return [];
        }
        return state.organization_feature_flags;
    },

    isSSOLogin() {
        return state.loginSSO === true;
    }
};

const mutations = {
    storeSSOLogin(state) {
        state.loginSSO = true;
        localStorage.setItem(process.env.VUE_APP_SSO_LOGIN, JSON.stringify({'sso_login': true}));
    },
    storeUser(state, user) {
        state.user = user;
    },
    storeUserData(state, user) {
        state.user.username = user.username;
    },
    storeOrganizationFeatureFlags(state, organization_feature_flags) {
        state.organization_feature_flags = organization_feature_flags;
        localStorage.setItem(process.env.VUE_APP_ORG_FEATURE_FLAGS, JSON.stringify(organization_feature_flags));
    },
    //limpa o state e o object Storage
    clearAuthData(state) {
        localStorage.removeItem(process.env.VUE_APP_LOCAL_STORAGE);
        localStorage.removeItem(process.env.VUE_APP_LOCAL_STORAGE_CUSTOMERS);
        localStorage.removeItem(process.env.VUE_APP_SSO_LOGIN);
        localStorage.removeItem(process.env.VUE_APP_ORG_FEATURE_FLAGS);
        if (state !== null) {

            state.user = {};

            if(state.refreshIntervalId !== null){
                state.refreshIntervalId=null;
            }

            if(state.loginSSO !== null){
                state.loginSSO = false;
            }

            state.organization_feature_flags = []
        }
    },
    clearPreferencesData(state) {
        localStorage.removeItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_ALERTS_OPTIONS);
    },
    updateLanguage(state, language) {
        state.user.language = language;
    },
    storeAccessTokenMutation(state, data) {

        let decodedJWT = jwt_decode(data.idToken);

        let expiresIn = decodedJWT.exp -  decodedJWT.nbf;

        state.user.access_token = data.idToken;
        state.user.refresh_token = data.idToken;
        state.user.expires_in = expiresIn;
        state.user.expiration = decodedJWT.exp;
    }
};

const actions = {
    storeSSOLogin({commit, dispatch}) {
        commit('storeSSOLogin');
    },
    storeUser({commit, dispatch}, data) {
        commit('storeUser', data);
    },
    storeAccessTokenAction({commit, dispatch}, data) {
        commit('storeAccessTokenMutation', data);
    },
    storeOrganizationFeatureFlags({commit, dispatch}, data) {
        commit('storeOrganizationFeatureFlags', data);
    },
    //tenta logar automatico
    tryAutoLogin({commit, dispatch}) {

        let organizationFeatureFlags = JSON.parse(localStorage.getItem(process.env.VUE_APP_ORG_FEATURE_FLAGS));

        if (!organizationFeatureFlags) {
            commit('clearPreferencesData')
            return;
        }

        commit('storeOrganizationFeatureFlags', organizationFeatureFlags);

        //pegar os valores do obj storage, se o token for valido, popular o user no state
        let data = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE));
        if (data != null) {

            commit('storeUser', {
                access_token: data.access_token,
                refresh_token: data.refresh_token,
                expires_in: data.expires_in,
                username: data.username,
                roles: data.roles,
                id: data.id,
                expiration: data.expiration,
                name: data.name,
                language: data.language,
                user_tenant: data.user_tenant,
                demo: data.demo
            });
        }

        let dataSSO = JSON.parse(localStorage.getItem(process.env.VUE_APP_SSO_LOGIN));

        if (dataSSO) {
            commit('storeSSOLogin');
        }
    },
    logout({commit}) {
        axiosAuth.post('/auth/v1/revokeJwt', {}, {skipAuthRefresh: true})
            .then(() => {
            }).catch(() => {
        }).finally(() => {
            commit('clearAuthData');
        });
    },
    updateLanguage({commit}, data) {
        commit('updateLanguage', data);

    },
};

export default {
    state,
    getters,
    mutations,
    actions
}
