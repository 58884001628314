<template>
    <div>
        <splitpanes :horizontal="is_col_12" class="maps-operations" :dbl-click-splitter="false"
                    @resize="paneSize = $event[1].size">
            <pane :size="isMapOverlap() ? 100 : 100 - paneSize">
                <div style="position: absolute; z-index: 2; left: 30px" :style="{ 'top': top_px + 'px' }">
                    <div class="row justify-content-center">
                        <app-google-maps-type-control :show_first_row="false" ref="googleMapsTypeControl"
                                                      @handleMapControlClick="handleMapControlClick"/>
                    </div>
                </div>
                <div v-if="!isMapOverlap()" :style="{'top': (top_px + 60) + 'px'}"
                     style="position: absolute; z-index: 1; left: 20px">
                    <app-operations-work-options :rate_steps="rateSteps" :map-type="selected_map_type" @edit="editScale"
                                                 @changeVisibility="changeVisibility"/>
                </div>
                <app-google-maps-operations v-if="selected_map_type" ref="googleMapsOperations" :loading="loading"
                                            :google_maps_operations_id="'analyze-operation-maps'" :focused="false"
                                            :position_center="operationMapCenter" :selected_field="selected_field"
                                            :tiles-data="tilesData"/>
            </pane>
            <pane v-if="!isMapOverlap()" style="z-index: 1; background-color: white" :size="paneSize" max-size="45"
                  min-size="18" class="stats">
                <app-operations-stats :stats="statistics" :operators="operators" :varieties="varieties"
                                      :operation="selected_operation" :vehicles="vehicles" :products="products"
                                      :pane_size="paneSize"/>
            </pane>
        </splitpanes>

        <app-operations-rate-steps-edit-dialog :show_edit_dialog="showEditDialog" :rate_steps="rateStepsEdit"
                                               :original-rate-steps="originalRateSteps" :min_value="minValue"
                                               :opacity="opacity" @applyNewScale="applyNewScale"
                                               @hideDialog="closeDialog"/>

        <AppOperationsReportDialog :show-dialog="showReportDialog" :operation="selected_operation"
                                   :selected_map_type="selected_map_type" :operation-map-center="operationMapCenter"
                                   :selected_field="selected_field" :tiles-data="tilesData"
                                   @hideDialog="closeDialog" @generateReport="generateReport"/>

        <AppOperationsReport ref="operationsReport" :operation="selected_operation" :rate_steps="rateSteps"
                             :map_type="selected_map_type" :stats="statistics" :operators="operators"
                             :varieties="varieties" :vehicles="vehicles" :products="products"/>
    </div>
</template>

<script>

import 'splitpanes/dist/splitpanes.css'
import {Pane, Splitpanes} from 'splitpanes'

import dateFormat from "@/mixins/DateMixin";
import buildColorRange from "@/mixins/ColorMixin";
import hexToRgb from "@/mixins/ColorMixin";
import getUserColorsSet from "@/mixins/ColorMixin";
import buildColorBounds from "@/mixins/ColorMixin";
import formatPercent from "@/mixins/ValuesFormat";
import buildStatistics from "@/utils/OperationsStatisticsBuilder";
import {MAPS_OVERLAY_OPEN_WEATHER_TYPES, MAPS_OVERLAY_WEATHER_TYPES} from "../../real-time/AppOptions";
import AppOperationsStats from "@/components/views/agricultureV2/operations/AppOperationsStats";
import AppGoogleMapsTypeControl from "@/components/common/AppGoogleMapsTypeControl";
import AppGoogleMapsOperations from "@/components/views/agricultureV2/operations/AppGoogleMapsOperations";
import AppOperationsWorkOptions from "@/components/views/agricultureV2/operations/AppOperationsWorkOptions";
import AppOperationsRateStepsEditDialog
    from "@/components/views/agricultureV2/operations/AppOperationsRateStepsEditDialog.vue";
import AppOperationsReportDialog from "@/components/views/agricultureV2/operations/AppOperationsReportDialog.vue";
import AppOperationsReport from "@/components/views/agricultureV2/operations/AppOperationsReport.vue"

import OperationsService from "@/services/OperationsService";

export default {
    mixins: [buildColorRange, hexToRgb, formatPercent, buildStatistics, getUserColorsSet, buildColorBounds, dateFormat],
    mounted() {
        this.operationsService = new OperationsService();
        this.top_px = this.isScreenMobile ? 50 : 30;
        this.is_col_12 = this.isScreenMobile;
        this.operationMapCenter = this.field_center;
        this.getStatistics();
    },
    data() {
        return {
            operationsService: null,
            top_px: 30,
            is_col_12: false,
            tilesData: {
                requestId: null,
                zooms: [],
                grouped: false,
                color_bounds: [],
                center_tile: false,
                center: {},
                bounds: []
            },
            operationMapCenter: {},
            operationMapBounds: [],
            tilesZooms: [],
            statistics: {},
            operators: [],
            vehicles: [],
            varieties: [],
            products: [],
            rateSteps: [],
            originalRateSteps: [],
            minValue: 0,
            rateStepsEdit: [],
            loading: false,
            requestId: null,
            layerColorBounds: [],
            opacity: 100,
            paneSize: 18,
            showEditDialog: false,
            showReportDialog: false,
            centerMap: true,
            previousMapEmpty: true
        }
    },
    methods: {
        handleMapControlClick(val) {
            switch (val) {
                case 'roadmap':
                    this.$refs.googleMapsOperations.setUpMapType('roadmap');
                    break;
                case 'hybrid':
                    this.$refs.googleMapsOperations.setUpMapType('hybrid');
                    break;
                case MAPS_OVERLAY_WEATHER_TYPES.PRECIPITATION:
                    this.$refs.googleMapsOperations.setupWeatherOverlay(MAPS_OVERLAY_OPEN_WEATHER_TYPES.PRECIPITATION);
                    break;
                case MAPS_OVERLAY_WEATHER_TYPES.TEMPERATURE:
                    this.$refs.googleMapsOperations.setupWeatherOverlay(MAPS_OVERLAY_OPEN_WEATHER_TYPES.TEMPERATURE);
                    break;
                case MAPS_OVERLAY_WEATHER_TYPES.CLOUDS:
                    this.$refs.googleMapsOperations.setupWeatherOverlay(MAPS_OVERLAY_OPEN_WEATHER_TYPES.CLOUDS);
                    break;
                case MAPS_OVERLAY_WEATHER_TYPES.REMOVE:
                    this.$refs.googleMapsOperations.setupWeatherOverlay(MAPS_OVERLAY_WEATHER_TYPES.REMOVE);
                    break;
                default:
            }
        },
        getStatistics() {
            switch (this.selected_operation.operation_type) {
                case 'SEEDING':
                    this.getOperationStatistics('seedingStatistics')
                    break
                case 'HARVEST':
                    this.getOperationStatistics('harvestStatistics')
                    break
                case 'MOWING':
                    this.getOperationStatistics('mowingStatistics')
                    break
                case 'TILLAGE':
                    this.getOperationStatistics('tillageStatistics')
                    break
                case 'DRY_APPLICATION':
                    this.getOperationStatistics('dryApplicationStatistics')
                    break
                case 'LIQUID_APPLICATION':
                    this.getOperationStatistics('liquidApplicationStatistics')
                    break
            }
        },
        getOperationStatistics(endpoint) {
            this.operationsService.getOperationStatistics(this.selected_operation.id, endpoint)
                .then(response => {
                    this.parseStatistics(this.buildStatistics(response, this.selected_operation.operation_type))
                })
                .catch(() => {
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppOperationsAnalyze.Erro'),
                        detail: this.$t('AppOperationsAnalyze.Não foi possível recuperar as estatísticas desta operação'),
                        life: 5000
                    });
                })
        },
        getFileName() {
            this.tilesData = {}
            this.rateSteps = []

            let config = {
                work_id: this.selected_operation.id,
                ddi: this.selected_map_type.ddi
            }

            this.operationsService.configMbtilesRaster(config)
                .then(response => {
                    this.previousMapEmpty = false
                    this.tilesZooms = response.zooms
                    this.operationMapCenter = response.center
                    this.operationMapBounds = response.bounds
                    this.requestId = response.request_id
                    this.getScale(null)
                })
                .catch(error => {
                    if (error.response && error.response.status === 404) {
                        this.previousMapEmpty = true
                        this.$toast.add({
                            severity: 'error',
                            summary: this.$t('AppOperationsAnalyze.Erro'),
                            detail: this.$t('AppOperationsAnalyze.Não há dados para gerar o mapa desta operação'),
                            life: 5000
                        });
                        return
                    }

                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppOperationsAnalyze.Erro'),
                        detail: this.$t('AppOperationsAnalyze.Houve um erro ao recuperar as informações, Tente novamente'),
                        life: 5000
                    });
                })
        },
        getScale(scaleLimits) {
            let params = this.configParams(scaleLimits)
            this.operationsService.getInitialState(this.requestId, params)
                .then(response => {
                    this.layerColorBounds = response.colors
                    this.processStepRate(response.colors.length, scaleLimits)
                    this.showEditDialog = false
                })
                .catch(() => {
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppOperationsAnalyze.Erro'),
                        detail: this.$t('AppOperationsAnalyze.Não foi possível carregar o mapa'),
                        life: 5000
                    });
                })
        },
        configParams(scaleLimits) {
            if (!scaleLimits) {
                return null
            }

            return {
                num_quantile: scaleLimits.length - 1,
                bounds: JSON.stringify(scaleLimits)
            }
        },
        processStepRate(steps, scaleLimits) {
            let rateStepsTemp = [];
            this.layerColorBounds = this.sortLayerColorBounds()
            let COLORS = scaleLimits ? this.getUserColorsSet(this.rateStepsEdit) : this.setColorRange(steps)
            for (const index in this.layerColorBounds) {
                rateStepsTemp.push({
                    value: this.parseValue(index),
                    color: COLORS[index],
                    percentage: this.formatPercent(this.layerColorBounds[index].area.percent),
                    visible: true,
                    invalid: false
                })
            }
            if (!this.selected_map_type.grouped) {
                this.minValue = this.layerColorBounds[this.layerColorBounds.length - 1].bound.lower
            }
            this.rateSteps = rateStepsTemp;
            if (scaleLimits === null) {
                this.originalRateSteps = JSON.parse(JSON.stringify(rateStepsTemp));
            }
            this.tilesData = {
                requestId: this.requestId,
                zooms: this.tilesZooms,
                grouped: this.selected_map_type.grouped,
                color_bounds: this.buildColorBounds(this.layerColorBounds, COLORS, this.opacity),
                center_tile: this.centerMap,
                center: this.operationMapCenter,
                bounds: this.operationMapBounds
            }
        },
        sortLayerColorBounds() {
            if (this.selected_map_type.grouped && this.selected_map_type.ddi !== 'date') {
                return this.layerColorBounds.sort((a, b) => a.bound.upper.localeCompare(b.bound.upper))
            }

            return this.layerColorBounds.sort((a, b) => b.bound.upper - a.bound.upper || b.bound.lower - a.bound.lower)
        },
        setColorRange(steps) {
            if (this.selected_map_type.grouped) {
                return this.buildColorRange(steps, 50, 250)
            }
            return this.buildColorRange(steps)
        },
        parseValue(index) {
            if (this.selected_map_type.value === 'date') {
                return this.dateFormat(Number.parseInt(this.layerColorBounds[index].bound.upper), 'GMT')
            }

            if (this.selected_map_type.value === 'vehicle') {
                let foundVehicle = this.vehicles.find(vehicle => vehicle.id === this.layerColorBounds[index].bound.upper)
                if (foundVehicle) {
                    return foundVehicle.display_name
                }

                return '---'
            }

            return this.layerColorBounds[index].bound.upper
        },
        parseStatistics(builtStatistics) {
            this.statistics = builtStatistics.statistics
            this.operators = builtStatistics.operators
            this.vehicles = builtStatistics.vehicles
            this.varieties = builtStatistics.varieties
            this.products = builtStatistics.products
        },
        changeVisibility(scaleSteps) {
            let tmpTilesData = JSON.parse(JSON.stringify(this.tilesData))
            let tmpColors = tmpTilesData.color_bounds

            tmpColors.forEach(bound => {
                let boundFound = scaleSteps.find(step => step.value === this.getBoundFromValue(bound.upper))

                if (boundFound) {
                    bound.color = this.hexToRgb(boundFound.color, boundFound.visible ? this.opacity : 0)
                }
            })

            tmpTilesData.center_tile = false
            this.tilesData = tmpTilesData
        },
        getBoundFromValue(upperBound) {
            if (this.selected_map_type.ddi === 'operator' || !this.selected_map_type.grouped) {
                return upperBound
            }

            if (this.selected_map_type.grouped) {
                if (this.selected_map_type.ddi === 'date') {
                    return this.dateFormat(Number.parseInt(upperBound), 'GMT');
                }

                if (this.selected_map_type.ddi === 'vehicle') {
                    let foundVehicle = this.vehicles.find(vehicle => vehicle.id === upperBound)
                    return foundVehicle ? foundVehicle.display_name : '---'
                }
            }
        },
        editScale() {
            this.rateStepsEdit = JSON.parse(JSON.stringify(this.rateSteps))
            this.showEditDialog = true
        },
        isMapOverlap() {
            if (!this.selected_map_type) {
                return
            }

            return this.selected_map_type.value === 'overlap'
        },
        applyNewScale(rateSteps, opacity) {
            this.rateStepsEdit = JSON.parse(JSON.stringify(rateSteps))

            let newScale = []
            this.rateStepsEdit.forEach(step => {
                newScale.push(step.value)
            })
            newScale.push(this.minValue)

            this.opacity = opacity
            this.getScale(newScale)
        },
        closeDialog() {
            this.showEditDialog = false
            this.showReportDialog = false
        },
        openReportDialog() {
            this.showReportDialog = true
        },
        generateReport() {
            this.$refs.operationsReport.generateReport();
            this.closeDialog();
        },
        initGetOperationMap() {
            this.centerMap = this.previousMapEmpty
            this.getFileName()
        }
    },
    watch: {
        selected_operation: function () {
            this.previousMapEmpty = true
            this.getStatistics();
            if (this.selected_map_type === this.map_types[0]) {
                this.initGetOperationMap()
            }
        },
        selected_map_type: function () {
            this.initGetOperationMap()
        }
    },
    computed: {
        isScreenMobile: function () {
            return window.screen.width <= 1024;
        }
    },
    props: {
        selected_operation: {
            type: Object
        },
        selected_map_type: {
            type: Object
        },
        map_types: {
            type: Array
        },
        operations: {
            type: Array
        },
        field_center: {
            type: Object
        },
        selected_field: {
            type: Object
        },
        selected_org: {
            type: Object
        }
    },
    components: {
        AppOperationsReportDialog,
        Pane,
        Splitpanes,
        AppOperationsStats,
        AppGoogleMapsOperations,
        AppGoogleMapsTypeControl,
        AppOperationsWorkOptions,
        AppOperationsRateStepsEditDialog,
        AppOperationsReport
    }
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/constants";

.splitpanes__pane.stats {
    padding: 10px;
    border-radius: 5px;
    overflow-y: auto;
}

.splitpanes__pane.stats::-webkit-scrollbar {
    width: 10px;
}

.splitpanes__pane.stats::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(98, 95, 95, 0.8);
}

.splitpanes__pane.stats::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 2px rgba(98, 95, 95, 0.7);
}

.splitpanes__pane.stats::-webkit-scrollbar-thumb:hover {
    -webkit-box-shadow: inset 0 0 5px rgba(98, 95, 95, 0.7);
}

.p-slider.p-slider-horizontal {
    height: 0.5rem;
    cursor: pointer;
}

</style>
