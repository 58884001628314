import axios from '../axios/axios-auth'

export default class OperationsService {

    /**
     * V3
     **/
    getOperations(fieldId) {
        return axios.get(`/api/v3/fields/${fieldId}/operations`).then(response => response.data);
    }

    getOperationStatistics(operationId, endpoint) {
        return axios.get(`/api/v3/operations/${operationId}/${endpoint}`).then(response => response.data);
    }

    configMbtilesRaster(config) {
        return axios.post(`https://api3.iotag.com.br/track-tiles-service/v1/getMbtilesRaster`, config).then(response => response.data);
    }

    getInitialState(requestId, params) {
        return axios.get(`https://api3.iotag.com.br/track-tiles-service/v1/operationColors/${requestId}`, {params: params}).then(response => response.data);
    }

}
