<template>
    <form @submit.prevent>
        <div class="kt-portlet__body">
            <div class="form-group row">
                <h2 v-if="vehicle_edit" class="title col-12">{{ $t('AppVehiclesForm.Editar Veículo') }}</h2>
                <h2 v-else class="title col-12">{{ $t('AppVehiclesForm.Cadastrar Veículo') }}</h2>
            </div>
            <div class="form-group row">
                <label>{{ $t('AppVehiclesForm.Identificação do Veículo') }}</label>
                <app-input v-model="$v.vehicle.identification.$model"
                           :class="{'is-invalid' : $v.vehicle.identification.$error && $v.vehicle.identification.$dirty}"/>
            </div>
            <div class="form-group row">
                <label>{{ $t('AppVehiclesForm.Fabricante do Veículo') }}</label><br>
                <Dropdown v-model="vehicle.manufacturer" :options="manufacturers" optionLabel="name"
                          :filter="true" :filterPlaceholder="$t('AppVehiclesForm.Procurar')"
                          @change="changeManufacturer"
                          :placeholder="$t('AppVehiclesForm.Selecione o fabricante')" :showClear="true"
                          :dataKey="'id'"
                          style="width: 100%;"
                          :class="{'p-invalid' : $v.vehicle.manufacturer.$error && $v.vehicle.manufacturer.$dirty}">
                    <template #value="slotProps">
                        <div class="p-dropdown-car-value" v-if="slotProps.value">

                            <span>{{ slotProps.value.name }}</span>
                        </div>
                        <span v-else>
                           {{ slotProps.placeholder }}
                    </span>
                    </template>
                    <template #option="slotProps">
                        <div class="p-dropdown-car-option">
                            <span>{{ slotProps.option.name }}</span>
                        </div>
                    </template>
                </Dropdown>
            </div>
            <div class="form-group row">
                <label>{{ $t('AppVehiclesForm.Modelo do Veículo') }}</label><br>
                <Dropdown v-model="vehicle.model" :options="modelsVehicles" :filter="true"
                          :filterPlaceholder="$t('AppVehiclesForm.Procurar')" dataKey="id" style="width: 100%"
                          :placeholder="$t('AppVehiclesForm.Selecione')" :showClear="true" optionLabel="value"
                          :emptyFilterMessage="$t('AppVehiclesForm.Não há modelos com este filtro')"
                          :class="{'p-invalid' : $v.vehicle.model.$error && $v.vehicle.model.$dirty}">
                    <template #value="slotProps">
                        <div class="p-dropdown-car-value" v-if="slotProps.value">
                            <span>{{ slotProps.value.name }}</span>
                        </div>
                        <span v-else>
                           {{ slotProps.placeholder }}
                    </span>
                    </template>
                    <template #option="slotProps">
                        <div class="p-dropdown-car-option">
                            <span>{{ slotProps.option.name }}</span>
                        </div>
                    </template>
                </Dropdown>
            </div>
            <div class="form-group row">
                <label>{{ $t('AppVehiclesForm.Ano de Fabricação') }}</label><br>

                <Dropdown v-model="vehicle.year" :options="years" :placeholder="$t('AppVehiclesForm.Selecione')"
                          style="width: 100%;" :filter="true" :filterPlaceholder="$t('AppVehiclesForm.Procurar')"
                          :class="{'p-invalid' : $v.vehicle.year.$error && $v.vehicle.year.$dirty}"/>
            </div>
            <div class="form-group row">
                <app-button v-if="!vehicle.unavailability_date" class="col-12 col-lg-3 btn-sucess size mt-2"
                            type="secondary" @handleClick="makeUnavailable">
                    {{ $t('AppVehiclesForm.Tornar Indisponível') }}
                </app-button>
                <app-button v-else class="col-12 col-lg-3 btn-sucess size mt-2" type="secondary"
                            @handleClick="makeAvailable">
                    {{ $t('AppVehiclesForm.Tornar Disponível') }}
                </app-button>
                <app-button class="col-12 col-lg-3 ml-lg-auto btn-sucess size mt-2" type="primary"
                            @handleClick="checkForm" v-if="vehicle_edit">{{ $t('AppVehiclesForm.Atualizar') }}
                </app-button>
                <app-button class="col-12 col-lg-3 ml-lg-auto btn-sucess size mt-2" type="primary"
                            @handleClick="checkForm" v-else>{{ $t('AppVehiclesForm.Salvar') }}
                </app-button>
            </div>
        </div>
    </form>
</template>

<script>
import {router} from '../../../router';

import AppButton from '../../common/AppButton'
import AppInput from '../../common/AppInput';
import axios from "../../../axios/axios-auth";
import {mapGetters} from "vuex";
import {required} from 'vuelidate/lib/validators';
import VehiclesService from "@/services/VehiclesService";

import Dropdown from 'primevue/dropdown';

export default {
    beforeMount() {
        this.vehiclesService = new VehiclesService();
        this.getManufacturers();
    },
    data() {
        return {
            organizations: [],
            show_modal_machine_group: false,
            errors: [],
            vehicles_group: [],
            vehicle: {
                id: '',
                identification: '',
                manufacturer: '',
                model: {},
                year: '',
                unavailability_date: null
            },
            manufacturers: [],
            modelsVehicles: [],
            vehiclesService: null,
        }
    },
    props: {
        vehicle_edit: {
            type: Object
        }
    },
    components: {
        AppButton, AppInput, Dropdown
    },
    watch: {
        async vehicle_edit(val) {
            this.vehicle.id = val.id;
            this.vehicle.identification = val.identification;
            this.vehicle.unavailability_date = val.unavailability_date

            // Find manufacturer
            const manufacturer = this.manufacturers.find(manufacturer => manufacturer.value === val.manufacturer || manufacturer.name.toLowerCase() === val.manufacturer.toLowerCase());
            if (manufacturer) {
                this.vehicle.manufacturer = manufacturer;
            }

            await this.changeManufacturer();

            // Find model
            const model = this.modelsVehicles.find(model => model.id === val.model.name || model.name.toLowerCase() === val.model.name.toLowerCase());
            if (model) {
                this.vehicle.model = model;
            }

            this.vehicle.year = val.year;
        },
        'vehicle.manufacturer': function (val, oldVal) {
            //n limpa quando carrega a tela
            if (typeof oldVal !== 'string') {
                this.vehicle.model = '';
            }
        },
        manufacturers: function (val) {
            this.vehicle.manufacturer = this.findManufacturer(this.vehicle.manufacturer);
        }
    },
    methods: {
        makeUnavailable() {
            console.log(this.vehicle)
            this.vehiclesService.makeUnavailable(this.vehicle.id).then((result) => {
                this.$toast.add({
                    severity: 'success',
                    summary: this.$t('AppVehiclesForm.Veículo Indisponível'),
                    life: 5000
                });
                this.goToVehicles();
            }).catch((error) => {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppVehiclesForm.Não foi possível realizar a operação'),
                    detail: this.$t('AppVehiclesForm.Tente novamente mais tarde'),
                    life: 3000
                });
            })
        },
        makeAvailable() {
            this.vehiclesService.makeAvailable(this.vehicle.id).then((result) => {
                this.$toast.add({
                    severity: 'success',
                    summary: this.$t('AppVehiclesForm.Veículo Disponível'),
                    life: 5000
                });
                this.goToVehicles();
            }).catch((error) => {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppVehiclesForm.Não foi possível realizar a operação'),
                    detail: this.$t('AppVehiclesForm.Tente novamente mais tarde'),
                    life: 3000
                })
            })
        },
        async changeManufacturer() {
            if (!this.vehicle.manufacturer) {
                return;
            }

            try {
                let response = await axios.get(`/api/v1/manufacturers/vehicles/${this.vehicle.manufacturer.id}`);
                if (response.status === 200 && response.data.models.length > 0) {
                    this.modelsVehicles = this.sortArrayByName(response.data.models);
                }
            } catch (error) {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppVehiclesForm.Não foi possível recuperar a lista de modelos'),
                    life: 5000
                });
            }
        },
        goToVehicles() {
            router.push({name: 'vehicles'})
        },
        checkForm() {
            this.$v.vehicle.$touch();
            if (!this.$v.vehicle.$invalid) {
                this.handleSalve();
            } else {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppVehiclesForm.Por favor preencha todos os campos obrigatórios'),
                    life: 5000
                });
            }
        },
        handleSalve() {
            let temp = JSON.parse(JSON.stringify(this.vehicle));
            temp.manufacturer = temp.manufacturer.id;

            this.$emit('handleSalve', temp)
        },
        getManufacturers() {
            let vm = this;
            axios.get('/api/v3/machine/manufacturers')
                .then(function (response) {
                    if (response.status === 200 && response.data.length > 0) {
                        vm.manufacturers = vm.sortArrayByName(response.data);
                    }
                }).catch(function (error) {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppVehiclesForm.Não foi possivel recuperar a lista de fabricante'),
                    life: 5000
                });
            })
        },
        findManufacturer(value) {
            for (let i = 0; i < this.manufacturers.length; i++) {
                if (this.manufacturers[i].value === value) {
                    return this.manufacturers[i];
                }
            }
        },
        sortArrayByName(values) {
            try {
                return values.sort((a, b) => a.name.localeCompare(b.name));
            } catch (e) {
                console.log(e);
                return values;
            }
        },
    },
    computed: {
        years: function () {
            let years = [];
            let current_year = new Date().getFullYear();
            //pega o ano atual e ate 20 anos
            let MIN = current_year - 20;
            for (let i = current_year; i > MIN; i--) {
                years.push(i)
            }
            return years;
        }
    },
    validations: {
        vehicle: {
            identification: {required},
            manufacturer: {required},
            model: {required},
            year: {required},
        }
    },

}
</script>

<style scoped type="scss">
.title {
    border-bottom: 0.5px #666666 solid;
    color: #666666;
    padding-bottom: 15px;
    padding-top: 5px;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
}
</style>
