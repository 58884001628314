<template>
    <Dialog :visible.sync="display" :style="{width: '400px'}"
            :header="$t('AppOperationsRateStepsEditDialog.Editar legenda')" modal @hide="closeDialog"
            :contentStyle="{'overflow': 'visible'}">
        <div class="d-flex flex-column">
            <div class="d-flex justify-content-center">
                <div>
                    <div v-for="(step, index) in rateStepsEdit">
                        <div class="d-flex"
                             :class="[(index === 0 && rateStepsEdit.length > 1) ? 'justify-content-end' : 'justify-content-center']">
                            <InputNumber v-show="index !== 0 || (index === 0 && rateStepsEdit.length === 1)"
                                         :disabled="index === 0 && rateStepsEdit.length === 1"
                                         v-model="step.value" mode="decimal" class="bound-input" :useGrouping="false"
                                         :class="{'p-invalid': step.invalid && index !== 0}" :maxFractionDigits="3"
                                         :min="0" @keyup.enter.native="$event.target.blur()" @blur="setValue(index)"/>
                            <div class="work-color" style="border-bottom-width: 0"
                                 :style="{borderColor: step.color, borderTopWidth: (index === 0 && rateStepsEdit.length > 1) ? '0' : '4px'}">
                                <ColorPicker v-model="step.color" @input="setColor($event, index)"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column justify-content-between ml-4">
                    <Button icon="pi pi-plus" class="p-button-secondary mb-1" @click="handleStepChange('add')"
                            :disabled="rateStepsEdit.length >= 11"
                            v-tooltip.top="$t('AppOperationsRateStepsEditDialog.Adicionar intervalo')"/>
                    <Button icon="pi pi-minus" class="p-button-secondary" @click="handleStepChange('remove')"
                            :disabled="rateStepsEdit.length <= 1"
                            v-tooltip.top="$t('AppOperationsRateStepsEditDialog.Remover intervalo')"/>
                </div>
            </div>
            <div class="mx-5 my-3">
                <div class="mt-4 mb-2">{{ $t('AppOperationsRateStepsEditDialog.Opacidade') }}: {{ opacityEdit }}%</div>
                <Slider v-model="opacityEdit"/>
            </div>
        </div>

        <div class="mt-4 d-flex justify-content-between">
            <div>
                <Button @click="applyOriginalScale" :label="$t('AppOperationsRateStepsEditDialog.Restaurar escala')"
                        class="p-button-secondary"
                        v-tooltip.bottom="$t('AppOperationsRateStepsEditDialog.Aplica os valores padrões da escala')"/>
                <Button @click="distributeEvenly" :label="$t('AppOperationsRateStepsEditDialog.Distribuir')"
                        class="p-button-secondary ml-1"
                        v-tooltip.bottom="$t('AppOperationsRateStepsEditDialog.Distribui os valores uniformemente entre os valores mínimo e máximo configurados na escala')"/>
            </div>
            <Button icon="pi pi-check" @click="applyNewScale" :label="$t('AppOperationsRateStepsEditDialog.Salvar')"
                    class="p-button-info"/>
        </div>
    </Dialog>
</template>

<script>
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import InputNumber from "primevue/inputnumber";
import Tooltip from "primevue/tooltip";
import Slider from "primevue/slider";
import ColorPicker from "primevue/colorpicker";

import buildColorRange from "@/mixins/ColorMixin";
import formatDecimals from "@/mixins/ValuesFormat";

export default {
    mixins: [buildColorRange, formatDecimals],
    data() {
        return {
            opacityEdit: 100,
            rateStepsEdit: [],
            display: false
        }
    },
    methods: {
        setValue(index) {
            this.rateStepsEdit.forEach((step) => step.invalid = false)
            let len = this.rateStepsEdit.length - 1;

            for (let i = len; i >= 1; i--) {
                for (let ii = i - 1; ii >= 0; ii--) {
                    if (this.rateStepsEdit[ii].value <= this.rateStepsEdit[i].value) {
                        if (ii === 0) {
                            continue
                        }
                        this.rateStepsEdit[i].invalid = true
                    }
                }
            }

            if (this.rateStepsEdit[len].value === 0) {
                this.rateStepsEdit[len].invalid = true
            }
        },
        setColor(event, index) {
            this.rateStepsEdit[index].color = '#' + event
        },
        handleStepChange(type) {
            let tmpRateStepsEdit = JSON.parse(JSON.stringify(this.rateStepsEdit))
            let currentSteps = tmpRateStepsEdit.length
            let newSteps = type === 'add' ? currentSteps + 1 : currentSteps - 1
            let editColors = this.buildColorRange(newSteps)

            let maxValue = tmpRateStepsEdit[0].value
            let decreaseValue = (maxValue - this.min_value) / newSteps
            if (type === 'add') {
                tmpRateStepsEdit.push({
                    visible: true,
                    percentage: '0',
                    color: '',
                    value: 0,
                    invalid: false
                })
            } else {
                tmpRateStepsEdit.pop()
            }

            for (let i in tmpRateStepsEdit) {
                tmpRateStepsEdit[i].value = parseFloat(this.formatDecimals(maxValue - (decreaseValue * i), 3, false))
                tmpRateStepsEdit[i].color = editColors[i]
            }
            this.rateStepsEdit = tmpRateStepsEdit
        },
        distributeEvenly() {
            if (this.zeroAsFirstValue()) {
                return;
            }

            if (this.rateStepsEdit.length < 3) {
                return
            }

            let tmpRateStepsEdit = JSON.parse(JSON.stringify(this.rateStepsEdit))
            let maxValue = tmpRateStepsEdit[1].value
            let visibleSteps = tmpRateStepsEdit.length - 1

            let decreaseValue = (maxValue - tmpRateStepsEdit[visibleSteps].value) / (visibleSteps - 1)

            for (let i in tmpRateStepsEdit) {
                if (i === '0') {
                    continue;
                }
                tmpRateStepsEdit[i].value = parseFloat(this.formatDecimals(maxValue - (decreaseValue * (i - 1)), 3, false))
            }
            this.rateStepsEdit = tmpRateStepsEdit
            this.rateStepsEdit.forEach((step) => step.invalid = false)
        },
        applyOriginalScale() {
            this.rateStepsEdit = JSON.parse(JSON.stringify(this.originalRateSteps))
        },
        applyNewScale() {
            let invalidScale = this.rateStepsEdit.some((step) => step.invalid)

            if (invalidScale) {
                if (this.zeroAsFirstValue()) {
                    return;
                }

                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppOperationsRateStepsEditDialog.Erro'),
                    detail: this.$t('AppOperationsRateStepsEditDialog.Há valores inválidos na escala'),
                    life: 5000
                });
                return
            }

            this.$emit('applyNewScale', this.rateStepsEdit, this.opacityEdit)
        },
        zeroAsFirstValue() {
            if (this.rateStepsEdit[this.rateStepsEdit.length - 1].value <= 0) {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppOperationsRateStepsEditDialog.Erro'),
                    detail: this.$t('AppOperationsRateStepsEditDialog.Não é possível aplicar valor zero na escala'),
                    life: 5000
                });
                return true;
            }

            return false;
        },
        closeDialog() {
            this.display = false;
            this.$emit('hideDialog');
        }
    },
    props: {
        show_edit_dialog: {
            type: Boolean,
            required: true
        },
        originalRateSteps: {
            type: Array
        },
        rate_steps: {
            type: Array
        },
        opacity: {
            type: Number
        },
        min_value: {
            type: Number
        }
    },
    watch: {
        show_edit_dialog: function (val) {
            this.display = val;
            if (val) {
                this.rateStepsEdit = this.rate_steps;
                this.opacityEdit = this.opacity;
            }
        }
    },
    directives: {
        'tooltip': Tooltip
    },
    components: {
        Dialog, Button, InputNumber, Tooltip, Slider, ColorPicker
    }
}
</script>

<style scoped>
.work-color {
    min-height: 80%;
    max-width: 2rem;
    margin-top: 0;
    margin-left: 3px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    overflow: visible;
}

.work-color > .p-colorpicker > .p-inputtext {
    border-radius: 0 !important;
}

.work-color > .p-colorpicker {
    margin-top: 0 !important;
}

.bound-input {
    max-width: 70px;
    height: 2rem;
    padding-bottom: 0.5rem;
}
</style>
