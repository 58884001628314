<template>
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile" id="tableDiv">
            <!-- Versão Desktop -->
            <div v-if="!showDialogFilters" class="kt-portlet__head kt-portlet__head--lg no-border-radius">
                <app-filter-bar @datesAndFiltersHandler="requestDataFromEvents"
                                :filter_button_is_loading="isLoading"
                                :feature="'ANALYSIS'"
                                @buttonSetClicked="buttonSetClicked">
                    <template v-slot:slot-two>
                        <div class="col items-right-desktop">
                            <div class="margin-items-desktop">
                                <div v-show="showGridStack && !showMenuButtons" class="menu-panel">
                                    <div style="display: block;">
                                        <div @click="dropdownMenu = !dropdownMenu"
                                             style="background-color: #3CBEB4; width: 80px;"
                                             id="config-menu" class="text-center button-graphics">
                                            <i v-show="!loadingPdf" class="pi pi-cog icon-graphic"/>
                                            <i v-show="loadingPdf" class="pi pi-spin pi-spinner icon-graphic"/>
                                            <i class="pi pi-chevron-down icon-down"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </app-filter-bar>
                <Dropdown v-model="selectedModel" v-show="showGridStack && !showMenuButtons" :options="modelOptions"
                          optionLabel="name" :disabled="noValue()"
                          :placeholder="$t('AppAnalysis.Selecione um Template')" class="dropdown-style"
                          style="position: relative; right: 170px;"/>
            </div>

            <!-- Conteúdo do Dropdown, usado para a versão Desktop e versão mobile -->
            <div v-show="dropdownMenu" class="dropdown-menu-style" id="content-config">
                <div @click="operationModel('save as')" style="display: flex;" class="hover-menu-item">
                    <div class="text-center button-graphics">
                        <i class="pi pi-save icon-graphic"/>
                    </div>
                    <span class="text-item-menu">{{ $t('AppAnalysis.Salvar Como') }}</span>
                </div>

                <div @click="operationModel('save')" style="display: flex;" class="hover-menu-item">
                    <div class="text-center button-graphics">
                        <i class="pi pi-save icon-graphic"/>
                    </div>
                    <span class="text-item-menu">{{ $t('AppAnalysis.Salvar') }}</span>
                </div>

                <div @click="operationModel('create')" style="display: flex;" class="hover-menu-item">
                    <div class="text-center button-graphics">
                        <i class="pi pi-plus icon-graphic"/>
                    </div>
                    <span class="text-item-menu">{{ $t('AppAnalysis.Novo Template') }}</span>
                </div>

                <div v-if="!firstTemplate" @click="operationModel('edit')" style="display: flex;"
                     class="hover-menu-item">
                    <div class="text-center button-graphics">
                        <i class="pi pi-pencil icon-graphic"/>
                    </div>
                    <span class="text-item-menu">{{ $t('AppAnalysis.Editar Nome') }}</span>
                </div>

                <div v-if="!firstTemplate" @click="operationModel('delete')" style="display: flex;"
                     class="hover-menu-item">
                    <div class="text-center button-graphics">
                        <i class="pi pi-trash icon-graphic"/>
                    </div>
                    <span class="text-item-menu">{{ $t('AppAnalysis.Excluir Template') }}</span>
                </div>

                <div @click="generateReport()" style="display: flex;" class="hover-menu-item">
                    <div class="text-center button-graphics">
                        <i class="pi pi-file-pdf icon-graphic"/>
                    </div>
                    <span class="text-item-menu">{{ $t('AppAnalysis.Exportar Template') }}</span>
                </div>
            </div>

            <!-- Versão Mobile -->
            <div v-if="showDialogFilters" class="kt-portlet__head kt-portlet__head--lg no-border-radius"
                 style="justify-content: center;">
                <app-button v-show="showDialogFilters" type="primary" class="mx-1 auto-center"
                            @handleClick="openMaximizable"
                            :class="{ 'kt-spinner kt-spinner--v2 kt-spinner--right kt-spinner--sm kt-spinner--dark': isLoading }">
                    {{ $t('AppAnalysis.Filtrar') }}
                </app-button>

                <div style="display: block; margin-top: 12px;">
                    <div @click="dropdownMenu = !dropdownMenu" v-show="showGridStack" id="config-menu-mobile"
                         class="text-center button-graphics menu-mobile">
                        <i v-show="!loadingPdf" class="pi pi-cog"
                           style="font-size: 20px; margin-top: 7px; color: white;"/>
                        <i v-show="loadingPdf" class="pi pi-spin pi-spinner"
                           style="font-size: 20px; margin-top: 7px; color: white;"/>
                    </div>
                </div>

                <Dropdown v-model="selectedModel" v-show="showGridStack" :options="modelOptions" optionLabel="name"
                          :disabled="noValue()"
                          :placeholder="$t('AppAnalysis.Selecione um Template')" class="dropdown-style"
                          style="margin-left: 3px;"/>

            </div>
            <Dialog :header="$t('AppAnalysis.Filtrar')" :visible.sync="displayMaximizable" :containerStyle="{width: '85vw'}"
                    :maximizable="true" :modal="true">
                <app-filter-bar @datesAndFiltersHandler="requestDataFromEvents"
                                :filter_button_is_loading="isLoading"
                                :feature="'ANALYSIS'"
                                :is_col_12="true"
                                @buttonSetClicked="buttonSetClicked">
                </app-filter-bar>
            </Dialog>

            <!-- Buttons para adicionar Gráficos ao Template -->
            <div v-show="showGridStack" class="submenu-analysis submenu-panel"
                 style="filter: blur(10px); pointer-events: none;">
                <div style="display: flex; justify-content: center;">
                    <div class="add-graphic">
                        <span class="text-add">{{ $t('AppAnalysis.Adicionar') }}</span>
                    </div>

                    <div @click="addNewWidget('Info')" v-tooltip.bottom="$t('AppAnalysis.Gráfico de Informação')"
                         style="background-color: #3CBEB4;"
                         class="text-center button-graphics">
                        <i class="pi pi-info-circle icon-graphic"/>
                    </div>

                    <div @click="addNewWidget('Pizza')" v-tooltip.bottom="$t('AppAnalysis.Gráfico de Pizza')"
                         style="background-color: #3CBEB4;"
                         class="text-center button-graphics">
                        <i class="pi pi-chart-pie icon-graphic"/>
                    </div>

                    <div @click="addNewWidget('Tubo')" v-tooltip.bottom="$t('AppAnalysis.Gráfico de Coluna')"
                         style="background-color: #3CBEB4;"
                         class="text-center button-graphics">
                        <i class="pi pi-chart-bar icon-graphic"/>
                    </div>

                    <div @click="addNewWidget('Gráfico')" v-tooltip.bottom="$t('AppAnalysis.Gráfico de Linha')"
                         style="background-color: #3CBEB4;"
                         class="text-center button-graphics">
                        <i class="pi pi-chart-line icon-graphic"/>
                    </div>
                    <div v-show="showMenuButtons && !showDialogFilters" style="display: flex; justify-content: center;">
                        <Dropdown v-model="selectedModel" v-show="showGridStack" :options="modelOptions"
                                  optionLabel="name" :disabled="noValue()"
                                  :placeholder="$t('AppAnalysis.Selecione um Template')" class="dropdown-style"
                                  appendTo="tableDiv"/>
                        <div style="display: block;">
                            <div @click="dropdownMenu = !dropdownMenu" style="background-color: #3CBEB4; width: 80px;"
                                 id="config-menu-responsive" class="text-center button-graphics">
                                <i v-show="!loadingPdf" class="pi pi-cog icon-graphic"/>
                                <i v-show="loadingPdf" class="pi pi-spin pi-spinner icon-graphic"/>
                                <i class="pi pi-chevron-down icon-down"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Avisos e dicas para o usuário -->
            <div v-show="noValue() && !showDialogFilters" class="div-tutorial"
                 style="width: 450px; position: fixed; top: 50vh; left: calc(50% + 70px); transform: translate(-50%, -50%);">
                <h1>{{ $t('AppAnalysis.Filtre os Dados') }}</h1>
                <p style="font-size: 16px;">{{$t('AppAnalysis.Selecione os veículos e datas desejados e clique no botão Filtrar')}} .</p>
            </div>

            <div v-show="noValue() && showDialogFilters" class="div-tutorial"
                 style="width: 80vw; position: fixed; top: 50vh; left: calc(50%); transform: translate(-50%, -50%);">
                <h1> {{ $t('AppAnalysis.Filtre os Dados') }}</h1>
                <p style="font-size: 16px;">
                    {{ $t('AppAnalysis.Selecione os veículos e datas desejados e clique no botão Filtrar') }}.</p>
            </div>

            <div v-show="showDivTutorial() && !showDialogFilters" class="div-tutorial"
                 style="width: 300px; margin: 10px auto 0px auto;">
                <i style="font-size: 25px; margin-bottom: 10px;" class="pi pi-arrow-up"/>
                <h2> {{ $t('AppAnalysis.Adicione os Gráficos') }}</h2>
                <p style="font-size: 16px;">
                    {{ $t('AppAnalysis.Selecione o tipo de gráfico que deseja adicionar ao Template') }}.</p>
            </div>

            <div v-show="showDivTutorial() && modelOptions.length === 1 && !showMenuButtons" class="div-tutorial"
                 style="width: 300px; margin: 20px 0px 0px auto; position: relative; bottom: 270px; right: 115px; z-index: 0 !important;">
                <i style="font-size: 25px; margin: 15px 0px 10px 0px;" class="pi pi-arrow-up"/>
                <h2>{{ $t('AppAnalysis.Gerenciamento dos Templates') }}</h2>
                <p style="font-size: 16px;">{{$t('AppAnalysis.Você pode selecionar outro template, criar, excluir, editar e exportar para PDF') }}</p>
            </div>

            <!-- GridStack -->
            <div>
                <div v-show="showGridStack" class="grid-stack" @change="verificarClique"
                     style="margin: 0px 10px 50px 0px; filter: blur(10px); pointer-events: none;" id="tableDiv"></div>
            </div>

            <!-- Button para alternar entre visualização da tabela e GridStack -->
            <div @click="buttonSetClicked(4)" v-show="showGridStack" v-tooltip.left="$t('AppAnalysis.Analítico')"
                 class="text-center button-graphics change-mode1">
                <i class="pi pi-align-justify icon-graphic"></i>
            </div>

            <div @click="buttonSetClicked(1)" v-show="!showGridStack" v-tooltip.left="$t('AppAnalysis.Estatísticas')"
                 class="text-center button-graphics change-mode2">
                <i class="pi pi-chart-line icon-graphic"></i>
            </div>

            <!-- Dialogs com perguntas para o usuário -->
            <Dialog :header="$t('AppAnalysis.Deletar Template')" :containerStyle="{width: '400px'}" :visible.sync="showDeleteModel"
                    :closable="false" :modal="true">
                <p class="m-0">{{ $t('AppAnalysis.Deseja deletar este Template?') }}</p>
                <template #footer>
                    <Button @click="showDeleteModel = false" :label="$t('AppAnalysis.Não')" class="p-button-text"/>
                    <Button @click="deleteModel()" :label="$t('AppAnalysis.Sim')" autofocus/>
                </template>
            </Dialog>

            <Dialog :header="$t('AppAnalysis.Criar Template')" :containerStyle="{width: '400px'}"
                    :visible.sync="showCreateModel" :closable="false" :modal="true">
                <p class="m-0">{{ $t('AppAnalysis.Digite um título para o Template') }} </p>
                <InputText class="input-text-dialog" type="text" v-model="nameModel"/>
                <template #footer>
                    <Button @click="showCreateModel = false" :label="$t('AppAnalysis.Cancelar')" class="p-button-text"/>
                    <Button @click="newModel()" :label="$t('AppAnalysis.Criar')" autofocus/>
                </template>
            </Dialog>

            <Dialog :header="$t('AppAnalysis.Salvar Template')" :containerStyle="{width: '400px'}"
                    :visible.sync="showSaveAsModel" :closable="false" :modal="true">
                <p class="m-0">{{ $t('AppAnalysis.Digite o título do Template') }} </p>
                <InputText class="input-text-dialog" type="text" v-model="nameModel"/>
                <template #footer>
                    <Button @click="saveAsModelFalse()" :label="$t('AppAnalysis.Cancelar')" class="p-button-text"/>
                    <Button @click="saveAsModel()" :label="$t('AppAnalysis.Salvar')" autofocus/>
                </template>
            </Dialog>

            <Dialog :header="$t('AppAnalysis.Deseja salvar antes de sair?')" :containerStyle="{width: '400px'}"
                    :visible.sync="showExitSave" :modal="true">
                <p class="m-0">{{ $t('AppAnalysis.Suas alterações serão perdidas se não salvá-las') }} .</p>
                <template #footer>
                    <Button @click="exitAnalysis(false)" :label="$t('AppAnalysis.Não salvar')" class="p-button-text"/>
                    <Button @click="exitAnalysis(true)" :label="$t('AppAnalysis.Salvar')" autofocus/>
                </template>
            </Dialog>

            <Dialog :header="$t('AppAnalysis.Deseja salvar antes de sair?')" :containerStyle="{width: '400px'}"
                    :closable="false" :visible.sync="showExitTemplate" :modal="true">
                <p class="m-0">{{ $t('AppAnalysis.Suas alterações serão perdidas se não salvá-las') }}.</p>
                <template #footer>
                    <Button @click="exitTemplate(false)" :label="$t('AppAnalysis.Não salvar')" class="p-button-text"/>
                    <Button @click="exitTemplate(true)" :label="$t('AppAnalysis.Salvar')" autofocus/>
                </template>
            </Dialog>

            <Dialog :header="$t('AppAnalysis.Editar nome do Template')" :containerStyle="{width: '400px'}"
                    :closable="false" :visible.sync="showEditTemplate" :modal="true">
                <p class="m-0">{{ $t('AppAnalysis.Digite um título para o Template') }}</p>
                <InputText class="input-text-dialog" type="text" v-model="nameModel"/>
                <p style="font-weight: bold; font-size: 13px; position: relative; top: 20px">Obs:{{
                        $t('AppAnalysis.Ao editar o Template você irá salvar as alterações feitas até o momento!')
                    }} </p>
                <template #footer>
                    <Button @click="editTemplate(false)" :label="$t('AppAnalysis.Não salvar')" class="p-button-text"/>
                    <Button @click="editTemplate(true)" :label="$t('AppAnalysis.Salvar')" autofocus/>
                </template>
            </Dialog>

            <!-- Components usados para acessar a tabela com os dados e para exportar PDF -->
            <app-analysis-summary v-show="showScreen4 && isThereData"
                                  :filter_events_results="filterEventsResultsToSummary"
                                  :chart_data_from_idb="chartDataFromIdb" :hide_cards="hideCards"/>

            <app-analysis-report :generate_report="generatePdf"
                                 @changeGenerate="changeGenerate"
                                 :filter_events_results="filterEventsResults"
                                 :chart_data_from_idb="chartDataFromIdb"
                                 :filter_time="filterTime"
                                 :chart_updater="chartUpdater"
                                 :chart_data_to_side_cards="chartDataToSideCards"
                                 :content_grid_stack="contentGridstack"
                                 :selected_model="selectedModel"/>
        </div>
    </div>
</template>

<script>
import ReportService from "../../../services/ReportsService";
import AppFilterBar from "../../common/AppFilterBar";
import AppAnalysisSideCards from "./AppAnalysisSideCards";
import AppAnalysisPieCharts from "./AppAnalysisPieCharts";
import AppAnalysisLineCharts from "./AppAnalysisLineCharts";
import AppAnalysisSummary from "./summary/AppAnalysisSummary";
import AppAnalysisReport from "./AppAnalysisReport.vue";
import AppAnalysisColumnCharts from "./AppAnalysisColumnCharts";
import getEventsFromMultipleVehicles from "@/mixins/ReportsMixin";
import Button from "primevue/button";
import InputText from 'primevue/inputtext';
import idb from '../../../api/idb';
import moment from 'moment-timezone';
import Tooltip from "primevue/tooltip";
import AppButton from "../../common/AppButton";
import Dialog from 'primevue/dialog';
import Vue from 'vue';
import 'gridstack/dist/gridstack.min.css';
import 'gridstack/dist/gridstack-extra.min.css';
import {GridStack} from 'gridstack';
import Dropdown from 'primevue/dropdown';
import eventBus from '@/eventBus'

export default {
    mixins: [getEventsFromMultipleVehicles],
    beforeMount() {
        this.reportsService = new ReportService();
    },
    mounted() {
        // Todas as vezes que o usuário tentar sair de Analysis o router.js vai chamar 'analysis-event'
        eventBus.$on('analysis-event', (val) => {
            this.unsavedData(true);
            if (this.isUnsaved || this.firstTemplate) {
                this.exitValue = val;
                this.showExitSave = true;
            } else {
                val.next();
            }
        })

        // Se alguma informação do usuário não foi salva esse evento é chamado
        window.addEventListener('beforeunload', this.handleBeforeUnload);

        this.showDialogFilters = this.isScreenMobile;
        this.showMenuButtons = this.screenSize;

        // Verifica algum click fora do menu de configurações e verifica a tecla Esc em caso especifico
        document.addEventListener('click', this.handleClickOutside);
        document.addEventListener('keydown', this.handleEscapeKey);

        // Criação do gridStack aplicando float de acordo com o tamanho da tela
        if (this.isScreenMobile) {
            this.grid = GridStack.init({
                float: false,
                cellHeight: "70px",
                minRow: 10,
                column: 20,
                removable: '#trash'
            });
        } else {
            this.grid = GridStack.init({
                float: true,
                cellHeight: "70px",
                minRow: 10,
                column: 20,
                removable: '#trash'
            });
        }

        // Recebe localStorage e se tiver algo nele chama loadWidget para colocar os gráficos na tela
        let temp = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_ANALYSIS));
        let foundName = false;

        if (temp != null) {
            temp.forEach(element => {
                if (Object.prototype.hasOwnProperty.call(element, 'value')) {
                    foundName = true;
                    return;
                }
            });
        }

        // Se existe algum campo chamado value o loadWidget é chamado, caso contrário o localStorage é limpo
        if (foundName) {
            this.setOptionsDropdown();
            if (this.modelOptions.length > 0) {
                let temp_selected = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_ANALYSIS_SELECTED));
                this.selectedModel = this.modelOptions[temp_selected.selected_model]
            }

            temp[0].value.forEach(item => {
                this.loadWidget(item);
            });
        } else {
            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_ANALYSIS, JSON.stringify([]));
        }

        // this.loadData();

        // Reorganiza os ids após a montagem dos widget para evitar erro de identificação
        this.grid.engine.nodes.sort((a, b) => a._id - b._id);
        this.grid.engine.nodes.forEach((e, i) => {
            e._id = i + 1;
        });
    },
    data() {
        return {
            model: 0,
            showGridStack: true,
            generatePdf: false,
            contentGridstack: [],
            info: "",
            grid: null,
            count: 0,
            graphicsArray: [],
            modelOptions: [],
            selectedModel: null,
            showDeleteModel: false,
            showCreateModel: false,
            nameModel: "",
            showSaveAsModel: false,
            showSaveModel: false,
            showCreateModelClick: false,
            isUnsaved: false,
            showExitSave: false,
            dropdownMenu: false,
            loadingPdf: false,
            showDialogFilters: false,
            showMenuButtons: false,
            displayMaximizable: false,
            reportsService: null,
            isThereData: false,
            isLoading: false,
            showScreen1: true,
            showScreen2: false,
            showScreen3: false,
            showScreen4: false,
            showScreen5: false,
            filterEventsResults: [],
            filterEventsResultsToSummary: [],
            chartDataFromIdb: [],
            filterTime: null,
            chartDataToSideCards: [],
            hideCards: false,
            chartUpdater: false,
            exitValue: null,
            showExitTemplate: false,
            oldItems: [],
            firstSend: true,
            firstTemplate: false,
            auxExitSave: false,
            showEditTemplate: false
        }
    },
    methods: {
        handleBeforeUnload(event) {
            this.unsavedData(true);
            if (this.isUnsaved) {
                event.preventDefault();
                event.returnValue = '';
            }
        },
        saveAsModelFalse() {
            this.auxExitSave = false;
            this.showSaveAsModel = false;
        },
        handleEscapeKey(event) {
            if (event.keyCode === 27 && this.showExitTemplate) {
                this.exitTemplate(false);
            }
        },
        saveAsModel() {
            let duplicateModel = this.modelOptions.some(element => element.name === this.nameModel);

            if (duplicateModel && !this.firstTemplate) {
                this.$toast.add({
                    severity: 'warn',
                    summary: this.$t('AppAnalysis.Altere o nome'),
                    detail: this.$t('AppAnalysis.Esse nome já foi cadastrado.'),
                    life: 3000
                });
                return;
            }

            if (this.modelOptions.length != this.model) {
                this.model = this.modelOptions.length
                return;
            }

            this.model = this.model + 1
        },
        exitAnalysis(val) {
            if (val) {
                if (this.firstTemplate) {
                    this.showExitSave = false;
                    this.auxExitSave = true;
                    this.operationModel("save as")
                } else {
                    this.saveTemplate();
                    this.exitValue.next();
                }
            } else {
                if (this.firstTemplate) {
                    localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_ANALYSIS, JSON.stringify([]));
                }
                this.exitValue.next();
            }
        },
        exitTemplate(val) {
            if (val) {
                this.saveLastTemplate();
                this.nextOptionModel();
                this.showExitTemplate = false;
            } else {
                this.nextOptionModel();
                this.showExitTemplate = false;
            }

            if (this.showCreateModelClick) {
                this.showCreateModel = true;
                this.nameModel = "";
            }
        },
        saveLastTemplate() {
            let objetosExistem = localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_ANALYSIS);
            let objetosArray = JSON.parse(objetosExistem);
            let val = this.oldItems[this.oldItems.length - 2];
            objetosArray[val.code].value = this.graphicsArray;
            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_ANALYSIS, JSON.stringify(objetosArray));
        },
        changeGenerate(val) {
            this.loadingPdf = val;
            let element = document.querySelector(".grid-stack")
            element.style.filter = val ? "blur(10px)" : "blur(0px)";
            element.style.pointerEvents = val ? "none" : "auto";
        },
        handleClickOutside(event) {
            let contentConfig;

            if (this.showMenuButtons && !this.showDialogFilters) {
                contentConfig = document.getElementById("config-menu-responsive");
            }

            if (this.showDialogFilters) {
                contentConfig = document.getElementById("config-menu-mobile");
            }

            if (!this.showMenuButtons) {
                contentConfig = document.getElementById("config-menu");
            }

            if (event.target !== contentConfig && !contentConfig.contains(event.target)) {
                this.dropdownMenu = false;
            }
        },
        showDivTutorial() {
            if (((this.graphicsArray === null || this.graphicsArray.length === 0) &&
                (this.filterEventsResults.length != 0 || this.chartDataFromIdb.length != 0 || this.chartDataToSideCards.length != 0)) && this.showGridStack && !this.noValue()) {
                return true;
            }
            return false;
        },
        operationModel(val) {
            if (this.noValue()) {
                this.$toast.add({
                    severity: 'warn',
                    summary: this.$t('AppAnalysis.Faça a filtragem'),
                    detail: this.$t('AppAnalysis.Selecione os filtros e datas de início e fim para gerar o relatório.'),
                    life: 3000
                });
                return;
            }

            switch (val) {
                case "create":
                    if (this.firstTemplate) {
                        this.$toast.add({
                            severity: 'warn',
                            summary: this.$t('AppAnalysis.Salve o primeiro Template'),
                            detail: this.$t('AppAnalysis.Para criar você deve salvar o primeiro Template.'),
                            life: 5000
                        });
                        return;
                    }

                    this.unsavedData(true);
                    if (this.isUnsaved) {
                        this.showExitTemplate = true;
                        this.showCreateModelClick = true;
                    } else {
                        this.showCreateModelClick = true;
                        this.showCreateModel = true;
                        this.nameModel = "";
                    }
                    break;

                case "delete":
                    this.showDeleteModel = true;
                    break;

                case 'save as':
                    this.nameModel = "";
                    this.showSaveAsModel = true;
                    break;

                case 'save':
                    if (this.firstTemplate) {
                        this.operationModel("save as")
                    } else {
                        this.saveTemplate()
                    }
                    break;

                case 'edit':
                    this.showEditTemplate = true;
                    break;

                default:
                    break;
            }

            this.dropdownMenu = false;
        },
        editTemplate(val) {
            if (val) {
                if (this.nameModel.trim() === "" || /^\s*$/.test(this.nameModel)) {
                    this.$toast.add({
                        severity: 'warn',
                        summary: this.$t('AppAnalysis.Nome Invalido'),
                        detail: this.$t('AppAnalysis.Escreva um nome para o Template.'),
                        life: 3000
                    });
                    return;
                }

                let duplicateModel = this.modelOptions.some(element => element.name === this.nameModel);

                if (duplicateModel) {
                    this.$toast.add({
                        severity: 'warn',
                        summary: this.$t('AppAnalysis.Altere o nome'),
                        detail: this.$t('AppAnalysis.Esse nome já foi cadastrado.'),
                        life: 3000
                    });
                    return;
                }

                this.saveTemplate();

                let tempLocalStorage = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_ANALYSIS));

                let position = 0
                tempLocalStorage.forEach((element, i) => {
                    if (element.name === this.selectedModel.name) {
                        element.name = this.nameModel
                        position = i
                    }
                });

                localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_ANALYSIS, JSON.stringify(tempLocalStorage));

                this.setOptionsDropdown()

                this.selectedModel = this.modelOptions[position]

                this.showEditTemplate = false;

                return;
            }

            this.showEditTemplate = false;
        },
        deleteModel() {
            if (this.noValue()) {
                this.$toast.add({
                    severity: 'warn',
                    summary: this.$t('AppAnalysis.Faça a filtragem'),
                    detail: this.$t('AppAnalysis.Selecione os filtros e datas de início e fim para gerar o relatório.'),
                    life: 3000
                });
                return;
            }

            let name = this.selectedModel.name;

            let tempLocalStorage = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_ANALYSIS));
            let temp = [];
            tempLocalStorage.forEach(element => {
                if (element.name != name) {
                    temp.push(element);
                }
            });

            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_ANALYSIS, JSON.stringify(temp));

            this.setOptionsDropdown()

            if (this.modelOptions.length > 0) {
                this.selectedModel = this.modelOptions[0]
            }

            if (this.modelOptions.length === 0) {
                this.grid.removeAll(true);
                this.graphicsArray = [];
                this.count = 0;
                let novoArray = [{name: "Template 1", value: this.graphicsArray}];
                localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_ANALYSIS, JSON.stringify(novoArray));
                this.firstTemplate = true;
                this.setOptionsDropdown();
                this.selectedModel = this.modelOptions[0];
            }
        },
        setOptionsDropdown() {
            this.modelOptions = [];
            let temp = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_ANALYSIS));

            if (temp != null) {
                temp.forEach((element, i) => {
                    this.modelOptions.push({name: element.name, code: i})
                });
            }
        },
        newModel() {
            if (this.noValue()) {
                this.$toast.add({
                    severity: 'warn',
                    summary: this.$t('AppAnalysis.Faça a filtragem'),
                    detail: this.$t('AppAnalysis.Selecione os filtros e datas de início e fim para gerar o relatório.'),
                    life: 3000
                });
                return;
            }

            if (this.nameModel.trim() === "" || /^\s*$/.test(this.nameModel)) {
                this.$toast.add({
                    severity: 'warn',
                    summary: this.$t('AppAnalysis.Nome Invalido'),
                    detail: this.$t('AppAnalysis.Escreva um nome para o Template.'),
                    life: 3000
                });
                return;
            }

            let duplicateModel = this.modelOptions.some(element => element.name === this.nameModel);

            if (duplicateModel) {
                this.$toast.add({
                    severity: 'warn',
                    summary: this.$t('AppAnalysis.Altere o nome'),
                    detail: this.$t('AppAnalysis.Esse nome já foi cadastrado.'),
                    life: 3000
                });
                return;
            }

            this.showCreateModel = false;
            this.grid.removeAll(true);
            this.graphicsArray = [];
            this.count = 0;

            if (this.modelOptions.length != this.model) {
                this.model = this.modelOptions.length
                return;
            }

            this.model = this.model + 1
        },
        generateReport() {
            if (this.noValue()) {
                this.$toast.add({
                    severity: 'warn',
                    summary: this.$t('AppAnalysis.Faça a filtragem'),
                    detail: this.$t('AppAnalysis.Selecione os filtros e datas de início e fim para gerar o relatório.'),
                    life: 3000
                });
                return;
            }

            this.generatePdf = !this.generatePdf;
            let temp = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_ANALYSIS));

            temp.forEach(element => {
                if (element.name === this.selectedModel.name) {
                    this.contentGridstack = element.value;
                }
            });
        },
        noValue() {
            return (this.chartDataFromIdb.length === 0 && this.chartDataToSideCards.length === 0 && !this.filterEventsResults.some(element => element.length > 0));
        },
        sendValues() {
            this.graphicsArray.forEach((graphic, i) => {
                this.$refs[`componenteRef${graphic.id}`].dataWatch(this.filterEventsResults, this.chartDataFromIdb, this.filterTime, this.chartUpdater, this.chartDataToSideCards);
                this.$refs[`componenteRef${graphic.id}`].setOption();
            });

            this.verificarClique();
        },
        verificarClique() {
            if (this.isScreenMobile) {
                this.graphicsArray.forEach((graphic, i) => {
                    this.grid.engine.nodes.forEach(item => {
                        if (item._id === graphic.id) {
                            graphic.y_mobile = item.y
                            graphic.x_mobile = item.x
                        }
                    });
                });

                const objetosExistem = localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_ANALYSIS);

                if (!objetosExistem) {
                    let novoArray = [{name: "Template 1", value: this.graphicsArray}];
                    localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_ANALYSIS, JSON.stringify(novoArray));
                    this.firstTemplate = true;
                }
                return;
            }

            this.graphicsArray.forEach((graphic, i) => {
                let height = this.getDimensions(graphic.id);
                this.grid.engine.nodes.forEach(item => {
                    if (item._id === graphic.id) {
                        graphic.y = item.y
                        graphic.x = item.x
                        graphic.w = item.w
                        graphic.h = item.h
                        graphic.heightPx = height
                    }
                });
            });

            const objetosExistem = localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_ANALYSIS);

            if (!objetosExistem || this.selectedModel === null) {
                let novoArray = [{name: "Template 1", value: this.graphicsArray}];
                localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_ANALYSIS, JSON.stringify(novoArray));
                this.firstTemplate = true;
            }
        },
        saveTemplate() {
            const objetosExistem = localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_ANALYSIS);
            const objetosArray = JSON.parse(objetosExistem);
            objetosArray[this.selectedModel.code].value = this.graphicsArray;
            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_ANALYSIS, JSON.stringify(objetosArray));

            this.showSaveModel = false;
        },
        unsavedData(val) {
            this.isUnsaved = false;
            const temp = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_ANALYSIS));

            if (temp != null && temp.length > 0) {
                let localValue;
                if (val) {
                    localValue = temp[this.selectedModel.code].value;
                } else {
                    const val = this.oldItems[this.oldItems.length - 2];
                    localValue = temp[val.code].value;
                }

                if (localValue.length !== this.grid.engine.nodes.length) {
                    this.isUnsaved = true;
                    return;
                }

                const tempGrid = [...this.grid.engine.nodes];
                tempGrid.sort((a, b) => a._id - b._id);
                tempGrid.forEach((e, i) => {
                    e._id = i + 1;
                });

                localValue.sort((a, b) => a.id - b.id);
                localValue.forEach((e, i) => {
                    e.id = i + 1;
                });

                if (!this.showDialogFilters) {
                    for (let i = 0; i < localValue.length; i++) {
                        const graphic = localValue[i];
                        const item = tempGrid.find(item => item._id === graphic.id);

                        if (!item || (item.y !== graphic.y || item.x !== graphic.x || item.h !== graphic.h || item.w !== graphic.w || localValue.length !== tempGrid.length)) {
                            this.isUnsaved = true;
                            break;
                        }
                    }
                }

                const tempGraphicsArray = [...this.graphicsArray];
                tempGraphicsArray.sort((a, b) => a.id - b.id);
                tempGraphicsArray.forEach((e, i) => {
                    e.id = i + 1;
                });

                for (let i = 0; i < localValue.length; i++) {
                    const graphic = localValue[i];
                    const element = tempGraphicsArray.find(element => graphic.id === element.id);

                    if (!element || (graphic.type !== element.type || graphic.code !== element.code || graphic.sumInGroupMode !== element.sumInGroupMode || graphic.name !== element.name)) {
                        this.isUnsaved = true;
                        break;
                    }
                }
            }
        },
        addNewWidget(val) {
            // Não tem valores para mostar
            if (this.noValue()) {
                this.$toast.add({
                    severity: 'warn',
                    summary: this.$t('AppAnalysis.Faça a filtragem'),
                    detail: this.$t('AppAnalysis.Selecione os filtros e datas de início e fim para gerar o relatório.'),
                    life: 3000
                });
                return;
            }

            const elementId = `para${++this.count}`;
            let isInfo = val === "Info";

            // Verificação para definir altura e largura do widget
            let widgetConfiguration = !this.isScreenMobile ?
                !isInfo ? {w: 6, h: 5, minW: 5, minH: 5} : {w: 2, h: 2, minW: 2, minH: 2} :
                !isInfo ? {w: 40, h: 5, noMove: true, noResize: true} : {w: 10, h: 2, noMove: true, noResize: true};

            this.grid.addWidget({
                ...widgetConfiguration,
                content: `<div id="${elementId}" style="height: 100%;"></div>`
            });

            // Cria o componente de gráfico dentro do widget e atribui chamadas no componente pai em caso de emit do componente filho
            this.createComponent(val);

            let temp = {
                id: this.count,
                type: val,
                y: 0,
                x: 0,
                w: isInfo ? 2 : 6,
                h: isInfo ? 2 : 5,
                name: null,
                code: null,
                sumInGroupMode: false,
                heightPx: isInfo ? 127 : 337,
                heightPx_mobile: isInfo ? 127 : 337,
                y_mobile: 0,
                x_mobile: 0,
                h_mobile: isInfo ? 2 : 5,
                w_mobile: isInfo ? 10 : 40
            };

            // Guarda informações do widget
            this.graphicsArray.push(temp);

            // Reorganiza ids da biblioteca do Gridstack
            let highestId = Math.max(...this.grid.engine.nodes.map(node => node._id));
            let position = this.grid.engine.nodes.findIndex(node => node._id === highestId);
            this.grid.engine.nodes[position]._id = this.count;

            // Chama método que verifica alturas das divs pai e recebe as posições do Gridstack
            this.verificarClique();

            // Em caso de uso da versão mobile atribui altura pré setada ao componente filho
            if (this.isScreenMobile) {
                this.$refs[`componenteRef${this.count}`].correctHeight(temp.heightPx_mobile);
            }
        },
        loadWidget(val) {
            this.count = this.count + 1;
            const elementId = `para${this.count}`;

            // Recebe dados do localStorage e cria widget com eles
            if (this.isScreenMobile) {
                this.grid.addWidget({
                    w: val.w_mobile,
                    h: val.h_mobile,
                    x: val.x_mobile,
                    y: val.y_mobile,
                    noMove: true,
                    noResize: true,
                    content: `<div id="${elementId}" style="height: 100%;"></div>`
                });
            } else if (val.type === "Info") {
                this.grid.addWidget({
                    x: val.x,
                    y: val.y,
                    w: val.w,
                    h: val.h,
                    minW: 2,
                    minH: 2,
                    content: `<div id="${elementId}" style="height: 100%;"></div>`
                });
            } else {
                this.grid.addWidget({
                    x: val.x,
                    y: val.y,
                    w: val.w,
                    h: val.h,
                    minW: 5,
                    minH: 5,
                    content: `<div id="${elementId}" style="height: 100%;"></div>`
                });
            }

            // Cria o componente de gráfico dentro do widget e atribui chamadas no componente pai em caso de emit do componente filho
            this.createComponent(val.type);

            let temp = {
                id: this.count,
                type: val.type,
                y: val.y,
                x: val.x,
                w: val.w,
                h: val.h,
                name: val.name != null ? val.name : null,
                code: val.code != null ? val.code : null,
                sumInGroupMode: val.sumInGroupMode,
                heightPx: val.heightPx,
                y_mobile: val.y_mobile,
                x_mobile: val.x_mobile,
                w_mobile: val.w_mobile,
                h_mobile: val.h_mobile,
                heightPx_mobile: val.heightPx_mobile
            };

            // Guarda informações do widget
            this.graphicsArray.push(temp);

            // Manda alturas recebidas do localStorage para os componentes filhos de acordo com o tamanho da tela
            if (this.isScreenMobile) {
                this.$refs[`componenteRef${this.count}`].correctHeight(temp.heightPx_mobile);
            } else {
                this.$refs[`componenteRef${this.count}`].correctHeight(temp.heightPx);
            }
        },
        eventsTimeToHours(eventsData) {
            let newEventsData = JSON.parse(JSON.stringify(eventsData))
            newEventsData.forEach((events) => {
                events.forEach(event => {
                    event.time = event.time / 3600;
                    event.area = event.area / 10000;
                })
            })

            return newEventsData;
        },
        createComponent(val) {
            let Component;

            if (val === "Info") {
                Component = Vue.extend(AppAnalysisSideCards);
            } else if (val === "Pizza") {
                Component = Vue.extend(AppAnalysisPieCharts);
            } else if (val === "Gráfico") {
                Component = Vue.extend(AppAnalysisLineCharts);
            } else if (val === "Tubo") {
                Component = Vue.extend(AppAnalysisColumnCharts);
            }

            const componentVue = new Component({
                ref: `componenteRef${this.count}`
            });

            // Montar o componentVue antes de adicioná-lo ao elemento
            componentVue.$mount();

            // Adicionar o componentVue diretamente como filho de elementId
            const elementId = `para${this.count}`;
            let appElement = document.getElementById(elementId);
            appElement.appendChild(componentVue.$el);

            this.$refs[`componenteRef${this.count}`] = componentVue;

            componentVue.dataWatch(this.filterEventsResults, this.chartDataFromIdb, this.filterTime, this.chartUpdater, this.chartDataToSideCards);

            componentVue.setLocalStorage(this.count);

            componentVue.setModelLocalStorage(this.selectedModel.code);

            componentVue.$on('dropdownChanged', (val) => {
                this.updateGraphicsArray(val);
            });
            componentVue.$on('remove', (val) => {
                this.remove(val);
            });
        },
        remove(val) {
            let position = this.grid.engine.nodes.findIndex(node => node._id === val);
            let items = this.grid.engine.nodes[position];

            this.grid.removeWidget(items.el, true, true);

            let temp = [];
            this.graphicsArray.forEach((element, i) => {
                if (element.id != items._id) {
                    temp.push(element);
                }
            });

            this.graphicsArray = temp;

            this.verificarClique();
        },
        updateGraphicsArray(val) {
            this.graphicsArray.forEach(element => {
                if (element.id === val.id) {
                    element.name = val.name
                    element.code = val.code
                    element.sumInGroupMode = val.sumInGroupMode
                }
            });
        },
        getDimensions(val) {
            let id = "para" + val
            let meuElemento = document.getElementById(id);
            let altura = meuElemento.clientHeight;
            this.$refs[`componenteRef${val}`].correctHeight(altura);

            return altura;
        },
        openMaximizable() {
            this.displayMaximizable = true;
        },
        buttonSetClicked(val) {
            for (let i = 1; i < 6; i++) {
                if (i === val) {
                    this['styleButton' + i] = "background-color: #474747";
                    this['showScreen' + i] = true;
                } else {
                    this['styleButton' + i] = "background-color: inherit";
                    this['showScreen' + i] = false;
                }
            }
            if (val === 4) {
                this.showGridStack = false;
            } else {
                this.showGridStack = true;
            }
            this.chartUpdater = !this.chartUpdater;
        },
        async treatEventsResponse(result) {
            let vm = this;

            // result.forEach((resultData) => {
            //     resultData.forEach((event) => {
            //         event['shift'] = event['shift'] ? event['shift'] : "Não Identificado";
            //     })
            // });

            if (result.length > 0) {
                vm.chartUpdater = !vm.chartUpdater;
                result = this.filterProductiveFields(result)
                await vm.storeEvents(result);
                vm.filterEventsResultsToSummary = JSON.parse(JSON.stringify(result))
                vm.filterEventsResults = this.eventsTimeToHours(result);
                vm.isThereData = true;

                let temp = [];
                //como não diferencia as maquinas, joga tudo em um array para facilitar
                result.forEach((resultData) => {
                    resultData.forEach((event) => {
                        event.time = event.time / 3600;
                        event.area = event.area / 10000;
                        temp.push(event)
                    })
                });
                vm.chartDataToSideCards = temp;
            }
        },
        filterProductiveFields(events) {
            let productiveFieldsEvent = []
            for (let i in events) {
                productiveFieldsEvent.push([]);
                events[i].forEach(event => {
                    let fieldTypes = event.field_type.split(',')
                    if (fieldTypes.includes('PRODUCTIVE')) {
                        productiveFieldsEvent[i].push(event)
                    }
                })
            }
            return productiveFieldsEvent;
        },
        async requestDataFromEvents(dates, selectedClients, selectedVehicles) {
            let vm = this;

            this.filterTime = {
                start_date: moment.tz(dates.start_date, 'America/Sao_Paulo').utc().valueOf(),
                end_date: moment.tz(dates.end_date, 'America/Sao_Paulo').utc().valueOf(),
                selectedVehicles: selectedVehicles.length
            }

            vm.isLoading = true;

            const promises = [];

            promises.push(this.getEventsFromMultipleVehicles(dates, selectedVehicles));

            let vehicles = []
            selectedVehicles.forEach(function (vehicle) {
                vehicles.push(vehicle.identification)
            })

            Promise.all(promises)
                .then(async (results) => {
                    if (results.length > 0) {
                        await vm.clearAllDataFromIdb();
                        await vm.treatEventsResponse(results[0]);
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.status === 404) {
                        vm.$toast.add({
                            severity: 'warn',
                            summary: this.$t('AppAnalysis.Análise não realizada'),
                            detail: this.$t('AppAnalysis.Não foi possível realizar a análise no período informado'),
                            life: 5000
                        });
                    } else {
                        vm.$toast.add({
                            severity: 'error',
                            summary: this.$t('AppAnalysis.Operação falhou'),
                            detail: this.$t('AppAnalysis.Selecione pelo menos um veículo'),
                            life: 5000
                        });
                    }
                }).finally(() => {
                vm.isLoading = false;
                this.displayMaximizable = false;

                let elements;
                if (this.showDialogFilters) {
                    elements = [
                        document.querySelector(".grid-stack"),
                        document.querySelector(".submenu-panel"),
                        document.querySelector(".menu-mobile"),
                        document.querySelector(".change-mode1"),
                        document.querySelector(".change-mode2")
                    ];
                } else {
                    elements = [
                        document.querySelector(".grid-stack"),
                        document.querySelector(".menu-panel"),
                        document.querySelector(".submenu-panel"),
                        document.querySelector(".change-mode1"),
                        document.querySelector(".change-mode2")
                    ];
                }


                // Envia novos dados para os gráficos
                if (!this.noValue()) {
                    this.sendValues();
                    this.setOptionsDropdown();
                    if (this.firstSend) {
                        let temp_selected = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_ANALYSIS_SELECTED));
                        let position = this.modelOptions.length === 1 ? 0 : temp_selected.selected_model;
                        this.selectedModel = this.modelOptions[position];
                        this.firstSend = false;
                    }
                } else {
                    this.graphicsArray.forEach(graphic => {
                        this.$refs[`componenteRef${graphic.id}`].setNoValue();
                    });

                    this.$toast.add({
                        severity: 'info',
                        summary: this.$t('AppAnalysis.Sem dados no período'),
                        detail: this.$t('AppAnalysis.Não foram localizados dados para o período informado'),
                        life: 5000
                    });
                }

                // Verifica se existem dados para apresentar e caso sim, o blur é retirado dos elementos
                elements.forEach(element => {
                    element.style.filter = this.noValue() ? "blur(10px)" : "blur(0px)";
                    element.style.pointerEvents = this.noValue() ? "none" : "auto";
                });
            });
        },
        async storeEvents(data) {
            for (const val of data) {
                if (val && val.length > 0) {
                    await idb.saveEvents(val, val[0].chassis);
                }
            }
        },
        async clearAllDataFromIdb() {
            await idb.deleteAll();
        },
        async loadData() {
            let idbData = await idb.getData();
            if (idbData) {
                idbData.forEach(data => {
                    if (!data.events) {
                        return;
                    }

                    data.events.forEach((event) => {
                        event['shift'] = event['shift'] ? event['shift'] : this.$t('AppAnalysis.Não Identificado');
                    })
                })

                this.chartDataFromIdb = idbData;
            }

            //Enviando estatísticas todas juntas para side cards
            if (idbData) {
                let temp = [];
                //como não diferencia as maquinas, joga tudo em um array para facilitar
                idbData.forEach((cardData) => {
                    if (cardData.events) {
                        cardData.events.forEach((events) => {
                            temp.push(events)
                        })
                    }
                    this.isThereData = true;
                });
                this.chartDataToSideCards = temp;
            }

            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_ANALYSIS, JSON.stringify(this.graphicsArray));

            // Buscar datas do LocalStorage
            let temp = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_BAR_OPTIONS));
            if (temp) {
                this.filterTime = {
                    start_date: new Date(temp.startDate).getTime(),
                    end_date: new Date(temp.endDate).getTime(),
                    selectedVehicles: temp.vehicles.length
                }
            }

            this.graphicsArray.forEach((graphic, i) => {
                this.$refs[`componenteRef${graphic.id}`].dataWatch(this.filterEventsResults, this.chartDataFromIdb, this.filterTime, this.chartUpdater, this.chartDataToSideCards);
                this.$refs[`componenteRef${graphic.id}`].setOption();
            });
        },
        nextOptionModel() {
            let val = this.selectedModel
            this.grid.removeAll(true);
            this.graphicsArray = [];
            this.count = 0;

            let temp = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_ANALYSIS));

            temp.forEach(element => {
                if (element.name === val.name) {
                    element.value.forEach((item, i) => {
                        item.id = i + 1;
                        this.loadWidget(item);
                    });
                }
            });

            this.setAnalysisSelected()

            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_ANALYSIS, JSON.stringify(temp));

            this.grid.engine.nodes.sort((a, b) => a._id - b._id);
            this.grid.engine.nodes.forEach((e, i) => {
                e._id = i + 1;
            });


            this.graphicsArray.forEach((graphic, i) => {
                this.$refs[`componenteRef${graphic.id}`].setOption();
            });
        },
        setAnalysisSelected() {
            let temp_selected = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_ANALYSIS_SELECTED));

            if (temp_selected === null) {
                let new_temp_selected = {selected_model: this.selectedModel.code};
                localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_ANALYSIS_SELECTED, JSON.stringify(new_temp_selected));
            } else {
                temp_selected.selected_model = this.selectedModel.code
                localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_ANALYSIS_SELECTED, JSON.stringify(temp_selected));
            }
        }
    },
    watch: {
        info: function (newVal) {
            if (newVal.length === 0) return;

            window.clearTimeout(this.timerId);
            this.timerId = window.setTimeout(() => {
                this.info = "";
            }, 2000);
        },
        model: function (val) {
            const objetosExistem = localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_ANALYSIS);
            const objetosArray = JSON.parse(objetosExistem);

            // Quando for o primeiro Template a ser salvo ele é colocado na posição 0 do localStorage
            if (this.firstTemplate) {
                objetosArray[0] = {name: this.nameModel, value: this.graphicsArray};
                this.firstTemplate = false;
            }
            // Caso o usuário selecione "Salvar Como" é criado um novo array no localStorage com o conteúdo atual
            else if (this.showSaveAsModel) {
                objetosArray.push({name: this.nameModel, value: this.graphicsArray});
            }
            // Para todos os demais casos é criado um novo array com conteúdo vazio
            else {
                objetosArray.push({name: this.nameModel, value: []});
            }
            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_ANALYSIS, JSON.stringify(objetosArray));

            this.setOptionsDropdown();

            this.selectedModel = this.modelOptions[this.modelOptions.length - 1]
        },
        selectedModel: function (val) {
            this.oldItems.push(val);
            if (this.oldItems.length > 2) {
                this.oldItems.shift();
            }

            if (this.showDeleteModel) {
                this.oldItems.shift();
                this.showDeleteModel = false;
            }

            if (this.oldItems.length > 1 && !this.showSaveAsModel && !this.showCreateModelClick) {
                this.unsavedData(false);
                if (this.isUnsaved) {
                    this.showExitTemplate = true;
                    return;
                }
            }
            this.showCreateModelClick = false;
            this.showSaveAsModel = false;

            this.nextOptionModel();

            if (this.auxExitSave) {
                this.exitValue.next();
            }
        },
        dropdownMenu: function (val) {
            if (!val) {
                return;
            }

            let configMenu;
            if (this.showMenuButtons && !this.showDialogFilters) {
                configMenu = document.getElementById("config-menu-responsive");
            }

            if (this.showDialogFilters) {
                configMenu = document.getElementById("config-menu-mobile");
            }

            if (!this.showMenuButtons) {
                configMenu = document.getElementById("config-menu");
            }

            let rect = configMenu.getBoundingClientRect();
            let content = document.getElementById("content-config")
            if (!this.showDialogFilters) {
                content.style.left = rect.left - 50 + "px"
                content.style.top = rect.top + 40 + "px"
                return;
            }

            content.style.left = rect.left - 65 + "px"
            content.style.top = rect.top + 36 + "px"
        }
    },
    components: {
        AppAnalysisColumnCharts,
        AppAnalysisSummary,
        AppAnalysisPieCharts,
        AppAnalysisLineCharts,
        AppAnalysisReport,
        InputText,
        AppFilterBar,
        AppAnalysisSideCards,
        AppButton,
        Dialog,
        Dropdown,
        Button,
        Tooltip
    },
    computed: {
        isScreenMobile: function () {
            return window.screen.width <= 1024;
        },
        screenSize: function () {
            return window.screen.width <= 1285;
        }
    },
    directives: {
        tooltip: Tooltip
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClickOutside);
        document.removeEventListener('keydown', this.handleEscapeKey);
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
        eventBus.$off('analysis-event')
    },
}
</script>


<style lang="scss">
@import "src/assets/styles/primevue";

.items-right-desktop {
    display: flex;
    flex-direction: row-reverse;
}

.margin-items-desktop {
    margin: auto 160px auto auto;
}

.menu-panel {
    display: flex;
    justify-content: center;
    filter: blur(10px);
    pointer-events: none;
}

.icon-down {
    font-size: 12px;
    color: white;
    margin-left: 10px;
    position: relative;
    bottom: 5px;
}

.text-item-menu {
    color: white;
    margin-top: 10px;
}

.menu-mobile {
    background-color: #3CBEB4;
    width: 50px;
    border-radius: 5px;
    filter: blur(10px);
    pointer-events: none;
}

.change-mode1 {
    background-color: #3CBEB4;
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 0px 20px 25px 0px !important;
    filter: blur(10px);
    pointer-events: none;
}

.change-mode2 {
    background-color: #3CBEB4;
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 0px 20px 25px 0px !important;
    z-index: 1;
    filter: blur(10px);
    pointer-events: none;
}

.button-remove {
    position: absolute;
    top: 15px;
    left: 15px;
    width: 25px;
    height: 25px;
    z-index: 1;
    border: 0.5px solid #bbbbbb;
    border-radius: 3px;
}

.button-remove:hover {
    cursor: pointer;
    box-shadow: 1px 1px 5px #666666;
}

.button-graphics {
    width: 40px;
    height: 40px;
    margin: auto 8px;
    border-radius: 10px;
}

.button-graphics:hover {
    cursor: pointer;
}

.icon-graphic {
    font-size: 22px !important;
    margin-top: 8px;
    color: white;
}

.submenu-analysis {
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 7px 15px 7px 0px;
    display: flex;
    border-bottom: 2px rgb(218, 218, 218) solid;
    border-left: 2px rgb(218, 218, 218) solid;
    border-right: 2px rgb(218, 218, 218) solid;
    border-end-end-radius: 8px;
    border-end-start-radius: 8px;
}

.margin-left-submenu {
    margin-left: 70px;
}

.div-tutorial {
    z-index: 1;
    border: dashed 4px #3CBEB4;
    border-radius: 5px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
    background-color: white;
}

.hover-menu-item:hover {
    background-color: #858585;
    cursor: pointer;
}

.dropdown-style {
    min-width: 150px;
    max-width: 150px;
    height: 40px;
    margin: auto 5px auto 10px;
    border-radius: 7px;
    align-items: center;
}

.dropdown-menu-style {
    position: absolute;
    background-color: #474747;
    width: 180px;
    z-index: 2;
    border-radius: 5px;
    padding: 10px 0px;
}

.add-graphic {
    height: 30px;
    margin: 5px 15px 0px 0px;
    padding: 3px 5px 0px 15px;
}

.text-add {
    position: relative;
    left: 8px;
    color: #3CBEB4;
    font-weight: bold;
    font-size: 16px;
}

.input-text-dialog {
    width: 100%;
    margin-top: 10px !important;
}

@media (max-width: 1024px) {
    .button-graphics {
        width: 40px;
        height: 36px;
        margin: auto 8px;
    }

    .icon-graphic {
        font-size: 17.5px !important;
        margin-top: 10px;
        color: white;
    }

    .submenu-analysis {
        display: block;
    }

    .margin-left-submenu {
        margin-left: 0px;
    }

    .dropdown-style {
        width: 125px;
        height: 36px;
    }
}

.btn-style {
    border: 2px solid white;
    background-color: inherit;
    min-width: 40px;
    padding: 7px;
    border-radius: 4px;
    color: white;
    font-size: 13px;
}

.grid-stack > .grid-stack-item > .grid-stack-item-content {
    margin: 0;
    position: absolute;
    width: calc(100% - 10px);
    height: calc(100% - 13px);
    overflow-x: hidden;
    overflow-y: auto;
    box-shadow: 0.1px 0.1px 5px #d3d3d3;
}

.grid-stack-item-content {
    text-align: center;
    background-color: white;
}

$grid-item-width: 5%;

@for $i from 1 through 20 {
    .gs-20 > .grid-stack-item[gs-w="#{$i}"] {
        width: $grid-item-width * $i;
    }

    .gs-20 > .grid-stack-item[gs-x="#{$i}"] {
        left: $grid-item-width * $i;
    }
}

</style>
