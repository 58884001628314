<template>
    <div class="operations" :class="statsPaneSize < 1 ? 'panel-show' : 'panel-hide'">
        <splitpanes class="default-theme" :dbl-click-splitter="false" horizontal @splitter-click="togglePane"
                    @resize="statsPaneSize = $event[1].size">
            <pane :size="100 - statsPaneSize">
                <splitpanes class="default-theme" :dbl-click-splitter="false" :horizontal="is_col_12"
                            @resize="paneSize = $event[1].size">
                    <pane :size="100 - paneSize" min-size="35">
                        <div style="position: relative; height: 100%" @mouseover="focusOne = true"
                             @mouseleave="focusOne = false">
                            <div style="position: absolute; z-index: 2; left: 30px" :style="{'top': top_px}">
                                <div class="row justify-content-center">
                                    <app-google-maps-type-control :show_first_row="false" ref="googleMapsTypeControlOne"
                                                                  @handleMapControlClick="handleMapControlClickOne">
                                    </app-google-maps-type-control>
                                </div>
                            </div>
                            <div v-if="!isMapOverlap(map_type_one)" :style="{'top': is_col_12 ? '110px' : '90px'}"
                                 style="position: absolute; z-index: 1; left: 20px">
                                <app-operations-work-options :rate_steps="rateStepsOne" :map-type="map_type_one"
                                                             @changeVisibility="changeVisibility($event, tilesDataOne, opacityOne, 'one')"
                                                             @edit="editScale(rateStepsOne, 'one')"/>
                            </div>
                            <app-google-maps-operations ref="googleMapsOperationOne" :loading="loadingOne"
                                                        :position_center="operationMapCenterOne" :selected_field="selected_field"
                                                        :google_maps_operations_id="'compare-tasks-1'"
                                                        :focused="focusOne" :tiles-data="tilesDataOne"
                                                        @boundsChanged="updateBoundsTwo"/>
                        </div>
                    </pane>
                    <pane :size="paneSize" min-size="35">
                        <div style="position: relative; height: 100%" @mouseover="focusTwo = true"
                             @mouseleave="focusTwo = false">
                            <div style="position: absolute; z-index: 2; left: 30px" :style="{'top': top_px}">
                                <div class="row justify-content-center">
                                    <app-google-maps-type-control :show_first_row="false" ref="googleMapsTypeControlTwo"
                                                                  @handleMapControlClick="handleMapControlClickTwo">
                                    </app-google-maps-type-control>
                                </div>
                            </div>
                            <div v-if="!isMapOverlap(map_type_two)" :style="{'top': is_col_12 ? '110px' : '90px'}"
                                 style="position: absolute; z-index: 1; left: 20px">
                                <app-operations-work-options :rate_steps="rateStepsTwo" :map-type="map_type_two"
                                                             @changeVisibility="changeVisibility($event, tilesDataTwo, opacityTwo, 'two')"
                                                             @edit="editScale(rateStepsTwo, 'two')"/>
                            </div>
                            <app-google-maps-operations ref="googleMapsOperationTwo" :loading="loadingTwo"
                                                        :position_center="operationMapCenterOne" :selected_field="selected_field"
                                                        :google_maps_operations_id="'compare-tasks-2'"
                                                        :focused="focusTwo" :tiles-data="tilesDataTwo"
                                                        @boundsChanged="updateBoundsOne"/>
                        </div>
                    </pane>
                </splitpanes>
            </pane>
            <pane :size="statsPaneSize" max-size="70" class="stats">
                <div class="d-flex">
                    <div :style="{width: (100 - paneSize) + '%'}">
                        <app-operations-stats :stats="statisticsOne" :operators="operatorsOne" :varieties="varietiesOne"
                                              :operation="operation_one" :vehicles="vehiclesOne" :products="productsOne"
                                              :pane_size="30"/>
                    </div>
                    <div :style="{width: paneSize + '%'}">
                        <app-operations-stats :stats="statisticsTwo" :operators="operatorsTwo" :varieties="varietiesTwo"
                                              :operation="operation_two" :vehicles="vehiclesTwo" :products="productsTwo"
                                              :pane_size="30"/>
                    </div>
                </div>
            </pane>
        </splitpanes>

        <app-operations-rate-steps-edit-dialog :show_edit_dialog="showEditDialog" :rate_steps="rateStepsEdit"
                                               :original-rate-steps="originalRateSteps" :min_value="minValue"
                                               :opacity="opacity" @applyNewScale="applyNewScale"
                                               @hideDialog="closeDialog"/>
    </div>
</template>

<script>

import 'splitpanes/dist/splitpanes.css'
import {Pane, Splitpanes} from 'splitpanes'

import buildColorRange from "@/mixins/ColorMixin";
import hexToRgb from "@/mixins/ColorMixin";
import getUserColorsSet from "@/mixins/ColorMixin";
import buildColorBounds from "@/mixins/ColorMixin";
import formatPercent from "@/mixins/ValuesFormat";
import buildStatistics from "@/utils/OperationsStatisticsBuilder";

import AppTasksWorkOptions from "@/components/views/agriculture/tasks/AppTasksWorkOptions";
import AppGoogleMapsOperations from "@/components/views/agricultureV2/operations/AppGoogleMapsOperations";
import AppGoogleMapsTypeControl from "@/components/common/AppGoogleMapsTypeControl";
import AppOperationsWorkOptions from "@/components/views/agricultureV2/operations/AppOperationsWorkOptions.vue";
import {OPERATIONS_LIST} from "@/components/views/agricultureV2/AppAgricultureV2Options";
import {MAPS_OVERLAY_OPEN_WEATHER_TYPES, MAPS_OVERLAY_WEATHER_TYPES} from "../../real-time/AppOptions";
import AppOperationsStats from "@/components/views/agricultureV2/operations/AppOperationsStats.vue";
import AppOperationsRateStepsEditDialog
    from "@/components/views/agricultureV2/operations/AppOperationsRateStepsEditDialog.vue";

import OperationsService from "@/services/OperationsService";

export default {
    mixins: [buildColorRange, hexToRgb, formatPercent, buildStatistics, getUserColorsSet, buildColorBounds],
    beforeMount() {
        this.operationsService = new OperationsService();
        this.is_col_12 = this.isScreenMobile;
        this.top_px = this.isScreenMobile ? '50px' : '30px';
    },
    mounted() {
        this.operationMapCenterOne = this.field_center;

        this.operationOne = this.operation_one;
        this.operationTwo = this.operation_two;

        this.getStatistics(this.operationOne, 'one')
        this.getStatistics(this.operationTwo, 'two')
    },
    data() {
        return {
            operationsService: null,
            is_col_12: false,
            top_px: '',
            tilesDataOne: {
                requestId: null,
                zooms: [],
                grouped: false,
                color_bounds: [],
                center_tile: false,
                center: {},
                bounds: []
            },
            tilesDataTwo: {
                requestId: null,
                zooms: [],
                grouped: false,
                color_bounds: [],
                center_tile: false,
                center: {},
                bounds: []
            },
            operationMapCenterOne: {},
            operationMapBoundsOne: [],
            originalRateStepsOne: [],
            originalRateStepsTwo: [],
            rateStepsOne: [],
            rateStepsTwo: [],
            originalRateSteps: [],
            rateStepsEdit: [],
            tilesZoomsOne: [],
            tilesZoomsTwo: [],
            operationOne: null,
            operationTwo: null,
            loadingOne: false,
            loadingTwo: false,
            focusOne: false,
            focusTwo: false,
            requestIdOne: null,
            requestIdTwo: null,
            layerColorBoundsOne: [],
            layerColorBoundsTwo: [],
            opacity: 100,
            opacityOne: 100,
            opacityTwo: 100,
            minValue: 0,
            minValueOne: 0,
            minValueTwo: 0,
            showEditDialog: false,
            scaleEdit: null,
            statisticsOne: {},
            operatorsOne: [],
            vehiclesOne: [],
            varietiesOne: [],
            productsOne: [],
            statisticsTwo: {},
            operatorsTwo: [],
            vehiclesTwo: [],
            varietiesTwo: [],
            productsTwo: [],
            paneSize: 50,
            statsPaneSize: 35,
            centerMap: false,
            previousMapEmptyOne: true,
            previousMapEmptyTwo: true
        }
    },
    methods: {
        togglePane() {
            if (this.statsPaneSize > 1) {
                this.statsPaneSize = 0
                return
            }

            this.statsPaneSize = 35
        },
        updateBoundsTwo(boundsOne) {
            this.$refs.googleMapsOperationTwo.updatePosition(boundsOne.zoom, boundsOne.center)
        },
        updateBoundsOne(boundsTwo) {
            this.$refs.googleMapsOperationOne.updatePosition(boundsTwo.zoom, boundsTwo.center)
        },
        handleMapControlClickOne(val) {
            this.handleMapControlClick(val, 'googleMapsOperationOne')
        },
        handleMapControlClickTwo(val) {
            this.handleMapControlClick(val, 'googleMapsOperationTwo')
        },
        handleMapControlClick(val, ref) {
            switch (val) {
                case 'roadmap':
                    this.$refs[ref].setUpMapType('roadmap');
                    break;
                case 'hybrid':
                    this.$refs[ref].setUpMapType('hybrid');
                    break;
                case MAPS_OVERLAY_WEATHER_TYPES.PRECIPITATION:
                    this.$refs[ref].setupWeatherOverlay(MAPS_OVERLAY_OPEN_WEATHER_TYPES.PRECIPITATION);
                    break;
                case MAPS_OVERLAY_WEATHER_TYPES.TEMPERATURE:
                    this.$refs[ref].setupWeatherOverlay(MAPS_OVERLAY_OPEN_WEATHER_TYPES.TEMPERATURE);
                    break;
                case MAPS_OVERLAY_WEATHER_TYPES.CLOUDS:
                    this.$refs[ref].setupWeatherOverlay(MAPS_OVERLAY_OPEN_WEATHER_TYPES.CLOUDS);
                    break;
                case MAPS_OVERLAY_WEATHER_TYPES.REMOVE:
                    this.$refs[ref].setupWeatherOverlay(MAPS_OVERLAY_WEATHER_TYPES.REMOVE);
                    break;
                default:
            }
        },
        isMapOverlap(mapType) {
            if (!mapType) {
                return
            }

            return mapType.value === 'overlap'
        },
        changeVisibility(scaleSteps, tilesData, opacity, map) {
            let tmpTilesData = JSON.parse(JSON.stringify(tilesData))
            let tmpColors = tmpTilesData.color_bounds

            tmpColors.forEach(bound => {
                let boundFound = scaleSteps.find(step => step.value === this.getBoundFromValue(bound.upper, map))

                if (boundFound) {
                    bound.color = this.hexToRgb(boundFound.color, boundFound.visible ? opacity : 0)
                }
            })

            tmpTilesData.center_tile = false
            if (map === 'one') {
                this.tilesDataOne = tmpTilesData
                return
            }

            this.tilesDataTwo = tmpTilesData
        },
        getBoundFromValue(upperBound, map) {
            let mapType = map === 'one' ? this.map_type_one : this.map_type_two
            if (mapType.ddi === 'operator' || !mapType.grouped) {
                return upperBound
            }

            if (mapType.grouped) {
                if (mapType.ddi === 'date') {
                    return this.dateFormat(Number.parseInt(upperBound), 'GMT');
                }

                let vehicles = map === 'one' ? this.vehiclesOne : this.vehiclesOne
                if (mapType.ddi === 'vehicle') {
                    let foundVehicle = vehicles.find(vehicle => vehicle.id === upperBound)
                    return foundVehicle ? foundVehicle.display_name : '---'
                }
            }
        },
        editScale(rateSteps, map) {
            this.scaleEdit = map
            this.rateStepsEdit = JSON.parse(JSON.stringify(rateSteps))
            this.originalRateSteps = JSON.parse(JSON.stringify(map === 'one' ? this.originalRateStepsOne : this.originalRateStepsTwo))
            this.minValue = map === 'one' ? this.minValueOne : this.minValueTwo
            this.opacity = map === 'one' ? this.opacityOne : this.opacityTwo
            this.showEditDialog = true
        },
        closeDialog() {
            this.showEditDialog = false
        },
        getFileNameOne() {
            this.tilesDataOne = {}
            this.rateStepsOne = []

            let config = {
                work_id: this.operation_one.id,
                ddi: this.map_type_one.ddi
            }

            this.operationsService.configMbtilesRaster(config)
                .then(response => {
                    this.previousMapEmptyOne = false
                    this.tilesZoomsOne = response.zooms
                    this.requestIdOne = response.request_id
                    this.operationMapCenterOne = response.center
                    this.operationMapBoundsOne = response.bounds
                    this.getScaleOne(null, this.requestIdOne)
                })
                .catch(error => {
                    if (error.response && error.response.status === 404) {
                        this.previousMapEmptyOne = true
                        this.$toast.add({
                            severity: 'error',
                            summary: this.$t('AppOperationsCompare.Erro'),
                            detail: this.$t('AppOperationsCompare.Não há dados para gerar o mapa desta operação'),
                            life: 5000
                        });
                        return
                    }

                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppOperationsCompare.Erro'),
                        detail: this.$t('AppOperationsCompare.Houve um erro ao recuperar as informações, Tente novamente'),
                        life: 5000
                    });
                })
        },
        getFileNameTwo() {
            this.tilesDataTwo = {}
            this.rateStepsTwo = []

            let config = {
                work_id: this.operation_two.id,
                ddi: this.map_type_two.ddi
            }

            this.operationsService.configMbtilesRaster(config)
                .then(response => {
                    this.previousMapEmptyTwo = false
                    this.tilesZoomsTwo = response.zooms
                    this.requestIdTwo = response.request_id
                    this.getScaleTwo(null, this.requestIdTwo)
                })
                .catch(error => {
                    if (error.response && error.response.status === 404) {
                        this.previousMapEmptyTwo = true
                        this.$toast.add({
                            severity: 'error',
                            summary: this.$t('AppOperationsCompare.Erro'),
                            detail: this.$t('AppOperationsCompare.Não há dados para gerar o mapa desta operação'),
                            life: 5000
                        });
                        return
                    }

                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppOperationsCompare.Erro'),
                        detail: this.$t('AppOperationsCompare.Houve um erro ao recuperar as informações, Tente novamente'),
                        life: 5000
                    });
                })
        },
        getScaleOne(scaleLimits, requestId) {
            let params = this.configParams(scaleLimits)
            this.operationsService.getInitialState(requestId, params)
                .then(response => {
                    this.layerColorBoundsOne = response.colors
                    this.processStepRateOne(response.colors.length, scaleLimits)
                    this.showEditDialog = false
                })
                .catch(() => {
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppOperationsCompare.Erro'),
                        detail: this.$t('AppOperationsCompare.Não foi possível carregar o mapa'),
                        life: 5000
                    });
                })
        },
        getScaleTwo(scaleLimits, requestId) {
            let params = this.configParams(scaleLimits)
            this.operationsService.getInitialState(requestId, params)
                .then(response => {
                    this.layerColorBoundsTwo = response.colors
                    this.processStepRateTwo(response.colors.length, scaleLimits)
                    this.showEditDialog = false
                })
                .catch(() => {
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppOperationsCompare.Erro'),
                        detail: this.$t('AppOperationsCompare.Não foi possível carregar o mapa'),
                        life: 5000
                    });
                })
        },
        configParams(scaleLimits) {
            if (!scaleLimits) {
                return null
            }

            return {
                num_quantile: scaleLimits.length - 1,
                bounds: JSON.stringify(scaleLimits)
            }
        },
        processStepRateOne(steps, scaleLimits) {
            let rateStepsTemp = [];
            this.layerColorBoundsOne = this.sortLayerColorBounds(this.map_type_one, this.layerColorBoundsOne)
            let COLORS_ONE = scaleLimits ? this.getUserColorsSet(this.rateStepsEdit) : this.buildColorRange(steps)

            for (const index in this.layerColorBoundsOne) {
                rateStepsTemp.push({
                    value: this.parseValue(this.map_type_one, this.layerColorBoundsOne, index, this.vehiclesOne),
                    color: COLORS_ONE[index],
                    percentage: this.formatPercent(this.layerColorBoundsOne[index].area.percent),
                    visible: true,
                    invalid: false
                })
            }

            if (!this.map_type_one.grouped) {
                this.minValueOne = this.layerColorBoundsOne[this.layerColorBoundsOne.length - 1].bound.lower
            }
            this.rateStepsOne = rateStepsTemp;
            if (scaleLimits === null) {
                this.originalRateStepsOne = JSON.parse(JSON.stringify(rateStepsTemp))
            }
            let fOne = this.focusOne
            this.focusOne = true
            this.tilesDataOne = {
                requestId: this.requestIdOne,
                zooms: this.tilesZoomsOne,
                grouped: this.map_type_one.grouped,
                color_bounds: this.buildColorBounds(this.layerColorBoundsOne, COLORS_ONE, this.opacityOne),
                center_tile: this.centerMap,
                center: this.operationMapCenterOne,
                bounds: this.operationMapBoundsOne
            }
            setTimeout(() => this.focusOne = fOne, 100)
        },
        processStepRateTwo(steps, scaleLimits) {
            let rateStepsTemp = [];
            this.layerColorBoundsTwo = this.sortLayerColorBounds(this.map_type_two, this.layerColorBoundsTwo)
            let COLORS_TWO = scaleLimits ? this.getUserColorsSet(this.rateStepsEdit) : this.buildColorRange(steps)

            for (const index in this.layerColorBoundsTwo) {
                rateStepsTemp.push({
                    value: this.parseValue(this.map_type_two, this.layerColorBoundsTwo, index, this.vehiclesTwo),
                    color: COLORS_TWO[index],
                    percentage: this.formatPercent(this.layerColorBoundsTwo[index].area.percent),
                    visible: true,
                    invalid: false
                })
            }

            if (!this.map_type_two.grouped) {
                this.minValueTwo = this.layerColorBoundsTwo[this.layerColorBoundsTwo.length - 1].bound.lower
            }
            this.rateStepsTwo = rateStepsTemp;
            if (scaleLimits === null) {
                this.originalRateStepsTwo = JSON.parse(JSON.stringify(rateStepsTemp))
            }
            let fTwo = this.focusTwo
            this.focusTwo = true
            this.tilesDataTwo = {
                requestId: this.requestIdTwo,
                zooms: this.tilesZoomsTwo,
                grouped: this.map_type_two.grouped,
                color_bounds: this.buildColorBounds(this.layerColorBoundsTwo, COLORS_TWO, this.opacityTwo),
                center_tile: this.centerMap,
                center: this.operationMapCenterOne,
                bounds: this.operationMapBoundsOne
            }
            setTimeout(() => this.focusTwo = fTwo, 100)
        },
        sortLayerColorBounds(mapType, layerColorBounds) {
            if (mapType.grouped && mapType.ddi !== 'date') {
                return layerColorBounds.sort((a, b) => a.bound.upper.localeCompare(b.bound.upper))
            }

            return layerColorBounds.sort((a, b) => b.bound.upper - a.bound.upper)
        },
        parseValue(mapType, layerColorBounds, index, vehicles) {
            if (mapType.value === 'date') {
                return this.dateFormat(Number.parseInt(layerColorBounds[index].bound.upper), 'GMT')
            }

            if (mapType.value === 'vehicle') {
                let foundVehicle = vehicles.find(vehicle => vehicle.id === layerColorBounds[index].bound.upper)
                if (foundVehicle) {
                    return foundVehicle.display_name
                }

                return '---'
            }

            return layerColorBounds[index].bound.upper
        },
        applyNewScale(rateSteps, opacity) {
            this.rateStepsEdit = JSON.parse(JSON.stringify(rateSteps))

            let newScale = []
            this.rateStepsEdit.forEach(step => {
                newScale.push(step.value)
            })

            if (this.scaleEdit === 'one') {
                newScale.push(this.minValueOne)
                this.opacityOne = opacity
                this.getScaleOne(newScale, this.requestIdOne)
                return
            }

            newScale.push(this.minValueTwo)
            this.opacityTwo = opacity
            this.getScaleTwo(newScale, this.requestIdTwo)
        },
        getStatistics(operation, map) {
            switch (operation.operation_type) {
                case 'SEEDING':
                    this.getOperationStatistics(operation, 'seedingStatistics', map)
                    break
                case 'HARVEST':
                    this.getOperationStatistics(operation, 'harvestStatistics', map)
                    break
                case 'MOWING':
                    this.getOperationStatistics(operation, 'mowingStatistics', map)
                    break
                case 'TILLAGE':
                    this.getOperationStatistics(operation, 'tillageStatistics', map)
                    break
                case 'DRY_APPLICATION':
                    this.getOperationStatistics(operation, 'dryApplicationStatistics', map)
                    break
                case 'LIQUID_APPLICATION':
                    this.getOperationStatistics(operation, 'liquidApplicationStatistics', map)
                    break
            }
        },
        getOperationStatistics(operation, endpoint, map) {
            this.operationsService.getOperationStatistics(operation.id, endpoint)
                .then(response => {
                    this.parseStatistics(this.buildStatistics(response, operation.operation_type), map)
                })
                .catch(() => {
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppOperationsCompare.Erro'),
                        detail: this.$t('AppOperationsCompare.Não foi possível recuperar as estatísticas desta operação'),
                        life: 5000
                    });
                })
        },
        parseStatistics(builtStatistics, map) {
            if (map === 'one') {
                this.statisticsOne = builtStatistics.statistics
                this.operatorsOne = builtStatistics.operators
                this.vehiclesOne = builtStatistics.vehicles
                this.varietiesOne = builtStatistics.varieties
                this.productsOne = builtStatistics.products
                return
            }

            this.statisticsTwo = builtStatistics.statistics
            this.operatorsTwo = builtStatistics.operators
            this.vehiclesTwo = builtStatistics.vehicles
            this.varietiesTwo = builtStatistics.varieties
            this.productsTwo = builtStatistics.products
        },
        initGetOperationMapOne() {
            this.centerMap = this.previousMapEmptyOne
            this.getFileNameOne()
        },
        initGetOperationMapTwo() {
            this.centerMap = this.previousMapEmptyOne
            this.getFileNameTwo()
        }
    },
    computed: {
        isScreenMobile: function () {
            return window.screen.width <= 1024;
        }
    },
    props: {
        operation_one: {
            type: Object
        },
        map_type_one: {
            type: Object
        },
        map_types_one: {
            type: Array
        },
        operations: {
            type: Array
        },
        operation_two: {
            type: Object
        },
        map_type_two: {
            type: Object
        },
        map_types_two: {
            type: Array
        },
        field_center: {
            type: Object
        },
        selected_field: {
            type: Object
        }
    },
    watch: {
        operation_one: function () {
            this.previousMapEmptyOne = true
            this.getStatistics(this.operation_one, 'one')
            if (this.map_type_one === this.map_types_one[0]) {
                this.initGetOperationMapOne()
            }
        },
        map_type_one: function () {
            this.initGetOperationMapOne()
        },
        operation_two: function () {
            this.previousMapEmptyTwo = true
            this.getStatistics(this.operation_two, 'two')
            if (this.map_type_two === this.map_types_two[0]) {
                this.initGetOperationMapTwo()
            }
        },
        map_type_two: function () {
            this.initGetOperationMapTwo()
        }
    },
    components: {
        Splitpanes,
        Pane,
        AppOperationsStats,
        AppTasksWorkOptions,
        AppGoogleMapsOperations,
        AppGoogleMapsTypeControl,
        AppOperationsWorkOptions,
        AppOperationsRateStepsEditDialog
    }
}

</script>

<style scoped lang="scss">
@import "src/assets/styles/constants";

.splitpanes.default-theme .splitpanes__pane {
    background-color: #fff;
}

.splitpanes__pane.stats {
    overflow-y: auto;
}

.splitpanes__pane.stats::-webkit-scrollbar {
    width: 10px;
}

.splitpanes__pane.stats::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(98, 95, 95, 0.8);
}

.splitpanes__pane.stats::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 2px rgba(98, 95, 95, 0.7);
}

.splitpanes__pane.stats::-webkit-scrollbar-thumb:hover {
    -webkit-box-shadow: inset 0 0 5px rgba(98, 95, 95, 0.7);
}

</style>
