import axios from '../axios/axios-auth'

export default class VehiclesManufacturersService {
    save(data) {
        return axios.post('/api/v1/manufacturers/vehicles', data).then(response => response.data)
    }

    update(data) {
        return axios.patch('/api/v1/manufacturers/vehicles', data).then(response => response.data)
    }

    findAll() {
        return axios.get('/api/v1/manufacturers/vehicles').then(response => response.data)
    }

    findById(id) {
        return axios.get('/api/v1/manufacturers/vehicles/' + id).then(response => response.data)
    }

    /**
     * V3
     */

    getManufacturers() {
        return axios.get('/api/v3/machine/manufacturers').then(response => response.data)
    }

    findAllModelsByManufacturerId(id) {
        return axios.get(`/api/v1/manufacturers/vehicles/${id}`).then(response => response.data)
    }
    
}
