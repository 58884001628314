<template>
    <Dialog :header="$t('AppConfigurationDialog.Atribuir à veículo')" :visible.sync="display" modal @hide="closeDialog()" :closable="!loadingTable">
        <template #header>
            <h3>{{ $t('AppConfigurationDialog.Atribuir Lista de Configurações') }}: <b>{{ configListTitle }}</b></h3>
        </template>

        <div style="width: 35vw">
            <p>{{
                    $t('AppConfigurationDialog.Selecione os veículos e clique em Enviar para enviar a Lista de Configurações para o dispositivo associado ao veículo')
                }}.</p>
            <DataTable :value="tableData" responsiveLAyout="scroll" :autoLayout="true" :filters.sync="filters"
                       :rowHover="true"
                       filterDisplay="row"
                       :selection.sync="selectedVehicles" :loading="loadingTable" dataKey="id" :paginator="true"
                       :rows="10" :currentPageReportTemplate="$t('AppConfigurationDialog.currentPageReportTemplate')"
                       paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                       :sortOrder="-1" sortField="status">

                <template #empty>
                    {{ $t('AppConfigurationDialog.Veículos não encontrados') }}
                </template>
                <template #loading>
                    {{ $t('AppConfigurationDialog.Carregando lista de veículos') }}
                </template>
                <Column selectionMode="multiple" headerStyle="display: flex; justify-content:start; width: 3.5rem"
                        bodyStyle="display: flex"></Column>
                <Column field="display_id" :header="$t('AppConfigurationDialog.Identificação')" sortable headerStyle="text-align: left"
                        :showFilterMenu="false"
                        bodyStyle="text-align: center">
                    <template #body="{data}">
                        {{ data.display_id }}
                    </template>

                    <template #filter="{filterModel,filterCallback}">
                        <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                                   class="p-column-filter"/>
                    </template>

                </Column>
                <Column field="identification_number" :header="$t('AppConfigurationDialog.Chassis')" sortable headerStyle="text-align: left"
                        :showFilterMenu="false"
                        bodyStyle="text-align: center">
                    <template #body="{data}">
                        {{ data.identification_number }}
                    </template>

                    <template #filter="{filterModel,filterCallback}">
                        <InputText type="text" v-model="filterModel.value" @input="filterCallback()"
                                   class="p-column-filter"/>
                    </template>

                </Column>

                <!--                NAO ESTA FUNCIONANDO-->
                <!--                <Column field="app_config_title" header="Lista de Configurações" sortable-->
                <!--                        headerStyle="text-align: center"-->
                <!--                        bodyStyle="text-align: center">-->
                <!--                    <template #body="{data}">-->
                <!--                        <div v-if="data.app_config_title">-->
                <!--                            {{ data.app_config_title }}-->
                <!--                        </div>-->
                <!--                        <div v-else>-->
                <!--                            <i>Sem configurações</i>-->
                <!--                        </div>-->
                <!--                    </template>-->
                <!--                </Column>-->
                <!--                <Column field="send_status" header="Status do Envio" sortable headerStyle="text-align: center"-->
                <!--                        bodyStyle="text-align: center">-->
                <!--                    <template #body="{data}">-->
                <!--                        <div v-if="data.status">-->
                <!--                            {{ data.status }}-->
                <!--                        </div>-->
                <!--                        <div v-else> &#45;&#45;</div>-->
                <!--                    </template>-->
                <!--                </Column>-->


            </DataTable>
        </div>

        <template #footer>
            <Button :disabled="loadingTable" :label="$t('AppConfigurationDialog.Cancelar')" icon="pi pi-times" class="p-button-text"
                    @click="closeDialog()"/>
            <Button :disabled="loadingTable" :label="$t('AppConfigurationDialog.Enviar')" icon="pi pi-check" @click="handleSendClicked"/>
        </template>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import InputText from 'primevue/inputtext';
import {FilterMatchMode} from "primevue/api";

export default {
    data() {
        return {
            filters: {
                'display_id': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'identification_number': {value: null, matchMode: FilterMatchMode.CONTAINS}
            },
            display: false,
            tableData: null,
            selectedVehicles: [],
        }
    },
    methods: {
        closeDialog() {
            this.display = false;
            this.tableData = null;
            this.$emit('hideDialog');
        },
        handleSendClicked() {
            if (this.verifySelections()) {
                //TODO REVER LÓGICA PARA MANIPULAR O ID DO VEÍCULO
                let vehiclesObj = []
                this.selectedVehicles.forEach((vehicle) => {
                    vehiclesObj.push({vehicle_id: vehicle.id})
                })

                let object = {
                    "vehicles": vehiclesObj
                }
                this.$emit("handleSendConfigListToVehicles", object);
            } else {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppConfigurationDialog.Erro'),
                    detail: this.$t('AppConfigurationDialog.Por favor, selecione pelo menos um veículo para enviar uma lista de configurações'),
                    life: 5000
                });
            }
        },
        verifySelections() {
            return this.selectedVehicles.length > 0;
        }
    },
    watch: {
        showDialog: function (val) {
            this.display = val;
            if (val) {
                this.tableData = this.vehiclesConfigList;
                this.selectedVehicles = [];
            }
        }
    },
    props: {
        showDialog: {
            type: Boolean,
            required: true
        },
        vehiclesConfigList: {
            type: Array,
            default: null
        },
        loadingTable: {
            type: Boolean,
            default: false,
            required: true
        },
        configListTitle: {
            type: String
        }
    },
    components: {
        Dialog, Button, DataTable, Column, ColumnGroup, InputText
    }
}
</script>

<style scoped lang="scss">

.table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

</style>
