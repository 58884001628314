<template>
    <div class="height-container">
        <div v-if="type === null" class="outer-container">
            <div class="title">{{$t('AppMachineReport.Selecione o tipo de operação')}}</div>

            <div class="container-boxes">
                <div class="box" @click="selectedType('PLANTING')">
                    <span>{{$t('AppMachineReport.Plantio')}}</span>
                    <img src="@/assets/icons/plantio.png">
                </div>
                <div class="box" @click="selectedType('HARVEST')">
                    <span>{{$t('AppMachineReport.Colheita')}}</span>
                    <img src="@/assets/icons/colheita.png">
                </div>
                <div class="box" @click="selectedType('PULVERIZATION')">
                    <span>{{$t('AppMachineReport.Pulverização')}}</span>
                    <img src="@/assets/icons/pulverizador.png">
                </div>
                <div class="box" @click="selectedType('SOLID_DISPENSERS')">
                    <span>{{$t('AppMachineReport.Distribuição')}}</span>
                    <img src="@/assets/icons/distribuidor.png">
                </div>
            </div>
            <app-button type="primary" @handleClick="generateReport" style="margin-top: 30px;"> 
                <i v-if="!loadingExport" class="pi pi-download" />
                <ProgressSpinner v-else style="width: 13px; height: 13px; margin-right: 6px;" />
                {{ $t('AppMachineReport.Exportar PDF') }}
            </app-button>
        </div>
        <app-machine-report-info v-else :type="type" :modelId="modelId" @cleanType="cleanType" />
        <app-machine-export ref="machineExport" />
    </div>
</template>

<script>
import AppMachineExport from "./AppMachineExport.vue";
import AppMachineReportInfo from './AppMachineReportInfo.vue';
import MachineReportService from '@/services/MachineReportService';
import AppButton from '@/components/common/AppButton.vue';
import ProgressSpinner from 'primevue/progressspinner';

export default {
    mounted(){
        this.getParams(this.$route.params)
        this.machineReportService = new MachineReportService()
    },
    data() {
        return {
            type: null,
            modelId: null,
            machineReportService: null,
            loadingExport: false
        }
    },
    methods: {
        selectedType(type){
            this.$router.push({ name: 'machine-report', params: { type: type, modelId: undefined } });
            this.type = type
        },
        cleanType(){
            this.$router.push({ name: 'machine-report', params: { type: undefined, modelId: undefined } });
            this.type = null
            this.modelId = null
        },
        getParams(params){
            this.modelId = params.modelId

            if(params.type === 'PLANTING' || params.type === 'HARVEST' || params.type === 'PULVERIZATION' || params.type === 'SOLID_DISPENSERS'){
                this.type = params.type
            }
        },
        async generateReport() {
            let operations = ['PLANTING', 'HARVEST', 'PULVERIZATION', 'SOLID_DISPENSERS'];
            try {
                this.loadingExport = true
                let results = await Promise.all(
                    operations.map(operation => 
                        this.machineReportService.getInfo(operation).then(data => ({ operation: this.typeText(operation), data }))
                    )
                );

                let resultToExport = results.map(({ operation, data }) => ({
                    operation,
                    machines: data.reduce((acc, { manufacturer, model }) => {
                        acc[manufacturer] = acc[manufacturer] || [];
                        acc[manufacturer].push(model);
                        return acc;
                    }, {})
                }));
                
                this.$refs.machineExport.generateReport(resultToExport);
            } catch (error) {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppMachineReport.Erro'),
                    detail: this.$t('AppMachineReport.Não foi possível carregar as organizações do revendedor'),
                    life: 5000
                });
            } finally {
                this.loadingExport = false
            }
        },
        typeText(type) {
            switch (type) {
                case 'PLANTING':
                    return this.$t(`AppMachineReport.Plantio`)
                case 'HARVEST':
                    return this.$t(`AppMachineReport.Colheita`)
                case 'PULVERIZATION':
                    return this.$t(`AppMachineReport.Pulverização`)
                case 'SOLID_DISPENSERS':
                    return this.$t(`AppMachineReport.Distribuição`)
                default:
                    type
            }
        }
    },
    watch: {
        '$route.params': function(val){
            if (Object.keys(val).length === 0 || (val.type === undefined && val.modelId === undefined)) {
                this.type = null;
                this.modelId = null;
                return
            }

            this.type = val.type
            this.modelId = val.modelId
        }
    },
    components: {
        AppMachineReportInfo, AppMachineExport, AppButton, ProgressSpinner
    }
}
</script>

<style scoped>

.height-container{
    background-color: #DADADA; 
    height: 100%; 
    min-height: 100vh;
}

.outer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.container-boxes {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 1px;
    height: 450px;
    width: 560px;
    background-color: #909090;
    border-radius: 10px;
    overflow: hidden;
}

.box {
    background-color: #DADADA;
    padding: 15px;
    font-size: 16px;
    color: #545454;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
}

.box:hover {
    background-color: #cacaca;
}

.box img{
    margin: 10px auto 0 auto;
    max-width: 160px;
    display: block;
}

.title{
    font-size: 25px;
    color: #545454;
    margin-bottom: 40px;
    font-weight: 700;
    padding: 0 10px;
}

@media (max-width: 1024px) {
    .height-container{
        min-height: calc(100vh - 50px);
    }

    .outer-container {
        height: calc(100vh - 50px);
    }
}

@media (max-width: 768px) {
    .outer-container{
        height: calc(100vh - 55px);
    }

    .box{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .box img{
        width: 100%;
        max-width: 160px;
    }

    .title{
        font-size: 23px;
        text-align: center;
    }

    .container-boxes {
        max-width: 560px;
        width: 90%;
    }
}

</style>