<template>
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__head kt-portlet__head--lg no-border-radius">
            </div>
            <div class="panel">
                <h4>{{ $t('AppOperationsCenterConnection.Conectando com Operations Center') }}</h4>
            </div>
        </div>
    </div>
</template>

<script>

import OperationsCenterService from "@/services/OperationsCenterService";
import JohnDeereService from "@/services/JohnDeereService";
import {mapGetters} from "vuex";
import {router} from "@/router";

export default {
    mounted() {
        this.operationsCenterService = new OperationsCenterService();
        this.johnDeereService = new JohnDeereService();
        this.getConnection();
    },
    data() {
        return {
            operationsCenterService: null,
            johnDeereService: null
        }
    },
    methods: {
        getConnection() {
            this.johnDeereService.getConnection(this.user.id).then((response) => {
                if (response.is_connected) {
                    router.push({name: 'operations-center-connection-callback'});
                } else {
                    this.getAuthorizationEndpoint()
                }
            }).catch((error) => {
                console.log(error);
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppOperationsCenterConnection.Operação falhou'),
                    detail: this.$t('AppOperationsCenterConnection.O serviço do Operations Center está indisponível'),
                    life: 5000
                });
                router.push({name: 'settings'});
            })
        },
        getAuthorizationEndpoint() {
            this.operationsCenterService.oAuth2WellKnownUrl().then((response) => {
                window.location.replace(response.authorization_endpoint + this.buildQueryStringForAuthorization())
            }).catch((error) => {
                console.log(error);
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppOperationsCenterConnection.Operação falhou'),
                    detail: this.$t('AppOperationsCenterConnection.O serviço do Operations Center está indisponível'),
                    life: 5000
                });
            })
        },
        buildQueryStringForAuthorization() {
            let queryParams = {
                "client_id": '0oa3cknprjwqyl7Fd5d7',
                "response_type": "code",
                "scope": 'ag1%20ag2%20ag3%20eq1%20eq2%20org1%20org2%20files%20offline_access%20work2',
                "redirect_uri": encodeURI(window.location.href) + '/callback',
                "state": this.guidGenerator(),
            }

            let queryParamsString = "?";
            for (let key in queryParams) {
                queryParamsString += key + "=" + queryParams[key] + "&";
            }

            return queryParamsString;
        },
        guidGenerator() {
            let S4 = function () {
                return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
            };
            return (S4() + S4() + S4() + S4() + S4() + S4() + S4() + S4());
        },
    },
    computed: {
        ...mapGetters([
            'user',
        ])
    },
}
</script>

<style scoped  lang="scss">

@import "src/assets/styles/constants";

.panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
}


</style>
