<template>
    <div></div>
</template>
<script>
    import AppButton from '@/components/common/AppButton.vue'
    import AppInput from '@/components/common/AppRegisterInput.vue'

    import {router} from '../../../router';
    import axios from "axios";

    export default {
        created() {
        },
        mounted() {
            this.fade();
            if (this.$route.params.token) {
                this.confirmAccount(this.$route.params.token)
            } else {
                router.push({name: 'login'});
            }
        },
        beforeDestroy() {
            this.fade();
        },
        data() {
            return {}
        },
        watch: {},
        components: {AppButton, AppInput},
        methods: {
            fade() {
                this.show = !this.show;
            },
            confirmAccount(id) {
                axios.get(process.env.VUE_APP_BACKEND_ADDRESS + "/auth/v1/account/" + id + "/confirm")
                    .then(() => {
                        router.push({path: '/login/account-confirmed/'});
                    }).catch(() => {
                    router.push({path: '/login/account-not-confirmed/'});
                })
            }
        },
    }
</script>

<style scoped>
    @import "../../../assets/styles/login-4.css";

    .container-transitions-enter-active {
        transition-duration: 2.1s !important;
        transition-property: opacity !important;
        transition-timing-function: ease !important;
    }

    .container-transitions-enter, .container-transitions-leave-active {
        opacity: 0 !important;
    }

</style>
