<template>
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__head kt-portlet__head--lg no-border-radius">
                <div class="kt-portlet__head-label">
                    <div class="row center">
                        <Dropdown v-model="selectedOrg" :options="organizations" optionLabel="display_name"
                            :placeholder="$t('AppAlertsCoaSensor.Organização')" class="auto-center" :dataKey="'id'" :filter="true"
                            @input="saveChosenOptionsToLocalStorage" style="width: 250px">
                            <template #option="slotProps" style="width: 500px">
                                <div v-if="loadingOrgs"> {{ $t('AppAlertsCoaSensor.Carregando, aguarde') }}</div>
                                <div style="padding-right: 20px" v-else-if="slotProps.option.name === 'ND'">
                                    -
                                </div>
                                <div style="padding-right: 20px" v-else>
                                    {{ slotProps.option.display_name }}
                                </div>
                            </template>
                        </Dropdown>
                        <app-button type="secondary"
                                    class="auto-center"
                                    style="background-color: #0D89EC; margin-left: 10px;"
                                    @handleClick="exportCsv">
                            <i class="pi pi-external-link" />
                            {{$t('AppAlertsCoaSensor.Exportar')}}
                        </app-button>
                    </div>
                </div>
            </div>
            <div class="kt-portlet__body no-padding" id="tableDiv">
                <div class="card data-table-width">
                    <DataTable :value="gridData" :paginator="true" :rows="50" :rowHover="true" :loading="loading"
                        filterDisplay="row" :filters.sync="filters" :sortOrder="-1" sortField="last_alert_time" stripedRows
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        :rowsPerPageOptions="[10, 25, 50]" responsiveLayout="scroll"
                        :currentPageReportTemplate="$t('AppAlertsCoaSensor.Mostrando {first} até {last} de {totalRecords} Alertas')"
                        class="p-datatable-striped">

                        <template #header>
                            <div style="display: flex; justify-content: space-between;">
                                <span style="font-size: 20px; font-weight: 500;">{{ $t('AppAlertsCoaSensor.Sensores') }}</span>
                                <Button :label="$t('AppAlertsCoaSensor.Adicionar')" icon="pi pi-plus" :disabled="!selectedOrg" @click="newSensor"/>
                            </div>
                        </template>
                        <template #empty>
                            {{ $t('AppAlertsCoaSensor.Sem dados para exibir') }}
                        </template>

                        <Column field="parameter" :header="$t('AppAlertsCoaSensor.Parâmetro')" headerClass="headerClass" :showFilterMenu="false"
                            :showClearButton="false" bodyClass="bodyClass" :sortable="true" :styles="{'min-width': '10rem'}">
                            <template #body="slotProps">
                                <span>{{ slotProps.data.parameter }}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column field="system" :header="$t('AppAlertsCoaSensor.Sistema')" headerClass="headerClass" :showFilterMenu="false"
                            :showClearButton="false" bodyClass="bodyClass" :sortable="true" :styles="{'min-width': '10rem'}">
                            <template #body="slotProps">
                                <span>{{ slotProps.data.system }}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column field="unit" :header="$t('AppAlertsCoaSensor.Unidade')" headerClass="headerClass" :showFilterMenu="false"
                            :showClearButton="false" bodyClass="bodyClass" :sortable="true" :styles="{'min-width': '10rem'}">
                            <template #body="slotProps">
                                <span>{{ slotProps.data.unit }}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column field="description" :header="$t('AppAlertsCoaSensor.Descrição')" headerClass="headerClass" :showFilterMenu="false"
                            :showClearButton="false" bodyClass="bodyClass" :sortable="true" :styles="{'min-width': '10rem'}">
                            <template #body="slotProps">
                                <span>{{ slotProps.data.description }}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column field="spn" :header="$t('AppAlertsCoaSensor.SPN')" headerClass="headerClass" :showFilterMenu="false"
                            :showClearButton="false" bodyClass="bodyClass" :sortable="true" :styles="{'min-width': '10rem'}">
                            <template #body="slotProps">
                                <span>{{ slotProps.data.spn }}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column field="description_spn" :header="$t('AppAlertsCoaSensor.Descrição SPN')" headerClass="headerClass" :showFilterMenu="false"
                            :showClearButton="false" bodyClass="bodyClass" :sortable="true" :styles="{'min-width': '10rem'}">
                            <template #body="slotProps">
                                <span>{{ slotProps.data.description_spn }}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column bodyClass="bodyClass" headerClass="headerClass" :styles="{'min-width': '10rem'}">
                            <template #body="slotProps">
                                <Button icon="pi pi-pencil" class="p-button-rounded"
                                    :class="'p-button-success'"
                                    style="margin-right: 25px" @click="handleEdit(slotProps.data)" />

                                <Button icon="pi pi-times" class="p-button-rounded"
                                    :class="'p-button-danger'" @click="handleDelete(slotProps.data)" />
                            </template>
                        </Column>
                    </DataTable>
                </div>

                <Dialog :visible.sync="showSensorDialog" :header="$t(isNewSensor ? 'AppAlertsCoaSensor.Cadastrar Sensor' : 'AppAlertsCoaSensor.Editar Sensor')"
                    :modal="true" :contentStyle="{ 'max-width': '90vw', 'width': '836px', 'overflow': 'visible' }">
                    <app-alerts-coa-sensor-form @saveForm="saveForm" :optionsSpnList="optionsSpnList" ref="sensorForm" />

                    <template #footer>
                        <Button :label="$t('AppAlertsCoaSensor.Cancelar')" icon="pi pi-times" class="p-button-text p-button-danger" @click="showSensorDialog = false"/>
                        <Button :label="$t('AppAlertsCoaSensor.Confirmar')" icon="pi pi-check" class="p-button-text" @click="$refs.sensorForm.validate()"/>
                    </template>
                </Dialog>

                <Dialog :visible.sync="deleteSensorDialog" :contentStyle="{ 'max-width': '90vw', 'width': '500px' }" :header="$t('AppAlertsCoaSensor.Excluir Sensor')" :modal="true">
                    <div class="confirmation-content">
                        <div>
                            {{ $t('AppAlertsCoaSensor.Tem certeza que deseja excluir o sensor?') }}<br>
                            {{ $t('AppAlertsCoaSensor.Todos os alertas cadastrados relacionados a ele serão excluídos também') }}
                        </div>
                    </div>
                    <template #footer>
                        <Button :label="$t('AppAlertsCoaSensor.Cancelar')" icon="pi pi-times" class="p-button-text p-button-danger" @click="deleteSensorDialog = false"/>
                        <Button :label="$t('AppAlertsCoaSensor.Confirmar')" icon="pi pi-check" class="p-button-text" @click="deleteSensor"/>
                    </template>
                </Dialog>
            </div>
        </div>
    </div>
</template>
<script>
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button/Button';
import Dialog from 'primevue/dialog/Dialog';
import AppButton from '../../../common/AppButton.vue';
import OrganizationsService from "@/services/OrganizationsService";
import AlertsCoaService from '../../../../services/AlertsCoaService';
import orderCustomFieldByNaturalOrder from "@/mixins/ArrayMixin";
import dateHourFormat from "@/mixins/DateMixin";
import getTimeFromSecondsDurationFormatMixin from "@/mixins/DateMixin";
import exportJsonToCsv from '@/mixins/JsonToCsvMixin';
import AppAlertsCoaSensorForm from './AppAlertsCoaSensorForm.vue';
import {FilterMatchMode} from 'primevue/api';

export default {
    mixins: [orderCustomFieldByNaturalOrder, dateHourFormat, getTimeFromSecondsDurationFormatMixin, exportJsonToCsv],
    created() {
        this.organizationsService = new OrganizationsService();
        this.alertsCoaService = new AlertsCoaService();
    },
    mounted() {
        this.getOrganizations();
    },
    data() {
        return {
            loading: false,
            organizationsService: null,
            alertsCoaService: null,
            loadingOrgs: false,
            selectedOrg: null,
            organizations: [],
            storedChosenOptions: {},
            gridData: [],
            filters: {
                'parameter': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'system': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'unit': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'description': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'spn': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'description_spn': {value: null, matchMode: FilterMatchMode.CONTAINS}
            },
            deleteSensorDialog: false,
            itemToDelete: null,
            showSensorDialog: false,
            isNewSensor: false,
            optionsSpnList: []
        }
    },
    methods: {
        exportCsv(){
            let csvData = [];
            JSON.parse(JSON.stringify(this.gridData)).forEach(data => csvData.push(data));

            const CSV_FIELDS = ['parameter', 'system', 'unit', 'description', 'spn', 'description_spn']
            const CSV_LABELS = [this.$t('AppAlertsCoaSensor.Parâmetro'), this.$t('AppAlertsCoaSensor.Sistema'), this.$t('AppAlertsCoaSensor.Unidade'), this.$t('AppAlertsCoaSensor.Descrição'), this.$t('AppAlertsCoaSensor.SPN'), this.$t('AppAlertsCoaSensor.Descrição SPN')]

            this.exportJsonToCsv(CSV_FIELDS, CSV_LABELS, csvData, "sensores");
        },
        getOrganizations() {
            let vm = this;
            this.loadingOrgs = true;
            this.organizationsService.getAllV3('VEHICLES').then((results) => {
                this.populateOrgsList(results);
                this.loadFilteredOptions();
            }).catch((error) => {
                vm.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppAlertsCoaSensor.Operação falhou'),
                    detail: this.$t('AppAlertsCoaSensor.Não foi possível buscar organizações'),
                    life: 5000
                });
                console.log(error)
            }).finally(() => {
                this.loadingOrgs = false;
            })
        },
        populateOrgsList(orgs) {
            this.organizations = this.orderCustomFieldByNaturalOrder(orgs, "name");
        },
        loadFilteredOptions() {
            this.storedChosenOptions = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_BAR_OPTIONS));

            if (!this.storedChosenOptions || !this.storedChosenOptions.clients || this.storedChosenOptions.clients.length === 0) {
                this.storedChosenOptions = {};
                return;
            }

            this.selectedOrg = this.storedChosenOptions['clients'].filter(a => this.organizations.some(b => a.id === b.id))[0];
        },
        saveChosenOptionsToLocalStorage() {
            this.storedChosenOptions['clients'] = [this.selectedOrg];
            this.storedChosenOptions['vehicles'] = [];
            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_BAR_OPTIONS, JSON.stringify(this.storedChosenOptions));
        },
        newSensor(){
            this.isNewSensor = true
            this.showSensorDialog = true
            let interval = setInterval(() => {
                if (this.$refs.sensorForm) {
                    clearInterval(interval);
                    this.$refs.sensorForm.cleanForm();
                }
            }, 50);
        },
        handleEdit(val) {
            this.isNewSensor = false
            this.showSensorDialog = true
            let interval = setInterval(() => {
                if (this.$refs.sensorForm) {
                    clearInterval(interval);
                    this.$refs.sensorForm.editForm(val);
                }
            }, 50);
        },
        saveForm(form, isNewForm){
            this.showSensorDialog = false
            let formId = form.id
            delete form.id
            delete form.organization_id
            form.spn = form.spn.spn
            form.description_spn = this.optionsSpnList.find(x => x.spn == form.spn).description

            if(isNewForm){
                this.alertsCoaService.createSensor(this.selectedOrg.id, form).then((data) => {
                    this.$toast.add({
                        severity: 'success',
                        summary: this.$t('AppAlertsCoaSensor.Criado com sucesso'),
                        life: 5000
                    });
                    this.getSensors(this.selectedOrg)
                }).catch((error) => {
                    if (!error.response || error.response.status !== 403) {
                        this.$toast.add({
                            severity: 'error',
                            summary: this.$t('AppAlertsCoaSensor.Operação falhou'),
                            detail: this.$t('AppAlertsCoaSensor.Não foi possível criar o sensor'),
                            life: 5000
                        });
                        return;
                    }
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppAlertsCoaSensor.Você não tem acesso a esta funcionalidade'),
                        life: 5000
                    });
                })
                return
            }

            this.alertsCoaService.editSensor(this.selectedOrg.id, form, formId).then((data) => {
                    this.$toast.add({
                        severity: 'success',
                        summary: this.$t('AppAlertsCoaSensor.Editado com sucesso'),
                        life: 5000
                    });
                    this.getSensors(this.selectedOrg)
                }).catch((error) => {
                    if (!error.response || error.response.status !== 403) {
                        this.$toast.add({
                            severity: 'error',
                            summary: this.$t('AppAlertsCoaSensor.Operação falhou'),
                            detail: this.$t('AppAlertsCoaSensor.Não foi possível editar o sensor'),
                            life: 5000
                        });
                        return;
                    }
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppAlertsCoaSensor.Você não tem acesso a esta funcionalidade'),
                        life: 5000
                    });
                })
        },
        handleDelete(val) {
            this.deleteSensorDialog = true
            this.itemToDelete = val
        },
        deleteSensor(){
            this.deleteSensorDialog = false
            this.alertsCoaService.deleteSensor(this.selectedOrg.id, this.itemToDelete.id).then((data) => {
                    this.$toast.add({
                        severity: 'success',
                        summary: this.$t('AppAlertsCoaSensor.Excluído com sucesso'),
                        life: 5000
                    });
                    this.getSensors(this.selectedOrg)
                }).catch((error) => {
                    if (!error.response || error.response.status !== 403) {
                        this.$toast.add({
                            severity: 'error',
                            summary: this.$t('AppAlertsCoaSensor.Operação falhou'),
                            detail: this.$t('AppAlertsCoaSensor.Não foi possível excluir o sensor'),
                            life: 5000
                        });
                        return;
                    }
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppAlertsCoaSensor.Você não tem acesso a esta funcionalidade'),
                        life: 5000
                    });
                })
        },
        getSensors(val){
            this.loading = true
            this.alertsCoaService.getSensorsByOrg(val.id).then((data) => {
                this.gridData = data
                this.getSPNlist();
            }).catch((error) => {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppAlertsCoaSensor.Operação falhou'),
                    detail: this.$t('AppAlertsCoaSensor.Não foi possível buscar os sensores'),
                    life: 5000
                });
                console.log(error)
            }).finally(() => {
                this.loading = false;
            })
        },
        getSPNlist(){
            this.loading = true
            this.alertsCoaService.getSPNlist(this.selectedOrg.id).then((data) => {
                data.forEach(item => {
                    item.name = "(" + item.spn + ") - " + item.description
                })
                this.optionsSpnList = data
            }).catch((error) => {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppAlertsCoaSensor.Operação falhou'),
                    detail: this.$t('AppAlertsCoaSensor.Não foi possível buscar a lista de SPNs'),
                    life: 5000
                });
                console.log(error)
            }).finally(() => {
                this.loading = false;
            })
        }
    },
    watch: {
        selectedOrg: function (val) {
            this.getSensors(val)
        }
    },
    components: {
        Dropdown,
        MultiSelect,
        DataTable,
        Column,
        InputText,
        Button,
        Dialog,
        AppButton,
        AppAlertsCoaSensorForm
    },
    computed: {

    }
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/primevue";
@import "src/components/views/alerts/style";

@media (max-width: 1024px){
    .data-table-width{
        width: 100vw;
    }
}

@media (min-width: 1025px){
    .data-table-width{
        max-width: calc(100vw - 140px);
    }
}
</style>
