<template>
    <div>
        <div style="display: flex; gap: 10px;">
            <div style="flex: 1;">
                <div>{{ $t('AppAlertsCoaAlertsConfigForm.Código do Alerta') }}</div>
                <InputText style="width: 100%; height: 40px;" v-model="form['code_alert']" 
                    :class="{'p-invalid': $v.form.code_alert.$error && $v.form.code_alert.$dirty}" />
            </div>
            <div style="flex: 1;">
                <div>{{ $t('AppAlertsCoaAlertsConfigForm.Sensor') }}</div>
                <Dropdown v-model="form['sensor_id']" :options="optionsSensors" :filter="true"
                    style="width: 100%; height: 40px; max-width: 276px;" :showClear="true" optionLabel="parameter" optionValue="id"
                    :class="{'p-invalid': $v.form.sensor_id.$error && $v.form.sensor_id.$dirty}" />
            </div>
            <div style="flex: 1;">
                <div>{{ $t('AppAlertsCoaAlertsConfigForm.Nível Criticidade') }}</div>
                <Dropdown v-model="form['criticality_level']" :options="optionsCriticalityLevel" :filter="true"
                        style="width: 100%; height: 40px; max-width: 276px;" :showClear="true" optionLabel="code" optionValue="code"
                        :class="{'p-invalid': $v.form.criticality_level.$error && $v.form.criticality_level.$dirty}">
                    <template #value="slotProps">
                        <span :class="'badge criticalityLevel-' + slotProps.value" style="margin-right: 5px;">
                            {{ getCriticalityLevel(slotProps.value) }}
                        </span>
                    </template>
                    <template #option="slotProps">
                        <span :class="'badge criticalityLevel-' + slotProps.option.code">
                            {{ getCriticalityLevel(slotProps.option.code) }}
                        </span>
                    </template>
                </Dropdown>
            </div>
        </div>

        <div style="display: flex; gap: 10px; margin-top: 10px;">
            <div style="flex: 1;">
                <div>{{ $t('AppAlertsCoaAlertsConfigForm.Fabricante') }}</div>
                <Dropdown v-model="form['manufacturer_id']" :options="optionsManufacturers" :filter="true" :dataKey="'id'"
                    style="width: 100%; height: 40px; max-width: 276px;" :showClear="true" optionLabel="name" optionValue="id"
                    :class="{'p-invalid': $v.form.manufacturer_id.$error && $v.form.manufacturer_id.$dirty}" />
            </div>
            <div style="flex: 1;">
                <div>{{ $t('AppAlertsCoaAlertsConfigForm.Modelo') }}</div>
                <Dropdown v-model="form['vehicles_manufacturer_models_id']" :options="optionsVehicles" :filter="true"
                    style="width: 100%; height: 40px; max-width: 276px;" :showClear="true" optionLabel="name" optionValue="id"
                    @change="changeVehiclesManufacturerModels" :disabled="!this.form.manufacturer_id" :dataKey="'id'"
                    :class="{'p-invalid': $v.form.vehicles_manufacturer_models_id.$error && $v.form.vehicles_manufacturer_models_id.$dirty}" />
            </div>
            <div style="flex: 1;">
                <div>{{ $t('AppAlertsCoaAlertsConfigForm.Status') }}</div>
                <Dropdown v-model="form['status']" :options="optionsStatus" :filter="true"
                        style="width: 100%; height: 40px; max-width: 276px;" :showClear="true" optionLabel="code" optionValue="code"
                        :class="{'p-invalid': $v.form.status.$error && $v.form.status.$dirty}">
                    <template #value="slotProps">
                        <span :class="'badge statusAlert-' + slotProps.value" style="margin-right: 5px;">
                            {{ getStatus(slotProps.value) }}
                        </span>
                    </template>
                    <template #option="slotProps">
                        <span :class="'badge statusAlert-' + slotProps.option.code">
                            {{ getStatus(slotProps.option.code) }}
                        </span>
                    </template>
                </Dropdown>
            </div>
        </div>
        
        <div style="display: flex; gap: 10px; margin-top: 10px;">
            <div style="flex: 1;">
                <div>{{ $t('AppAlertsCoaAlertsConfigForm.Valor de Alerta Mínimo Atenção') }}</div>
                <div class="p-inputgroup" style="width: 100%; height: 40px;">
                    <span class="p-inputgroup-addon">&lt;</span>
                    <InputNumber v-model="form['alert_value_min_attention']" mode="decimal" :minFractionDigits="0" :maxFractionDigits="20"
                        :class="{'p-invalid': $v.form.alert_value_min_attention.$error && $v.form.alert_value_min_attention.$dirty}"/>
                </div>
            </div>
            <div style="flex: 1;">
                <div>{{ $t('AppAlertsCoaAlertsConfigForm.Valor de Alerta Mínimo Crítico') }}</div>
                <div class="p-inputgroup" style="width: 100%; height: 40px;">
                    <span class="p-inputgroup-addon">&lt; <span style="position: relative; top: 1.5px; margin-left: 5px;"> =</span></span>
                    <InputNumber v-model="form['alert_value_min_critical']" mode="decimal" :minFractionDigits="0" :maxFractionDigits="20"
                        :class="{'p-invalid': $v.form.alert_value_min_critical.$error && $v.form.alert_value_min_critical.$dirty}"/>
                </div>
            </div>
            <div style="flex: 1;">
                <div>{{ $t('AppAlertsCoaAlertsConfigForm.Valor de Alerta Mínimo Tempo') }}</div>
                <div class="p-inputgroup" style="width: 100%; height: 40px;">
                    <span class="p-inputgroup-addon">></span>
                    <InputNumber v-model="form['alert_value_min_time']" :min="0"
                        :class="{'p-invalid': $v.form.alert_value_min_time.$error && $v.form.alert_value_min_time.$dirty}"/>
                    <span class="p-inputgroup-addon">seg</span>
                </div>
            </div>
        </div>

        <div style="display: flex; gap: 10px; margin-top: 10px;">
            <div style="flex: 1;">
                <div>{{ $t('AppAlertsCoaAlertsConfigForm.Valor de Alerta Máximo Atenção') }}</div>
                <div class="p-inputgroup" style="width: 100%; height: 40px;">
                    <span class="p-inputgroup-addon">></span>
                    <InputNumber v-model="form['alert_value_max_attention']" mode="decimal" :minFractionDigits="0" :maxFractionDigits="20"
                        :class="{'p-invalid': $v.form.alert_value_max_attention.$error && $v.form.alert_value_max_attention.$dirty}"/>
                </div>
            </div>
            <div style="flex: 1;">
                <div>{{ $t('AppAlertsCoaAlertsConfigForm.Valor de Alerta Máximo Crítico') }}</div>
                <div class="p-inputgroup" style="width: 100%; height: 40px;">
                    <span class="p-inputgroup-addon">> <span style="position: relative; top: 1.5px; margin-left: 5px;"> =</span></span>
                    <InputNumber v-model="form['alert_value_max_critical']" mode="decimal" :minFractionDigits="0" :maxFractionDigits="20"
                        :class="{'p-invalid': $v.form.alert_value_max_critical.$error && $v.form.alert_value_max_critical.$dirty}"/>
                </div>
            </div>
            <div style="flex: 1;">
                <div>{{ $t('AppAlertsCoaAlertsConfigForm.Valor de Alerta Máximo Tempo') }}</div>
                <div class="p-inputgroup" style="width: 100%; height: 40px;">
                    <span class="p-inputgroup-addon">></span>
                    <InputNumber v-model="form['alert_value_max_time']" :min="0"
                        :class="{'p-invalid': $v.form.alert_value_max_time.$error && $v.form.alert_value_max_time.$dirty}"/>
                    <span class="p-inputgroup-addon">seg</span>
                </div>
            </div>
        </div>
        
        <div style="display: flex; gap: 10px; margin-top: 10px;">
            <div style="flex: 1;">
                <div>{{ $t('AppAlertsCoaAlertsConfigForm.Mensagem de Alerta Mínimo Atenção') }}</div>
                <InputText style="width: 100%; height: 40px;" v-model="form['alert_message_min_attention']" 
                    :class="{'p-invalid': $v.form.alert_message_min_attention.$error && $v.form.alert_message_min_attention.$dirty}"/>
            </div>
            <div style="flex: 1;">
                <div>{{ $t('AppAlertsCoaAlertsConfigForm.Mensagem de Alerta Mínimo Crítico') }}</div>
                <InputText style="width: 100%; height: 40px;" v-model="form['alert_message_min_critical']" 
                    :class="{'p-invalid': $v.form.alert_message_min_critical.$error && $v.form.alert_message_min_critical.$dirty}"/>
            </div>
            <div style="flex: 1;">
                <div>{{ $t('AppAlertsCoaAlertsConfigForm.Valor Referência Min') }}</div>
                <InputNumber style="width: 100%; height: 40px;" v-model="form['reference_value_chart_min']" mode="decimal" :minFractionDigits="0"
                    :maxFractionDigits="20" :class="{'p-invalid': $v.form.reference_value_chart_min.$error && $v.form.reference_value_chart_min.$dirty}" />
            </div>
        </div>

        <div style="display: flex; gap: 10px; margin-top: 10px;">
            <div style="flex: 1;">
                <div>{{ $t('AppAlertsCoaAlertsConfigForm.Mensagem de Alerta Máximo Atenção') }}</div>
                <InputText style="width: 100%; height: 40px;" v-model="form['alert_message_max_attention']" 
                    :class="{'p-invalid': $v.form.alert_message_max_attention.$error && $v.form.alert_message_max_attention.$dirty}"/>
            </div>
            <div style="flex: 1;">
                <div>{{ $t('AppAlertsCoaAlertsConfigForm.Mensagem de Alerta Máximo Crítico') }}</div>
                <InputText style="width: 100%; height: 40px;" v-model="form['alert_message_max_critical']"
                    :class="{'p-invalid': $v.form.alert_message_max_critical.$error && $v.form.alert_message_max_critical.$dirty}" />
            </div>
            <div style="flex: 1;">
                <div>{{ $t('AppAlertsCoaAlertsConfigForm.Valor Referência Max') }}</div>
                <InputNumber style="width: 100%; height: 40px;" v-model="form['reference_value_chart_max']" mode="decimal" :minFractionDigits="0" 
                    :maxFractionDigits="20" :class="{'p-invalid': $v.form.reference_value_chart_max.$error && $v.form.reference_value_chart_max.$dirty}" />
            </div>
        </div>

        <div style="display: flex; gap: 10px; margin-top: 10px;">
            <div style="flex: 1;">
                <div>{{ $t('AppAlertsCoaAlertsConfigForm.Source') }}</div>
                <InputNumber style="width: 100%; height: 40px;" v-model="form['source']" />
                <small style="display: block; margin-top: 4px; color: #6c757d;">{{ $t('AppAlertsCoaAlertsConfigForm.Preencha esse campo caso a source seja obrigatória') }}</small>
            </div>
            <div style="flex: 1;"></div>
            <div style="flex: 1;"></div>
        </div>
    </div>
</template>

<script>
import Button from 'primevue/button/Button';
import Dialog from 'primevue/dialog/Dialog';
import InputText from 'primevue/inputtext/InputText';
import InputNumber from 'primevue/inputnumber';
import Dropdown from 'primevue/dropdown';
import SelectButton from 'primevue/selectbutton';
import {VEHICLES_TYPES, CRITICALY_LEVELS, STATUSES_ALERT} from '../AppOptionsCoa';
import {required} from 'vuelidate/lib/validators';
import VehiclesManufacturersService from '../../../../services/VehiclesManufacturersService';

export default {
    props: {
        optionsManufacturers: {
            type: Array,
        },
        optionsSensors: {
            type: Array,
        },
    },
    data() {
        return {
            form: {},
            optionsCriticalityLevel: CRITICALY_LEVELS,
            optionsStatus: STATUSES_ALERT,
            isNewForm: false,
            optionsVehicles: []
        }
    },
    created() {
        this.vehiclesManufacturersService = new VehiclesManufacturersService();
    },
    methods: {
        validate(){
            this.$v.form.$touch();
            if (!this.$v.form.$invalid) {
                delete this.form.parameter
                delete this.form.unit
                this.$emit('saveAlertConfig', this.form, this.isNewForm);
            } else {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppAlertsCoaAlertsConfigForm.Por favor preencha todos os campos obrigatórios'),
                    life: 5000
                });
            }
        },
        cleanForm(){
            this.isNewForm = true
            this.form = {}
        },
        editForm(val){
            this.isNewForm = false
            this.form = {}
            this.form = { ...val }
            this.form.vehicles_manufacturer_models_id = this.optionsManufacturers.find(item => item.name === val.vehicle_name);
        },
        getCriticalityLevel(status) {
            let result = this.optionsCriticalityLevel.find(({code}) => code === status);
            if (result) {
                return result.name;
            }
        },
        getStatus(status) {
            let result = this.optionsStatus.find(({code}) => code === status);
            if (result) {
                return result.name;
            }
        },
        changeVehiclesManufacturerModels(val){
            this.form.vehicle_type = VEHICLES_TYPES.find(item => item.value === this.optionsVehicles.find(element => element.id === val.value).vehicle_type).name
            this.form.vehicle_name = this.optionsVehicles.find(element => element.id === val.value).name
        }
    },
    watch: {
        'form.manufacturer_id': function (val) {           
            this.vehiclesManufacturersService.findAllModelsByManufacturerId(val)
                .then((data) => {
                    let newData = []
                    data.models.forEach(model => {
                        newData.push({
                            id: model.id,
                            name: model.name,
                            vehicle_type: model.vehicle_type
                        })
                    });

                    if(this.form.vehicles_manufacturer_models_id){
                        this.form.vehicles_manufacturer_models_id = null
                    }
                    this.optionsVehicles = newData;
                })
                .catch((error) => {
                    if (!error.response || error.response.status !== 403) {
                        this.$toast.add({
                            severity: 'error',
                            summary: this.$t('AppAlertsCoaAlertsConfigForm.Operação falhou'),
                            detail: this.$t('AppAlertsCoaAlertsConfigForm.Não foi possível buscar os modelos'),
                            life: 5000
                        });
                        return;
                    }
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppAlertsCoaAlertsConfigForm.Você não tem acesso a esta funcionalidade'),
                        life: 5000
                    });
                });
        },
        optionsVehicles: function (val) {
            if(!this.isNewForm){
                this.form.vehicles_manufacturer_models_id = val.find(item => item.name === this.form.vehicle_name).id;
            }
        }
    },
    validations: {
        form: {
            code_alert: {required},
            sensor_id: {required},
            vehicles_manufacturer_models_id: {required},
            criticality_level: {required},
            reference_value_chart_min: {required},
            reference_value_chart_max: {required},
            alert_value_min_attention: {required},
            alert_value_min_critical: {required},
            alert_value_min_time: {required},
            alert_value_max_attention: {required},
            alert_value_max_critical: {required},
            alert_value_max_time: {required},
            alert_message_min_attention: {required},
            alert_message_min_critical: {required},
            alert_message_max_attention: {required},
            alert_message_max_critical: {required},
            status: {required},
            manufacturer_id: {required}
        }
    },
    components: {
        Dialog, Button, InputText, Dropdown, InputNumber, SelectButton
    }
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/primevue";
@import "src/components/views/alerts-coa/style";
</style>