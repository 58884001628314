<template>
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__head kt-portlet__head--lg no-border-radius">
                <div class="kt-portlet__head-label">
                    <Dropdown v-model="selectedOrg" :options="orgsList"
                              optionLabel="display_name" :placeholder="$t('AppAlertsDictionary.Organização')"
                              class="auto-center" :dataKey="'id'"
                              :filter="true"
                              @input="saveChosenOptionsToLocalStorage">
                              style="width: 250px">
                        <template #option="slotProps" style="width: 500px">
                            <div v-if="loadingOrgs"> {{$t('AppAlertsDictionary.Carregando, aguarde')}}</div>
                            <div style="padding-right: 20px" v-else-if="slotProps.option.name === 'ND'">
                                -
                            </div>
                            <div style="padding-right: 20px" v-else>
                                {{slotProps.option.display_name}}
                            </div>
                        </template>
                    </Dropdown>
                </div>
                <div class="kt-portlet__head-toolbar">
                    <div class="kt-portlet__head-wrapper">
                        <app-button v-show="selectedOrg" type="primary" @handleClick="goToNew">
                            <i class="glyphicons glyphicons-edit"></i>{{$t('AppAlertsDictionary.Modificar')}}
                        </app-button>
                    </div>
                </div>
            </div>
            <div v-if="!selectedOrg" style="display: flex; justify-content: center;align-items: center;font-size: 18px;margin-top:2rem">
                {{$t('AppAlertsDictionary.Selecione uma organização')}}
            </div>
            <div v-else class="kt-portlet__body no-padding" id="tableDiv">
                <div class="card data-table-width">
                <DataTable :value="gridData" :paginator="true" :rows="50" :loading="loading"
                           :sortOrder="-1" sortField="created_date"
                           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                           :rowsPerPageOptions="[10,25,50]"
                           dataKey="identification"
                           :currentPageReportTemplate="$t('AppAlertsDictionary.currentPageReportTemplate')"
                           :filters.sync="filters"
                           class="p-datatable-striped"
                           filterDisplay="row" responsiveLayout="scroll" removableSort>
                    <template #empty>
                        {{$t('AppAlertsDictionary.Nenhum registro localizado')}}
                    </template>
                    <template #loading>
                        {{$t('AppAlertsDictionary.Carregando registros, por favor aguarde')}}
                    </template>
                    <Column field="created_date" :header="$t('AppAlertsDictionary.Horário')" headerClass="headerClass" bodyClass="bodyClass" :styles="{'min-width': '115px'}"
                            :sortable="true" filterMatchMode="contains" :showFilterMenu="false" :showClearButton="false">
                        <template #body="slotProps">
                            <span style="position: relative; right: 9px;">{{slotProps.data.created_date}}</span>
                        </template>
                        <template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                        </template>
                    </Column>
                    <Column field="code" :header="$t('AppAlertsDictionary.Código')" headerClass="headerClass" bodyClass="bodyClass" :styles="{'min-width': '115px'}"
                            :sortable="true" filterMatchMode="contains" :showFilterMenu="false" :showClearButton="false">
                        <template #body="slotProps">
                            <span style="position: relative; right: 9px;">{{slotProps.data.code}}</span>
                        </template>
                        <template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                        </template>
                    </Column>
                    <Column field="description" :header="$t('AppAlertsDictionary.Descrição')" headerClass="headerClass" bodyClass="bodyClass" :styles="{'min-width': '115px'}"
                            :sortable="true" filterMatchMode="contains" :showFilterMenu="false" :showClearButton="false">
                        <template #body="slotProps">
                            <span style="position: relative; right: 9px;">{{slotProps.data.description}}</span>
                        </template>
                        <template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                        </template>
                    </Column>
                    <Column field="priority" :header="$t('AppAlertsDictionary.Prioridade')" headerClass="headerClass" bodyClass="bodyClass" :styles="{'min-width': '115px', 'max-width': '200px'}"
                            :sortable="true" filterMatchMode="in" :showFilterMenu="false" :showClearButton="false">
                        <template #body="{data}">
                            <span :class="'badge priorities-' + data.priority" style="position: relative; right: 9px;">
                            {{getPriority(data.priority)}}
                            </span>
                        </template>
                        <template #filter class="headerClass">
                            <MultiSelect v-model="selectedPriority" :options="priorities" optionLabel="name"
                                         style="width: 100%" appendTo="tableDiv"
                                         optionValue="code" :placeholder="$t('AppAlertsDictionary.Selecione')" class="p-column-filter">
                                <template #value="slotProps">
                                    <span v-for="singleValue in slotProps.value" :class="'badge priorities-' + singleValue">
                                        {{ getPriority(singleValue) }}
                                    </span>
                                </template>
                                <template #option="slotProps">
                                    <span :class="'badge priorities-' + slotProps.option.code">
                                        {{getPriority(slotProps.option.code)}}
                                    </span>
                                </template>
                            </MultiSelect>
                        </template>
                    </Column>
                    <Column field="organization" :header="$t('AppAlertsDictionary.Organização')" headerClass="headerClass"
                            bodyClass="bodyClass" :sortable="true" filterMatchMode="contains"
                            :showFilterMenu="false" :showClearButton="false">
                        <template #body="slotProps">
                            {{slotProps.data.organization}}
                        </template>
                        <template #filter class="headerClass">
                            <InputText type="text" v-model="filters['organization']" class="p-column-filter"/>
                        </template>
                    </Column>
                    <Column :header="$t('AppAlertsDictionary.Ação')" headerClass="headerClass" bodyClass="bodyClass">
                        <template #body="slotProps">
                            <div style="min-width: 10px">
                                <Button @click="edit(slotProps.data)" icon="pi pi-pencil" style="position: relative; right: 9px;"
                                        class="p-button-rounded actions" v-tooltip.bottom="$t('AppAlertsDictionary.Editar')"/>
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </div>
            </div>
        </div>

    <app-alerts-dictionary-modal :displayDialogEditAlert="displayDialogEditAlert"
                                 @handleSalve="handleSalveEditAlert"
                                 @handleClose="handleCloseModalAlertsEdit"
                                 :alertEditDetails="alertEditDetails"></app-alerts-dictionary-modal>
    </div>
</template>

<script>
import AppButton from "../../../../common/AppButton";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import MultiSelect from 'primevue/multiselect';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import ProgressBar from 'primevue/progressbar';
import Button from 'primevue/button';
import dateHourFormat from "../../../../../mixins/DateMixin";
import AlertsDictionaryService from "../../../../../services/AlertsDictionaryService";
import {PRIORITIES} from '../AppOptions';
import AppAlertsDictionaryModal from "./AppAlertsDictionaryModal";
import Tooltip from 'primevue/tooltip';
import {FilterMatchMode} from 'primevue/api';
import OrganizationsService from "@/services/OrganizationsService";
import orderCustomFieldByNaturalOrder from "../../../../../mixins/ArrayMixin";

export default {
    mixins: [dateHourFormat, orderCustomFieldByNaturalOrder],
    mounted() {
        this.alertsDictionaryService = new AlertsDictionaryService();
        this.organizationsService = new OrganizationsService();
        this.getOrganizations();
    },
    data() {
        return {
            gridData: [],
            loading: false,
            selectedPriority: null,
            filters: {
                'customer': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'priority': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'description': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'code': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'created_date': {value: null, matchMode: FilterMatchMode.CONTAINS}
            },
            alertsDictionaryService: null,
            priorities: PRIORITIES,
            alertEditDetails: null,
            displayDialogEditAlert: false,
            originalResponse: null,
            organizationsService: null,
            orgsList: [],
            selectedOrg: null,
            loadingOrgs: false,
            storedChosenOptions: {}
        }
    },
    methods: {
        getOrganizations() {
            this.loadingOrgs = true
            this.organizationsService.getAllV3('ALERTS')
                .then((response) => {
                    this.populateOrgsList(response);
                    this.loadFilteredOptions()
                }).catch((error) => {
                console.log(error);
            }).finally(() => {
                this.loadingOrgs = false
            })
        },
        populateOrgsList(orgs) {
            this.orgsList = this.orderCustomFieldByNaturalOrder(orgs, "name");
        },
        loadFilteredOptions() {
            this.storedChosenOptions = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_BAR_OPTIONS));

            if (!this.storedChosenOptions || !this.storedChosenOptions.clients || this.storedChosenOptions.clients.length === 0) {
                this.storedChosenOptions = {};
                return;
            }

            this.selectedOrg = this.storedChosenOptions['clients'].filter(a => this.orgsList.some(b => a.id === b.id))[0];
        },
        getAll(org) {
            this.loading = true;
            this.alertsDictionaryService.getAllV3(org.id).then(data => {
                this.gridData = data
                this.gridData.forEach(element => {
                    element.created_date = this.dateHourFormat(element.created_date);
                });
                this.originalResponse = this.gridData;
            }).catch(error => {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppAlertsDictionary.Problemas ao listar'),
                    detail: this.$t('AppAlertsDictionary.Não foi possível recuperar os dados, tente mais tarde'),
                    life: 5000
                });
            }).finally(() => {
                this.loading = false;
            })
        },
        getPriority(priority) {
            let result = this.priorities.find(({code}) => code === priority);
            if (result) {
                return result.name;
            }
        },
        handleSalveEditAlert(data) {
            if (data.id) {
                this.update(data);
            } else {
                this.save(data);
            }
        },
        save(data) {
            this.alertsDictionaryService.saveV3(this.selectedOrg.id, data)
                .then(response => {
                    this.$toast.add({
                        severity: 'success',
                        summary: this.$t('AppAlertsDictionary.Operação realizada com sucesso'),
                        life: 5000
                    });
                    this.alertEditDetails = {};
                    this.displayDialogEditAlert = false;
                    this.getAll(this.selectedOrg);

                }).catch(error => {
                let message = this.$t('AppAlertsDictionary.Não foi possivel completar a operação, tente novamente');
                if (error.response && error.response.data && error.response.data.details) {
                    message = error.response.data.details;
                }

                this.$toast.add({
                    severity: 'error',
                    summary: message,
                    life: 5000
                });
            })

        },
        update(data) {
            this.alertsDictionaryService.updateV3(this.alertEditDetails.id, data)
                .then(response => {
                    this.$toast.add({
                        severity: 'success',
                        summary:  this.$t('AppAlertsDictionary.Operação realizada com sucesso'),
                        life: 5000
                    });
                    this.alertEditDetails = {};
                    this.displayDialogEditAlert = false;
                    this.getAll(this.selectedOrg);

                }).catch(error => {
                let message =  this.$t('AppAlertsDictionary.Não foi possivel completar a operação, tente novamente');
                if (error.response && error.response.data && error.response.data.details) {
                    message = error.response.data.details;
                }

                this.$toast.add({
                    severity: 'error',
                    summary: message,
                    life: 5000
                });
            })
        },
        handleCloseModalAlertsEdit() {
            this.displayDialogEditAlert = false;
        },
        goToNew() {
            this.alertEditDetails = {};
            this.displayDialogEditAlert = true;
        },
        edit(data) {
            this.alertEditDetails = JSON.parse(JSON.stringify(data));
            this.displayDialogEditAlert = true;
        },
        remove(data) {

        },
        filterData(){
            let newGridData = [];
            this.originalResponse.forEach((alert) => {
                if (this.selectedPriority != null && this.selectedPriority.length > 0 && !this.selectedPriority.includes(alert.priority)) {
                    return;
                }
                newGridData.push(alert)
            })

            this.gridData = newGridData;
        },
        saveChosenOptionsToLocalStorage() {
            this.storedChosenOptions['clients'] = [this.selectedOrg];
            this.storedChosenOptions['vehicles'] = [];
            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_BAR_OPTIONS, JSON.stringify(this.storedChosenOptions));
        },
    },
    directives: {
        tooltip: Tooltip
    },
    watch: {
        selectedPriority: function (val) {
            this.filterData();
        },
        selectedOrg: function (val) {
            this.getAll(val);
        },
    },
    components: {
        DataTable, Column, InputText, MultiSelect, Calendar, Dropdown, ProgressBar, Button, AppButton,
        AppAlertsDictionaryModal,
    },
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/primevue";
@import "src/components/views/alerts/style";


@media (min-width: 1025px){
.data-table-width{
    max-width: calc(100vw - 140px);
}
}

@media (max-width: 1024px){
.data-table-width{
    width: 100vw;
}
}

</style>
