<template>
    <div>
        <div style="display: flex; gap: 10px;">
            <div style="flex: 1;">
                <div>{{ $t('AppAlertsCoaSensorForm.Parâmetro') }}</div>
                <InputText style="width: 100%; height: 40px;" v-model="form['parameter']" 
                    :class="{'p-invalid': $v.form.parameter.$error && $v.form.parameter.$dirty}"/>
            </div>
            <div style="flex: 1;">
                <div>{{ $t('AppAlertsCoaSensorForm.Sistema') }}</div>
                <InputText style="width: 100%; height: 40px;" v-model="form['system']" 
                    :class="{'p-invalid': $v.form.system.$error && $v.form.system.$dirty}"/>
            </div>
            <div style="flex: 1;">
                <div>{{ $t('AppAlertsCoaSensorForm.Unidade') }}</div>
                <InputText style="width: 100%; height: 40px;" v-model="form['unit']" 
                    :class="{'p-invalid': $v.form.unit.$error && $v.form.unit.$dirty}"/>
            </div>
        </div>

        <div style="display: flex; gap: 10px; margin-top: 10px;">
            <div style="flex: 1;">
                <div>{{ $t('AppAlertsCoaSensorForm.Descrição') }}</div>
                <InputText style="width: 100%; height: 40px;" v-model="form['description']" 
                    :class="{'p-invalid': $v.form.description.$error && $v.form.description.$dirty}"/>
            </div>
            <div style="flex: 1;">
                <div>{{ $t('AppAlertsCoaSensorForm.SPN') }}</div>
                <Dropdown v-model="form['spn']" :options="optionsSpnList" :filter="true"
                    style="width: 100%; max-width: 259px; height: 40px;" :showClear="true" optionLabel="name" :dataKey="'spn'"
                    overlayVisible="true" :class="{'p-invalid': $v.form.spn.$error && $v.form.spn.$dirty}" />
            </div>
            <div style="flex: 1;"></div>
        </div>
    </div>
</template>

<script>
import Button from 'primevue/button/Button';
import Dialog from 'primevue/dialog/Dialog';
import Dropdown from 'primevue/dropdown/Dropdown';
import InputNumber from 'primevue/inputnumber/InputNumber';
import InputText from 'primevue/inputtext/InputText';
import {required} from 'vuelidate/lib/validators';

export default {
    props: {
        optionsSpnList: {
            type: Array
        }
    },
    data() {
        return {
            form: {},
            isNewForm: false
        }
    },
    methods: {
        validate(){
            this.$v.form.$touch();
            if (!this.$v.form.$invalid) {
                this.$emit('saveForm', this.form, this.isNewForm);
            } else {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppAlertsCoaSensorForm.Por favor preencha todos os campos obrigatórios'),
                    life: 5000
                });
            }
        },
        cleanForm(){
            this.isNewForm = true
            this.form = {}
        },
        editForm(val){
            this.isNewForm = false
            this.form = {}
            this.form = { ...val }
            this.form.spn = this.optionsSpnList.find(x => x.spn == val.spn)
        }
    },
    validations: {
        form: {
            parameter: {required},
            system: {required},
            unit: {required},
            description: {required},
            spn: {required}
        }
    },
    components: {
        Dialog, Button, InputText, InputNumber, Dropdown
    }
}
</script>