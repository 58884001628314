<template>
    <div class="row justify-content-around">
        <div v-for="stat in stats" class="card-border text-center mb-4 mt-3" :class="colSize">
            <div class="mt-1 mb-2"><b>{{ stat.name }}</b></div>
            <div v-for="item in stat.stats" class="ml-1 text-left">
                <b>{{ item.title }}:</b> {{ item.value }} {{ item.symbol }}
            </div>
        </div>
        <div :class="pane_size <= 45 ? 'row justify-content-around col-12 clear-padding' : 'mt-3 mb-2 col-3'">
            <div class="card-border-list text-center mb-4" :class="colListSize">
                <div class="mt-1 mb-2"><b>{{ $t('AppOperationsStats.Operadores') }}</b></div>
                <div class="ml-1 text-left">
                    <div v-if="operators.length === 0">
                        ---
                    </div>
                    <div v-else v-for="operator in operators">
                        {{ operator }}
                    </div>
                </div>
            </div>
            <div class="card-border-list text-center mb-4" :class="colListSize">
                <div class="mt-1 mb-2"><b>{{ $t('AppOperationsStats.Veículos') }}</b></div>
                <div class="ml-1 text-left">
                    <div v-if="vehicles.length === 0">
                        ---
                    </div>
                    <div v-else v-for="vehicle in vehicles">
                        {{ vehicle.display_name }}
                    </div>
                </div>
            </div>
            <div v-if="showVarietyList" class="card-border-list text-center" :class="[colListSize, {'mb-4': pane_size <= 45}]">
                <div class="mt-1 mb-2"><b>{{ $t('AppOperationsStats.Variedades') }}</b></div>
                <div class="ml-1 text-left">
                    <div v-if="varieties.length === 0">
                        ---
                    </div>
                    <div v-else v-for="variety in varieties">
                        {{ variety }}
                    </div>
                </div>
            </div>
            <div v-if="showProductList" class="card-border-list text-center" :class="[colListSize, {'mb-4': pane_size <= 45}]">
                <div class="mt-1 mb-2"><b>{{ $t('AppOperationsStats.Produtos') }}</b></div>
                <div class="ml-1 text-left">
                    <div v-if="products.length === 0">
                        ---
                    </div>
                    <div v-else v-for="product in products">
                        {{ product }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            colSize: 'col-5',
            colListSize: this.numberOfLists === 2 ? 'col-5 inline' : 'col-3 inline',
            numberOfLists: 2,
            showProductList: false,
            showVarietyList: false
        }
    },
    methods: {
        setColSize(paneSize) {
            if (this.isScreenMobile) {
                this.colSize = 'col-11'
                this.colListSize = 'col-11 inline'
                return;
            }

            if (paneSize > 45) {
                this.colSize = 'col-4'
                this.colListSize = 'col-11'
                return
            }

            if (paneSize > 25) {
                this.colSize = 'col-5'
                this.colListSize = this.numberOfLists === 2 ? 'col-5 inline' : 'col-3 inline'
                return
            }

            this.colSize = 'col-11'
            this.colListSize = 'col-11 inline'
        }
    },
    props: {
        stats: {
            type: Object,
            required: true
        },
        operators: {
            type: Array
        },
        operation: {
            type: Object
        },
        vehicles: {
            type: Array
        },
        varieties: {
            type: Array
        },
        products: {
            type: Array
        },
        pane_size: {
            type: Number
        }
    },
    computed: {
        isScreenMobile: function () {
            return window.screen.width <= 1024;
        }
    },
    watch: {
        pane_size: {
            handler(size) {
                this.setColSize(size)
            }
        },
        operation: {
            immediate: true,
            handler(op) {
                switch (op.operation_type) {
                    case 'TILLAGE':
                        this.numberOfLists = 2
                        this.showProductList = false
                        this.showVarietyList = false
                        break;
                    case 'SEEDING':
                    case 'HARVEST':
                    case 'MOWING':
                        this.numberOfLists = 3
                        this.showProductList = false
                        this.showVarietyList = true
                        break;
                    case 'LIQUID_APPLICATION':
                    case 'DRY_APPLICATION':
                        this.numberOfLists = 3
                        this.showProductList = true
                        this.showVarietyList = false
                        break;
                }
                this.setColSize(this.pane_size)
            }
        }
    }
}
</script>

<style scoped>
.card-border {
    border: 1px solid;
    border-radius: 5px;
    padding-bottom: 1rem;
    margin-right: 1rem;
    margin-left: 1rem;
}

.card-border-list {
    border: 1px solid;
    border-radius: 5px;
    padding-bottom: 1rem;
}

.inline {
    margin-top: 1rem;
    margin-right: 1rem;
    margin-left: 1rem;
}

.clear-padding {
    padding-right: 0;
    padding-left: 0;
}
</style>
