import i18n from '../../../i18n';

export const optionsPieCharts = () => {
    return [
        {
            name: i18n.t('chartsOptions_analysis.Análise'), code: 'ANALISE',
            chart: [
                {cname: i18n.t('chartsOptions_analysis.Análise de Atividades'), code: 'ANALISE_ATIVIDADE'},
                {cname: i18n.t('chartsOptions_analysis.Análise de Combustível'), code: 'ANALISE_COMBUSTIVEL'},
            ]
        },
        {
            name: i18n.t('chartsOptions_analysis.Motivos de paradas'), code: 'PARADAS',
            chart: [
                {cname: i18n.t('chartsOptions_analysis.Motivos de paradas'), code: 'PARADAS'}
            ]
        },
        /*{
            name: i18n.t('chartsOptions_analysis.Atividade'), code: 'ATIVIDADE',
            chart: [
                {cname: i18n.t('chartsOptions_analysis.Atividade x Área'), code: 'ATIVIDADE_AREA'},
                {cname: i18n.t('chartsOptions_analysis.Atividade x Tempo'), code: 'ATIVIDADE_TEMPO'},
                {cname: i18n.t('chartsOptions_analysis.Atividade x Combustível'), code: 'ATIVIDADE_COMBUSTIVEL'},
                {cname: i18n.t('chartsOptions_analysis.Atividade x Distância'), code: 'ATIVIDADE_DISTANCIA'},
            ]
        },*/
        {
            name: i18n.t('chartsOptions_analysis.Locais'), code: 'LOCAIS',
            chart: [
                {cname: i18n.t('chartsOptions_analysis.Locais x Área'), code: 'LOCAIS_AREA'},
                {cname: i18n.t('chartsOptions_analysis.Locais x Tempo'), code: 'LOCAIS_TEMPO'},
                {cname: i18n.t('chartsOptions_analysis.Locais x Combustível'), code: 'LOCAIS_COMBUSTIVEL'},
                {cname: i18n.t('chartsOptions_analysis.Locais x Distância'), code: 'LOCAIS_DISTANCIA'},
            ]
        },
        {
            name: i18n.t('chartsOptions_analysis.Máquina'), code: 'MAQUINA',
            chart: [
                {cname: i18n.t('chartsOptions_analysis.Máquina x Área'), code: 'MAQUINA_AREA'},
                {cname: i18n.t('chartsOptions_analysis.Máquina x Tempo'), code: 'MAQUINA_TEMPO'},
                {cname: i18n.t('chartsOptions_analysis.Máquina x Combustível'), code: 'MAQUINA_COMBUSTIVEL'},
                {cname: i18n.t('chartsOptions_analysis.Máquina x Distância'), code: 'MAQUINA_DISTANCIA'},
            ]
        },
        {
            name: i18n.t('chartsOptions_analysis.Operadores'), code: 'OPERADORES',
            chart: [
                {cname: i18n.t('chartsOptions_analysis.Operadores x Área'), code: 'OPERADORES_AREA'},
                {cname: i18n.t('chartsOptions_analysis.Operadores x Tempo'), code: 'OPERADORES_TEMPO'},
                {cname: i18n.t('chartsOptions_analysis.Operadores x Combustível'), code: 'OPERADORES_COMBUSTIVEL'},
                {cname: i18n.t('chartsOptions_analysis.Operadores x Distância'), code: 'OPERADORES_DISTANCIA'},
            ]
        },
        {
            name: i18n.t('chartsOptions_analysis.Turnos'), code: 'TURNOS',
            chart: [
                {cname: i18n.t('chartsOptions_analysis.Turnos x Área'), code: 'TURNOS_AREA'},
                {cname: i18n.t('chartsOptions_analysis.Turnos x Tempo'), code: 'TURNOS_TEMPO'},
                {cname: i18n.t('chartsOptions_analysis.Turnos x Combustível'), code: 'TURNOS_COMBUSTIVEL'},
                {cname: i18n.t('chartsOptions_analysis.Turnos x Distância'), code: 'TURNOS_DISTANCIA'},
            ]
        },
    ];
};

export const optionsColumnCharts = () => {
    return [
        {
            name: i18n.t('chartsOptions_analysis.Análise de Atividades (modos)'),
            code: 'ANALISE_ATIVIDADES',
            sumInGroupMode: true
        },
        {
            name: i18n.t('chartsOptions_analysis.Análise de Combustível (modos)'),
            code: 'ANALISE_COMBUSTIVEL',
            sumInGroupMode: true
        },
        {name: i18n.t('chartsOptions_analysis.Área (ha)'), code: 'AREA', sumInGroupMode: true},
        {name: i18n.t('chartsOptions_analysis.Produtividade (ha/h)'), code: 'PRODUTIVIDADE', sumInGroupMode: false},
        {name: i18n.t('chartsOptions_analysis.Disponibilidade Mecânica'), code: 'DISP_MECANICA', sumInGroupMode: false},
        {name: i18n.t('chartsOptions_analysis.Rendimento (L/ha)'), code: 'RENDIMENTO', sumInGroupMode: false},
        {name: i18n.t('chartsOptions_analysis.Consumo (L/h)'), code: 'CONSUMO', sumInGroupMode: false},
        {
            name: i18n.t('chartsOptions_analysis.Quilômetros por Litro (km/L)'),
            code: 'QUILOMETRO_LITRO',
            sumInGroupMode: false
        },
        {
            name: i18n.t('chartsOptions_analysis.Combustível Médio(Litros)'),
            code: 'COMBUSTIVEL_MEDIO',
            sumInGroupMode: true
        },
        {name: i18n.t('chartsOptions_analysis.Velocidade'), code: 'VELOCIDADE', sumInGroupMode: false},
        {name: i18n.t('chartsOptions_analysis.Horas em Atividade'), code: 'HORAS_EM_ATIVIDADE', sumInGroupMode: true},
        {name: i18n.t('chartsOptions_analysis.Motivos de paradas'), code: 'MOTIVOS_DE_PARADAS', sumInGroupMode: true}
    ];
};

export const optionsLineCharts = () => {
    return [
        {name: i18n.t('chartsOptions_analysis.Área (ha)'), code: 'AREA'},
        {name: i18n.t('chartsOptions_analysis.Produtividade (ha/h)'), code: 'PRODUTIVIDADE'},
        {name: i18n.t('chartsOptions_analysis.Rendimento (L/ha)'), code: 'RENDIMENTO'},
        {name: i18n.t('chartsOptions_analysis.Consumo (L/h)'), code: 'CONSUMO'},
        {name: i18n.t('chartsOptions_analysis.Combustível Médio(Litros)'), code: 'COMBUSTIVEL_MEDIO'},
        {name: i18n.t('chartsOptions_analysis.Velocidade'), code: 'VELOCIDADE'},
    ];
};

export const optionsSideCards = () => {
    return [
        {name: i18n.t('chartsOptions_analysis.Área (ha)'), code: 'AREA'},
        {name: i18n.t('chartsOptions_analysis.Produtividade (ha/h)'), code: 'PRODUTIVIDADE'},
        {name: i18n.t('chartsOptions_analysis.Disponibilidade Mecânica'), code: 'DISP_MECANICA'},
        {name: i18n.t('chartsOptions_analysis.Rendimento (L/ha)'), code: 'RENDIMENTO'},
        {name: i18n.t('chartsOptions_analysis.Consumo (L/h)'), code: 'CONSUMO'},
        {name: i18n.t('chartsOptions_analysis.Combustível (Litros)'), code: 'COMBUSTIVEL'},
        {name: i18n.t('chartsOptions_analysis.Velocidade Média(km/h)'), code: 'VELOCIDADE_MEDIA'},
        {name: i18n.t('chartsOptions_analysis.Eficiência(%)'), code: 'EFICIENCIA'},
        {name: i18n.t('chartsOptions_analysis.Tempo de Motor Ligado'), code: 'TEMPO'},
        {name: i18n.t('chartsOptions_analysis.Tempo Trabalhando'), code: 'TEMPO_TRABALHANDO'},
        {name: i18n.t('chartsOptions_analysis.Tempo Manobrando'), code: 'TEMPO_MANOBRANDO'},
        {name: i18n.t('chartsOptions_analysis.Tempo Deslocando'), code: 'TEMPO_DESLOCANDO'},
        {name: i18n.t('chartsOptions_analysis.Tempo Parado'), code: 'TEMPO_PARADO'},
        {name: i18n.t('chartsOptions_analysis.Consumo Trabalhando'), code: 'CONSUMO_TRABALHANDO'},
        {name: i18n.t('chartsOptions_analysis.Consumo Manobrando'), code: 'CONSUMO_MANOBRANDO'},
        {name: i18n.t('chartsOptions_analysis.Consumo Deslocando'), code: 'CONSUMO_DESLOCANDO'},
        {name: i18n.t('chartsOptions_analysis.Consumo Parado'), code: 'CONSUMO_PARADO'}

    ];
}

