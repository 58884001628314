<template>
    <Dialog @hide="closeDialog" :header="headerText" :visible.sync="displayDialog" :style="{width: '75vw'}"
            :maximizable="true" :modal="true" :contentStyle="{height: '450px'}">
        <DataTable :value="eventsToDisplayWithShowMore" :rowHover="true"
                   :sortOrder="-1" sortField="last_modified_date"
                   class="p-datatable-striped"
                   :autoLayout="true" :style="{paddingTop: '0px'}"
                   :scrollable="true"
                   ref="report" removableSort>

            <template #empty>
                {{ $t('AppAnalysisSummaryDialog.Nenhum evento localizado') }}
            </template>

            <template #footer>
                <div style="text-align: center;" class="loadMoreDiv">
                    <Button icon="pi pi-search-plus" :label="$t('AppAnalysisSummaryDialog.Carregar Mais')" @click="loadMoreClicked()" :disabled="loadMoreButtonDisabled"
                            style="position: absolute;left: 45%;"/>
                </div>
                <div style="text-align: right">
                    <Button icon="pi pi-external-link" :label="$t('AppAnalysisSummaryDialog.Exportar')" @click="exportCsvReport($event)"/>
                </div>
            </template>

            <Column v-for="(col, index) of raw_columns" :field="col.field" :key="col.field + '_' + index"
                    :header="col.header" :headerStyle="{width: '150px !important', overflow: 'hidden'}"
                    :style="{overflow: 'hidden'}"
                    :sortable="false" :bodyStyle="{width: '150px !important', overflow: 'hidden'}">
                <template #body="slotProps">
                    <div>
                        {{hideValuesGreaterThanMaximumDisplay(slotProps.data[col.field])}}
                    </div>
                </template>
            </Column>


            <Column v-for="(col, index) of selected_statistics_columns" :header="col.header"
                    :field="col.field"
                    :key="col.field + '_' + index" :headerStyle="col.headerStyle" sortable>


                <template #body="slotProps">
                    <div v-if="col.field === 'event_start' || col.field === 'event_end'">
                        {{getTimeFromSecondsMinusThreeHoursMixin(slotProps.data[col.field])}}
                    </div>
                    <div v-if="col.field === 'time'">
                        {{getTimeFromSecondsDurationFormatMixin(slotProps.data[col.field])}}
                    </div>
                    <div v-if=" col.field === 'distance' || col.field === 'consumption' || col.field === 'area' || col.field === 'yield'||
                                            col.field === 'consumption_rate' || col.field === 'productivity' || col.field === 'refuel'">
                        {{displayStatistics(slotProps.data[col.field])}}
                    </div>
                </template>
            </Column>
        </DataTable>
    </Dialog>
</template>

<script>

import Dialog from 'primevue/dialog';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ProgressBar from 'primevue/progressbar';
import Button from 'primevue/button';

import getTimeFromHoursDurationFormatMixin from "@/mixins/DateMixin";
import getTimeFromSecondsMinusThreeHoursMixin from "@/mixins/DateMixin";
import getTimeFromSecondsDurationFormatMixin from "@/mixins/DateMixin";

export default {
    mixins: [getTimeFromHoursDurationFormatMixin, getTimeFromSecondsMinusThreeHoursMixin, getTimeFromSecondsDurationFormatMixin],
    data() {
        return {
            displayDialog: false,
            eventsToDisplay: [],
            selectedColumnsFromProps: [],
            frozenValue: null,
            numberOfEventsToShow: 7,
            headerText: "",
        }
    },
    methods: {
        exportCsvReport() {
            this.$refs.report.exportCSV();
        },
        displayStatistics(value) {
            return value.toFixed(2);
        },
        hideValuesGreaterThanMaximumDisplay(value) {
            const MAX_NUMBER_OF_CHARACTERS = 27;
            if (value && value.length > MAX_NUMBER_OF_CHARACTERS) {
                return value.substring(0, MAX_NUMBER_OF_CHARACTERS);
            }
            return value;
        },
        loadMoreClicked() {
            this.numberOfEventsToShow = this.numberOfEventsToShow + 7;

            this.$nextTick(function () {
                const el =  this.$el.getElementsByClassName('loadMoreDiv')[0];

                if (el) {
                    el.scrollIntoView({behavior: 'smooth'});
                }
            });
        },
        closeDialog() {
            this.numberOfEventsToShow = 7;
            this.$emit('closeDialog');
        },
        setHeaderText() {
            if (!this.group_on) {
                this.headerText = this.$t('AppAnalysisSummaryDialog.Eventos iguais a esse')+":";
                return;
            }

            let newHeaderText = this.$t('AppAnalysisSummaryDialog.Eventos de');
            this.group_checked.forEach((columnGrouped, index) => {
                if (columnGrouped) {
                    newHeaderText += this.grouped_row_clicked[this.raw_columns[index].field];
                }
            })
            this.headerText = newHeaderText;
        },
        setEventsToDisplay() {
            let newSelectedEvents = [];
            let newSelectedColumns = [];
            this.selectedColumnsFromProps = JSON.parse(JSON.stringify(this.selected_columns));


            for (let i = 0; i < this.selectedColumnsFromProps.length; i++) {
                if (this.grouped_row_clicked[this.selectedColumnsFromProps[i].field] === '-') {
                    newSelectedColumns.push(this.selectedColumnsFromProps[i]);
                    continue;
                }

                let newOptions = []
                for (let j = 0; j < this.selectedColumnsFromProps[i].options.length; j++) {
                    if (this.grouped_row_clicked[this.selectedColumnsFromProps[i].field] === this.selectedColumnsFromProps[i].options[j].option) {
                        newOptions.push(this.selectedColumnsFromProps[i].options[j].option);
                    }
                }
                this.selectedColumnsFromProps[i].options = newOptions;
                newSelectedColumns.push(this.selectedColumnsFromProps[i]);
            }

            this.raw_events.forEach(event => {
                let addEventToList = false;
                for (let i = 0; i < newSelectedColumns.length; i++) {
                    addEventToList = false;
                    for (let j = 0; j < newSelectedColumns[i].options.length; j++) {
                        if (newSelectedColumns[i].options.length === 1) {
                            if (event[newSelectedColumns[i].field] === newSelectedColumns[i].options[j]) {
                                addEventToList = true;
                                break;
                            }
                        } else {
                            if (event[newSelectedColumns[i].field] === newSelectedColumns[i].options[j].option) {
                                addEventToList = true;
                                break;
                            }
                        }
                    }
                    if (!addEventToList) {
                        break;
                    }
                }
                if (addEventToList) {
                    newSelectedEvents.push(event);
                }
            });

            this.eventsToDisplay = JSON.parse(JSON.stringify(newSelectedEvents));

        },
    },
    components: {
        Dialog,
        DataTable,
        Column,
        ProgressBar,
        Button
    },
    props: {
        raw_events: {
            type: Array,
        },
        selected_events: {
            type: Array,
        },
        selected_columns: {
            type: Array,
        },
        grouped_row_clicked: {
            type: Object,
        },
        dialog_visible: {
            type: Boolean,
            default: false
        },
        raw_columns: {
            type: Array
        },
        selected_statistics_columns: {
            type: Array
        },
        group_checked: {
            type: Array
        },
        group_on: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        eventsToDisplayWithShowMore: function () {
            let eventsToReturn = [];
            let minimumValueToDisplay = Math.min(this.numberOfEventsToShow, this.eventsToDisplay.length);
            for (let i = 0; i < minimumValueToDisplay; i++) {
                eventsToReturn.push(this.eventsToDisplay[i]);
            }

            return eventsToReturn;
        },
        loadMoreButtonDisabled() {
            return this.numberOfEventsToShow >= this.eventsToDisplay.length;
        },
    },
    watch: {
        dialog_visible() {
            this.displayDialog = this.dialog_visible;

            if (this.dialog_visible) {
                this.setEventsToDisplay();
                this.setHeaderText();
            }
        },
    },
}
</script>

<style scoped>

</style>
