<template>
    <div class="padding-container">
        <div class="container-header col-12 md:col-9 lg:col-9 xl:col-10">
            <div class="container-header-content">
                <div class="container-header-title">{{ typeText(type) }}</div>
                <div class="container-header-right">
                    <span class="p-input-icon-left mr-3">
                        <i class="pi pi-search pl-1" />
                        <InputText v-model="filter" :placeholder="$t('AppMachineReport.Pesquisar')" class="input-search" style="height: 38px;"/>
                    </span>
                    <app-button type="secondary" @handleClick="cleanType">
                        <i class="pi pi-chevron-left mr-2" style="font-size: 12px;" />
                        <span class="m-0" >{{$t('AppMachineReport.Voltar')}}</span>
                    </app-button>
                </div>
            </div>
            <div class="border-line"></div>
        </div>
        <div class="container-content">
            <section v-if="gridDataFiltered.length > 0">
                <div v-for="(item, index) in gridDataFiltered" style="margin-bottom: 40px;">
                    <div style="text-align: left; font-size: 17px; padding: 0 0 10px 5px;">{{ item.manufacturer }}</div>
                    <Accordion :multiple="true" :activeIndex.sync="active[index]" @tab-open="tabOpen(item.manufacturer, $event.index, index)">
                        <AccordionTab v-for="(model, indexModel) in item.models" :id="`${index}-${indexModel}`" :key="`${index}-${indexModel}`">
                            <template #header>
                                <span style="color: #777777; font-size: 14px;">{{ model.model }}</span>
                                <app-button type="primary" @handleClick="copyLink(index, indexModel)" style="margin: 0 0 0 auto; width: 40px;" v-tooltip.top="$t('AppMachineReport.Copiar link')">
                                    <i class="pi pi-share-alt" style="font-size: 17px; position: relative; right: 3px;" />
                                </app-button>
                            </template>
                            <section v-if="model.tractors || model.monitors" class="content">
                                <div class="content-first">
                                    <section v-if="model.tractors">
                                        <div class="content-first-subtitle">{{$t('AppMachineReport.Selecione o trator')}}</div>
                                        <div class="items-list">
                                            <div v-for="(tractors, indexTractor) in model.tractors"
                                                :class="{'b-color-selected': tractors.selected === true}"
                                                @click="selectedTractor(index, indexModel, indexTractor)"
                                                class="content-first-card">
                                                <img :src="tractors.url" class="content-first-img">
                                                <div style="font-size: 14px;">{{ tractors.tractor }}</div>
                                            </div>
                                        </div>
                                        <div v-if="isSelectedTractor(index, indexModel)" style="margin-top: 35px;">
                                            <div class="content-first-subtitle">{{$t('AppMachineReport.Selecione o monitor')}}</div>
                                            <div class="items-list">
                                                <div
                                                    v-for="(monitors, indexMonitor) in getMonitorsByTractor(index, indexModel)"
                                                    :class="{'b-color-selected': monitors.selected === true}"
                                                    @click="selectedMonitorByTractor(index, indexModel, indexMonitor)"
                                                    class="content-first-card">
                                                    <img :src="monitors.url" class="content-first-img">
                                                    <div style="font-size: 14px; text-align: center">
                                                        {{ monitors.monitor }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section v-else>
                                        <div class="content-first-subtitle">{{$t('AppMachineReport.Selecione o monitor')}}</div>
                                        <div class="items-list" style="display: flex; flex-wrap: wrap; gap: 10px;">
                                            <div v-for="(monitors, indexMonitor) in model.monitors"
                                                @click="selectedMonitor(index, indexModel, indexMonitor)"
                                                :class="{'b-color-selected': monitors.selected === true}"
                                                class="content-first-card">
                                                <img :src="monitors.url" class="content-first-img">
                                                <div style="font-size: 14px; text-align: center">
                                                    {{ monitors.monitor }}
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                <div class="content-second">
                                    <section v-if="getDataByModel(index, indexModel)" style="display: flex; align-items: center; height: 100%; width: 100%;">
                                        <div style="width: 50%;">
                                            <div class="content-second-subtitle">{{$t('AppMachineReport.Chicote')}}</div>
                                            <div v-if="getDataByModel(index, indexModel).harness.length === 0"
                                                style="font-size: 14px; margin-bottom: 10px">N/A
                                            </div>
                                            <div v-for="item in getDataByModel(index, indexModel).harness">
                                                <div style="font-size: 14px; margin-bottom: 10px">
                                                    {{ item.name }}
                                                </div>
                                                <img v-if="item.url" :src="item.url" class="content-first-img">
                                            </div>
                                        </div>
                                        <div style="width: 50%;">
                                            <div class="content-second-subtitle">{{$t('AppMachineReport.Instalação')}}</div>
                                            <div v-if="getDataByModel(index, indexModel).installation.length === 0"
                                                style="font-size: 14px; margin-bottom: 10px">N/A
                                            </div>
                                            <div v-for="item in getDataByModel(index, indexModel).installation">
                                                <div style="font-size: 14px; margin-bottom: 10px">
                                                    {{ item.name }}
                                                </div>
                                                <img v-if="item.url" :src="item.url" class="content-first-img">
                                            </div>
                                        </div>
                                    </section>
                                    <section v-else>
                                        {{ model.tractors ? $t('AppMachineReport.Selecione o trator e monitor à esquerda') : $t('AppMachineReport.Selecione o monitor à esquerda') }}
                                    </section>
                                </div>
                            </section>
                            <section v-else style="padding: 15px 0; text-align: center">
                                <ProgressSpinner />
                            </section>
                        </AccordionTab>
                    </Accordion>
                </div>
                <div style="font-size: 16px; text-align: center;">
                    {{$t('AppMachineReport.Solicite a homologação de outros equipamentos para nossa equipe de suporte')}}
                    <a :href="`https://wa.me/554130249825?text=${$t('AppMachineReport.Olá! Quero solicitar a homologação de um equipamento usado em ')}${typeText(type)}`">
                        {{$t('AppMachineReport.clicando aqui')}}
                    </a>
                </div>
            </section>
            <section v-else style="font-size: 16px;">{{$t('AppMachineReport.Sem dados para apresentar')}}</section>
        </div>
    </div>
</template>

<script>
import InputText from 'primevue/inputtext/InputText';
import AppButton from "@/components/common/AppButton";
import MachineReportService from '@/services/MachineReportService';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import ProgressSpinner from 'primevue/progressspinner';
import Tooltip from "primevue/tooltip";

export default {
    data() {
        return {
            filter: '',
            gridData: [],
            machineReportService: null,
            active: null
        }
    },
    mounted(){
        this.machineReportService = new MachineReportService()
        this.filter = ''
        this.getInfo()
    },
    props: {
        type: {
            type: String,
            default: ''
        },
        modelId: {
            type: String,
            default: ''
        }
    },
    methods: {
        copyLink(index, indexModel){
            let type = this.$route.params.type
            let id = this.gridDataFiltered[index].models[indexModel].id
            let link = `https://plataforma.iotag.com.br/settings/machine/report/${type}/${id}`;
            navigator.clipboard.writeText(link)
            this.$toast.add({
                severity: 'success',
                summary: this.$t('AppMachineReport.Conteúdo copiado para a área de transferência'),
                life: 5000
            });
        },
        isSelectedTractor(index, indexModel){
            return this.gridDataFiltered[index].models[indexModel].tractors.some(element => element.selected === true);
        },
        selectedTractor(index, indexModel, indexTractor){
            let tractors = this.gridDataFiltered[index].models[indexModel].tractors

            tractors.forEach(element => {
                element.selected = false
            });

            tractors[indexTractor].selected = true

            if(tractors[indexTractor].monitors.length === 1){
                tractors[indexTractor].monitors[0].selected = true
            }
        },
        getMonitorsByTractor(index, indexModel){
            let response;
            this.gridDataFiltered[index].models[indexModel].tractors.forEach(element => {
                if(element.selected === false) {
                    return
                }
                
                response = element.monitors
            });
            return response
        },
        selectedMonitorByTractor(index, indexModel, indexMonitor){
            this.gridDataFiltered[index].models[indexModel].tractors.forEach(element => {
                if(element.selected === false){
                    return
                }

                element.monitors.forEach(element => {
                    element.selected = false
                });
                element.monitors[indexMonitor].selected = true
            });
        },
        getDataByModel(index, indexModel){
            let response = ''
            let model = this.gridDataFiltered[index].models[indexModel]

            if(model.tractors){
                model.tractors.forEach(element => {
                    if(element.selected === false){
                        return
                    }
                    
                    element.monitors.forEach(element => {
                        if(element.selected === true){
                            response = element
                        }
                    });
                });

                return response
            }

            if(!model.monitors){
                return response
            }

            model.monitors.forEach(element => {
                if(element.selected === true){
                    response = element
                }
            });

            return response
        },
        selectedMonitor(index, indexModel, indexMonitor){
            let monitors = this.gridDataFiltered[index].models[indexModel].monitors
            monitors.forEach(element => {
                element.selected = false
            });

            monitors[indexMonitor].selected = true
        },
        cleanType(){
            this.$emit('cleanType');
        },
        typeText(type) {
            switch (type) {
                case 'PLANTING':
                    return this.$t(`AppMachineReport.Plantio`)
                case 'HARVEST':
                    return this.$t(`AppMachineReport.Colheita`)
                case 'PULVERIZATION':
                    return this.$t(`AppMachineReport.Pulverização`)
                case 'SOLID_DISPENSERS':
                    return this.$t(`AppMachineReport.Distribuição`)
                default:
                    type
            }
        },
        getInfo(){
            this.active = []
            this.machineReportService.getInfo(this.type).then(data => {
                let newData = []
                data.forEach(item => {
                    if (!newData.some(element => element.manufacturer === item.manufacturer)) {
                        newData.push({
                            models: [{model: item.model, id: item.model_id}],
                            manufacturer: item.manufacturer
                        })
                        return
                    }

                    let itemIndex = newData.findIndex(element => element.manufacturer === item.manufacturer)
                    let models = newData[itemIndex].models
                    if (!models.some(model => model.id === item.model_id)) {
                        models.push({model: item.model, id: item.model_id})
                    }
                })
                
                this.gridData = newData
                this.openByModelId()
            }).catch(error => {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppMachineReport.Erro'),
                    detail: this.$t('AppMachineReport.Não foi possível carregar as organizações do revendedor'),
                    life: 5000
                });
            })
        },
        tabOpen(manufacturer, event, index){
            if(this.gridDataFiltered[index].models[event].tractors || this.gridDataFiltered[index].models[event].monitors){
                return
            }

            let id = this.gridDataFiltered.find(element => element.manufacturer === manufacturer).models[event].id
            this.machineReportService.getInfoById(id, 'Pt').then(data => {
                this.setTheValues(this.transformJson(data), event, index)
            }).catch(error => {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppMachineReport.Erro'),
                    detail: this.$t('AppMachineReport.Não foi possível carregar os modelos'),
                    life: 5000
                });
            })
        },
        setTheValues(newData, event, index){
            if(newData.tractors){
                newData.tractors.forEach(element => {
                    element.selected = false
                    element.monitors.forEach(element => {
                        element.selected = false
                    });
                });
                
                if(newData.tractors.length === 1){
                    newData.tractors[0].selected = true
                    if (newData.tractors[0].monitors.length === 1) {
                        newData.tractors[0].monitors[0].selected = true
                    }
                }

                this.$set(this.gridDataFiltered[index].models[event], 'tractors', newData.tractors);
                return
            } 
            
            newData.monitors.forEach(element => {
                element.selected = false
            });

            if(newData.monitors.length === 1){
                newData.monitors[0].selected = true
            }

            this.$set(this.gridDataFiltered[index].models[event], 'monitors', newData.monitors);
        },
        cleanText(str) {
            return str.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        },
        openByModelId(){
            if(!this.modelId){
                return
            }

            this.active = Array.from({ length: this.gridDataFiltered.length }, () => []);

            this.gridDataFiltered.forEach((manufacturer, indexManufacturer) => {
                manufacturer.models.forEach((model, indexModel) => {
                    if(model.id != this.modelId){
                        return
                    }

                    this.active[indexManufacturer].push(indexModel)
                    this.tabOpen(manufacturer.manufacturer, indexModel, indexManufacturer)
                    let element = null;
                    
                    let intervalId = setInterval(() => {
                        element = document.getElementById(`${indexManufacturer}-${indexModel}`);
                        if (element !== null) {
                            element.scrollIntoView({ behavior: 'smooth', block: 'center' });                               
                            clearInterval(intervalId);
                        }
                    }, 100);
                });
            });
        },
        transformJson(data){
            let newData = {}
            if (!data[0].tractor) {
                newData.monitors = []
                data.forEach(item => {
                    if (!newData.monitors.some(element => element.monitor === item.monitor)) {
                        newData.monitors.push({ 
                            monitor: item.monitor, 
                            url: item.monitor_url, 
                            harness: item.harness ? [{ name: item.harness, url: item.harness_url }] : [], 
                            installation: item.installation ? [{ name: item.installation, url: item.installation_url }] : [] 
                        })
                        return
                    }
    
                    let itemMonitor = newData.monitors.find(element => element.monitor === item.monitor)
                    if (item.harness) {
                        itemMonitor.harness.push({ name: item.harness, url: item.harness_url })
                    }
                    if (item.installation) {
                        itemMonitor.installation.push({ name: item.installation, url: item.installation_url })
                    }
                })
                return newData
            }

            newData.tractors = []
            data.forEach(item => {
                if (!newData.tractors.some(element => element.tractor === item.tractor)) {
                    let harness = item.harness ? [{ name: item.harness, url: item.harness_url }] : []
                    let installation = item.installation ? [{ name: item.installation, url: item.installation_url }] : []
                    newData.tractors.push({ 
                        tractor: item.tractor, 
                        url: item.tractor_url, 
                        monitors: [{ monitor: item.monitor, url: item.monitor_url, harness: harness, installation: installation }] 
                    })
                    return
                }

                let itemTractor = newData.tractors.find(element => element.tractor === item.tractor)
                if (!itemTractor.monitors.some(element => element.monitor === item.monitor)) {
                    itemTractor.monitors.push({ 
                        monitor: item.monitor, 
                        url: item.monitor_url, 
                        harness: item.harness ? [{ name: item.harness, url: item.harness_url }] : [], 
                        installation: item.installation ? [{ name: item.installation, url: item.installation_url }] : [] 
                    })
                    return
                }
                
                let itemMonitor = itemTractor.monitors.find(element => element.monitor === item.monitor)
                if (item.harness) {
                    itemMonitor.harness.push({name: item.harness, url: item.harness_url })
                }
                if (item.installation) {
                    itemMonitor.installation.push({name: item.installation, url: item.installation_url })
                }
            })
            return newData
        }
    },
    computed: {
        gridDataFiltered: function () {
            if(this.filter === ''){
                return this.gridData
            }

            let filter = this.cleanText(this.filter)
            return this.gridData
                .map(element => {
                    if(this.cleanText(element.manufacturer).includes(filter)){
                        return {
                            manufacturer: element.manufacturer,
                            models: element.models
                        }
                    }

                    let filteredModels = element.models.filter(model => 
                        this.cleanText(model.model).includes(filter)
                    );

                    if (this.cleanText(element.manufacturer).includes(filter) || filteredModels.length > 0) {
                        return {
                            manufacturer: element.manufacturer,
                            models: filteredModels.length > 0 ? filteredModels : element.models
                        };
                    }
                    return null;
                })
                .filter(element => element !== null);
        },
    },
    watch: {
        filter: function(){
            this.active = []
        }
    },
    directives: {
        tooltip: Tooltip
    },
    components: {
        InputText, AppButton, Accordion, AccordionTab, ProgressSpinner, Tooltip
    }
}
</script>

<style scoped>

.padding-container {
    padding: 0 30px 30px 30px;
    color: #545454;
}

.container-header {
    width: 100%;
    background-color: #DADADA;
    padding-top: 25px;
    padding-bottom: 0px;
}

.container-header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.container-header-title {
    font-size: 23px;
    font-weight: 600;
    padding-left: 20px;
    text-transform: uppercase;
}

.container-header-right {
    display: flex;
    padding-right: 15px;
    align-items: center;
}

.border-line {
    width: 100%;
    height: 1px;
    background-color: #bbbbbb;
    margin-top: 25px;
}

.input-search {
    max-width: 240px;
}

.content {
    display: flex;
}

.container-content {
    padding: 30px 20px 30px 20px;
}

.content-first {
    width: calc(50% - 1px);
    border-right: 1px solid #cccccc;
    padding: 15px;
}

.content-first-subtitle {
    padding: 0 0 15px 0;
    text-align: left;
    font-size: 17px;
    font-weight: 500;
}

.content-first-card {
    padding: 15px;
    border: 1px solid #bbbbbb;
    border-radius: 5px;
    width: 150px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.content-first-img {
    max-width: 70%;
    margin: 0 auto;
    border-radius: 3px;
}

.b-color-selected {
    background-color: #E9ECEF
}

.content-second {
    width: calc(50% - 1px);
    text-align: center;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.content-second-subtitle {
    font-weight: 500;
    font-size: 17px;
    margin-bottom: 15px
}

.items-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.content-display {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
}

@media (max-width: 1024px) {
    .content {
        display: block;
    }

    .input-search {
        max-width: 130px;
    }

    .content-first {
        border-right: none;
        border-bottom: 1px solid #cccccc;
        width: 100%;
        padding: 15px 15px 30px 15px;
    }

    .content-second {
        width: 100%;
        padding: 30px 15px 15px 15px;
    }
}

@media (max-width: 767px) {
    .container-header {
        padding: 25px 0 0 0;
    }

    .padding-container {
        padding: 0 0 60px 0;
    }

    .container-header-content {
        display: block;
    }

    .container-header-title {
        padding: 0 10px;
        text-align: center;
        text-transform: uppercase;
    }

    .container-header-right {
        margin-top: 20px;
        padding: 0 10px;
        justify-content: center;
    }
}

</style>