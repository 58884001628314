import i18n from "@/i18n";

export const CSV_FIELDS_HOUR = ['date', 'MOTONIVELADORA', 'RETRO_ESCAVADEIRA'];

export const CSV_LABELS_HOUR = [i18n.t('AppAnomaliasOptions.Data'), i18n.t('AppAnomaliasOptions.Horas de Motoniveladoras'), i18n.t('AppAnomaliasOptions.Horas de Retro-Escavadeiras')];

export const CSV_FIELDS_DAILY = ['date', 'PA_CARREGADEIRA', 'BASCULANTE', 'PIPA', 'ESCAVADEIRA'];

export const CSV_LABELS_DAILY = [i18n.t('AppAnomaliasOptions.Data'), i18n.t('AppAnomaliasOptions.Diárias de Pás-Carregadeiras'), i18n.t('AppAnomaliasOptions.Diárias de Caminhões Basculantes'), i18n.t('AppAnomaliasOptions.Diárias de Caminhões Pipa'), i18n.t('AppAnomaliasOptions.Diárias de Escavadeiras')];

export const ANOMALIAS_LAYERS_LIST = () => {
    return [
    {name: i18n.t('AppAnomaliasOptions.Qualidade'), code: "QUALITY"},
    {name: i18n.t('AppAnomaliasOptions.Mapa de Calor'), code: "HEAT_MAP"},
    {name: i18n.t('AppAnomaliasOptions.Trechos'), code: "STRETCHES"},
];}

export const ANOMALIAS_MACHINERY = () => {
    return [
        {name: i18n.t('AppAnomaliasOptions.Motoniveladora'), code: "MOTONIVELADORA", time_control: 'HOURLY'},
        {name: i18n.t('AppAnomaliasOptions.Pá-Carregadeira'), code: "PA_CARREGADEIRA", time_control: 'DAILY'},
        {name: i18n.t('AppAnomaliasOptions.Caminhão Basculante'), code: "BASCULANTE", time_control: 'DAILY'},
        {name: i18n.t('AppAnomaliasOptions.Caminhão Pipa'), code: "PIPA", time_control: 'DAILY'},
        {name: i18n.t('AppAnomaliasOptions.Escavadeira'), code: "ESCAVADEIRA", time_control: 'DAILY'},
        {name: i18n.t('AppAnomaliasOptions.Retro-Escavadeira'), code: "RETRO_ESCAVADEIRA", time_control: 'HOURLY'},
    ];
}
