<template>
    <div id="google-maps"></div>
</template>

<script>
    import gmapsInit from '../../../../utils/gmaps';

    export default {
        props: {
            lat: {
                type: Number,
                required: true,
            },
            lng: {
                type: Number,
                required: true,
            },
        },
        async mounted() {
            try {
                const google = await gmapsInit();
                this.google = google;

                // Inicializa o mapa
                this.map = new google.maps.Map(document.getElementById("google-maps"), {
                    zoom: 12, // Zoom inicial
                    center: { lat: this.lat, lng: this.lng }, // Centraliza no local
                    mapTypeId: 'hybrid',
                    disableDefaultUI: true,
                });

                // Adiciona marcador
                this.addMarker(this.lat, this.lng);
            } catch (error) {
                console.error("Error loading Google Maps:", error);
            }
        },
        methods: {
            addMarker(lat, lng) {
                new this.google.maps.Marker({
                    position: { lat, lng },
                    map: this.map,
                });

                // Centraliza o mapa na posição do marcador
                this.map.setCenter({ lat, lng });
            },
        },
    };
</script>

<style scoped>
    #google-maps {
        height: 100%;
    }
</style>
