<template>
    <div id="google-maps-fields"></div>
</template>

<script>

import gmapsInit from '../../../utils/gmaps';
import {mapGetters} from 'vuex';

import * as jsts from 'jsts';

import jstsWithHolesToGoogleMaps from "@/mixins/GeometryMixin";
import jstsWithoutHolesToGoogleMaps from "@/mixins/GeometryMixin";

export default {
    mixins: [jstsWithHolesToGoogleMaps, jstsWithoutHolesToGoogleMaps],
    async mounted() {
        let mapType = 'hybrid';

        if (this.isUserDemo) {
            mapType = 'satellite'
        }

        let vm = this;
        try {
            const google = await gmapsInit();
            vm.google_maps_reference = google;
            vm.infoWindow = new google.maps.InfoWindow();
            this.map = new google.maps.Map(document.getElementById('google-maps-fields'), {
                // https://developers.google.com/maps/documentation/javascript/tutorial#MapOptions
                minZoom: 4, maxZoom: 20,
                zoom: 4,
                center: vm.positionCenter,
                pan: true,
                mapTypeId: mapType,
                rotateControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                tilt: 0,
                disableDefaultUI: true
            });

            this.drawingManager = new google.maps.drawing.DrawingManager({
                drawingControl: false,
                polygonOptions: {
                    fillColor: 'white',
                    strokeColor: 'white'
                },
                polylineOptions: {
                    fillColor: 'white',
                    strokeColor: 'white'
                }
            });

            google.maps.event.addListener(vm.drawingManager, 'overlaycomplete', function (event) {
                let newShape;
                if (vm.draw_mode === 'polyline') {
                    if (event.overlay.getPath().getLength() == 1) {
                        vm.$toast.add({
                            severity: 'error',
                            summary: this.$t('AppGoogleMapsFields.Deve ter, no mínimo, dois pontos distintos'),
                            life: 5000
                        });
                        event.overlay.setMap(null)
                        return;
                    }
                    newShape = vm.createPolygonFromPolyline(event);
                    newShape.setMap(vm.map);
                    event.overlay.setMap(null);
                } else {
                    newShape = event.overlay;
                    newShape.type = event.type;
                }

                google.maps.event.addListener(newShape.getPath(), 'set_at', function () {
                    newShape.area = vm.getShapeArea(newShape);
                    vm.$emit('newFieldDrawn', newShape);
                });

                google.maps.event.addListener(newShape.getPath(), 'insert_at', function () {
                    newShape.area = vm.getShapeArea(newShape);
                    vm.$emit('newFieldDrawn', newShape);
                });

                newShape.area = vm.getShapeArea(newShape);

                // Disable drawingManager
                vm.drawingManager.setDrawingMode(null);
                newShape.setEditable(true);

                vm.newShape = newShape;

                vm.$emit('newFieldDrawn', newShape);
            })

            this.drawingManager.setMap(this.map);

            this.map.addListener('bounds_changed', () => this.handleBoundsChanged())
        } catch (error) {
            console.error(error);
        }

    },
    data() {
        return {
            map: null,
            google_maps_reference: {},
            fieldsList: [],
            detectedFields: [],
            newShape: null,
            infoWindowsMap: new Map(),
            currentDetectionCenter: null,
            areaGeneratedLimits: null,
            requestBuffer: null,
            TILE_SIZE: 256,
            detectedFieldsColor: '#0023ff',
            detectedFieldsSelectionColor: '#ffffff'
        }
    },
    props: {
        //posição no mapa, ex: {lat: -14.916356, lng: -53.516697}
        positionCenter: {
            required: true,
        },
        fields_to_initialize: {
            type: Array,
            required: true
        },
        selected_field: {
            type: Object,
        },
        draw_field: {
            type: Boolean
        },
        edit_field: {
            type: Boolean
        },
        field_to_edit: {
            type: Object,
        },
        draw_mode: {
            type: String,
        },
        color_polygon: {
            type: String,
        },
        detecting_fields: {
            type: Boolean
        },
        detected_fields: {
            type: Array,
            default() {
                return [];
            }
        },
        pending_request: {
            type: Boolean
        }
    },
    methods: {
        createPolygonFromPolyline(event) {
            let pathToBuffer = [];
            let colorFormat = this.color_polygon === null ? '#FFFFFF' : `#${this.color_polygon}`;
            for (let i = 0; i < event.overlay.getPath().getLength(); i++) {
                pathToBuffer.push(
                    [event.overlay.getPath().getAt(i).lng(), event.overlay.getPath().getAt(i).lat()]
                );
            }
            const POLYGON_WIDTH_IN_METERS = 4 / 11112, // Roughly 40m
                geoInput = {
                    type: "LineString",
                    coordinates: pathToBuffer
                };
            let geoReader = new jsts.io.GeoJSONReader(),
                geoWriter = new jsts.io.GeoJSONWriter();
            let geometry = geoReader.read(geoInput).buffer(POLYGON_WIDTH_IN_METERS, 2, 2);
            let polygon = geoWriter.write(geometry);
            const SIMPLIFY_DISTANCE_TOLERANCE = 1 / 11112; // Roughly 10m
            polygon = jsts.simplify.TopologyPreservingSimplifier.simplify(geometry, SIMPLIFY_DISTANCE_TOLERANCE);
            return new this.google_maps_reference.maps.Polygon({
                paths: this.jstsWithoutHolesToGoogleMaps(polygon, this.google_maps_reference.maps),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillOpacity: 0.35,
                editable: false,
                strokeColor: colorFormat,
                fillColor: colorFormat
            });
        },
        getShapeArea(shape) {
            return (this.google_maps_reference.maps.geometry.spherical.computeArea(shape.getPaths().getArray()[0]) / 10000).toFixed(3);
        },
        getCoordinatesCenter(coordinates) {
            if (!coordinates || coordinates.length === 0) {
                return null;
            }

            let northernmostCoordinates = -90;
            let southernmostCoordinates = 90;
            let easternmostCoordinates = -180;
            let westernmostCoordinates = 180;

            coordinates.forEach(coordinate => {
                if (coordinate.lat() > northernmostCoordinates) {
                    northernmostCoordinates = coordinate.lat();
                }
                if (coordinate.lat() < southernmostCoordinates) {
                    southernmostCoordinates = coordinate.lat();
                }
                if (coordinate.lng() > easternmostCoordinates) {
                    easternmostCoordinates = coordinate.lng();
                }
                if (coordinate.lng() < westernmostCoordinates) {
                    westernmostCoordinates = coordinate.lng();
                }
            })

            return {
                lat: (northernmostCoordinates + southernmostCoordinates) / 2,
                lng: (easternmostCoordinates + westernmostCoordinates) / 2
            };
        },
        clearSelection() {
            this.fieldsList.forEach(field => {
                field.shape.setOptions({
                    fillColor: '#ff6060',
                    strokeColor: '#ff6060',
                });
            });
        },
        getPolygonCenter(shape) {
            return this.getCoordinatesCenter(shape.getPath());
        },
        setSelection(shape, center, color) {
            let colorFormat = color.startsWith("#") ? color : `#${color}`;
            this.clearSelection();
            shape.setOptions({
                fillColor: colorFormat,
                strokeColor: colorFormat,
            });

            this.map.setCenter({lat: center.lat, lng: center.lng})
            if (this.map.getZoom() < 14) {
                this.map.setZoom(14)
            }
        },
        polygonClicked(field) {
            if (this.edit_field || this.detecting_fields) {
                return;
            }
            this.setSelection(field.shape, this.getPolygonCenter(field.shape), field.color);
            this.$emit('changeSelectedField', field);
        },
        addSavedFields(fields) {
            this.fieldsList = [];
            let colorFormat;
            fields.forEach(field => {
                let vm = this;
                colorFormat = field.color.startsWith("#") ? field.color : `#${field.color}`;
                let reader = new jsts.io.WKTReader();
                let geometry = reader.read(field.coordinates);

                let newPolygon = new vm.google_maps_reference.maps.Polygon({
                    paths: this.jstsWithHolesToGoogleMaps(geometry, vm.google_maps_reference.maps),
                    strokeColor: colorFormat,
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: colorFormat,
                    fillOpacity: 0.35,
                    editable: false,
                    zIndex: 1 / geometry.getArea()
                });

                field.shape = newPolygon;
                field.shape.area = (this.google_maps_reference.maps.geometry.spherical.computeArea(
                    this.jstsWithoutHolesToGoogleMaps(geometry,
                        this.google_maps_reference.maps)[0]) / 10000).toFixed(4);

                this.fieldsList.push(field)

                vm.google_maps_reference.maps.event.addListener(newPolygon, 'click', function () {
                    vm.polygonClicked(field);
                });

                newPolygon.setMap(vm.map);

            })
        },
        addDetectedFields(detectedFields) {
            this.detectedFields = [];
            detectedFields.forEach(field => {
                let vm = this;
                let reader = new jsts.io.WKTReader();
                let geometry = reader.read(field.coordinates);

                let newPolygon = new vm.google_maps_reference.maps.Polygon({
                    paths: this.jstsWithHolesToGoogleMaps(geometry, vm.google_maps_reference.maps),
                    strokeColor: this.detectedFieldsColor,
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: this.detectedFieldsColor,
                    fillOpacity: 0.35,
                    editable: false,
                    zIndex: 1 / geometry.getArea()
                });

                field.shape = newPolygon;
                field.shape.area = (this.google_maps_reference.maps.geometry.spherical.computeArea(
                    this.jstsWithoutHolesToGoogleMaps(geometry,
                        this.google_maps_reference.maps)[0]) / 10000).toFixed(4);

                this.detectedFields.push(field)

                vm.google_maps_reference.maps.event.addListener(newPolygon, 'click', function () {
                    vm.showDetectedFieldInfoWindow(field.id);
                });

                newPolygon.setMap(vm.map);
            })
        },
        showDetectedFieldInfoWindow(id) {
            let vm = this;
            let infoWindow = null;
            this.closeDetectedFieldsInfoWindows();

            this.detectedFields[id].shape.setOptions({fillColor: this.detectedFieldsSelectionColor})

            if (!this.infoWindowsMap.has(id)) {
                infoWindow = new vm.google_maps_reference.maps.InfoWindow({
                    position: this.getPolygonCenter(this.detectedFields[id].shape)
                });
                let content = '<div id="content"><div class="m-2" id="bodyContent">' +
                    '<input id="detected-input" class="p-inputtext p-component mr-3" type="text" placeholder=' + this.$t('AppGoogleMapsFields.Nome do talhão') + '>' +
                    '<button id="save-btn" class="btn btn-primary">' + this.$t('AppGoogleMapsFields.Salvar talhão') + '</button>' +
                    '</div></div>';
                infoWindow.setContent(content);

                this.infoWindowsMap.set(id, infoWindow);
            } else {
                infoWindow = this.infoWindowsMap.get(id);
            }

            if (infoWindow) {
                infoWindow.open(vm.map);
            }

            infoWindow.addListener('domready', () => {
                const btn = document.getElementById('save-btn')
                const input = document.getElementById('detected-input');

                input.addEventListener('input', () => {
                    input.classList.remove('p-invalid');
                })

                btn.addEventListener('click', () => {
                    if (!input.value) {
                        input.classList.add('p-invalid');
                        this.$toast.add({
                            severity: 'error',
                            summary: this.$t('AppGoogleMapsFields.Adicione um nome ao talhão'),
                            life: 5000
                        });
                        return;
                    }

                    let newDetectedField = {
                        area: this.detectedFields[id].shape.area,
                        color: 'FFFFFF',
                        coordinates: this.detectedFields[id].coordinates,
                        fixed_working_width: 0,
                        name: input.value,
                        type: 'UNDEFINED'
                    }

                    this.closeDetectedFieldsInfoWindows();
                    this.$emit('saveField', newDetectedField);
                })
            })

            this.google_maps_reference.maps.event.addListener(infoWindow, 'closeclick', function() {
                vm.detectedFields[id].shape.setOptions({fillColor: vm.detectedFieldsColor})
            });
        },
        clearInfoWindowMap() {
            this.infoWindowsMap = new Map();
        },
        closeDetectedFieldsInfoWindows() {
            for (const key of this.infoWindowsMap.keys()) {
                if (this.infoWindowsMap.get(key).map) {
                    let clearInfo = this.infoWindowsMap.get(key)
                    clearInfo.setMap(null);
                    this.infoWindowsMap.set(key, clearInfo);
                }
            }

            let field = this.detectedFields.find(f => f.shape.fillColor === this.detectedFieldsSelectionColor);
            if (field) {
                field.shape.setOptions({fillColor: this.detectedFieldsColor})
            }
        },
        setSelectionFromList(val) {
            this.fieldsList.forEach(field => {
                if (field.id === val.id) {
                    this.setSelection(field.shape, this.getPolygonCenter(field.shape), val.color);
                }
            })
        },
        clearAllFields() {
            if (this.newShape) {
                this.newShape.setMap(null);
            }

            this.fieldsList.forEach(field => {
                field.shape.setMap(null);
            })

            this.fieldsList = [];
        },
        clearAllDetectedFields() {
            this.detectedFields.forEach(field => {
                field.shape.setMap(null);
            })
        },
        discardCurrentShape(clean) {
            if (this.newShape) {
                this.newShape.setMap(null);
            }
            if (clean) {
                this.newShape = null;
            }
        },
        handleBoundsChanged() {
            if (!this.detecting_fields) {
                return;
            }

            if (this.map.getZoom() <= 14) {
                this.$emit('clearDetectedFields');
                this.currentDetectionCenter = null
                this.areaGeneratedLimits = null
                return;
            }

            this.$emit('hideMessage')

            let bounds = {
                north: this.map.getBounds().getNorthEast().lat(),
                west: this.map.getBounds().getSouthWest().lng(),
                south: this.map.getBounds().getSouthWest().lat(),
                east: this.map.getBounds().getNorthEast().lng()
            }

            if (this.detectedMapsStillInBound(bounds)) {
                return;
            }

            if (this.pending_request) {
                this.requestBuffer = this.map.getCenter()
                return;
            }

            this.requestDetection(this.map.getCenter())
        },
        requestDetection(screenCenter) {
            this.currentDetectionCenter = this.getTileCenter(screenCenter)
            let params = {
                lat: this.currentDetectionCenter.lat(),
                lon: this.currentDetectionCenter.lng(),
                type: 0
            }
            this.$emit('requestFieldsDetection', params)
        },
        getTileCenter(screenCenter) {
            let currentTile = this.getCurrentTile(screenCenter);
            this.areaGeneratedLimits = this.getAreaLimits(currentTile)
            let edges = this.getAreaEdges(currentTile)

            let centralLat = (edges.nw.lat() + edges.se.lat()) / 2;
            let centralLng = (edges.nw.lng() + edges.se.lng()) / 2;

            return new this.google_maps_reference.maps.LatLng({lat: centralLat, lng: centralLng});
        },
        getAreaLimits(currentTile) {
            let edges = this.getAreaEdges(currentTile, 3, 2)

            return {
                north: edges.nw.lat(),
                west: edges.nw.lng(),
                south: edges.se.lat(),
                east: edges.se.lng()
            }
        },
        getAreaEdges(tile, xTileGap = 0, yTileGap = 0) {
            let nwPixelX = (tile.x - xTileGap) * this.TILE_SIZE;
            let nwPixelY = (tile.y - yTileGap) * this.TILE_SIZE;
            let sePixelX = (tile.x + xTileGap + 1)  * this.TILE_SIZE - 1;
            let sePixelY = (tile.y + yTileGap + 1)  * this.TILE_SIZE - 1;

            let nwWorldX = nwPixelX / (Math.pow(2, 15));
            let nwWorldY = nwPixelY / (Math.pow(2, 15));
            let seWorldX = sePixelX / (Math.pow(2, 15));
            let seWorldY = sePixelY / (Math.pow(2, 15));

            let nwWorldPoint = new this.google_maps_reference.maps.Point(nwWorldX, nwWorldY);
            let seWorldPoint = new this.google_maps_reference.maps.Point(seWorldX, seWorldY);

            return {
                nw: this.map.getProjection().fromPointToLatLng(nwWorldPoint),
                se: this.map.getProjection().fromPointToLatLng(seWorldPoint)
            }
        },
        detectedMapsStillInBound(bounds) {
            if (!this.currentDetectionCenter) {
                return false;
            }

            return !(bounds.north > this.areaGeneratedLimits.north ||
                bounds.south < this.areaGeneratedLimits.south ||
                bounds.west < this.areaGeneratedLimits.west ||
                bounds.east > this.areaGeneratedLimits.east);
        },
        getCurrentTile(center) {
            let scale = 1 << 15;
            let worldCoordinate = this.project(center);

            return {
                x: Math.floor((worldCoordinate.x * scale) / 256),
                y: Math.floor((worldCoordinate.y * scale) / 256)
            }
        },
        project(latLng) {
            let siny = Math.sin((latLng.lat() * Math.PI) / 180);
            siny = Math.min(Math.max(siny, -0.9999), 0.9999);

            return new this.google_maps_reference.maps.Point(
                256 * (0.5 + latLng.lng() / 360),
                256 * (0.5 - Math.log((1 + siny) / (1 - siny)) / (4 * Math.PI))
            );
        }
    },
    computed: {
        ...mapGetters([
            'isUserDemo'
        ])
    },
    watch: {
        detecting_fields: function (val) {
            if (val) {
                this.currentDetectionCenter = null
                this.handleBoundsChanged();
            }
        },
        color_polygon: function (val) {
            if (val) {
                const newColor = '#' + val;

                const polygonOptions = {
                    fillColor: newColor,
                    strokeColor: newColor,
                };

                const polylineOptions = {
                    fillColor: newColor,
                    strokeColor: newColor,
                };

                this.drawingManager.setOptions({
                    polygonOptions,
                    polylineOptions,
                });

                if (this.newShape !== null) {
                    this.discardCurrentShape(false);
                    this.newShape.setOptions({
                        fillColor: newColor,
                        strokeColor: newColor,
                    });
                    this.newShape.setMap(this.map);
                }
            }
        },
        positionCenter: {
            handler: function (val) {
                if (val) {
                    this.map.setCenter({lat: val.lat, lng: val.lng,})
                    if (this.map.getZoom() < 10) {
                        this.map.setZoom(16)
                    }
                }
            },
            deep: true
        },
        fields_to_initialize: {
            handler: function (val) {
                this.clearAllFields();
                if (val) {
                    this.addSavedFields(val);
                }
            }
        },
        detected_fields: {
            handler: function (val) {
                this.closeDetectedFieldsInfoWindows();
                this.clearInfoWindowMap();
                this.clearAllDetectedFields();
                if (!this.detecting_fields) {
                    return;
                }

                if (val && this.map.getZoom() >= 15) {
                    this.addDetectedFields(val)
                }
            }
        },
        pending_request: {
            handler: function (val) {
                if (!val && this.requestBuffer) {
                    this.requestDetection(this.requestBuffer)
                    this.requestBuffer = null
                }
            }
        },
        selected_field: {
            handler: function (val) {
                this.clearSelection();
                if (val) {
                    this.setSelectionFromList(val)
                }
            }
        },
        draw_field: {
            handler: function (val) {
                if (!val) {
                    this.drawingManager.setDrawingMode(null);
                    return;
                }

                if (this.draw_mode === 'polygon') {
                    this.drawingManager.setDrawingMode(this.google_maps_reference.maps.drawing.OverlayType.POLYGON);
                } else {
                    this.drawingManager.setDrawingMode(this.google_maps_reference.maps.drawing.OverlayType.POLYLINE);
                }
            }
        },
        edit_field: {
            handler: function (val) {
                if (val) {
                    this.fieldsList.forEach(field => {
                        field.shape.setOptions({
                            clickable: false
                        });
                    })
                    return;
                }
                this.drawingManager.setDrawingMode(null);
            }
        },
        field_to_edit: {
            handler: function (val) {
                let vm = this;
                if (val) {
                    this.fieldsList.forEach(field => {
                        if (field.id === val.id) {

                            field.shape.setEditable(true)

                            field.shape.setOptions({
                                clickable: false,
                                fillColor: '#' + val.color,
                                strokeColor: '#' + val.color,
                            });

                            vm.google_maps_reference.maps.event.addListener(field.shape.getPath(), 'set_at', function () {
                                field.shape.area = vm.getShapeArea(field.shape);
                                vm.$emit('newFieldDrawn', field.shape);
                            });

                            vm.google_maps_reference.maps.event.addListener(field.shape.getPath(), 'insert_at', function () {
                                field.shape.area = vm.getShapeArea(field.shape);
                                vm.$emit('newFieldDrawn', field.shape);
                            });

                            vm.newShape = field.shape;
                        }
                    })
                }
            }
        }
    }
}
</script>

<style scoped>

#google-maps-fields {
    height: 100%;
}

</style>
