<template>
    <div style="height: 100%">
        <div class="menu-buttons">
            <div class="zoom-buttons">
                <app-button type="primary" @handleClick="zoomIn" class="center zoom">
                    <span class="pi pi-search-plus" style="font-size: 16px"/>
                </app-button>
                <app-button type="primary" @handleClick="zoomOut" class="center zoom">
                    <span class="pi pi-search-minus" style="font-size: 16px"/>
                </app-button>
            </div>
            <div>
                <span class="arrow arrow-left" @click="moveLeft">
                    <span class="glyphicons x2" :class="'glyphicons-chevron-left'"></span>
                </span>
                <span class="arrow arrow-right" @click="moveRight">
                    <span class="glyphicons x2" :class="'glyphicons-chevron-right'"></span>
                </span>
            </div>
        </div>
        <div id="timeline" class="operations-timeline" style="height: 100%"></div>
    </div>
</template>

<script>

import {Timeline} from "vis-timeline/standalone";

import AppButton from "@/components/common/AppButton";

export default {
    mounted() {
        this.initializeTimeline();
    },
    data() {
        return {
            timeline: null,
            options: {
                locale: "pt",
                locales: {
                    pt: {
                        current: "atual",
                        time: "tempo",
                    },
                },
                orientation: {
                    axis: 'bottom',
                    item: 'bottom'
                },
                min: new Date(2021, 5, 1), // data mínima
                max: new Date(2027, 0, 1), // data máxima
                zoomMin: 1000 * 60 * 60 * 24 * 7, // uma semana em ms
                zoomMax: 1000 * 60 * 60 * 24 * 31 * 24, // dois anos em ms
                multiselect: false,
                showCurrentTime: true,
                editable: false,
                moveable: true,
                stack: false,
            }
        }
    },
    props: {
        items: {
            type: Array,
            required: true
        }
    },
    methods: {
        async initializeTimeline() {
            let vm = this;
            await this.sleep(1);

            this.timeline = new Timeline(document.getElementById('timeline'));
            this.timeline.setOptions(this.options);
            this.timeline.setItems(this.items);

            this.timeline.on('select', function (event) {
                vm.handleSelect(event)
            });

            this.timeline.on('itemover', function (event) {
                vm.handleItemOver(event)
            });

            this.timeline.on('itemout', function (event) {
                vm.handleItemOut(event)
            });
        },
        refresh() {
            this.timeline.destroy();
            this.initializeTimeline();
        },
        handleSelect(event) {
            this.$emit('handleSelect', event);
        },
        handleItemOver(event) {
            this.$emit('handleItemOver', event);
        },
        handleItemOut(event) {
            this.$emit('handleItemOut', event);
        },
        zoomIn() {
            this.timeline.zoomIn(0.8);
        },
        zoomOut() {
            this.timeline.zoomOut(0.8);
        },
        moveLeft() {
            this.move(0.2);
        },
        moveRight() {
            this.move(-0.2);
        },
        move(percentage) {
            let range = this.timeline.getWindow();
            let interval = range.end - range.start;

            this.timeline.setWindow(
                range.start.valueOf() - interval * percentage,
                range.end.valueOf() - interval * percentage
            );
        },
        moveTo(time, options) {
            this.timeline.moveTo(time, options);
        },
        redraw() {
            if (this.timeline) {
                this.timeline.redraw();
            }
        },
        setSelection(id) {
            this.timeline.setSelection(id);
        },
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        setFocusItem(item) {
            let itemData = this.timeline.itemSet.items[item.id].data;
            this.timeline.moveTo(itemData.start, {animation: {duration: 500, easingFunction: "linear"}});
        },
        setHover(item) {
            let itemData = this.timeline.itemSet.items[item.id].data;
            itemData.className = 'hovered';
            this.timeline.itemsData.update(itemData);
        },
        clearHover(item) {
            let itemData = this.timeline.itemSet.items[item.id].data;
            itemData.className = 'operation-analyze'
            this.timeline.itemsData.update(itemData);
        },
        unselectAll() {
            this.timeline.setSelection(null);
        }
    },
    watch: {
        items: {
            deep: true,
            handler(val) {
                if (this.timeline) {
                    this.timeline.setItems(val);
                }
            }
        }
    },
    components: {
        AppButton
    }
}

</script>

<style>

.vis-item .vis-item-overflow {
    overflow: visible;
    z-index: 1;
}

.vis-panel > .vis-content {
    min-height: 100% !important;
}

.manage-operations-timeline > .operations-timeline > .vis-timeline {
    background-color: white;
    border-radius: 5px;
}

.analyze-operations-timeline > .operations-timeline .vis-timeline {
    background-color: white;
    border-radius: 5px;
    height: 100% !important;
}

.vis-time-axis .vis-grid.vis-odd {
    background: #f5f5f5;
}

.vis-item.operation-analyze {
    background-color: #784bd1;
    border-color: #311d5c;
    /*height: 170px;*/
    z-index: 2;
    margin-top: 40px !important;
    top: 5px !important;
}

.vis-item.vis-selected {
    border-color: yellow;
}

.vis-item:hover {
    border-color: yellow;
    cursor: pointer;
}

.vis-item.hovered {
    border-color: #47acff;
    background-color: #78c2ff;
    height: 67px;
    margin-top: 40px !important;
    top: 5px !important;
}

</style>

<style scoped>

.menu-buttons {
    position: relative;
    z-index: 1;
    height: 0;
}

.zoom-buttons {
    position: absolute;
    top: 5px;
    right: 5px;
    width: fit-content;
}

.zoom {
    min-height: 36px;
    min-width: 36px;
    margin-left: 10px;
    display: inline-block;
}

.glyphicons.x2::before {
    padding: 0 !important;
}

.arrow {
    position: absolute;
    top: 45px;
    color: gray;
    opacity: 0.8;
    cursor: pointer;
    padding: 0 !important;
}

.arrow:hover {
    opacity: 1.0;
}

.arrow-left {
    left: 0;
}

.arrow-right {
    right: 0;
}

</style>
