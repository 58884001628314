<template>
    <div class="tasks-list">
        <div class="row no-gutters sub-header">
            <div class="col-12">
                <div v-if="selected_field" class="title">
                    {{ $t('AppTasksList.Analisar Trabalhos de') }} {{ selected_field.name }}
                </div>
                <div class="sub-title">
                    <Button :label="$t('AppTasksList.Todos')" style="font-size: 10px;" @click="allClicked"
                            class="mr-2 p-1 p-button-sm p-button-rounded"
                            :class="{'p-button-outlined': isCurrentOptionActive('all')}"/>
                    <Button :label="$t('AppTasksList.Agendados')" style="font-size: 10px" @click="scheduledClicked"
                            class="mr-2 p-1 p-button-sm p-button-rounded"
                            :class="{'p-button-outlined': isCurrentOptionActive('scheduled')}"/>
                    <Button :label="$t('AppTasksList.Completos')" style="font-size: 10px" @click="doneClicked"
                            class="mr-2 p-1 p-button-sm p-button-rounded"
                            :class="{'p-button-outlined': isCurrentOptionActive('done')}"/>
                </div>
            </div>
        </div>
        <perfect-scrollbar class="mt-4">
            <div v-if="sortedTasks.length > 0">
                <div v-for="task in sortedTasks">
                    <div v-if="task.status === tasksToShow || tasksToShow === 'all'"
                         class="row no-gutters tasks" :id="task.id"
                         v-tooltip.top="taskStatusTooltip(task.status)" @click="taskClicked(task)"
                         @mouseleave="leaveTask(task)" @mouseover="hoverTask(task)">
                        <div class="task-body pt-2 pb-2" style="width: 100%">
                            <div class="task-body-date">
                                {{ dateFormat(task.start.valueOf() / 1000) }} {{ $t('AppTasksList.a') }} {{
                                    dateFormat(task.end.valueOf() / 1000)
                                }}
                            </div>
                            <div class="row no-gutters mt-1" style="width: 100%" id="task-body-content">
                                <div class="col task-body-title">
                                    {{ task.name }}
                                    <span :class="getStatusIcon(task.status)"
                                          style="margin-left: 10px; font-size: 18px"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else style="font-size: 16px">
                 {{ $t('AppTasksList.Não há trabalhos para este talhão') }}

            </div>
        </perfect-scrollbar>
    </div>
</template>

<script>

import AppButton from "@/components/common/AppButton";

import Button from 'primevue/button'
import Tooltip from "primevue/tooltip";

import dateFormat from "@/mixins/DateMixin";

export default {
    mixins: [dateFormat],
    data() {
        return {
            manageTask: false,
            tasksToShow: 'all',
        }
    },
    props: {
        tasks: {
            type: Array,
        },
        selected_field: {
            type: Object,
        },
    },
    methods: {
        getStatusIcon(status) {
            return status === 'done' ? 'pi pi-check-circle' : 'pi pi-calendar-times';
        },
        taskStatusTooltip(status) {
            return status === 'done' ? this.$t('AppTasksList.Finalizada') : this.$t('AppTasksList.Agendada');
        },
        hoverTask(task) {
            this.$emit('hoverTask', task);
        },
        leaveTask(task) {
            this.$emit('leaveTask', task);
        },
        taskClicked(task) {
            this.$emit('taskClickedFromList', task);
        },
        timelineHover(item) {
            let elementById = document.getElementById(item);
            if (elementById) {
                elementById.classList.add('tl-hover');
            }
        },
        timelineHoverOut(item) {
            let elementById = document.getElementById(item);
            if (elementById) {
                elementById.classList.remove('tl-hover');
            }
        },
        allClicked() {
            this.tasksToShow = 'all';
            this.$emit('allClicked');
        },
        scheduledClicked() {
            this.tasksToShow = 'scheduled';
            this.$emit('scheduledClicked');
        },
        doneClicked() {
            this.tasksToShow = 'done';
            this.$emit('doneClicked');
        },
        isCurrentOptionActive(option) {
            return this.tasksToShow !== option;
        }
    },
    watch: {},
    computed: {
        sortedTasks: function () {
            let vm = this;
            return vm.tasks.sort((a, b) => b.start - a.start);
        }
    },
    components: {
        Button, Tooltip, AppButton
    },
    directives: {
        tooltip: Tooltip
    },
}
</script>

<style scoped lang="scss">

.tasks-list {
    margin-left: 40px;
    margin-right: 40px;
}

.title {
    padding-top: 30px;
    font-size: 22px;
    font-weight: bold;
    color: black;
}

.assign-task {
    padding-top: 30px;
    float: right;
}

.sub-title {
    margin-top: 10px;
}

.sub-header {
    height: 120px;
    border-bottom: 1px solid #d6d6d6;
}

.task-body-date {
    font-size: 12px;
    color: #a2a2a2;
    font-weight: 700;
}

.task-body-title {
    font-size: 18px;
    font-weight: 900;
    color: black;
}

.ps {
    height: calc(100vh - 490px) !important;
}

.task-body:hover {
    background-color: #addaff;
    cursor: pointer;
    border-radius: 5px;
}

.tl-hover {
    background-color: #addaff;
}

.glyphicons:before {
    padding: 0;
}


</style>
