<template>
    <Dialog :visible.sync="display" modal @update:visible="closeDialog">
        <template #header>
            <h3 v-if="sendChecklist">{{ $t('AppChecklistsAssociationDialog.Atribuir Checklist') }}: <b>{{ checklistTitle }}</b></h3>
            <h3 v-else>{{ $t('AppChecklistsAssociationDialog.Limpar checklist') }}</h3>
        </template>

        <div style="width: 35vw">
            <p v-if="sendChecklist">{{ $t('AppChecklistsAssociationDialog.Selecione os veículos e clique em Enviar para enviar checklist para o dispositivo associado ao veículo')}}.</p>
            <p v-else>{{ $t('AppChecklistsAssociationDialog.Escolha os veículos para os quais você não deseja que haja nenhum checklist associado')}}.</p>
            <DataTable :value="tableData" responsiveLAyout="scroll" :autoLayout="true" :filters.sync="filters" :rowHover="true"
                       filterDisplay="row"
                       :selection.sync="selectedVehicles" :loading="loadingVehicles" dataKey="id" :paginator="true"
                       :rows="10" :currentPageReportTemplate="$t('AppChecklistsAssociationDialog.currentPageReportTemplate')"
                       paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                       :sortOrder="-1" sortField="status">

                <template #empty>
                    {{ $t('AppChecklistsAssociationDialog.Veículos não encontrados')}}
                </template>
                <template #loading>
                    {{ $t('AppChecklistsAssociationDialog.Carregando lista de veículos')}}
                </template>
                <Column selectionMode="multiple" headerStyle="display: flex; justify-content:start; width: 3.5rem"
                        bodyStyle="display: flex"></Column>
                <Column field="display_id" :header="$t('AppChecklistsAssociationDialog.Identificação')" sortable headerStyle="text-align: left" :showFilterMenu="false"
                        bodyStyle="text-align: center">
                    <template #body="{data}">
                        {{ data.display_id }}
                    </template>

                    <template #filter="{filterModel,filterCallback}">
                        <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                    </template>

                </Column>
                <Column field="identification_number" :header="$t('AppChecklistsAssociationDialog.Chassis')" sortable headerStyle="text-align: left" :showFilterMenu="false"
                        bodyStyle="text-align: center">
                    <template #body="{data}">
                        {{ data.identification_number }}
                    </template>

                    <template #filter="{filterModel,filterCallback}">
                        <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                    </template>

                </Column>
            </DataTable>
        </div>

        <template #footer>
            <Button :label="$t('AppChecklistsAssociationDialog.Cancelar')" icon="pi pi-times" class="p-button-text"
                    @click="closeDialog"/>
            <Button v-if="sendChecklist" :label="$t('AppChecklistsAssociationDialog.Enviar')" icon="pi pi-check" @click="handleSendClicked"/>
            <Button v-else :label="$t('AppChecklistsAssociationDialog.Limpar')" icon="pi pi-eraser" @click="handleSendClicked"/>
        </template>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import InputText from 'primevue/inputtext';
import {FilterMatchMode} from "primevue/api";


export default {
    data(){
        return {
            filters: {
                'display_id': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'identification_number': {value: null, matchMode: FilterMatchMode.CONTAINS}
            },
            display: false,
            tableData: null,
            selectedVehicles: [],
        }
    },
    methods: {
        closeDialog(){
            this.display = false;
            this.$emit('closeDialog');
        },
        getVehiclesChecklists(){
            this.$emit('getVehiclesChecklists')
        },
        handleSendClicked(){
            if (this.selectedVehicles.length > 0) {
                //TODO REVER LÓGICA PARA MANIPULAR O ID DO VEÍCULO
                let vehiclesObj = []
                this.selectedVehicles.forEach((vehicle) => {
                    vehiclesObj.push({vehicle_id: vehicle.id})
                })

                let object = {
                    "vehicles": vehiclesObj
                }
                if (this.checklistId) {
                    this.$emit("handleSendChecklistToVehicles", object);
                    return
                }

                this.$emit("handleSendEmptyChecklistToVehicles", object);
            } else {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppChecklistsAssociationDialog.Erro'),
                    detail: this.$t('AppChecklistsAssociationDialog.Por favor, selecione pelo menos um veículo para enviar um checklists'),
                    life: 5000
                });
            }
        }
    },
    watch: {
        showDialog: function (val) {
            this.display = val;
            if(val){
                this.tableData = this.vehiclesChecklists;
                this.selectedVehicles = [];
            }
        }
    },
    props: {
        sendChecklist: {
          type: Boolean,
          required: true
        },
        showDialog: {
            type: Boolean,
            required: true
        },
        checklistTitle: {
            type: String,
        },
        checklistId:{
            type:String,
        },
        vehiclesChecklists:{
            type: Array,
        },
        loadingVehicles: {
            type: Boolean,
            required: true
        },
        loadingSendChecklist: {
            type: Boolean,
            required: true
        }
    },
    components: {
        Dialog, Button, DataTable, Column, ColumnGroup, InputText
    }
}
</script>

<style scoped>

</style>
