import dateHourFormat from "./DateMixin.js";
import getTimeFromSecondsMixin from "./DateMixin.js";
import {ANOMALIAS_MACHINERY} from "@/components/views/anomalias/AppAnomaliasOptions";

let anomaliasMachinery = ANOMALIAS_MACHINERY()

export default {
    mixins: [dateHourFormat, getTimeFromSecondsMixin],
    methods: {
        sendWhatsAppAlertDetailsMessage(form, realTimeData, faultDetails, alertEditDetails) {
            const baseURL = "https://api.whatsapp.com/send?text="

            let textContent = "⚠⚠⚠️"
                + "%0a" + "%0a" + "*Ordem:* " + form.work_order
                + "%0a" + "*Responsável:* " + form.assignee
                + "%0a" + "*Status:* " + this.getStatus(form.status)
                + "%0a" + "*Máquina:* " + alertEditDetails.display_id
                + "%0a" + "*Código:* " + alertEditDetails.code;

            if (faultDetails && faultDetails.description) {
                textContent += "%0a" + "*Detalhes:* " + faultDetails.description;
            }

            textContent += "%0a" + "*Unidade:* " + alertEditDetails.trade_name
                + "%0a" + "*Data:* " + this.dateHourFormat(alertEditDetails.date);

            if (form.observation) {
                textContent += "%0a" + "%0a" + "*Observação:* " + form.observation;
            }

            if (realTimeData && realTimeData.data.length > 0) {
                const THREE_HOURS_IN_SECONDS = 10800;
                textContent += "%0a" + "%0a" + "*Última Localização (" +
                    this.getTimeFromSecondsMixin((realTimeData.data[0]._005 / 1000) - THREE_HOURS_IN_SECONDS) + "):* "
                    + "https://maps.google.com.br?q="
                    + realTimeData.data[0]._009 + "," + realTimeData.data[0]._010;
            }

            window.open(baseURL + textContent);
        },
        getStatus(status) {
            switch (status) {
                case 'NEW':
                    return 'PENDENTE';
                case 'IN_PROGRESS':
                    return 'EXECUTANDO';
                case 'CLOSED':
                    return 'FINALIZADO';
                default:
                    return '';
            }
        },
        sendWhatsAppAlertServiceOrderMessage(form) {
            const baseURL = "https://api.whatsapp.com/send?text="

            let textContent = "⚠⚠⚠️"
                + "%0a" + "*Responsável:* " + form.assignee
                + "%0a" + "*Status:* " + form.status
                + "%0a" + "*Frota:* " + form.fleet
                + "%0a" + "*Sensor:* " + form.sensor_name
                + "%0a" + "*Comentário:* " + form.comment
                + "%0a" + "*Data/Hora Último Alerta:* " + this.dateHourFormat(form.hour_last_alert_value)
                + "%0a" + "*Valor Último Alerta:* " + form.alert_value
                + "%0a" + "*Localização do equipamento:* " + "https://maps.google.com.br?q=" + form.lat + "," + form.lng;

            window.open(baseURL + textContent);
        },
        sendWhatsAnomaliasOrderDetails(order, fieldsNamesAndCoordinates) {
            const baseURL = "https://api.whatsapp.com/send?text="

            let googleMapsUrl = 'https://www.google.com/maps/dir/'
            fieldsNamesAndCoordinates.fieldsCenterCoordinates.forEach(center => {
                googleMapsUrl += '/' + center.lat + ',' + center.lng;
            })

            let textContent = "⚠️⚒️⚠️"
                + "%0a" + "%0a" + "*Ordem:* " + order.order_id
                + "%0a" + "%0a" + "*Número de Trechos:* " + order.fields_ids_list.length
                + "%0a" + "*Número de Pontos Isolados:* " + order.points_list.length
                + "%0a" + "*Motoniveladoras:* x" + this.getNumberOfMachinesByType(order.machinery_list, anomaliasMachinery[0].code)
                + "%0a" + "*Pás-Carregadeiras:* x" + this.getNumberOfMachinesByType(order.machinery_list, anomaliasMachinery[1].code)
                + "%0a" + "*Caminhões Basculantes:* x" + this.getNumberOfMachinesByType(order.machinery_list, anomaliasMachinery[2].code)
                + "%0a" + "*Caminhões Pipa:* x" + this.getNumberOfMachinesByType(order.machinery_list, anomaliasMachinery[3].code)
                + "%0a" + "*Escavadeiras:* x" + this.getNumberOfMachinesByType(order.machinery_list, anomaliasMachinery[4].code)
                + "%0a" + "*Retro-Escavadeiras:* x" + this.getNumberOfMachinesByType(order.machinery_list, anomaliasMachinery[5].code)
                + "%0a" + "*Observação:* " + order.observation
                + "%0a" + "*Início:* " + this.dateHourFormat(order.start_date)
                + "%0a" + "*Fim:* " + (this.dateHourFormat(order.end_date) ? this.dateHourFormat(order.end_date) : '')
                + "%0a" + "*Status:* " + this.getStatus(order.status)
                + "%0a" + "%0a" + "*Trechos:* "
                + "%0a" + fieldsNamesAndCoordinates.fieldsNames
                + "%0a" + "%0a" + googleMapsUrl


            window.open(baseURL + textContent);
        },
        getNumberOfMachinesByType(machineryArray, type) {
            let numberOfMachinesByType = 0;
            machineryArray.forEach(machine => {
                if (machine.type === type) {
                    numberOfMachinesByType++;
                }
            })

            return numberOfMachinesByType;
        },
    }
}
