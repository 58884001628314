import i18n from '../../../i18n';

export const STATUSES = [
    {name: i18n.t('AppOptionsCoa.PENDENTE'), code: 'PENDING'},
    {name: i18n.t('AppOptionsCoa.EXECUTANDO'), code: 'IN_PROCESS'},
    {name: i18n.t('AppOptionsCoa.FINALIZADO'), code: 'FINISHED'},
    {name: i18n.t('AppOptionsCoa.SISTEMA'), code: 'FINISHED_BY_THE_SYSTEM'}
];

export const STATUSES_COA = [
    {name: i18n.t('AppOptionsCoa.PENDENTE'), code: 'PENDING'},
    {name: i18n.t('AppOptionsCoa.EXECUTANDO'), code: 'IN_PROCESS'},
    {name: i18n.t('AppOptionsCoa.FINALIZADO'), code: 'FINISHED'}
];

export const STATUSES_ALERT = [
    {name: i18n.t('AppOptionsCoa.HABILITADO'), code: 'ENABLED'},
    {name: i18n.t('AppOptionsCoa.DESABILITADO'), code: 'DISABLED'}
];

export const CRITICALY_LEVELS = [
    { name: i18n.t('AppOptionsCoa.ALTO'), code: 'HIGH' },
    { name: i18n.t('AppOptionsCoa.MÉDIO'), code: 'MEDIUM' },
    { name: i18n.t('AppOptionsCoa.BAIXO'), code: 'LOW' }
];

export const HANDLINGS = [
    {name: i18n.t('AppOptionsCoa.COM MANUTENÇÃO'), code: 'FINISHED_WITH_MAINTENANCE'},
    {name: i18n.t('AppOptionsCoa.SEM MANUTENÇÃO'), code: 'FINISHED_WITHOUT_MAINTENANCE'},
    {name: i18n.t('AppOptionsCoa.SEM TRATATIVA'), code: 'NO_HANDLING'}
];

export const ALERT_TYPE = [
    {name: i18n.t('AppOptionsCoa.ATENÇÃO'), code: 'ATTENTION'},
    {name: i18n.t('AppOptionsCoa.CRÍTICO'), code: 'CRITICAL'},
    {name: i18n.t('AppOptionsCoa.NORMAL'), code: 'NORMAL'}
]

export const VEHICLES_TYPES = [

    {
        value: 0,
        measured: null,
        name: "Desconhecido"
    },
    {
        value: 1,
        measured: "Tractor",
        name: "Trator"
    },
    {
        value: 2,
        measured: "Harvester",
        name: "Colhedora"
    },
    {
        value: 3,
        measured: "Harvester",
        name: "Colheitadeira"
    },
    {
        value: 4,
        measured: "Sprayer",
        name: "Pulverizador"
    },
    {
        value: 5,
        measured: "Sprayer",
        name: "Distribuidor"
    },
    {
        value: 6,
        measured: "Harvester",
        name: "Forrageira"
    },
    {
        value: 7,
        measured: null,
        name: "Escavadeira"
    },
    {
        value: 8,
        measured: null,
        name: "Pá Carregadeira"
    },
    {
        value: 9,
        measured: null,
        name: "Caminhão"
    },
    {
        value: 10,
        measured: null,
        name: "Carregadeira"
    },
    {
        value: 11,
        measured: null,
        name: "Motoniveladora"
    },
    {
        value: 12,
        measured: null,
        name: "Transportadora"
    },
    {
        value: 13,
        measured: null,
        name: "Colhedora Florestal"
    },
    {
        value: 14,
        measured: null,
        name: "Harverster"
    },
    {
        value: 15,
        measured: null,
        name: "Forwarder"
    },
    {
        value: 16,
        measured: null,
        name: "Feller Buncher"
    },
    {
        value: 17,
        measured: null,
        name: "Outro"
    },
    {
        value: 18,
        measured: "Sprayer",
        name: "Semeadora pneumática"
    },
    {
        value: 19,
        measured: null,
        name: "Retroescavadeira"
    },
    {
        value: 20,
        measured: null,
        name: "Espargidor de Agente Aglutinante"
    },
    {
        value: 21,
        measured: null,
        name: "Compactador"
    },
    {
        value: 22,
        measured: null,
        name: "Cultivador"
    },
    {
        value: 23,
        measured: null,
        name: "Guindaste"
    },
    {
        value: 24,
        measured: null,
        name: "Máquina Florestal"
    },
    {
        value: 25,
        measured: null,
        name: "Empilhadeira"
    },
    {
        value: 26,
        measured: null,
        name: "Caminhonete"
    },
    {
        value: 27,
        measured: null,
        name: "Rolo pneumático"
    },
    {
        value: 28,
        measured: null,
        name: "Pavimentadora de Estradas"
    },
    {
        value: 29,
        measured: null,
        name: "Skidder"
    },
    {
        value: 30,
        measured: null,
        name: "Veículos Utilitários"
    },
    {
        value: 31,
        measured: null,
        name: "Enfardadeira"
    },
    {
        value: 32,
        measured: null,
        name: "Caminhão Comboio"
    },
    {
        value: 33,
        measured: null,
        name: "Plataforma"
    }
]
