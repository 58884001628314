<template>
        <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg no-border-radius">
                    <div class="row center">
                        <Dropdown v-model="selectedOrg" :options="orgsList"
                                  optionLabel="display_name" :placeholder="$t('AppManageTasks.Organização')"
                                  class="auto-center dropdown-width-org" :dataKey="'id'"
                                  :filter="true"
                                  style="width: 250px">
                            <template #option="slotProps" style="width: 500px">
                                <div v-if="loadingClients"> {{$t('AppManageTasks.Carregando, aguarde')}}</div>
                                <div style="padding-right: 20px" v-else-if="slotProps.option.name === 'ND'">
                                    -
                                </div>
                                <div style="padding-right: 20px" v-else>
                                    {{ slotProps.option.display_name }}
                                </div>
                            </template>
                        </Dropdown>
                        <Dropdown v-model="selectedField" :options="fieldsList"
                                  optionLabel="name" :placeholder="$t('AppManageTasks.Talhões')"
                                  class="auto-center dropdown-width-field" :filter="true" :dataKey="'id'"
                                  style="width: 250px;" ref="fieldsDropDown">
                            <template #option="slotProps" style="max-width: 250px">
                                <div v-if="loadingFields"> {{$t('AppManageTasks.Carregando, aguarde')}}</div>
                                <div style="padding-right: 20px" v-else-if="slotProps.option.name === 'ND'">
                                    -
                                </div>
                                <div style="padding-right: 20px" v-else>
                                    {{ slotProps.option.name }}
                                </div>
                            </template>
                        </Dropdown>
                    </div>
                </div>
                <div class="kt-portlet__body no-padding">
                    <div v-if="selectedField && loadedSuccessfully" style="margin-left: 10px;margin-right: 10px;">
                        <div class="manage-crops-title">
                            {{ $t('AppManageTasks.Gerenciar Culturas de') }} {{ fieldName }}
                            <div style="display: inline-block;position: absolute; right: 10px">
                                <app-button type="primary" @handleClick="handleSaveClicked"
                                            :disabled="!itemModified">{{ $t('AppManageTasks.Salvar') }}
                                </app-button>
                            </div>
                        </div>
                        <div style="position: sticky !important;top: 0;z-index: 50;height: 250px !important;">
                            <app-timeline ref="timeline" class="manage-tasks-timeline"
                                          :items="items" :options="options"
                                          @handleChange="handleChange"
                                          @handleDrop="handleDrop"
                                          @handleSelect="handleSelect"/>
                        </div>

                        <div class="row" style="margin-left: 1px;margin-right: 1px;margin-top: 30px">
                            <app-manage-tasks-list :selected_client="selectedOrg" @handleDragStart="handleDragStart"
                                                   @handleDragEnd="handleDragEnd"/>
                            <div v-if="selectedItem" class="col-12 col-md-6 mt-4 mt-md-0" style="color: black">
                                <div v-if="groupName !== 'hist'">
                                    <p class="m-0" style="font-size: 16px; font-weight: 800">{{ contentName }}</p>
                                    <div class="p-3" style="border: 1px solid gray;margin-top: 6px">
                                        <div v-if="groupName === 'crop'">
                                            <p>{{ $t('AppManageTasks.Lista de Tarefas') }}:</p>
                                            <div v-for="task in selectedItem.tasks" style="display: inline-block">
                                                <Button style="font-size: 10px;" @click="taskClicked(task)"
                                                        class="mr-2 p-1 p-button-sm p-button-rounded p-button-outlined">
                                                    {{ task.data.name }}
                                                </Button>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <p style="display: inline-block; font-weight: bold">{{ $t('AppManageTasks.Cultura') }}:</p>
                                            {{ belongingCrop }}
                                        </div>
                                        <label class="mt-4"> {{ $t('AppManageTasks.Data Inicial') }}</label>
                                        <div>
                                            <Calendar v-model="selectedItem.data.start"
                                                      @date-select="dateStartSelect"/>
                                        </div>
                                        <label class="mt-4">{{ $t('AppManageTasks.Data Final') }}</label>
                                        <div>
                                            <Calendar id="end-date" v-model="selectedItem.data.end"
                                                      @date-select="dateEndSelect"/>
                                        </div>
                                    </div>
                                    <div style="width: 100%;" class="mt-5">
                                        <app-button type="secondary" style="float: right" @handleClick="deleteItem">

                                            {{ $t('AppManageTasks.Excluir') }}
                                        </app-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        <Dialog :visible.sync="saveDialog" :style="{width: '450px'}" :header="$t('AppManageTasks.Salvar')" :modal="true">
            {{ $t('AppManageTasks.Deseja salvar a configuração atual?') }}
            <template #footer>
                <Button :label="$t('AppManageTasks.Cancelar')" icon="pi pi-times" class="p-button-text" @click="saveDialog = false"/>
                <Button :label="$t('AppManageTasks.Salvar')" icon="pi pi-check" class="p-button-text" @click="saveConfiguration"/>
            </template>
        </Dialog>
        </div>
</template>

<script>

import * as jsts from 'jsts';

import Slider from 'primevue/slider';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';
import RadioButton from 'primevue/radiobutton';

import dateFormat from "@/mixins/DateMixin";
import CropsService from "@/services/CropsService";
import TasksService from "@/services/TasksService";
import FieldsService from "@/services/FieldsService";
import AppButton from "@/components/common/AppButton";
import AppTimeline from "@/components/common/AppTimeline";
import FieldWorksService from "@/services/FieldWorksService";
import AppManageTasksList from "@/components/views/agriculture/manage-tasks/AppManageTasksList";
import {CROPS_LIST, TASKS_LIST} from "@/components/views/agriculture/AppAgricultureOptions";
import OrganizationsService from "@/services/OrganizationsService";
import gmapsInit from "@/utils/gmaps";
import jstsWithoutHolesToGoogleMaps from "@/mixins/GeometryMixin";

export default {
    mixins: [dateFormat, jstsWithoutHolesToGoogleMaps],
    async mounted() {
        this.google_maps_reference = await gmapsInit();
        this.organizationsService = new OrganizationsService();
        this.fieldsService = new FieldsService();
        this.cropsService = new CropsService();
        this.fieldWorksService = new FieldWorksService();
        this.tasksService = new TasksService();
        this.getOrgs();
    },
    data() {
        return {
            google_maps_reference: null,
            selectedOrg: null,
            orgsList: [],
            loadingClients: false,
            fieldsService: null,
            cropsService: null,
            tasksService: null,
            fieldWorksService: null,
            items: [],
            options: {
                locale: "pt",
                locales: {
                    pt: {
                        current: "atual",
                        time: "tempo",
                    },
                },
                orientation: "bottom",
                height: "250px",
                min: new Date(2021, 5, 1), // data mínima
                max: new Date(2027, 0, 1), // data máxima
                start: new Date(2023, 0, 1),
                end: new Date(2025, 0, 1),
                zoomMin: 1000 * 60 * 60 * 24 * 48, // uma semana em ms
                zoomMax: 1000 * 60 * 60 * 24 * 31 * 24, // dois anos em ms
                multiselect: false,
                showCurrentTime: true,
                editable: {
                    add: true,
                    updateTime: true,
                    updateGroup: true,
                    remove: false,
                    overrideItems: false
                },
                moveable: true,
                stack: false,
            },
            timeline: null,
            draggedElement: null,
            targetElement: null,
            itemDraggedId: null,
            selectedItem: null,
            loadingFields: false,
            selectedField: null,
            fieldsList: [],
            center: {lat: -25.407688, lng: -49.253990},
            itemModified: false,
            saveDialog: false,
            loadedSuccessfully: false,
            loadingItem: false,
            originalCropsList: [],
            originalTasksList: [],
            cropsListDefault: CROPS_LIST(),
            tasksListDefault: TASKS_LIST(),
            SECONDS_TO_NOON: 43200,
            changedOrg: false
        }
    },
    props: {},
    methods: {
        handleSaveClicked() {
            this.saveDialog = true;
        },
        isThereItemWithNoYield() {
            let isThereItemWithNoYield = false;
            for (let key of Object.keys(this.timeline.getItems())) {
                if (this.timeline.getItemDataProperty(key, 'group') === 'task' &&
                    !this.timeline.getItemDataProperty(key, 'expected_yield')) {
                    isThereItemWithNoYield = true
                }
            }

            return isThereItemWithNoYield;
        },
        saveConfiguration() {
            if (this.isThereItemWithNoYield()) {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppManageTasks.Não foi possível salvar configuração: há trabalhos sem rendimento esperado.'),
                    life: 5000
                });
                this.saveDialog = false;
                return;
            }

            if (this.isThereAnyOverlappingCrops()) {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppManageTasks.Não foi possível salvar configuração: há culturas sobrepostas.'),
                    life: 5000
                });
                this.saveDialog = false;
                return;
            }

            if (this.isThereAnyTaskOutOfRange()) {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppManageTasks.Não foi possível salvar configuração: há trabalhos sem cultura associada.'),
                    life: 5000
                });
                this.saveDialog = false;
                return;
            }


            this.saveItems();
            this.saveDialog = false;
        },
        isThereAnyTaskOutOfRange() {
            let thereIsATaskOutOfRange = false;
            for (let keyCrop of Object.keys(this.timeline.getItems())) {
                if (this.timeline.getItemDataProperty(keyCrop, 'className') === 'out-of-range') {
                    thereIsATaskOutOfRange = true;
                }
            }
            return thereIsATaskOutOfRange;
        },
        isThereAnyOverlappingCrops() {
            for (let keyCrop of Object.keys(this.timeline.getItems())) {
                if (this.timeline.getItemDataProperty(keyCrop, 'group') !== 'crop') {
                    continue;
                }

                for (let innerKeyCrop of Object.keys(this.timeline.getItems())) {
                    if (this.timeline.getItemDataProperty(innerKeyCrop, 'group') !== 'crop') {
                        continue;
                    }

                    if (this.timeline.getItemDataProperty(keyCrop, 'id') === this.timeline.getItemDataProperty(innerKeyCrop, 'id')) {
                        continue;
                    }

                    if (this.timeline.getItemDataProperty(keyCrop, 'start') < this.timeline.getItemDataProperty(innerKeyCrop, 'start') &&
                        this.timeline.getItemDataProperty(innerKeyCrop, 'start') < this.timeline.getItemDataProperty(keyCrop, 'end')) {
                        return true;
                    }

                    if (this.timeline.getItemDataProperty(keyCrop, 'start') < this.timeline.getItemDataProperty(innerKeyCrop, 'end') &&
                        this.timeline.getItemDataProperty(innerKeyCrop, 'end') < this.timeline.getItemDataProperty(keyCrop, 'end')) {
                        return true;
                    }

                    if (this.timeline.getItemDataProperty(innerKeyCrop, 'start') < this.timeline.getItemDataProperty(keyCrop, 'start') &&
                        this.timeline.getItemDataProperty(keyCrop, 'end') < this.timeline.getItemDataProperty(innerKeyCrop, 'end')) {
                        return true;
                    }

                    if (this.timeline.getItemDataProperty(keyCrop, 'start') === this.timeline.getItemDataProperty(innerKeyCrop, 'start') &&
                        this.timeline.getItemDataProperty(keyCrop, 'end') === this.timeline.getItemDataProperty(innerKeyCrop, 'end')) {
                        return true;
                    }
                }
            }

            return false;
        },
        isCropEdited(item, key) {
            return item.start * 1000 !== this.timeline.getItemDataProperty(key, 'start').valueOf() ||
                item.end * 1000 !== this.timeline.getItemDataProperty(key, 'end').valueOf()
        },
        isTaskEdited(item, key) {
            return item.start * 1000 !== this.timeline.getItemDataProperty(key, 'start').valueOf() ||
                item.end * 1000 !== this.timeline.getItemDataProperty(key, 'end').valueOf() ||
                item.expected_yield !== this.timeline.getItemDataProperty(key, 'expected_yield')
        },
        saveCrops() {
            let objToPost = {
                added_crops: [],
                edited_crops: [],
                deleted_crops: [],
            };

            for (let keyCrop of Object.keys(this.timeline.getItems())) {
                if (this.timeline.getItemDataProperty(keyCrop, 'group') === 'crop') {
                    let cropToAdd = {};

                    cropToAdd.id = this.timeline.getItemDataProperty(keyCrop, 'id');
                    cropToAdd.name = this.timeline.getItemDataProperty(keyCrop, 'name');
                    cropToAdd.start = this.timeline.getItemDataProperty(keyCrop, 'start').valueOf();
                    cropToAdd.end = this.timeline.getItemDataProperty(keyCrop, 'end').valueOf();

                    let originalCropItem = this.originalCropsList.find(obj => obj.id ===
                        this.timeline.getItemDataProperty(keyCrop, 'id'));

                    if (originalCropItem && this.isCropEdited(originalCropItem, keyCrop)) {
                        objToPost.edited_crops.push(cropToAdd);
                        continue;
                    }

                    if (!originalCropItem) {
                        objToPost.added_crops.push(cropToAdd);
                    }
                }
            }

            this.originalCropsList.forEach(crop => {
                let deletedCrop = true
                for (let keyCrop of Object.keys(this.timeline.getItems())) {
                    if (this.timeline.getItemDataProperty(keyCrop, 'group') === 'crop') {

                        if (crop.id === this.timeline.getItemDataProperty(keyCrop, 'id')) {
                            deletedCrop = false;
                        }
                    }
                }

                if (deletedCrop) {
                    objToPost.deleted_crops.push(crop);
                }
            })

            this.cropsService.saveCrops(this.selectedField.id, objToPost).catch((error) => {
                if (!error.response || error.response.status !== 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppManageTasks.Não foi possível completar a operação, Tente novamente'),
                        life: 5000
                    });
                    return;
                }
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppManageTasks.Você não tem acesso a esta funcionalidade'),
                    life: 5000
                });
            });
        },
        saveTasks() {
            let objToPost = {
                added_tasks: [],
                edited_tasks: [],
                deleted_tasks: [],
            };


            for (let keyTask of Object.keys(this.timeline.getItems())) {
                if (this.timeline.getItemDataProperty(keyTask, 'group') === 'task') {
                    let taskToAdd = {};

                    taskToAdd.id = this.timeline.getItemDataProperty(keyTask, 'id');
                    taskToAdd.name = this.timeline.getItemDataProperty(keyTask, 'name');
                    taskToAdd.start = this.timeline.getItemDataProperty(keyTask, 'start').valueOf();
                    taskToAdd.end = this.timeline.getItemDataProperty(keyTask, 'end').valueOf();
                    taskToAdd.expected_yield = this.timeline.getItemDataProperty(keyTask, 'expected_yield');


                    let originalTaskItem = this.originalTasksList.find(obj => obj.id ===
                        this.timeline.getItemDataProperty(keyTask, 'id'));

                    if (originalTaskItem && this.isTaskEdited(originalTaskItem, keyTask)) {
                        objToPost.edited_tasks.push(taskToAdd);
                        continue;
                    }

                    if (!originalTaskItem) {
                        objToPost.added_tasks.push(taskToAdd);
                    }
                }
            }

            this.originalTasksList.forEach(task => {
                let deletedTask = true
                for (let keyTask of Object.keys(this.timeline.getItems())) {
                    if (this.timeline.getItemDataProperty(keyTask, 'group') === 'task') {

                        if (task.id === this.timeline.getItemDataProperty(keyTask, 'id')) {
                            deletedTask = false;
                        }
                    }
                }

                if (deletedTask) {
                    objToPost.deleted_tasks.push(task);
                }
            })

            return this.tasksService.saveTasks(this.selectedField.id, objToPost).catch((error) => {
                if (!error.response || error.response.status !== 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppManageTasks.Não foi possível completar a operação, Tente novamente'),
                        life: 5000
                    });
                    return;
                }
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppManageTasks.Você não tem acesso a esta funcionalidade'),
                    life: 5000
                });
            });
        },
        saveItems() {
            const promises = [];

            this.selectedItem = null;
            promises.push(this.saveCrops());
            promises.push(this.saveTasks());

            Promise.all(promises)
                .then((results) => {
                    this.$toast.add({
                        severity: 'success',
                        summary: this.$t('AppManageTasks.Itens salvos com sucesso'),
                        life: 5000
                    });
                })
                .catch(error => {
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppManageTasks.Não foi possível salvar os itens'),
                        life: 5000
                    });
                })
                .finally(() => {
                    this.getCropsAndTasks();
                })
        },
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        async initTimeline() {
            await this.sleep(1);
            this.timeline = this.$refs.timeline;
            this.timeline.redraw();
        },
        handleDrop(item) {
            this.selectedItem = this.timeline.getItem(this.itemDraggedId);
            this.handleChange();
        },
        taskClicked(task) {
            this.timeline.setSelection(task.id);
            this.selectedItem = task;
        },
        handleSelect(event) {
            this.selectedItem = this.timeline.getItem(event.items[0]);
        },
        dateStartSelect(value) {
            this.timeline.setItemDataProperty(this.selectedItem.data.id, 'start', value);
            this.timeline.redraw();
            this.timeline.zoomIn(0.0001);
        },
        dateEndSelect(value) {
            this.timeline.setItemDataProperty(this.selectedItem.data.id, 'end', value);
            this.timeline.redraw()
            this.timeline.zoomIn(0.0001)
        },
        deleteItem() {
            this.timeline.remove(this.selectedItem.data.id)
            this.selectedItem = null;
            this.handleChange();
        },
        handleChange() {

            if (!this.timeline) {
                return;
            }

            this.itemModified = true;

            //Percorre as tasks atualizando
            for (let keyTask of Object.keys(this.timeline.getItems())) {
                let belongingCrop = null;

                if (this.timeline.getItemDataProperty(keyTask, 'group') === 'task') {
                    for (let keyCrop of Object.keys(this.timeline.getItems())) {
                        if (this.timeline.getItemDataProperty(keyCrop, 'group') === 'crop') {
                            if (this.timeline.getItemDataProperty(keyTask, 'start') >= this.timeline.getItemDataProperty(keyCrop, 'start') &&
                                this.timeline.getItemDataProperty(keyTask, 'end') <= this.timeline.getItemDataProperty(keyCrop, 'end')) {
                                belongingCrop = this.timeline.getItem(keyCrop);
                            }
                        }
                    }
                    if (!belongingCrop) {
                        this.timeline.setItemProperty(keyTask, 'crop', null);
                        if (this.timeline.getItemDataProperty(keyTask, 'className') !== 'out-of-range') {
                            this.timeline.setItemDataProperty(keyTask, 'name',
                                this.timeline.getItemDataProperty(keyTask, 'name') + ' - Sem Cultura');
                            this.timeline.setItemDataProperty(keyTask, 'className',
                                'out-of-range');
                            this.timeline.redraw()
                        }
                    } else {
                        this.timeline.setItemProperty(keyTask, 'crop', belongingCrop);
                        if (this.timeline.getItemDataProperty(keyTask, 'className') === 'out-of-range') {
                            this.timeline.setItemDataProperty(keyTask, 'name',
                                this.timeline.getItemDataProperty(keyTask, 'name').split(" - Sem Cultura")[0]);
                            this.timeline.setItemDataProperty(keyTask, 'className', 'task');
                            this.timeline.redraw();
                        }
                    }
                }
            }

            //Percorre os crops atualizando
            for (let keyCrop of Object.keys(this.timeline.getItems())) {

                if (this.timeline.getItemDataProperty(keyCrop, 'group') === 'crop') {
                    let belongingTasks = [];

                    for (let keyTask of Object.keys(this.timeline.getItems())) {
                        if (this.timeline.getItemDataProperty(keyTask, 'group') === 'task') {
                            if (this.timeline.getItemDataProperty(keyCrop, 'start') <= this.timeline.getItemDataProperty(keyTask, 'start') &&
                                this.timeline.getItemDataProperty(keyCrop, 'end') >= this.timeline.getItemDataProperty(keyTask, 'end')) {
                                belongingTasks.push(this.timeline.getItem(keyTask));
                            }
                        }
                    }
                    this.timeline.setItemProperty(keyCrop, 'tasks', belongingTasks);
                }
            }
        },
        handleDragStart(event) {
            event.dataTransfer.effectAllowed = "move";
            let name = event.target.id.split("-")[0].trim();
            let className = event.target.id.split("-")[1].trim();
            this.itemDraggedId = new Date().valueOf();
            let item = {
                id: this.itemDraggedId,
                type: 'range',
                name: name,
                content: this.createTimelineContent(name, className).outerHTML,
                className: className,
                group: className
            };

            if (className === 'task') {
                item['expected_yield'] = 20
            }

            event.dataTransfer.setData("text", JSON.stringify(item));
            this.timeline.setAddingItem();
        },
        handleDragEnd(event) {
            this.timeline.removeAddingItem();
        },
        getOrgs() {
            this.organizationsService.getAllV3("AGRICULTURE")
                .then((response) => {
                    this.setOrgsList(response);
                    this.selectSavedOrg();
                }).catch((error) => {
                console.log(error);
            }).finally(() => {
                this.loadingClients = false;
            })
        },
        setOrgsList(response) {
            this.orgsList = response.sort(function (a, b) {
                return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0;
            });
        },
        selectSavedOrg() {
            //se so tem um já seleciona
            if (this.orgsList.length === 1) {
                this.selectedOrg = this.orgsList[0];
                return;
            }

            let storedChosenOptions = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_AGRO_FILTER_OPTIONS));
            if (storedChosenOptions && storedChosenOptions.selectedOrg && this.orgsList.find(obj => {return obj.id === storedChosenOptions.selectedOrg.id})) {
                this.selectedOrg = storedChosenOptions.selectedOrg;
            }
        },
        getFieldsFromOrg(val) {
            this.loadingFields = true;
            this.fieldsList = [];
            let fieldSelected = false
            this.fieldsService.getFieldsSummaryV3(val.id).then((response) => {
                this.fieldsList = response.fields.sort((a, b) => a.name.localeCompare(b.name));
                this.fieldsList.forEach(field => {
                    field.area = this.calculateArea(field);
                })
                if (!this.changedOrg) {
                    fieldSelected = this.selectSavedField();
                }
            }).catch((error) => {
                if (!error.response || error.response.status !== 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppManageTasks.Não foi possível completar a operação, Tente novamente'),
                        life: 5000
                    });
                    return;
                }
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppManageTasks.Você não tem acesso a esta funcionalidade'),
                    life: 5000
                });
            }).finally(() => {
                this.loadingFields = false;
                if (!fieldSelected) {
                    this.$refs.fieldsDropDown.show();
                }
                this.changedOrg = true;
            })
        },
        calculateArea(field) {
            return (this.google_maps_reference.maps.geometry.spherical.computeArea(
                this.jstsWithoutHolesToGoogleMaps(this.buildPolygonFromCoordinates(field.coordinates),
                    this.google_maps_reference.maps)[0]) / 10000).toFixed(3);
        },
        buildPolygonFromCoordinates(wtkString) {
            let reader = new jsts.io.WKTReader();
            return reader.read(wtkString);
        },
        selectSavedField() {
            //se so tem um já seleciona
            if (this.fieldsList.length === 1) {
                this.selectedField = this.fieldsList[0];
                return true;
            }

            let storedChosenOptions = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_AGRO_FILTER_OPTIONS));
            if (storedChosenOptions && storedChosenOptions.selectedField && this.fieldsList.find(obj => {return obj.id === storedChosenOptions.selectedField.id})) {
                this.selectedField = storedChosenOptions.selectedField;
                return true;
            }

            return false;
        },
        getContentIcon(name, className) {
            if (className === 'crop') {
                for (let i = 0; i < this.cropsListDefault.length; i++) {
                    if (this.cropsListDefault[i].name === name) {
                        return this.cropsListDefault[i].src;
                    }
                }
                return require("../../../../assets/icons/icone-leaf.svg");
            }

            for (let i = 0; i < this.tasksListDefault.length; i++) {
                if (this.tasksListDefault[i].name === name) {
                    return this.tasksListDefault[i].src;
                }
            }
            return require("../../../../assets/icons/icone-task.svg");
        },
        createTimelineContent(name, className) {
            let wrapper = document.createElement("div");
            let content = document.createElement("div");

            content.textContent = name;
            wrapper.appendChild(content);

            let wrapperImg = document.createElement("div");

            let img = document.createElement("IMG");
            img.src = this.getContentIcon(name, className);
            img.width = "30";
            img.color = "white";
            wrapperImg.appendChild(img);

            wrapper.appendChild(wrapperImg);

            return wrapper;
        },
        initCropsFromDatabase(response) {
            let itemsTemp = [];
            this.originalCropsList = response.crops;

            response.crops.forEach(crop => {
                itemsTemp.push({
                    id: crop.id,
                    type: 'range',
                    name: crop.name,
                    content: this.createTimelineContent(crop.name, 'crop'),
                    className: 'crop',
                    group: 'crop',
                    start: new Date(crop.start * 1000),
                    end: new Date(crop.end * 1000)
                })
            })

            this.itemModified = false;
            this.items = itemsTemp;
        },
        initTasksFromDatabase(response) {
            let itemsTemp = [];
            this.originalTasksList = response.tasks;

            response.tasks.forEach(task => {
                itemsTemp.push({
                    id: task.id,
                    type: 'range',
                    name: task.name,
                    content: this.createTimelineContent(task.name, 'task'),
                    className: 'task',
                    group: 'task',
                    expected_yield: task.expected_yield,
                    start: new Date(task.start * 1000),
                    end: new Date(task.end * 1000)
                })
            })
            this.items.push(...itemsTemp);
        },
        initWorkDatesDatabase(response) {
            let itemsTemp = [];
            response.forEach(workDate => {
                itemsTemp.push(
                    {
                        id: this.guidGenerator(),
                        content: "",
                        editable: false,
                        start: new Date((workDate.occurrence_date + this.SECONDS_TO_NOON) * 1000),
                        group: 'hist',
                        className: 'hist',
                        cultural_practice: workDate.cultural_practice,
                        title: `<b>${this.$t('AppManageTasks.Trabalho identificado no dia')} ${this.dateFormat(workDate.occurrence_date + this.SECONDS_TO_NOON)}</b>`
                    }
                )
            })
            this.items.push(...itemsTemp);
        },
        getCropsAndTasks() {
            const promises = [];
            let vm = this;
            this.loadedSuccessfully = false;

            promises.push(this.cropsService.getCrops(this.selectedField.id));
            promises.push(this.tasksService.getTasks(this.selectedField.id));
            promises.push(this.fieldWorksService.getFieldWorksDates(this.selectedField.id));

            Promise.all(promises)
                .then(async (results) => {
                    await vm.initCropsFromDatabase(results[0]);
                    await vm.initTasksFromDatabase(results[1]);
                    await vm.initWorkDatesDatabase(results[2]);

                    vm.initTimeline();
                    this.loadedSuccessfully = true;
                }).catch((error) => {
                    if (!error.response || error.response.status !== 403) {
                        this.$toast.add({
                            severity: 'error',
                            summary: this.$t('AppManageTasks.Não foi possível completar a operação, Tente novamente'),
                            life: 5000
                        });
                        return;
                    }
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppManageTasks.Você não tem acesso a esta funcionalidade'),
                        life: 5000
                    });
                });
        },
        guidGenerator() {
            let S4 = function () {
                return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
            };
            return (S4() + S4() + S4() + S4() + S4() + S4() + S4() + S4());
        },
        saveFilterOptions() {
            let storedChosenOptions = {}
            storedChosenOptions["selectedOrg"] = this.selectedOrg;
            storedChosenOptions["selectedField"] = this.selectedField;
            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_AGRO_FILTER_OPTIONS, JSON.stringify(storedChosenOptions));
        },

    },
    watch: {
        selectedOrg: function (val) {
            this.getFieldsFromOrg(val);
            this.items = [];
            this.selectedItem = null;
            this.selectedField = null;
        },
        selectedField: function (val) {

            if (!val) {
                return;
            }

            this.selectedItem = null;
            this.getCropsAndTasks();
            this.saveFilterOptions();
        },
    },
    computed: {
        contentName: function () {
            if (!this.selectedItem) {
                return "";
            }
            return this.selectedItem.data.name;
        },
        groupName: function () {
            if (!this.selectedItem) {
                return "";
            }
            return this.selectedItem.data.group
        },
        fieldName: function () {
            if (!this.selectedField) {
                return "";
            }
            return this.selectedField.name;
        },
        belongingCrop: {
            cache: false,
            get() {
                if (!this.selectedItem || !this.selectedItem.crop) {
                    return "";
                }

                return this.selectedItem.crop.data.name;
            },
        },
    },
    components: {
        Dropdown, InputNumber, Calendar, AppButton, Button, AppTimeline, AppManageTasksList, Dialog, Slider, RadioButton
    }
}

</script>

<style scoped lang="scss">

.img-scheme:hover {
    cursor: pointer;
}

.manage-crops-title {
    font-size: 22px;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
    color: black;
    font-weight: bold;
    display: flex;
    align-items: center;
    position: relative;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapper {
    position: relative;
}

.menu-buttons {
    position: relative;
    z-index: 200;
    height: 0;
}

.zoom-buttons {
    position: absolute;
    top: 5px;
    right: 5px;
    width: fit-content;
}

.glyphicons.x2::before {
    padding: 0 !important;
}

.arrow-left {
    z-index: 999;
    position: absolute;
    top: 85px;
    left: 0;
    color: gray;
    opacity: 0.8;
    cursor: pointer;
    padding: 0 !important;
}

.arrow-right {
    z-index: 999;
    position: absolute;
    top: 85px;
    right: 0;
    color: gray;
    opacity: 0.8;
    cursor: pointer;
    padding: 0 !important;
}

.arrow:hover {
    opacity: 1.0;
}

.move-left {
    position: absolute;
    top: 100px;
    z-index: 200;
    left: 5px;
}

.move-right {
    position: absolute;
    top: 100px;
    z-index: 200;
    right: 5px;
}

.glyphicons.x2 {
    color: black
}

.glyphicons:before {
    padding: 0;
}

.dropdown-width-field{
    margin-left: 10px;
}

@media(max-width: 519px){
    .dropdown-width-org{
        margin: 10px 10px 10px 10px !important;
    }

    .dropdown-width-field{
        margin: 0px 10px 10px 10px !important;
    }
}

</style>
