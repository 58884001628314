export default {
    methods: {
        /**
         * Creates color range with defined number of steps varying between inital and final hue (HSL)
         * @param steps The number of colors
         * @param INITIAL_HUE The initial range value (120 by default)
         * @param FINAL_HUE The final range value (0 by default)
         * @returns {string[]} the array of colors in HEX format
         */
        buildColorRange(steps, INITIAL_HUE = 140, FINAL_HUE = 0) {
            let hexColor = []
            if (steps === 1) {
                hexColor.push(this.rgbToHex(this.convertHSLtoRGB(INITIAL_HUE, 1, INITIAL_HUE === 140 ? 0.3 : 0.45)))
                return hexColor
            }

            steps--
            let luminosity = this.generateLuminosityWeights(0.3, 0.45, steps + 1)
            for (let i = 0; i <= steps; i++) {
                let hue = ((i / steps) * (FINAL_HUE - INITIAL_HUE)) + INITIAL_HUE
                let rgb = this.convertHSLtoRGB(hue, 1, luminosity[i])
                hexColor.push(this.rgbToHex(rgb))
            }

            return hexColor
        },
        /**
         * Creates array with luminosity weights to apply to the scale, so that the color transition is smoother
         * @param dark the darkest luminosity
         * @param light the lightest luminosity
         * @param steps the number of colors
         * @returns {number[]|*[]} the array of luminosity values
         */
        generateLuminosityWeights(dark, light, steps) {
            let weights = []

            if (steps === 2) {
                return [0.3, 0.3]
            }

            let num = (steps % 2 === 0 ? steps : steps + 1) / 2;
            let step = (light - dark) / (num - 1);
            for (let i = 0; i < num; i++) {
                weights.push(dark + i * step)
            }
            let reversedWeights = JSON.parse(JSON.stringify(weights))
            if (steps % 2 !== 0) {
                weights.pop()
            }
            weights.push(...reversedWeights.reverse())
            return weights
        },
        /**
         * Converts HSL to RGB
         * @param h Hue [0 - 360]
         * @param s Saturation [0 - 1]
         * @param l Lightness [0 - 1]
         * @returns {number[]} the RGB values as an array
         */
        convertHSLtoRGB(h, s, l) {
            const k = n => (n + h / 30) % 12;
            const a = s * Math.min(l, 1 - l);
            const f = n => l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
            return [255 * f(0), 255 * f(8), 255 * f(4)];
        },
        /**
         * Converts RGB array to hex color code
         * @param rgb The array of RGB values
         * @returns {string} the hex color code as a String
         */
        rgbToHex(rgb) {
            return "#" + (1 << 24 | rgb[0] << 16 | rgb[1] << 8 | rgb[2]).toString(16).slice(1);
        },
        /**
         * Converts the hex color code to RGB
         * @param hex The hex color code as a String
         * @param a The opacity
         * @returns {number[]} The RGB array with opacity value
         */
        hexToRgb(hex, a = 100) {
            let h = hex.split('#')[1]
            let bigint = parseInt(h, 16);
            let r = (bigint >> 16) & 255;
            let g = (bigint >> 8) & 255;
            let b = bigint & 255;

            return [r, g, b, (a / 100)];
        },
        /**
         * Get array of colors from object array
         * @param rateSteps The array of objects
         * @returns {string[]} The string array of user set colors in hex format
         */
        getUserColorsSet(rateSteps) {
            let userColors = []
            rateSteps.forEach(step => {
                userColors.push(step.color)
            })
            return userColors
        },
        /**
         * Builds the color bound array of objects with lower and upper bounds
         * and the color associated with the interval in RGB format
         * @param layerColorBounds The array of objects with the bounds
         * @param colors The array of colors
         * @param opacity The opacity of the map
         * @returns {Object[]} The array of objects with lower and upper bounds and the color in Hex format
         */
        buildColorBounds(layerColorBounds, colors, opacity) {
            let bounds = []

            for (const lb in layerColorBounds) {
                let bound = {
                    upper: null,
                    lower: null,
                    color: null
                }

                bound.lower = layerColorBounds[lb].bound.lower
                bound.upper = layerColorBounds[lb].bound.upper
                bound.color = this.hexToRgb(colors[lb], opacity)

                bounds.push(bound)
            }

            return bounds
        }
    }
}
