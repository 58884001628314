import Vue from 'vue'
import Router from 'vue-router'
import Profile from './components/views/auth/profile/AppProfile.vue'
import Login from './components/views/auth/AppLogin.vue'
import ForgotPassword from './components/views/auth/AppForgotPassword.vue'
import AppProfileAccountInformation from './components/views/auth/profile/AppProfileAccountInformation.vue'
import AppProfileChangePassword from './components/views/auth/profile/AppProfileChangePassword.vue'
import AppVehiclesNew from './components/views/vehicles/AppVehicleNew.vue'
import AppNotFound from './components/views/error-pages/AppNotFound404.vue'
import AppVehicleInfo from "@/components/views/vehicles/AppVehicleInfo.vue";
import AppDevices from "./components/views/devices/AppDevices";
import AppUsers from "./components/views/users/AppUsers";
import AppVehicles from "./components/views/vehicles/AppVehicle";
import AppVehiclesTrack from "./components/views/real-time/AppVehiclesTrack";
import AppCosts from "./components/views/costs/AppCosts";
import AppOperators from "./components/views/operators/AppOperators";
import AppOperatorsEdit from "./components/views/operators/AppOperatorsEdit";
import AppAlerts from "./components/views/alerts/machine/AppAlerts";
import AppAlertsServiceOrders from "./components/views/alerts/orders/AppAlertsServiceOrders";
import AppChangePassword from "./components/views/auth/AppChangePassword";
import AppConfirmAccount from "./components/views/auth/AppConfirmAccount";
import AppAnalysis from "./components/views/analysis/AppAnalysis";
import AppSettings from "./components/views/settings/AppSettings";
import AppWebhook from "./components/views/webhook/AppWebhook";
import AppAlertsDictionary from "./components/views/alerts/machine/dictionary/AppAlertsDictionary";
import AppChecklists from "./components/views/checklist/AppChecklists";
import AppChecklistsNew from "./components/views/checklist/AppChecklistsNew";
import AppActivitiesList from "./components/views/configuration-list/AppActivitiesList";
import AppStopReasons from "./components/views/configuration-list/AppStopReasons";
import AppConfigurationList from "./components/views/configuration-list/AppConfigurationList";
import AppConfigurationListForm from "./components/views/configuration-list/AppConfigurationListForm";
import AppAnomalias from "./components/views/anomalias/AppAnomalias";
import AppFields from "./components/views/fields/AppFields";
import AppTasks from "./components/views/agriculture/tasks/AppTasks";
import AppManageTasks from "./components/views/agriculture/manage-tasks/AppManageTasks";
import AppHeader from "./components/layout/AppHeader";
import AppAlertsOperational from "./components/views/alerts/operational/AppAlertsOperational";
import AppLogout from "@/components/views/auth/AppLogout";
import AppOrgInvite from "@/components/views/auth/AppOrgInvite";
import AppOperationsCenterConnection from "@/components/views/settings/AppOperationsCenterConnection";
import AppOperationsCenterConnectionCallback from "@/components/views/settings/AppOperationsCenterConnectionCallback";
import AppWaterWithdrawalDashboard from "@/components/views/water_withdrawal/AppWaterWithdrawalDashboard";
import AppDashboard from "@/components/views/dashboard/AppDashboard";
import AppSignUp from "@/components/views/auth/AppSignUp";
import AppWorkingModes from '@/components/views/working-modes/AppWorkingModes';
import AppAlertsCoa from '@/components/views/alerts-coa/coa/AppAlertsCoa';
import AppAlertsCoaHistorical from '@/components/views/alerts-coa/historical/AppAlertsCoaHistorical'
import AppAlertsCoaSensor from '@/components/views/alerts-coa/sensors/AppAlertsCoaSensor';
import AppAlertsCoaAlertsConfig from '@/components/views/alerts-coa/alerts-config/AppAlertsCoaAlertsConfig';
import AppMachineReport from '@/components/views/machine-report/AppMachineReport';
import eventBus from './eventBus'
import AppOperations from "@/components/views/agricultureV2/operations/AppOperations.vue";

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [

        // @formatter:off
        {path: '*', name: 'not-found', component: AppNotFound},
        {path: '/login/:confirmed?', name: 'login', component: Login},
        {path: '/signup', name: 'signup', component: AppSignUp},
        {path: '/forgot-password', name: 'forgot-password', component: ForgotPassword},
        {path: '/change-password/:token', name: 'change-password', component: AppChangePassword},
        {path: '/confirm-acc/:token', name: 'confirm-acc', component: AppConfirmAccount},
        {path: '/logout', name: 'logout', component: AppLogout},
        {path: '/invite', name: 'invite', component: AppOrgInvite},

        {path: '/', name: 'home', component: AppHeader, meta: {requiresAuth: true}, redirect: {name: 'vehicles-track'},
          children: [

                {path: '/profile', name: 'profile', component: Profile},

                ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                //DASHBOARD CAPTAÇÃO ÁGUA

                {path: '/water-withdrawal/dashboard',name: 'water-withdrawal-dashboard',component: AppWaterWithdrawalDashboard},

                ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                //PROFILE

                {path: '/profile/account-information',name: 'profile-account-information',component: AppProfileAccountInformation},
                {path: '/profile/change-password',name: 'profile-change-password',component: AppProfileChangePassword},


                ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                // USERS
                {path: '/users', name: 'users', component: AppUsers},

                ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                //VEHICLES
                {path: '/vehicles', name: 'vehicles', component: AppVehicles},
                {path: '/vehicles/new', name: 'vehicles-new', component: AppVehiclesNew},
                // {path: '/track', name: 'vehicles-track', component: AppVehiclesTrack, meta: {requiresAuth: true}},
                {path: '/track', name: 'vehicles-track', component: AppVehiclesTrack},
                {path: '/vehicles/:id', name: 'vehicles-edit', component: AppVehicleInfo},

                ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                //DEVICES
                {path: '/devices', name: 'devices', component: AppDevices},

                ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                //COSTS
                {path: '/costs/costs-analysis', name: 'costs-analysis', component: AppCosts},
                {path: '/costs/working-modes', name: 'working-modes', component: AppWorkingModes},


                ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                //Análises
                {path: '/analysis', name: 'analysis', component: AppAnalysis},

                ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                //Operadores
                {path: '/operators', name: 'operators', component: AppOperators},
                {path: '/operators/:id', name: 'operators-edit', component: AppOperatorsEdit},

                ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                // alertas
                // {path: '/alerts', name: 'alerts', component: AppAlerts},
                {path: '/alerts/machine', name: 'alerts-machine', component: AppAlerts},
                {path: '/alerts/operational', name: 'alerts-operational', component: AppAlertsOperational},
                {path: '/alerts/serviceOrders', name: 'alerts-orders', component: AppAlertsServiceOrders},


                ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                // Checklist
                {path: '/settings/checklists', name: 'checklists', component: AppChecklists},
                {path: '/settings/checklists/new:org_id?', name: 'new-checklist', component: AppChecklistsNew},
                {path: '/settings/checklists/new:org_id?:checklist_id?', name: 'new-pre-existent-checklist', component: AppChecklistsNew},

                ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                // Lista de atividades, motivos de paradas e configuração do aplicatvio
                {path: '/settings/activities-list', name: 'activities-list', component: AppActivitiesList},
                {path: '/settings/stop-reasons', name: 'stop-reasons', component: AppStopReasons},
                {path: '/settings/configuration-list', name: 'configuration-list', component: AppConfigurationList},
                {path: '/settings/configuration-list/new:org_id?', name: 'new-configuration-list', component: AppConfigurationListForm},
                {path: '/settings/configuration-list/new:org_id?:list_id?', name: 'edit-configuration-list', component: AppConfigurationListForm},


                ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                // Ajustes
                {path: '/settings', name: 'settings', component: AppSettings},
                {path: '/settings/operationsCenterConnection', name: 'operations-center-connection', component: AppOperationsCenterConnection},
                {path: '/settings/operationsCenterConnection/callback', name: 'operations-center-connection-callback', component: AppOperationsCenterConnectionCallback},

                ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                // Webhook
                {path: '/settings/webhook', name: 'webhook', component: AppWebhook},
                

                ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                //Máquinas Homologadas
                {path: '/settings/machine/report/:type?/:modelId?', name: 'machine-report', component: AppMachineReport},

                ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                // Dicionario alertas
                {path: '/alerts/dictionary', name: 'alerts-dictionary', component: AppAlertsDictionary},

                ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                //Anomalias
                {path: '/anomalias', name: 'anomalias', component: AppAnomalias},

                ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                //Agriculture
                {path: '/agriculture/tasks', name: 'agriculture-tasks', component: AppTasks},
                {path: '/agriculture/manage-tasks', name: 'agriculture-manage-tasks', component: AppManageTasks},

                {path: '/agriculture/operations', name: 'agriculture-operations', component: AppOperations},

                ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                //Fields
                {path: '/fields', name: 'fields', component: AppFields},

                ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                //Dashboard
                {path: '/dashboard', name: 'dashboard', component: AppDashboard},

                ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                //Alerts-Coa
                {path: 'alerts-coa/coa', name: 'alerts-coa', component: AppAlertsCoa},
                {path: 'alerts-coa/sensor', name: 'alerts-sensor', component: AppAlertsCoaSensor},
                {path: 'alerts-coa/historical', name: 'alerts-historical', component: AppAlertsCoaHistorical},
                {path: 'alerts-coa/alerts', name: 'alerts-coa-alerts', component: AppAlertsCoaAlertsConfig},
             ]
        },
    ]
});

router.beforeEach((to, from, next) => {

    // Verifica saida de Análises e se o usuário tem algo não salvo
    if (from.name === "analysis") {
        let values = {
            to: to,
            from: from,
            next: next
        }
        eventBus.$emit('analysis-event', values)
        return;
    }

    //adiciona o background nas views abaixo
    if (to.name === 'login' || to.name === 'signup' || to.name === 'forgot-password' || to.name === 'change-password' ||
        to.name === 'invite') {
        document.body.classList.add("background");
        next();
    } else if (to.name === 'not-found') {
        document.body.classList.add("error");
        next();
    } else {
        document.body.classList.remove("background");
        next();
    }

    // valida o login
    if (to.matched.some(record => record.meta.requiresAuth)) {
        const data = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE));
        if (data != null) {
            if (Math.floor(Date.now() / 1000) < data.expiration) {
                next();
            } else {
                next({name: 'login'});
            }
        } else {
            next({name: 'login'});
        }
    } else {
        next();
    }
});

export {
    router
}
