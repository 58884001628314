<template>
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile ">
            <div v-if="!showDialogFilters" class="kt-portlet__head kt-portlet__head--lg no-border-radius">
                <app-filter-bar @datesAndFiltersHandler="validateDataPreRequest"
                                :filter_button_is_loading="loading"
                                :selected-vehicles-prop="selectedVehicles"
                                :show_historical_data="false"
                                :feature="'ALERTS'"
                                @selectedVehiclesChanged="selectedVehiclesChanged"
                                ref="filterBar">
                </app-filter-bar>
            </div>

            <div v-if="showDialogFilters" class="kt-portlet__head kt-portlet__head--lg no-border-radius" style="justify-content: center;">
                <div style="display: flex;">
                    <app-button v-show="showDialogFilters" type="primary" class="auto-center"
                                @handleClick="openMaximizable"
                                :class="{ 'kt-spinner kt-spinner--v2 kt-spinner--right kt-spinner--sm kt-spinner--dark': loading }">
                                <span>
                                    {{ $t('AppAlertsOperational.Filtrar') }}
                                </span>
                    </app-button>
                </div>
            </div>

            <Dialog :header="$t('AppAlertsOperational.Filtrar')" :visible.sync="displayMaximizable" :containerStyle="{width: '85vw'}" :maximizable="true" :modal="true">
                <app-filter-bar @datesAndFiltersHandler="validateDataPreRequest"
                                :filter_button_is_loading="loading"
                                :selected-vehicles-prop="selectedVehicles"
                                :show_historical_data="false"
                                :feature="'ALERTS'"
                                :is_col_12="true"
                                @selectedVehiclesChanged="selectedVehiclesChanged"
                                ref="filterBar">
                </app-filter-bar>
            </Dialog>

            <app-alerts-dialog-alert-details :displayDialogCodigo="displayDialogCodigo"
                                             @handleClose="handleCloseModalAlerts"
                                             :faultDetails="faultDetails"/>

            <app-alerts-dialog-vehicle-details :displayDialogVehicle="displayDialogVehicle"
                                               @handleClose="handleCloseModalVehicle"
                                               :vehicleDetails="vehicleDetails"/>

            <div class="kt-portlet__body no-padding" id="tableDiv">
                <div class="card data-table-width">
                    <DataTable :value="gridData" :expandedRows.sync="expandedRows" dataKey="id"
                               class="p-datatable-striped"
                               :paginator="true" :rows="50"
                               :loading="loading"
                               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                               :rowsPerPageOptions="[10,25,50]"
                               :currentPageReportTemplate="$t('AppAlertsOperational.currentPageReportTemplate')"
                               :sortOrder="-1" sortField="date"
                               :filters.sync="filters" removableSort
                               filterDisplay="row" responsiveLayout="scroll">

                        <template #empty>
                            <div style="text-align: center">
                                {{ $t('AppAlertsOperational.Nenhum alerta localizado') }}
                            </div>
                        </template>
                        <template #loading>
                            <div style="text-align: center">
                                {{ $t('AppAlertsOperational.Carregando alertas, por favor aguarde') }}
                            </div>
                        </template>
                        <Column field="date" :header="$t('AppAlertsOperational.Horário')" headerClass="headerClass" bodyClass="bodyClass"
                                :showFilterMenu="false" :showClearButton="false"
                                :styles="{'min-width': '115px'}"
                                :sortable="true" filterMatchMode="contains">
                            <template #header>
                            </template>
                            <template #body="slotProps">
                                <span>{{dateHourFormat(slotProps.data.date)}}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column field="fleet" :header="$t('AppAlertsOperational.Identificação')" headerClass="headerClass" bodyClass="bodyClass"
                                :showFilterMenu="false" :showClearButton="false"
                                :styles="{'min-width': '115px'}"
                                :sortable="true" filterMatchMode="contains">
                            <template #body="slotProps">
                                <a href="#"
                                   @click.prevent="showModalVehicleAndGetDetails(slotProps.data.chassis)"
                                   style="text-decoration: underline; position: relative; right: 7px;">
                                    {{slotProps.data.fleet}}</a>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column field="chassis" :header="$t('AppAlertsOperational.Chassis')" headerClass="headerClass" bodyClass="bodyClass"
                                :showFilterMenu="false" :showClearButton="false"
                                :styles="{'min-width': '115px'}"
                                headerStyle="width: 150px; overflow:hidden"
                                :sortable="true" filterMatchMode="contains">
                            <template #body="slotProps">
                                <span>{{slotProps.data.chassis}}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column field="org_name" :header="$t('AppAlertsOperational.Propriedade')" headerClass="headerClass" bodyClass="bodyClass"
                                :showFilterMenu="false" :showClearButton="false"
                                :styles="{'min-width': '115px'}"
                                :sortable="true" filterMatchMode="contains">
                            <template #body="slotProps">
                                <span>{{slotProps.data.org_name}}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column field="user_identification" :header="$t('AppAlertsOperational.Operador')" headerClass="headerClass" bodyClass="bodyClass"
                                :showFilterMenu="false" :showClearButton="false"
                                :styles="{'min-width': '115px'}"
                                :sortable="true" filterMatchMode="contains">
                            <template #body="slotProps">
                                <span>{{slotProps.data.user_identification}}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column field="code" :header="$t('AppAlertsOperational.Código')" headerClass="headerClass" bodyClass="bodyClass"
                                :showFilterMenu="false" :showClearButton="false"
                                :styles="{'min-width': '115px'}"
                                :sortable="true" filterMatchMode="contains">
                            <template #body="slotProps">
                                <a href="#" @click.prevent="showModalAndGetDetails(slotProps.data.code)"
                                   style="text-decoration: underline; position: relative; right: 7px;">
                                    {{slotProps.data.code}}</a>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column field="priority" :header="$t('AppAlertsOperational.Nível')" headerClass="headerClass" bodyClass="bodyClass"
                                style="width: 100%" :showFilterMenu="false" :showClearButton="false"
                                :styles="{'min-width': '115px'}"
                                :sortable="true" filterMatchMode="in">
                            <template #body="{data}">
                                <span :class="'badge priorities-' + data.priority">
                                    {{getPriority(data.priority)}}
                                </span>
                            </template>
                            <template #filter class="headerClass">
                                <MultiSelect v-model="selectedPriority" :options="priorities" optionLabel="name"
                                             style="width: 100%" appendTo="tableDiv"
                                             optionValue="code" :placeholder="$t('AppAlertsOperational.Selecione')" class="p-column-filter">
                                    <template #value="slotProps">
                                        <span v-for="singleValue in  slotProps.value" :class="'badge priorities-' + singleValue">
                                            {{ getPriority(singleValue) }}
                                        </span>
                                    </template>
                                    <template #option="slotProps">
                                        <span :class="'badge priorities-' + slotProps.option.code">
                                            {{getPriority(slotProps.option.code)}}
                                        </span>
                                    </template>
                                </MultiSelect>
                            </template>
                        </Column>

                        <Column field="value" :header="$t('AppAlertsOperational.Valor')" headerClass="headerClass" bodyClass="bodyClass"
                                :showFilterMenu="false" :showClearButton="false"
                                :styles="{'min-width': '115px'}"
                                :sortable="true" filterMatchMode="contains">
                            <template #body="slotProps">
                                <span v-show="slotProps.data.value">{{slotProps.data.value}}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>

                        <Column :headerStyle="{width: '4rem', padding: '0px'}"
                                bodyClass="bodyClass"
                                :bodyStyle="{width: '4rem', padding: '0px'}">
                            <template #body="slotProps">
                                <Button icon="pi pi-map-marker" class="p-button-rounded p-button-success p-mr-2"
                                        style="margin-right: 5px"
                                        @click="openLocation(slotProps.data.geom)" v-if="slotProps.data.geom"/>
                            </template>
                        </Column>

                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import moment from 'moment';
import * as jsts from 'jsts';


import Button from 'primevue/button';
import Column from 'primevue/column';
import Dropdown from 'primevue/dropdown';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import MultiSelect from 'primevue/multiselect';
import ProgressSpinner from 'primevue/progressspinner';

import AppInput from "../../../common/AppInput";
import {PRIORITIES} from '../machine/AppOptions';
import AppButton from "../../../common/AppButton";
import dateHourFormat from "../../../../mixins/DateMixin";
import AppFilterBar from "@/components/common/AppFilterBar";
import AlertsService from "../../../../services/AlertsService";
import AppAlertsDialogAlertDetails from "../AppAlertsDialogAlertDetails";
import AppDateTimeControlBar from "../../../common/AppDateTimeControlBar";
import AppAlertsDialogVehicleDetails from "../AppAlertsDialogVehicleDetails";
import AppVehiclesSelectVehicles from "../../real-time/AppVehiclesSelectVehicles";
import OperationalAlertsService from "../../../../services/OperationalAlertsService";
import OperationalAlertsOccurrencesService from "../../../../services/OperationalAlertsOccurrencesService";
import orderVehiclesByDisplayIdentificationNaturalOrder from '../../../../mixins/ArrayMixin';
import {FilterMatchMode} from 'primevue/api';
import Dialog from 'primevue/dialog';
import {FilterService} from 'primevue/api';
const DATE_FILTER = 'DATE FILTER';

export default {
    mixins: [dateHourFormat, orderVehiclesByDisplayIdentificationNaturalOrder],
    mounted() {
        this.alertsService = new AlertsService();
        this.operationalAlertsOccurrencesService = new OperationalAlertsOccurrencesService();
        this.operationalAlertsService = new OperationalAlertsService();
        this.queryParamVehicleId = this.$route.query.vehicle_id;
        this.queryParamStart = this.$route.query.start;
        this.queryParamEnd = this.$route.query.end;
        FilterService.register(DATE_FILTER, (value, filter) => {
            if (filter === undefined || filter === null || filter.trim() === '') {
                return true;
            }
            if (value === undefined || value === null) {
                return false;
            }
            return this.dateHourFormat(value).includes(filter);
        });

        //Carregar dados salvos em localstorage
        // this.localFilterData = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_OPERATIONAL_ALERTS_FILTER));
        // if(this.localFilterData){
        //     this.loadLocalFilterData();
        // }

        this.showDialogFilters = this.isScreenMobile;
    },
    data() {
        return {
            showDialogFilters: false,
            localFilterData: null,
            priorities: PRIORITIES,
            selectedPriority: null,
            datePickerStartDate: moment().subtract(1, 'day').startOf('day').toDate(),
            datePickerEndDate: moment().toDate(),
            selectedClients: [],
            clientsList: [],
            loadingClients: false,
            displayMaximizable: false,
            selectedVehicles: [],
            vehiclesList: [],
            loadingVehicles: false,
            faultDetails: 'LOADING',
            vehicleDetails: 'LOADING',
            displayDialogCodigo: false,
            displayDialogVehicle: false,
            expandedRows: [],
            alertsService: null,
            operationalAlertsService: null,
            operationalAlertsOccurrencesService: null,
            filters: {
                'date': {value: null, matchMode: DATE_FILTER},
                'fleet': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'chassis': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'org_name': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'user_identification': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'code': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'value': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'priority': {value: null, matchMode: FilterMatchMode.CONTAINS}
            },
            loading: false,
            gridData: [],
            clientVehicles: [],
            cardsData: [],
            queryParamVehicleId: null,
            queryParamStart: null,
            queryParamEnd: null,
            originalResponse: []
        }
    },
    methods: {
        openMaximizable() {
            this.displayMaximizable = true;
        },
        loadLocalFilterData(){
            this.datePickerStartDate = new Date(this.localFilterData.start_date);
            this.datePickerEndDate = new Date(this.localFilterData.end_date);
            let local_date = {
                start_date: new Date(this.localFilterData.start_date),
                end_date: new Date(this.localFilterData.end_date)
            }
            this.selectedClients = this.localFilterData.clients;
            this.selectedVehicles = this.localFilterData.vehicles;

            this.validateDataPreRequest(local_date);
        },
        openLocation(geomString) {
            let reader = new jsts.io.WKTReader();
            let geom = reader.read(geomString);
            window.open( "https://maps.google.com.br?q=" + geom.getCoordinates()[0].y + "," + geom.getCoordinates()[0].x);
        },
        validateDataPreRequest(val) {
            if(this.displayMaximizable === true){
                this.displayMaximizable = false;
            }
            let vehicles = this.getVehiclesRequest();
            this.loading = true;
            this.gridData = [];

            this.operationalAlertsOccurrencesService.findAllByIdAndBetween({
                start: moment(val.start_date).unix(),
                end: moment(val.end_date).unix(),
                vehicles_ids: vehicles
            }).then((response) => {
                if (response && response.length > 0) {
                    this.gridData = this.treatResponse(response);
                    this.gridData.forEach(element => {
                        element.value = `${element.value} ${element.unit}`;
                    });
                    this.originalResponse = this.gridData;
                } else {
                    this.$toast.add({
                        severity: 'info',
                        summary: this.$t('AppAlertsOperational.Nenhum alerta localizado'),
                        life: 5000
                    });
                }
            }).catch((error) => {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppAlertsOperational.Não foi possivel completar a operação, tente novamente'),
                    life: 5000
                });
            }).finally(() => {
                this.loading = false;
                let local_obj = {start_date: val.start_date, end_date: val.end_date, clients:this.selectedClients, vehicles: this.selectedVehicles};
                localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_OPERATIONAL_ALERTS_FILTER, JSON.stringify(local_obj));
            });

        },
        treatResponse(response) {
            return JSON.parse(JSON.stringify(response));
        },
        showModalAndGetDetails(code) {
            this.displayDialogCodigo = true;
            this.getDetailsFault(code);
        },
        handleCloseModalAlerts() {
            this.displayDialogCodigo = false;
        },
        handleCloseModalVehicle() {
            this.displayDialogVehicle = false;
        },
        showModalVehicleAndGetDetails(val) {
            this.displayDialogVehicle = true;
            this.getVehicleDetails(val);
        },
        getFaults() {
            this.validateDataPreRequest({
                start_date: this.datePickerStartDate,
                end_date: this.datePickerEndDate
            });
        },
        getDetailsFault(code) {
            this.faultDetails = "LOADING";
            this.operationalAlertsService.getAlertDescriptionByCode(code)
                .then((response) => {
                    this.faultDetails = response;
                }).catch((error) => {
                this.faultDetails = 'ERROR';
            });

        },
        getVehicleDetails(vid) {
            this.vehicleDetails = "LOADING";
            this.alertsService.getVehicleDetails(vid)
                .then((response) => {
                    this.vehicleDetails = response;
                }).catch((error) => {
                this.vehicleDetails = 'ERROR';
            });

        },
        formatCode(code, severity) {
            if (severity) {
                return code + "." + severity;
            }
            return code;
        },
        updateMachinesFromSelect() {
            let temp = [];
            //se n tem nenhum cliente selecionado mostra todas as maquinas
            if (this.selectedClients.length === 0) {
                this.clientsList.forEach((vehicles) => {
                    vehicles.vehicles.forEach((vehicle) => {
                        temp.push(vehicle);
                    });
                });
            } else {
                //caso contrario mostra so as maquinas dos clientes selecionados
                this.selectedClients.forEach((vehicles) => {
                    vehicles.vehicles.forEach((vehicle) => {
                        temp.push(vehicle);
                    });
                });
            }
            this.vehiclesList = this.orderVehiclesByDisplayIdentificationNaturalOrder(temp);
            this.updateMachinesSelected();
        },
        selectVehicleFromQueryParam() {
            let temp = [];

            this.vehiclesList.forEach((vehicle) => {
                if (this.queryParamVehicleId === vehicle.id) {
                    temp.push(vehicle);
                }
            });

            this.selectedVehicles = temp;
        },
        updateMachinesSelected() {
            let temp = [];

            if (this.selectedClients.length === 0) {
                this.selectedVehicles = [];
                return;
            }

            this.selectedVehicles.forEach((vehicle) => {
                if (this.findById(this.vehiclesList, vehicle.id)) {
                    temp.push(vehicle);
                }
            });

            this.selectedVehicles = temp;
        },
        findById(array, id) {
            for (let i = 0; i < array.length; i++) {
                if (array[i].id === id) {
                    return true;
                }
            }
            return false;
        },
        getVehiclesRequest() {

            let vehicles = [];

            //nenhuma maquina selecionada
            if (this.selectedVehicles.length === 0) {
                //nenhum cliente selecionado pega de todos
                if (this.selectedClients.length === 0) {
                    this.clientsList.forEach((client) => {
                        client.vehicles.forEach((vehicle) => {
                            vehicles.push(vehicle.id);
                        });
                    });
                } else {
                    //caso contrario pega os clientes selecionados
                    this.selectedClients.forEach((client) => {
                        client.vehicles.forEach((vehicle) => {
                            vehicles.push(vehicle.id);
                        });
                    });
                }
            } else {
                //pega so as maquinas selecioandas
                vehicles = this.selectedVehicles.map(function (item) {
                    return item['id'];
                });
            }
            return vehicles;
        },
        getPriority(priority) {
            let result = this.priorities.find(({code}) => code === priority);
            if (result) {
                return result.name;
            }
        },
        roundNumber(val, num) {

            if (!val) {
                return null;
            }

            if (!num) {
                num = 2;
            }
            return parseFloat(val).toFixed(num);
        },
        filterData(){
            let newGridData = [];
            this.originalResponse.forEach((alert) => {
                if (this.selectedPriority != null && this.selectedPriority.length > 0 && !this.selectedPriority.includes(alert.priority)) {
                    return;
                }
                newGridData.push(alert)
            })

            this.gridData = newGridData;
        },
        selectedVehiclesChanged(val) {
            this.selectedVehicles = val;
        }
    },
    watch: {
        selectedClients: function (val) {
            this.updateMachinesFromSelect();
        },
        selectedPriority: function (val) {
            this.filterData();
        },
    },
    computed: {
        isScreenMobile: function() {
            return window.screen.width <= 1024;
        }
    },
    components: {
        AppButton, AppInput, DataTable, Column, InputText, Dropdown, AppAlertsDialogAlertDetails, AppFilterBar,
        AppAlertsDialogVehicleDetails, ProgressSpinner, Button, AppDateTimeControlBar, AppVehiclesSelectVehicles, MultiSelect, Dialog,
    },
}
</script>

<style scoped lang="scss">
@import "src/components/views/alerts/style";

@media (min-width: 1025px){
    .data-table-width{
        max-width: calc(100vw - 140px);
    }
}

@media (max-width: 1024px){
    .data-table-width{
        width: 100vw;
    }
}

</style>
