<template>
    <div class="kt-portlet__body p-0 dashboard" v-if="showDashboard" id="dashboard-start">
        <div v-if="loading" class="center">
            <ProgressSpinner class="center"/>
        </div>
        <div v-else class="m-0 m-md-2">
            <div class="row no-gutters mt-2 status-height">
                <div class="col mr-3 pl-2 status status-border">
                    {{ $t('AppAnomaliasServiceOrdersDashboard.Finalizadas') }}
                    <div class="status-description">
                        {{ $t('AppAnomaliasServiceOrdersDashboard.ordens de serviço') }}
                    </div>
                    <div class="statusCounter" style="background-color: #689f38;">
                        {{ closedStatusCounter }}
                    </div>
                </div>
                <div class="col mr-3 pl-2 status status-border">

                    {{ $t('AppAnomaliasServiceOrdersDashboard.Programadas') }}
                    <div class="status-description">
                        {{ $t('AppAnomaliasServiceOrdersDashboard.ordens de serviço') }}
                    </div>
                    <div class="statusCounter" style="background-color: #2194f3;">
                        {{ scheduledStatusCounter }}
                    </div>
                </div>
                <div class="col pl-2 status status-border">
                    {{ $t('AppAnomaliasServiceOrdersDashboard.Atrasadas') }}
                    <div class="status-description">
                        {{ $t('AppAnomaliasServiceOrdersDashboard.ordens de serviço') }}
                    </div>
                    <div class="statusCounter" style="background-color: #d32f2f;">
                        {{ delayedStatusCounter }}
                    </div>
                </div>
            </div>

            <div class="row no-gutters mt-3">
                <div style="width: 50%; padding-right: 12px; height: 100px;">
                    <div class="col mr-3 pl-2 status" style="min-height: 60px;">
                        <div style="width: 70%;">
                            {{ $t('AppAnomaliasServiceOrdersDashboard.Ordens Finalizadas') }}
                            <div class="status-description">

                                {{ $t('AppAnomaliasServiceOrdersDashboard.do total de ordens') }}
                            </div>
                        </div>
                        <div class="statusCounterSecondRow" style="background-color: #a0a0a0;">
                            {{ percentageClosedOrders }}%
                        </div>
                    </div>
                    <div class="col mr-3 pl-2 status" style="min-height: 60px; margin-top: 12px;">
                        <div style="width: 70%;">
                             {{ $t('AppAnomaliasServiceOrdersDashboard.Quilômetros Viajados') }}
                            <div class="status-description">
                                kms
                            </div>
                        </div>
                        <div class="statusCounterSecondRow" style="background-color: #a0a0a0;">
                            {{ kilometersTraveled }}
                        </div>
                    </div>
                </div>
                <div style="width: 50%;">
                    <div class="col mr-3 pl-2 status" style="min-height: 60px;">
                        <div style="width: 70%;">
                            {{ $t('AppAnomaliasServiceOrdersDashboard.Anomalias/km') }}
                            <div class="status-description">
                                {{ $t('AppAnomaliasServiceOrdersDashboard.anomalias/km') }}
                            </div>
                            <div class="statusCounterSecondRow" style="background-color: #a0a0a0;">
                                {{ anomaliasKm }}
                            </div>
                        </div>
                    </div>
                    <div class="col pl-2 status" style="min-height: 60px; margin-top: 12px;">
                        <div style="width: 70%;">

                            {{ $t('AppAnomaliasServiceOrdersDashboard.Trechos com Defeitos') }}
                            <div class="status-description">

                                {{ $t('AppAnomaliasServiceOrdersDashboard.trechos') }}
                            </div>
                            <div class="statusCounterSecondRow" style="background-color: #a0a0a0;">
                                {{ fieldsWithOccurrences }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="data-table-width" id="tableDiv">
                <DataTable :value="gridData" dataKey="id"
                           class="p-datatable-striped mt-3"
                           :paginator="true" :rows="5"
                           :loading="loading"
                           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                           :rowsPerPageOptions="[5,10,25,50]"
                           :currentPageReportTemplate="$t('AppAnomaliasServiceOrdersDashboard.currentPageReportTemplate')"
                           :sortOrder="-1" sortField="order_id"
                           :filters="filters" filterDisplay="row" responsiveLayout="scroll" removableSort>

                    <template #empty>
                        <div style="text-align: center">
                            {{ $t('AppAnomaliasServiceOrdersDashboard.Nenhuma ordem localizada neste período') }}
                        </div>
                    </template>

                    <Column field="order_id" header="Id" headerClass="headerClass"
                            :sortable="true" bodyClass="bodyClass">
                        <template #body="slotProps">
                            {{ slotProps.data.order_id }}
                        </template>
                    </Column>

                    <Column field="fields_ids_list" :header="$t('AppAnomaliasServiceOrdersDashboard.N° de trechos')" headerClass="headerClass"
                            bodyClass="bodyClass"
                            :sortable="true">
                        <template #body="slotProps">
                            {{ slotProps.data.fields_ids_list.length }}
                        </template>
                    </Column>

                    <Column field="start_date" :header="$t('AppAnomaliasServiceOrdersDashboard.Início')" headerClass="headerClass"
                            bodyClass="bodyClass" :sortable="true">
                        <template #body="slotProps">
                            {{ dateHourFormat(slotProps.data.start_date) }}
                        </template>
                    </Column>

                    <Column field="end_date" :header="$t('AppAnomaliasServiceOrdersDashboard.Fim')" headerClass="headerClass"
                            bodyClass="bodyClass" :sortable="true">
                        <template #body="slotProps">
                            {{ dateHourFormat(slotProps.data.end_date) }}
                        </template>
                    </Column>

                    <Column field="motoniveladoras" :header="$t('AppAnomaliasServiceOrdersDashboard.Motoniveladoras')" headerClass="headerClass"
                            bodyClass="bodyClass" :sortable="true">
                        <template #body="slotProps">
                            {{ getNumberOfMachinesByType(slotProps.data.machinery_list, anomaliasMachinery[0].code) }}
                        </template>
                    </Column>

                    <Column field="pas" :header="$t('AppAnomaliasServiceOrdersDashboard.Pás-Carregadeiras')" headerClass="headerClass"
                            bodyClass="bodyClass" :sortable="true">
                        <template #body="slotProps">
                            {{ getNumberOfMachinesByType(slotProps.data.machinery_list, anomaliasMachinery[1].code) }}
                        </template>
                    </Column>

                    <Column field="basculantes" :header="$t('AppAnomaliasServiceOrdersDashboard.Caminhões Basculantes')" headerClass="headerClass"
                            bodyClass="bodyClass" :sortable="true">
                        <template #body="slotProps">
                            {{ getNumberOfMachinesByType(slotProps.data.machinery_list, anomaliasMachinery[2].code) }}
                        </template>
                    </Column>

                    <Column field="pipa" :header="$t('AppAnomaliasServiceOrdersDashboard.Caminhões Pipa')" headerClass="headerClass"
                            bodyClass="bodyClass" :sortable="true">
                        <template #body="slotProps">
                            {{ getNumberOfMachinesByType(slotProps.data.machinery_list, anomaliasMachinery[3].code) }}
                        </template>
                    </Column>

                    <Column field="escavadeira" :header="$t('AppAnomaliasServiceOrdersDashboard.Escavadeiras')" headerClass="headerClass"
                            bodyClass="bodyClass" :sortable="true">
                        <template #body="slotProps">
                            {{ getNumberOfMachinesByType(slotProps.data.machinery_list, anomaliasMachinery[4].code) }}
                        </template>
                    </Column>

                    <Column field="retro-escavadeira" :header="$t('AppAnomaliasServiceOrdersDashboard.Retro-Escavadeiras')" headerClass="headerClass"
                            bodyClass="bodyClass" :sortable="true">
                        <template #body="slotProps">
                            {{ getNumberOfMachinesByType(slotProps.data.machinery_list, anomaliasMachinery[5].code) }}
                        </template>
                    </Column>

                    <Column field="observation" :header="$t('AppAnomaliasServiceOrdersDashboard.Observação')" headerClass="headerClass" bodyClass="bodyClass">
                        <template #body="slotProps">
                            {{ slotProps.data.observation }}
                        </template>
                    </Column>

                    <Column field="status" header="Status" headerClass="headerClass"
                            bodyClass="bodyClass" :sortable="true"
                            filterMatchMode="in">
                        <template #body="slotProps">
                            <span :class="'badge status-' + slotProps.data.status">
                                {{ getStatus(slotProps.data.status) }}
                            </span>
                        </template>
                    </Column>

                    <Column :headerStyle="{width: '12rem', padding: '0px'}"
                            bodyClass="bodyClass"
                            :bodyStyle="{width: '12rem', padding: '0px'}">
                        <template #header>
                            <MultiSelect v-model="filterStatus" :options="statuses" :placeholder="$t('AppAnomaliasServiceOrdersDashboard.Selecione')"
                                         class="p-column-filter" :showClear="true" optionLabel="name"
                                         optionValue="code" appendTo="tableDiv">
                                <template #option="slotProps">
                                    <span
                                        :class="'badge status-' + slotProps.option.code">{{ slotProps.option.name }}</span>
                                </template>
                                <template #value="slotProps">
                                    <template>
                                        Sel. Status
                                    </template>
                                </template>
                            </MultiSelect>
                        </template>
                        <template #body="slotProps">
                            <Button icon="pi pi-check" class="p-button-rounded p-button-success p-mr-2"
                                    style="margin-right: 5px" v-tooltip.left="$t('AppAnomaliasServiceOrdersDashboard.Finalizar')"
                                    @click="closeOrder(slotProps.data)"
                                    :disabled="slotProps.data.status === 'CLOSED'"/>
                            <Button icon="pi pi-share-alt" class="p-button-rounded p-button-primary p-mr-2"
                                    style="margin-right: 5px" v-tooltip.left="$t('AppAnomaliasServiceOrdersDashboard.Compartilhar')"
                                    @click="shareOrder(slotProps.data)"/>
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning p-mr-2"
                                    style="margin-right: 5px" v-tooltip.left="$t('AppAnomaliasServiceOrdersDashboard.Editar')"
                                    @click="showModalEdit(slotProps.data)"/>
                            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger p-mr-2"
                                    style="margin-right: 5px" v-tooltip.left="$t('AppAnomaliasServiceOrdersDashboard.Deletar')"
                                    @click="deleteOrder(slotProps.data)"/>
                        </template>
                    </Column>
                </DataTable>
            </div>

            <div class="row no-gutters mt-4" style="width: 100%; padding-bottom: 5px">
                <div class="col-md-6 containers-charts" style="padding-right: 5px">
                    <FullCalendar :options="calendarOptions"/>
                    <app-anomalias-machine-hours-per-day
                        :orders_response="ordersResponse"
                        :last_searched_dates="last_searched_dates">
                    </app-anomalias-machine-hours-per-day>
                    <app-anomalias-machine-daily-per-day
                        :orders_response="ordersResponse"
                        :last_searched_dates="last_searched_dates">
                    </app-anomalias-machine-daily-per-day>
                </div>
                <div class="col-md-6 containers-charts" style="padding-right: 5px; margin-top: 6px;">
                    <app-pie-chart :chart_data="pieData" :chart_code="''" :chart_title="$t('AppAnomaliasServiceOrdersDashboard.Ordens por Trecho')"/>
                    <app-pie-chart :chart_data="pieDataMachineryHour" :chart_code="''"
                                   :chart_title="$t('AppAnomaliasServiceOrdersDashboard.Horas por Maquinário')"
                                   class="mt-2"/>
                    <app-pie-chart :chart_data="pieDataMachineryDay" :chart_code="''"
                                   :chart_title="$t('AppAnomaliasServiceOrdersDashboard.Diárias por Maquinário')"
                                   class="mt-2"/>
                </div>
            </div>
        </div>
        <app-anomalias-create-order-dialog :display_dialog="displayCreateOrderDialog" :selected_fields="selectedFields"
                                           :polygons_google_maps="polygons_google_maps" :order_data="orderData"
                                           :create_order="false"
                                           :organization_id="this.organization.id"
                                           :fix_point_markers="fixPointMarkers"
                                           @handleClose="handleCloseDialog"/>

        <Dialog :visible.sync="deleteOrderDialog" :style="{width: '425px'}" :header="$t('AppAnomaliasServiceOrdersDashboard.Confirmar')" :modal="true">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 15px; margin-right: 10px"/>
                <span
                    v-if="orderToConfirm">{{ $t('AppAnomaliasServiceOrdersDashboard.Tem certeza que deseja deletar ordem') }} <b>{{ orderToConfirm.order_id }}</b>?</span>
            </div>
            <template #footer>
                <Button :label="$t('AppAnomaliasServiceOrdersDashboard.Não')" icon="pi pi-times" class="p-button-text" @click="deleteOrderDialog = false"/>
                <Button :label="$t('AppAnomaliasServiceOrdersDashboard.Sim')" icon="pi pi-check" class="p-button-text" @click="askToDeleteOrder"/>
            </template>
        </Dialog>

        <Dialog :visible.sync="closeOrderDialog" :style="{width: '425px'}" :header="$t('AppAnomaliasServiceOrdersDashboard.Confirmar')" :modal="true">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 15px; margin-right: 10px"/>
                <span v-if="orderToConfirm">{{ $t('AppAnomaliasServiceOrdersDashboard.Tem certeza que deseja finalizar ordem') }} <b>{{ orderToConfirm.order_id }}</b>?</span>
            </div>
            <div class="center mt-2">
                {{ $t('AppAnomaliasServiceOrdersDashboard.Dia') }}
            </div>
            <div class="center">
                <Calendar v-model="dateModelDay" :inline="true" :showWeek="true"/>
            </div>
            <div class="center mt-4">
                {{ $t('AppAnomaliasServiceOrdersDashboard.Horário') }}
            </div>
            <div class="center">
                <Calendar class="time-calendar" v-model="dateModelTime" :timeOnly="true" hourFormat="24"
                          :inline="true"/>
            </div>
            <template #footer>
                <Button :label="$t('AppAnomaliasServiceOrdersDashboard.Não')" icon="pi pi-times" class="p-button-text" @click="closeOrderDialog = false"/>
                <Button :label="$t('AppAnomaliasServiceOrdersDashboard.Sim')" icon="pi pi-check" class="p-button-text" @click="askToCloseOrder"/>
            </template>
        </Dialog>

        <div style="position: fixed; z-index: 5; bottom: 14px; right: 14px">
            <div>
                <app-button class="m-0 auto-center" type="primary" @handleClick="startStopScroll">
                    <i :class="fullScreenButtonClass"></i>
                </app-button>
            </div>
        </div>
    </div>
</template>

<script>

import AnomaliasOrderService from '../../../services/AnomaliasOrderService'
import ProgressSpinner from 'primevue/progressspinner';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import dateHourFormat from '../../../mixins/DateMixin'
import sendWhatsAnomaliasOrderDetails from '../../../mixins/WhatsAppMixin'
import AppAnomaliasCreateOrderDialog from "./AppAnomaliasCreateOrderDialog";
import AppPieChart from "../analysis/AppPieChart";
import Dialog from 'primevue/dialog';
import MultiSelect from 'primevue/multiselect';
import AppButton from "../../common/AppButton";
import AppAnomaliasMachineHoursPerDay from "./AppAnomaliasMachineHoursPerDay";
import AppAnomaliasMachineDailyPerDay from "./AppAnomaliasMachineDailyPerDay";

import '@fullcalendar/core/vdom'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import brLocale from "@fullcalendar/core/locales/pt-br";
import moment from 'moment';
import Tooltip from "primevue/tooltip";
import {ANOMALIAS_MACHINERY} from "@/components/views/anomalias/AppAnomaliasOptions";

export default {
    mixins: [dateHourFormat, sendWhatsAnomaliasOrderDetails],
    mounted() {
        this.anomaliasOrderService = new AnomaliasOrderService();
        this.getOrders();
        this.computeDashboardData();
    },
    data() {
        return {
            scheduledStatusCounter: 0,
            delayedStatusCounter: 0,
            closedStatusCounter: 0,

            anomaliasOrderService: null,
            loading: false,
            showDashboard: true,
            gridData: [],

            statuses: [
                {name: this.$t('AppAnomaliasServiceOrdersDashboard.PROGRAMADO'), code: 'SCHEDULED'},
                {name: this.$t('AppAnomaliasServiceOrdersDashboard.ATRASADO'), code: 'DELAYED'},
                {name: this.$t('AppAnomaliasServiceOrdersDashboard.FINALIZADO'), code: 'CLOSED'},
            ],
            displayCreateOrderDialog: false,
            selectedFields: [],
            orderData: {},
            calendarOptions: {
                plugins: [dayGridPlugin],
                initialView: 'dayGridMonth',
                locale: brLocale,
                headerToolbar: {
                    start: 'title',
                    center: '',
                    end: 'prev,next'
                },
                events: [],
                eventDidMount: function (info) {
                    info.el.style.backgroundColor = info.borderColor
                    info.el.style.borderColor = info.borderColor
                },
            },
            pieData: [],
            columnData: [],
            pieDataMachineryHour: [],
            pieDataMachineryDay: [],
            orderToConfirm: null,
            deleteOrderDialog: false,
            closeOrderDialog: false,

            filters: {},
            isAutoScroll: false,

            anomaliasKm: 0,
            kilometersTraveled: 0,
            fieldsWithOccurrences: 0,
            dateModelDay: new Date(),
            dateModelTime: new Date(),
            fixPointMarkers: [],
            machineryDailyChartData: [],
            ordersResponse: [],
            anomaliasMachinery: ANOMALIAS_MACHINERY(),
            filterStatus: [],
            originalResponse: []
        }
    },
    props: {
        organization: {
            type: Object,
            required: true
        },
        polygons_google_maps: {
            type: Array,
            required: true
        },
        fields_data: {
            type: Array,
            required: true
        },
        last_searched_dates: {
            type: Object,
            required: true
        },
    },
    methods: {
        getNumberOfMachinesByType(machineryArray, type) {
            let numberOfMachinesByType = 0;
            machineryArray.forEach(machine => {
                if (machine.type === type) {
                    numberOfMachinesByType++;
                }
            })

            return numberOfMachinesByType;
        },
        scrollToSmoothly(scrollBottom) {
            let vm = this;
            let elementToScroll;
            let pos = 0;
            let time = 15000;
            if (scrollBottom) {
                elementToScroll = document.querySelector("#dashboard-start");
                pos = +elementToScroll.scrollHeight;
                time = 25000;
            }

            let currentPos = window.pageYOffset;
            let start = null;

            window.requestAnimationFrame(function step(currentTime) {
                if (!vm.isAutoScroll) {
                    return;
                }

                start = !start ? currentTime : start;
                let progress = currentTime - start;
                if (currentPos < pos) {
                    window.scrollTo(0, ((pos - currentPos) * progress / time) + currentPos);
                } else {
                    window.scrollTo(0, currentPos - ((currentPos - pos) * progress / time));
                }
                if (progress < time) {
                    window.requestAnimationFrame(step);
                } else {
                    window.scrollTo(0, pos);
                    scrollBottom = !scrollBottom;
                    setTimeout(function () {
                        vm.scrollToSmoothly(scrollBottom);
                    }, 10000);
                }
            });
        },
        startStopScroll() {
            this.isAutoScroll = !this.isAutoScroll;

            if (!this.isAutoScroll) {
                return;
            }

            this.scrollToSmoothly(true);
        },
        computeDashboardData() {
            let totalKm = 0;
            let totalOccurrences = 0;
            let totalFieldsWithOccurrences = 0;
            this.fields_data.forEach(field => {
                if (field.kilometersTraveled === 0) {
                    return;
                }
                totalKm += parseFloat(field.kilometersTraveled);
                totalOccurrences += parseFloat(field.occurrences);
                if (parseFloat(field.occurrences) > 0) {
                    totalFieldsWithOccurrences++;
                }
            })

            if (totalKm === 0) {
                this.anomaliasKm = 0.0;
            } else {
                this.anomaliasKm = (totalOccurrences / totalKm).toFixed(2);
            }

            this.kilometersTraveled = totalKm.toFixed(2);
            this.fieldsWithOccurrences = totalFieldsWithOccurrences;
        },
        async askToDeleteOrder() {
            this.deleteOrderDialog = false;
            this.loading = true;
            await this.anomaliasOrderService.deleteOrder(this.orderToConfirm.order_id)
                .then((response) => {
                    this.$toast.add({severity: 'success', summary: this.$t('AppAnomaliasServiceOrdersDashboard.Ordem deletada com sucesso'), life: 3000});
                }).catch((error) => {
                    console.log(error.response)
                    if (error.response && error.response.status === 403) {
                        this.$toast.add({severity: 'error', summary: error.response.data.details, life: 3000});
                    } else {
                        this.$toast.add({severity: 'error', summary: this.$t('AppAnomaliasServiceOrdersDashboard.Não foi possível deletar ordem'), life: 3000});
                    }
                })
            this.getOrders();
        },
        async askToCloseOrder() {
            this.closeOrderDialog = false;
            this.orderToConfirm.status = 2;
            this.orderToConfirm.end_date = this.selectedEndDate.valueOf() / 1000;
            this.loading = true;
            this.orderToConfirm.organization_id = this.organization.id
            await this.anomaliasOrderService.closeOrder(this.orderToConfirm.order_id, this.orderToConfirm)
                .then((response) => {
                    this.$toast.add({severity: 'success', summary: this.$t('AppAnomaliasServiceOrdersDashboard.Ordem finalizada com sucesso'), life: 3000});
                }).catch((error) => {
                    if (error.response && error.response.status === 403) {
                        this.$toast.add({severity: 'error', summary: error.response.data.details, life: 3000});
                    } else {
                        this.$toast.add({severity: 'error', summary: this.$t('AppAnomaliasServiceOrdersDashboard.Não foi possível atualizar a ordem'), life: 3000});
                    }
                })
            this.getOrders();
        },
        shareOrder(data) {
            this.sendWhatsAnomaliasOrderDetails(data, this.getOrderFieldsNamesAndCoordinates(data));
        },
        getOrderFieldsNamesAndCoordinates(data) {
            let fieldsNames = '';
            let fieldsCenterCoordinates = [];
            data.fields_ids_list.forEach(fieldId => {
                let fieldObject = this.polygons_google_maps.find(item => item.id === fieldId);
                if (!fieldObject) {
                    return;
                }
                let coordinateToAdd = this.getCoordinatesCenter(fieldObject.geom);
                if (coordinateToAdd) {
                    fieldsCenterCoordinates.push(coordinateToAdd);
                }
                fieldsNames += fieldObject.name + '%0a';
            })

            data.points_list.forEach(point => {
                fieldsCenterCoordinates.push({lat: point.latitude, lng: point.longitude})
            })

            return {
                fieldsNames: fieldsNames,
                fieldsCenterCoordinates: fieldsCenterCoordinates
            };
        },
        getCoordinatesCenter(geom) {
            if (!geom) {
                return null;
            }

            let northernmostCoordinates = -90;
            let southernmostCoordinates = 90;
            let easternmostCoordinates = -180;
            let westernmostCoordinates = 180;

            geom.getCoordinates().forEach(coordinate => {
                if (coordinate.y > northernmostCoordinates) {
                    northernmostCoordinates = coordinate.y;
                }
                if (coordinate.y < southernmostCoordinates) {
                    southernmostCoordinates = coordinate.y;
                }
                if (coordinate.x > easternmostCoordinates) {
                    easternmostCoordinates = coordinate.x;
                }
                if (coordinate.x < westernmostCoordinates) {
                    westernmostCoordinates = coordinate.x;
                }
            })

            let center = {
                lat: (northernmostCoordinates + southernmostCoordinates) / 2,
                lng: (easternmostCoordinates + westernmostCoordinates) / 2
            };

            let closestPointToCenter = geom.getCoordinates()[0];
            let minimumDistanceToCenter = Math.abs(center.lat - closestPointToCenter.y) + Math.abs(center.lng - closestPointToCenter.x);

            geom.getCoordinates().forEach(coordinate => {
                if (Math.abs(center.lat - coordinate.y) + Math.abs(center.lng - coordinate.x) < minimumDistanceToCenter) {
                    closestPointToCenter = {lat: coordinate.y, lng: coordinate.x}
                    minimumDistanceToCenter = Math.abs(center.lat - coordinate.y) + Math.abs(center.lng - coordinate.x);
                }
            })
            const MIN_NUMBER_OF_POINTS_TO_CREATE_CURVE = 6;


            return geom.getCoordinates().length >= MIN_NUMBER_OF_POINTS_TO_CREATE_CURVE ? closestPointToCenter : center;
        },
        closeOrder(data) {
            this.orderToConfirm = data;
            this.closeOrderDialog = true;
        },
        showModalEdit(data) {
            this.selectedFields = [];
            this.fixPointMarkers = []
            this.orderData = data;
            data.fields_ids_list.forEach(fieldId => {
                this.selectedFields.push({
                    id: fieldId
                })
            })
            data.points_list.forEach(point => {
                this.fixPointMarkers.push({
                    lat: point.latitude,
                    lng: point.longitude,
                })
            })
            this.displayCreateOrderDialog = true;
        },
        deleteOrder(data) {
            this.orderToConfirm = data;
            this.deleteOrderDialog = true;
        },
        getOrders() {
            this.loading = true;
            this.gridData = [];
            this.ordersResponse = [];
            this.anomaliasOrderService.getOrdersByOrganization(this.organization.id,
                this.last_searched_dates.start_date.valueOf(), this.last_searched_dates.end_date.valueOf())
                .then((response) => {
                    try {
                        this.processGridData(response)
                        this.populatePieChartData();
                        this.populateMachinesHourChartData(response);
                        this.populateMachinesDayChartData(response);
                        this.updateOrdersResponseWithDelay(response);
                    } catch (error) {
                        console.log(error);
                    }

                }).catch((error) => {
                this.$toast.add({severity: 'error', summary: this.$t('AppAnomaliasServiceOrdersDashboard.Não foi possível carregar ordens'), life: 3000});
                this.showDashboard = false;
            }).finally(() => {
                this.loading = false;
            })
        },
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        async updateOrdersResponseWithDelay(response) {
            await this.sleep(1);
            this.ordersResponse = response;
        },
        processGridData(val) {
            let tempGridData = [];

            this.clearData();

            this.calendarOptions.events = []

            val.forEach(order => {
                let orderDate = new Date(0);
                orderDate.setUTCSeconds(order.start_date);

                if (order.status === 'SCHEDULED') {
                    if (moment(orderDate).isBefore(moment().startOf('day'))) {
                        order.status = 'DELAYED';
                    }
                }

                this.calendarOptions.events.push({
                    title: `${this.$t('AppAnomaliasServiceOrdersDashboard.Ordem')} ${order.order_id}`,
                    date: orderDate,
                    color: this.getEventColor(order.status),
                });

                this.setStatusCounter(order.status, 1);

                tempGridData.push(order);
            })
            this.gridData = tempGridData;
            this.originalResponse = this.gridData

        },
        getEventColor(status) {
            switch (status) {
                case 'SCHEDULED':
                    return "#2194f3"
                case 'DELAYED':
                    return "#d32f2f"
                case 'CLOSED':
                    return "#689f38"
            }
            return "#2194f3"
        },
        populatePieChartData() {
            let fields = [];
            let total = 0;

            this.gridData.forEach(order => {
                order.fields_ids_list.forEach(fieldId => {
                    total++;
                    let index = fields.findIndex(i => i.id === fieldId);
                    if (index >= 0) {
                        fields[index].count = fields[index].count + 1;
                        return;
                    }

                    let polygon = this.polygons_google_maps.find(item => item.id === fieldId);
                    if (!polygon) {
                        return;
                    }

                    fields.push({
                        name: polygon.name,
                        count: 1,
                        id: fieldId
                    })
                })
            })

            let tempPieData = [];
            fields.forEach(field => {
                tempPieData.push({
                    name: field.name,
                    y: this.formatValue(field.count / total),
                    rawValue: field.count,
                    rawSymbol: field.count === 1 ? this.$t('AppAnomaliasServiceOrdersDashboard.ordem') : this.$t('AppAnomaliasServiceOrdersDashboard.ordens')                });
            })
            this.pieData = tempPieData;
        },
        buildDefaultHourSeriesDataList() {
            let seriesDataList = [];

            seriesDataList.push({
                name: this.$t('AppAnomaliasServiceOrdersDashboard.Motoniveladoras'),
                hours: 0,
                code: this.anomaliasMachinery[0].code
            })

            seriesDataList.push({
                name: this.$t('AppAnomaliasServiceOrdersDashboard.Retro-Escavadeiras'),
                hours: 0,
                code: this.anomaliasMachinery[5].code
            })

            return seriesDataList;

        },
        buildDefaultDaySeriesDataList() {
            let seriesDataList = [];

            seriesDataList.push({
                name: this.$t('AppAnomaliasServiceOrdersDashboard.Pás-Carregadeiras'),
                days: 0,
                code: this.anomaliasMachinery[1].code
            })

            seriesDataList.push({
                name: this.$t('AppAnomaliasServiceOrdersDashboard.Caminhões Basculantes'),
                days: 0,
                code: this.anomaliasMachinery[2].code
            })

            seriesDataList.push({
                name: this.$t('AppAnomaliasServiceOrdersDashboard.Caminhões Pipa'),
                days: 0,
                code: this.anomaliasMachinery[3].code
            })

            seriesDataList.push({
                name: this.$t('AppAnomaliasServiceOrdersDashboard.Escavadeiras'),
                days: 0,
                code: this.anomaliasMachinery[4].code
            })

            return seriesDataList
        },
        populateMachinesHourChartData(response) {
            let seriesDataList = this.buildDefaultHourSeriesDataList();
            let total = 0;
            response.forEach(order => {
                order.machinery_list.forEach(machine => {
                    if (machine.final_hour_meter - machine.initial_hour_meter < 0) {
                        return;
                    }

                    let seriesDataIndex = seriesDataList.findIndex(obj => obj.code === machine.type);

                    if (seriesDataIndex < 0) {
                        return;
                    }

                    total += machine.final_hour_meter - machine.initial_hour_meter;
                    seriesDataList[seriesDataIndex].hours += machine.final_hour_meter - machine.initial_hour_meter;
                })
            })

            let tempPieData = [];
            seriesDataList.forEach(data => {
                tempPieData.push({
                    name: data.name,
                    y: this.formatValue(data.hours / total),
                    rawValue: data.hours.toFixed(2),
                    rawSymbol: this.$t('AppAnomaliasServiceOrdersDashboard.horas')
                });
            })
            this.pieDataMachineryHour = tempPieData;
        },
        populateMachinesDayChartData(response) {
            let seriesDataList = this.buildDefaultDaySeriesDataList();
            let total = 0;
            response.forEach(order => {
                order.machinery_list.forEach(machine => {
                    let seriesDataIndex = seriesDataList.findIndex(obj => obj.code === machine.type);
                    if (seriesDataIndex < 0) {
                        return;
                    }

                    total += machine.final_hour_meter;

                    seriesDataList[seriesDataIndex].days += machine.final_hour_meter;
                })
            })

            let tempPieData = [];
            seriesDataList.forEach(data => {
                tempPieData.push({
                    name: data.name,
                    y: this.formatValue(data.days / total),
                    rawValue: parseInt(data.days),
                    rawSymbol: this.$t('AppAnomaliasServiceOrdersDashboard.dias')
                });
            })
            this.pieDataMachineryDay = tempPieData;
        },
        formatValue(value) {
            return parseFloat((value * 100).toFixed(2))
        },
        setStatusCounter(status, count) {
            switch (status) {
                case 'SCHEDULED':
                    this.scheduledStatusCounter += count;
                    break;
                case 'DELAYED':
                    this.delayedStatusCounter += count;
                    break;
                case 'CLOSED':
                    this.closedStatusCounter += count;
                    break;
            }
        },
        clearData() {
            this.scheduledStatusCounter = 0;
            this.delayedStatusCounter = 0;
            this.closedStatusCounter = 0;
        },
        getStatus(status) {
            let result = this.statuses.find(({code}) => code === status);
            if (result) {
                return result.name;
            }
        },
        handleCloseDialog() {
            this.displayCreateOrderDialog = false;
            this.getOrders();
        },
        filterData() {
            let newGridData = [];
            this.originalResponse.forEach((alert) => {
                if (this.filterStatus != null && this.filterStatus.length > 0 && !this.filterStatus.includes(alert.status)) {
                    return;
                }

                newGridData.push(alert)
            })

            this.gridData = newGridData;
        }
    },
    components: {
        ProgressSpinner, DataTable, Column, Button, AppAnomaliasCreateOrderDialog, FullCalendar, AppPieChart, Dialog,
        MultiSelect, AppButton, Calendar, AppAnomaliasMachineHoursPerDay, AppAnomaliasMachineDailyPerDay
    },
    computed: {
        fullScreenButtonClass: function () {
            return this.isAutoScroll ? 'pi pi-times pr-0' : 'pi pi-eye pr-0'
        },
        percentageClosedOrders: function () {
            if (this.delayedStatusCounter === 0 && this.scheduledStatusCounter === 0 && this.closedStatusCounter === 0) {
                return 0.0;
            }

            return (this.closedStatusCounter /
                (this.delayedStatusCounter + this.scheduledStatusCounter + this.closedStatusCounter) * 100).toFixed(2);
        },
        selectedEndDate: function () {
            let newDate = this.dateModelDay;
            newDate.setHours(this.dateModelTime.getHours());
            newDate.setMinutes(this.dateModelTime.getMinutes());
            return newDate;
        },
    },
    directives: {
        tooltip: Tooltip
    },
    watch: {
        filterStatus: function (val) {
            this.filterData();
        },
    }

}
</script>

<style scoped lang="scss">
@import "./src/assets/styles/primevue";
@import "./ordersStyle";


.status {
    background-color: white;
    display: flex;
    align-items: center;
    font-weight: bold;
    color: black;
    font-size: 18px;
}

.status-description {
    position: absolute;
    bottom: 1px;
    left: 10px;
    font-size: 10px;
    color: #8f8f8f;
}

.statusCounter {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: white;
    font-size: 18px;
    position: absolute;
    top: 10px;
    right: 10px;
    min-height: 40px;
    min-width: 40px;
    border-radius: 20px;
}

.statusCounterSecondRow {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: white;
    font-size: 18px;
    position: absolute;
    top: 10px;
    right: 10px;
    min-height: 40px;
    min-width: 40px;
    border-radius: 20px;
    padding-left: 5px;
    padding-right: 5px;
}

.priority {
    font-weight: bold;
    color: #8f8f8f;
    font-size: 28px;
    padding: 0;
    display: flex;
    align-items: center;
    font-weight: bold;
    justify-content: center;
    width: 50%;
}

.priority-counter {
    background-color: white;
    font-weight: bold;
    color: black;
    font-size: 34px;
    padding: 0;
    display: flex;
    align-items: center;
    font-weight: bold;
    justify-content: center;
    width: 50%;

}

.priority-description {
    position: absolute;
    bottom: 1px;
    right: 5px;
    font-size: 10px;
    color: #8f8f8f;
}

.containers-charts {
    position: relative;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.containers-charts {
    position: relative;
}

.dashboard {
    background-color: #f4f4f4;
}

.status-height {
    min-height: 60px;
}

@media (max-width: 767px) {
    .status {
        font-size: 14px;
        align-items: start;
        padding-top: 13px;
    }

    .status-description {
        bottom: 12px;
        left: 6px;
    }

    .statusCounter {
        min-width: 25px;
        min-height: 25px;
        margin-top: 6px;
    }

    .statusCounterSecondRow {
        font-size: 13px;
    }
}

@media(max-width: 440px) {
    .status {
        font-size: 13px;
    }
}

@media (max-width: 1024px) {
    .data-table-width {
        width: 100vw;
    }
}
</style>
