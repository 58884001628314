<template>
    <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="!show_canva"
        :paginate-elements-by-height="1100" :filename="$t('AppSettings.Máquinas Homologadas')" :pdf-quality="2"
        :manual-pagination="true" pdf-format="a4" pdf-orientation="portrait" pdf-content-width="800px" ref="html2Pdf">
        <template #pdf-content>
            <section class="text-color">
                <div class="brand-background">
                    <img alt="Logo" style="height: 80px; width: 150px" :src="headerImage" />
                </div>
                <div class="title" style="text-align: center;">{{ $t('AppSettings.Máquinas Homologadas') }}</div>
                <hr class="hr-title">
                <div class="date-time center">{{ currentDateTime }}</div>
                <div v-for="(item, index) in resultToExport" :key="item.operation" class="content">
                    <div class="title-operation">{{ item.operation }}</div>
                    <hr class="hr-title-operation">
                    <div v-for="[manufacturer, models] in Object.entries(item.machines)" :key="manufacturer"
                        class="content-title-manufacturer">
                        <div class="title-manufacturer">{{ manufacturer }}</div>
                        <span v-for="(model, index) in models" :key="model">
                            {{ model }}<span v-if="index !== models.length - 1">, </span>
                        </span>
                    </div>
                    <hr v-if="index !== resultToExport.length - 1 && index != 1" class="hr-divisor">
                    <div v-if="index == 1" class="html2pdf__page-break" />
                </div>
            </section>
        </template>
    </vue-html2pdf>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'

export default {
    data() {
        return {
            headerImage: require("../../../assets/images/brands/" + process.env.VUE_APP_THEME_IMAGE_HEADER + ".png"),
            resultToExport: [],
            currentDateTime: new Date().toLocaleString()
        }
    },
    methods: {
        generateReport(resultToExport) {
            this.resultToExport = resultToExport;
            this.$refs.html2Pdf.generatePdf();
        }
    },
    computed: {
        show_canva() {
            return window.screen.width <= 1024;
        }
    },
    components: {
        VueHtml2pdf
    }
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/constants";

.text-color {
    color: #666666;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.brand-background {
    display: flex;
    justify-content: center;
    align-self: center;
    width: 100%;
    background-color: $aside-menu-item-active;
}

.title {
    font-size: 23px;
    margin: 25px 0px 10px 0px;
}

.date-time{
    font-size: 14px;
    position: relative;
    bottom: 5px;
}

.content {
    padding: 0px 50px;
}

.title-operation {
    color: $aside-menu-item-active;
    font-size: 23px;
    margin: 15px 0px 0px 0px;
}

.title-manufacturer {
    font-size: 20px;
    margin-bottom: 3px;
}

.content-title-manufacturer {
    margin-bottom: 15px;
}

.hr-divisor {
    border: 0;
    height: 2px;
    background-image: linear-gradient(to right, #FFF, $aside-menu-item-active, #FFF);
    margin-top: 25px;
}

.hr-title {
    border: 0;
    height: 2px;
    width: 270px;
    position: relative;
    bottom: 3px;
    border-top: $aside-menu-item-active solid 2px;
}

.hr-title-operation {
    border: 0;
    border-top: $aside-menu-item-active solid 2px;
    width: 60px;
    text-align: left;
    margin-left: 0;
    position: relative;
    bottom: 3px;
}
</style>