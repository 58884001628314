<template>
    <Dialog :visible="showDialog" @update:visible="closeDialog" :modal="true" :header="$t('AppChecklistsAnswersDialog.Respostas de Checklists')">
        <div>
            <div v-if="!showSpecificAnswer" style="width: 50vw">

                <app-checklists-answers-list :answers-list="answersList" :answers-list-loading="answersListLoading"
                                             @handleSelectedAnswer="handleSelectedAnswer"/>
            </div>
            <div v-else style="width: 30vw">
                <app-checklists-answers-read :selected-answer-from-list="selectedAnswerFromList"
                                             :selected-answer-response="selectedAnswerResponse"/>
            </div>
        </div>

        <template #footer>
            <div v-if="!showSpecificAnswer">
                <Button :label="$t('AppChecklistsAnswersDialog.Cancelar')" icon="pi pi-times" class="p-button-text"
                        @click="closeDialog"/>
                <Button :label="$t('AppChecklistsAnswersDialog.Continuar')" icon="pi pi-check" :disabled="selectedAnswerFromList === null"
                        @click="handleContinueButton"/>
            </div>
            <div v-else>
                <Button :label="$t('AppChecklistsAnswersDialog.Voltar')" icon="pi pi-arrow-left" class="p-button-text"
                        @click="handleBackButton"/>
            </div>
        </template>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import Button from "primevue/button";
import DateMixin from "@/mixins/DateMixin";
import ChecklistService from "@/services/ChecklistService";
import AppChecklistsAnswersList from "@/components/views/checklist/AppChecklistsAnswersList.vue";
import AppChecklistsAnswersRead from "@/components/views/checklist/AppChecklistsAnswersRead.vue";

export default {
    data() {
        return {
            checklistService: new ChecklistService(),
            selectedAnswerFromList: null,
            showSpecificAnswer: false,
            answersList: [],
            answersListLoading: false,
            selectedAnswerResponse: null,
            selectedAnswerResponseLoading: false,
        }
    },
    props: {
        showDialog: {
            type: Boolean,
            default: false
        },
        organization: {
            type: Object,
            default: null
        }
    },
    watch: {
        showDialog: function (value) {
            if (value) {
                this.getChecklistsAnswersList()
            }
        }
    },
    methods: {
        closeDialog() {
            this.answersList = []
            this.showSpecificAnswer = false
            this.selectedAnswerFromList = null
            this.selectedAnswerResponse = null
            this.$emit('closeDialog')
        },
        handleContinueButton() {
            this.showSpecificAnswer = true
            this.getChecklistAnswer()
        },
        handleBackButton() {
            this.selectedAnswerResponse = null
            this.showSpecificAnswer = false
        },
        getChecklistsAnswersList() {
            this.answersListLoading = true
            this.checklistService.getChecklistsAnswersByVehicle(this.organization.id)
            .then(response => {
                this.buildChecklistAnswerList(response)
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.answersListLoading = false
            })
        },
        buildChecklistAnswerList(response) {
            let list = response;
            list.forEach(item => {
                item.date_string = this.dateHourFormat(item.answer_date);
                if (!("operator_name" in item)) {
                    item.operator_name = this.$t('AppChecklistsAnswersDialog.desconhecido')
                }
            })
            this.answersList = list
        },
        handleSelectedAnswer(value) {
            this.selectedAnswerFromList = value
        },
        getChecklistAnswer() {
            this.selectedAnswerResponseLoading = true
            this.checklistService.getChecklistAnswers(this.selectedAnswerFromList.answer_id)
            .then(response => {
                this.selectedAnswerResponse = response
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.selectedAnswerResponseLoading = false
            })
        }
    },
    mixins: [DateMixin],
    components: {
        AppChecklistsAnswersRead,
        AppChecklistsAnswersList,
        Button,
        Dialog
    }
}

</script>

<style scoped lang="scss">
@import "../../../assets/styles/primevue";

</style>
