<template>
        <div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
            <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div class="kt-grid kt-grid--desktop kt-grid--ver kt-grid--ver-desktop kt-app">
                    <app-profile-aside-menu></app-profile-aside-menu>
                    <div class="kt-grid__item kt-grid__item--fluid kt-app__content">

                        <div class="col-xl-12">
                            <div class="kt-portlet">
                                <div class="kt-portlet__head">
                                    <div class="kt-portlet__head-label">
                                        <h3 class="kt-portlet__head-title">
                                            {{ $t('AppProfileAccountInformation.Informações Pessoais') }}
                                            <small>{{ $t('AppProfileAccountInformation.Atualize suas informações pessoais') }}</small>
                                        </h3>
                                    </div>
                                </div>
                                <app-alert :type="'danger'" :icon="'glyphicons-exclamation-sign'" @handleClick="errors = []" v-show="errors.length">
                                    <b>{{ $t('AppProfileAccountInformation.Por favor, verifique os erros informados') }}</b>
                                    <ul>
                                        <li v-for="error in errors" :key="error">{{ error }}</li>
                                    </ul>
                                </app-alert>
                                <form class="kt-form kt-form--label-right" v-on:submit.prevent>
                                    <div class="kt-portlet__body">
                                        <div class="kt-section kt-section--first">
                                            <div class="kt-section__body">
                                                <div class="row">
                                                    <label class="col-xl-3"></label>
                                                    <div class="col-lg-9 col-xl-6">
                                                        <h3 class="kt-section__title kt-section__title-sm">{{ $t('AppProfileAccountInformation.Informações Pessoais') }}</h3>
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label class="col-xl-3 col-lg-3 col-form-label">
                                                        {{ $t('AppProfileAccountInformation.Nome') }}
                                                    </label>
                                                    <div class="col-lg-9 col-xl-6">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                            </div>
                                                            <app-input v-model="user.name" :placeholder="$t('AppProfileAccountInformation.Nome')"></app-input>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <label class="col-xl-3"></label>
                                                    <div class="col-lg-9 col-xl-6">
                                                        <h3 class="kt-section__title kt-section__title-sm">{{ $t('AppProfileAccountInformation.Informações de contato') }}</h3>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label class="col-xl-3 col-lg-3 col-form-label">
                                                        {{ $t('AppProfileAccountInformation.Endereço de E-mail') }}
                                                    </label>
                                                    <div class="col-lg-9 col-xl-6">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                            </div>
                                                            <app-input v-model="user.username" :placeholder="$t('AppProfileAccountInformation.Endereço de E-mail')"></app-input>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="kt-portlet__foot">
                                        <div class="kt-form__actions">
                                            <div class="row">
                                                <div class="col-lg-3 col-xl-3">
                                                </div>
                                                <div class="col-lg-3 col-xl-3">
                                                    <app-button type="primary" @handleClick="validateForm">{{ $t('AppProfileAccountInformation.Salvar') }}</app-button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
    import AppInput from '../../../common/AppInput';
    import AppButton from '../../../common/AppButton';
    import AppProfileAsideMenu from './AppProfileAsideMenu';
    import AppAlert from '../../../common/AppAlert';

    import axios from '../../../../axios/axios-auth';
    import {router} from '../../../../router';

    export default {
        mounted() {
            this.user = this.$store.getters.user;
        },
        data() {
            return {
                user: {
                    name: '',
                    user_email: "",
                },
                show_alert: false,
                errors: []
            }
        },
        components: {
            AppInput,
            AppButton,
            AppProfileAsideMenu,
            AppAlert
        },
        methods: {
            validateForm() {
                if (!this.user.name) {
                    this.errors.push(this.$t('AppProfileAccountInformation.Nome obrigatório'));
                }
                if (!this.user.username) {
                    this.errors.push(this.$t('AppProfileAccountInformation.Email obrigatório'));
                }
                if (this.errors.length === 0) {
                    this.updateProfile();
                }
            },
            updateProfile() {
                let vm = this;
                axios.post(process.env.VUE_APP_BACKEND_ADDRESS + '/me/email', {
                    email: vm.user.username,
                    name: vm.user.name
                }).then(function (response) {
                    if (response.status === 200) {
                        vm.showToast(vm.$t('AppProfileAccountInformation.Informações atualizadas com sucesso, necessário efetuar login novamente'), 'success');
                        router.push({name: 'login'});
                    }
                }).catch(function (error) {
                    if (error.response) {
                        if (error.response.status === 400 && error.response.statusText === 'RECORD_ALREADY_EXISTS') {
                            vm.showToast(vm.$t('AppProfileAccountInformation.Não foi possível atualizar as informações, este email já está em uso'), 'error')
                        } else {
                            vm.showToast(vm.$t('AppProfileAccountInformation.Não foi possível atualizar as informações'), 'error');
                        }
                    } else if (error.request) {
                        vm.showToast(vm.$t('AppProfileAccountInformation.Não foi possível atualizar as informações'), 'error');
                    } else {
                        vm.showToast(vm.$t('AppProfileAccountInformation.Não foi possível atualizar as informações'), 'error');
                    }
                });
            },
            showToast(message, type) {
                this.$toast.add({
                    severity: type,
                    summary: message,
                    life: 5000
                });
            },
        }
    }
</script>

<style scoped>
    a {
        color: #74788d;
    }

    a:hover {
        color: #2c77f4;
    }
</style>
