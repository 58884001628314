<template>
    <div id="kt_user_profile_aside" class="kt-grid__item kt-app__toggle kt-app__aside" style="opacity: 1;">
        <div class="kt-portlet">
            <div class="kt-portlet__head  kt-portlet__head--noborder">
            </div>
            <div class="kt-portlet__body kt-portlet__body--fit-y">
                <div class="kt-widget kt-widget--user-profile-1">
                    <div class="kt-widget__head">
                        <div class="kt-widget__media">
                        </div>
                        <div class="kt-widget__content">
                            <div class="kt-widget__section">
                                <a class="kt-widget__username" href=""> {{ user.name }} </a>
                                <span class="kt-widget__subtitle">
                                                    </span>
                            </div>
                        </div>
                    </div>
                    <div class="kt-widget__body">
                        <div class="kt-widget__content">
                            <div class="kt-widget__info">
                                <span class="kt-widget__label">{{ $t('AppProfileAsideMenu.Email') }}</span>
                                <a class="kt-widget__data">{{ user.username }}</a>
                            </div>
                            <div class="kt-widget__info">
                                <span class="kt-widget__label">{{ $t('AppProfileAsideMenu.Telefone') }}</span>
                                <a class="kt-widget__data"></a>
                            </div>
                        </div>
                        <div class="kt-widget__items">
                            <router-link class="kt-widget__item kt-widget__item" :to="{name:'profile-account-information'}">
                                                 <span class="kt-widget__section">
                                                      <span class="kt-widget__icon">
                                                        <i class="kt-menu__link-icon glyphicons glyphicons-user"></i>
                                                     </span>
                                                     <span class="kt-widget__desc">
                                                            {{ $t('AppProfileAsideMenu.Informações Pessoais') }}
                                                     </span>
                                                 </span>
                            </router-link>
                            <router-link class="kt-widget__item" :to="{name:'profile-change-password'}">
                                                 <span class="kt-widget__section">
                                                      <span class="kt-widget__icon">
                                                          <i class="kt-menu__link-icon glyphicons glyphicons-lock"></i>
                                                     </span>
                                                     <span class="kt-widget__desc"> {{ $t('AppProfileAsideMenu.Alterar Senha') }} </span>
                                                 </span>
                            </router-link>
                            <a class="kt-widget__item " href="#" data-toggle="kt-tooltip" title="" data-placement="right" data-original-title="Coming soon...">
                                                 <span class="kt-widget__section">
                                                      <span class="kt-widget__icon">
                                                        <i class="kt-menu__link-icon glyphicons glyphicons-credit-card"></i>
                                                     </span>
                                                     <span class="kt-widget__desc" href="#">
                                                            {{ $t('AppProfileAsideMenu.Cartões de credito') }}
                                                     </span>
                                                 </span>
                            </a>
                            <a class="kt-widget__item" href="#" data-toggle="kt-tooltip" title="" data-placement="right" data-original-title="Coming soon...">
                                                 <span class="kt-widget__section">
                                                      <span class="kt-widget__icon">
                                                            <i class="kt-menu__link-icon glyphicons glyphicons-invoice"></i>
                                                     </span>
                                                     <span class="kt-widget__desc"> {{ $t('AppProfileAsideMenu.Extratos') }} </span>
                                                 </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        mounted() {
            this.user = this.$store.getters.user;
        },
        data() {
            return {
                user: {
                    name: '',
                    email: ''
                }
            }
        },
        components: {},
        methods: {
            validateForm() {
                console.log('Click validateForm')
            },
        }
    }
</script>

<style scoped>
    a {
        color: #74788d;
    }

    a:hover {
        color: #2c77f4;
    }
</style>
