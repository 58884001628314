<template>
    <div class="row no-gutters">
        <div class="col-12">
            <div @click="remove" v-show="showStyle" class="button-remove">
                <i class="pi pi-times" style="font-size: 12px; margin-top: 6px; color: black;"/>
            </div>
            <CascadeSelect v-model="chartOne" v-show="showStyle" :options="chartsOptions" appendTo="tableDiv"
                           optionLabel="cname" optionGroupLabel="name"
                           :optionGroupChildren="['chart']"
                           class="cascade-select" :placeholder="placeHolderCascade"
                           @change="handleCascadeChange($event)"/>

            <h2 v-show="!showStyle" style="font-size: 20px; text-align: center;">{{ chartOne.cname }}</h2>

            <app-pie-chart ref="pieChart"
                           :is_analysis="true"
                           :is_pdf="is_pdf"
                           :chart_data="chartOneData"
                           :chart_code="chartOneCode"
                           :reflow_chart="chartUpdater"
                           :series_symbol="'%'">
            </app-pie-chart>
        </div>
    </div>
</template>

<script>
import AppPieChart from "./AppPieChart";
import CascadeSelect from 'primevue/cascadeselect';
import {optionsPieCharts} from './chartsOptions';
import i18n from '@/i18n'


export default {
    mounted() {
        this.is_col_12 = this.isScreenMobile;
        this.chartsOptions = optionsPieCharts()
    },
    data() {
        return {
            placeHolderCascade: i18n.t('AppAnalysisPieCharts.Selecione'),
            is_pdf: false,
            is_col_12: false,
            showStyle: true,
            chartsData: [],
            value: null,
            chartsOptions: null,
            chartOne: [],
            chartOneData: [],
            storedChosenOptions: {},
            chartOneCode: '',
            dialogVisible: false,
            filteredTime: null,
            selectedVehicles: null,
            chartUpdater: false,
            id: 0,
            modelLocalStorage: 0,
            selectPlaceholder: null,
        }
    },
    methods: {

        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        setModelLocalStorage(val) {
            this.modelLocalStorage = val;
        },
        isPdf(val) {
            this.is_pdf = val;
        },
        cleanStyle() {
            this.showStyle = !this.showStyle
        },
        remove() {
            this.$emit('remove', this.id);
        },
        correctHeight(val) {
            this.$refs.pieChart.redimensionar(val);
        },
        fillCharts() {
            this.chartOneData = this.getChartData(this.chartOne.code);
        },
        getChartData(code) {
            if (!code) {
                return [];
            }
            switch (code) {
                case 'ANALISE_ATIVIDADE':
                    return this.getAnaliseAtividade();
                case 'ANALISE_COMBUSTIVEL':
                    return this.getAnaliseCombustivel();
                case 'PARADAS':
                    return this.getParadas();
                case 'ATIVIDADE_AREA':
                    return this.getAtividadeArea();
                case 'ATIVIDADE_TEMPO':
                    return this.getAtividadeTempo();
                case 'ATIVIDADE_COMBUSTIVEL':
                    return this.getAtividadeCombustivel();
                case 'ATIVIDADE_DISTANCIA':
                    return this.getAtividadeDistancia();
                case 'LOCAIS_AREA':
                    return this.getLocaisArea();
                case 'LOCAIS_TEMPO':
                    return this.getLocaisTempo();
                case 'LOCAIS_COMBUSTIVEL':
                    return this.locaisCombustivel();
                case 'LOCAIS_DISTANCIA':
                    return this.getLocaisDistancia();
                case 'MAQUINA_AREA':
                    return this.maquinaArea();
                case 'MAQUINA_TEMPO':
                    return this.getMaquinaTempo();
                case 'MAQUINA_COMBUSTIVEL':
                    return this.getMaquinaCombustivel();
                case 'MAQUINA_DISTANCIA':
                    return this.getMaquinaDistancia();
                case 'OPERADORES_AREA':
                    return this.getOperadoresArea();
                case 'OPERADORES_COMBUSTIVEL':
                    return this.getOperadoresCombustivel();
                case 'OPERADORES_DISTANCIA':
                    return this.getOperadoresDistancia();
                case 'OPERADORES_TEMPO':
                    return this.getOperadoresTempo();
                case 'TURNOS_AREA':
                    return this.getTurnosArea();
                case 'TURNOS_COMBUSTIVEL':
                    return this.getTurnosCombustivel();
                case 'TURNOS_DISTANCIA':
                    return this.getTurnosDistancia();
                case 'TURNOS_TEMPO':
                    return this.getTurnosTempo();
                default:
                    break;
            }
        },
        getAnaliseAtividade() {
            let ocioso = 0;
            let manobrando = 0;
            let produzindo = 0;
            let deslocando = 0;
            let desligado = 0;
            let aguardando_dados = 0;
            let nao_monitorado = 0;
            let manutencao = 0;

            let tempo_total = 0;

            this.chartsData.forEach((data) => {
                if (data.mode === 1) {
                    produzindo += data.time;
                }
                if (data.mode === 2) {
                    ocioso += data.time;
                }
                if (data.mode === 3) {
                    manobrando += data.time;
                }
                if (data.mode === 4) {
                    deslocando += data.time;
                }
                if (data.mode === 5) {
                    desligado += data.time;
                }
                if (data.mode === 6) {
                    aguardando_dados += data.time;
                }
                if (data.mode === 7) {
                    nao_monitorado += data.time;
                }
                if (data.mode === 8) {
                    manutencao += data.time;
                }
            });

            tempo_total = ocioso + manobrando + produzindo + deslocando + desligado + aguardando_dados + nao_monitorado + manutencao

            let dataResponse = [
                {
                    name: i18n.t('AppAnalysisPieCharts.Ocioso'),
                    y: this.formatValue((ocioso / tempo_total) / this.selectedVehicles),
                    rawValue: ocioso.toFixed(2), rawSymbol: i18n.t('AppAnalysisPieCharts.Horas'),
                    color: '#d62728'
                },
                {
                    name: i18n.t('AppAnalysisPieCharts.Manobrando'),
                    y: this.formatValue((manobrando / tempo_total) / this.selectedVehicles),
                    rawValue: manobrando.toFixed(2),
                    rawSymbol: i18n.t('AppAnalysisPieCharts.Horas'),
                    color: '#ff7e0e'
                },
                {
                    name: i18n.t('AppAnalysisPieCharts.Produzindo'),
                    y: this.formatValue((produzindo / tempo_total) / this.selectedVehicles),
                    rawValue: produzindo.toFixed(2),
                    rawSymbol: i18n.t('AppAnalysisPieCharts.Horas'),
                    color: '#2ca02c'
                },
                {
                    name: i18n.t('AppAnalysisPieCharts.Deslocando'),
                    y: this.formatValue((deslocando / tempo_total) / this.selectedVehicles),
                    rawValue: deslocando.toFixed(2),
                    rawSymbol: i18n.t('AppAnalysisPieCharts.Horas'),
                    color: '#1f76b4'
                },
                {
                    name: i18n.t('AppAnalysisPieCharts.Desligado'),
                    y: this.formatValue((desligado / tempo_total) / this.selectedVehicles),
                    rawValue: desligado.toFixed(2),
                    rawSymbol: i18n.t('AppAnalysisPieCharts.Horas'),
                    color: '#5E3F20'
                },
                {
                    name: i18n.t('AppAnalysisPieCharts.Aguardando Dados'),
                    y: this.formatValue((aguardando_dados / tempo_total) / this.selectedVehicles),
                    rawValue: aguardando_dados.toFixed(2),
                    rawSymbol: i18n.t('AppAnalysisPieCharts.Horas'),
                    color: '#FFFF33'
                },
                {
                    name: i18n.t('AppAnalysisPieCharts.Não Monitorado'),
                    y: this.formatValue((nao_monitorado / tempo_total) / this.selectedVehicles),
                    rawValue: nao_monitorado.toFixed(2),
                    rawSymbol: i18n.t('AppAnalysisPieCharts.Horas'),
                    color: '#797979'
                },
                {
                    name: i18n.t('AppAnalysisPieCharts.Manutenção'),
                    y: this.formatValue((manutencao / tempo_total) / this.selectedVehicles),
                    rawValue: manutencao.toFixed(2),
                    rawSymbol: i18n.t('AppAnalysisPieCharts.Horas'),
                    color: '#B507A7'
                }
            ];

            return dataResponse;
        },
        getAnaliseCombustivel() {

            let total = 0;
            let produzindo = 0;
            let ocioso = 0;
            let manobrando = 0;
            let deslocando = 0;
            let manutencao = 0

            this.chartsData.forEach((data) => {
                if (typeof data.consumption === 'number') {
                    total += data.consumption;
                }
                if (data.mode === 1) {
                    produzindo += data.consumption;
                }
                if (data.mode === 2) {
                    ocioso += data.consumption;
                }
                if (data.mode === 3) {
                    manobrando += data.consumption;
                }
                if (data.mode === 4) {
                    deslocando += data.consumption;
                }
                if (data.mode === 8) {
                    manutencao += data.consumption;
                }
            });

            let dataResponse = [
                {
                    name: i18n.t('AppAnalysisPieCharts.Ocioso'),
                    y: this.formatValue(ocioso / total),
                    rawValue: ocioso.toFixed(2), rawSymbol: i18n.t('AppAnalysisPieCharts.Litros'),
                    color: '#d62728'
                },
                {
                    name: i18n.t('AppAnalysisPieCharts.Manobrando'),
                    y: this.formatValue(manobrando / total),
                    rawValue: manobrando.toFixed(2),
                    rawSymbol: i18n.t('AppAnalysisPieCharts.Litros'),
                    color: '#ff7e0e'
                },
                {
                    name: i18n.t('AppAnalysisPieCharts.Produzindo'),
                    y: this.formatValue(produzindo / total),
                    rawValue: produzindo.toFixed(2),
                    rawSymbol: i18n.t('AppAnalysisPieCharts.Litros'),
                    color: '#2ca02c'
                },
                {
                    name: i18n.t('AppAnalysisPieCharts.Deslocando'),
                    y: this.formatValue(deslocando / total),
                    rawValue: deslocando.toFixed(2),
                    rawSymbol: i18n.t('AppAnalysisPieCharts.Litros'),
                    color: '#1f76b4'
                },
                {
                    name: i18n.t('AppAnalysisPieCharts.Manutenção'),
                    y: this.formatValue(manutencao / total),
                    rawValue: manutencao.toFixed(2),
                    rawSymbol: i18n.t('AppAnalysisPieCharts.Litros'),
                    color: '#B507A7'
                }
            ];
            return dataResponse;
        },
        getParadas() {

            let paradas = [];
            let total = 0;

            this.chartsData.forEach((data) => {
                if (data.mode !== 2) {
                    return
                }

                if (typeof data.time === 'number') {
                    let eventDurationInHours = data.time;
                    total += eventDurationInHours;
                    let index = paradas.findIndex(i => i.reason_code === data.reason_code);

                    if (index >= 0) {
                        paradas[index].time += data.time;
                    } else {
                        paradas.push({
                            reason_code: data.reason_code,
                            time: data.time,
                            reason_description: data.reason_description
                        })
                    }
                }
            });

            let response = [];
            paradas.forEach((parada) => {
                response.push({
                    name: parada.reason_code,
                    y: this.formatValue(parada.time / total),
                    description: parada.reason_description,
                    rawValue: parada.time.toFixed(2),
                    rawSymbol: i18n.t('AppAnalysisPieCharts.Horas')
                });
            });
            return response;
        },

        getAtividadeArea() {
            let dataSeries = [];
            let totalAreaGlobal = 0;

            this.chartsData.forEach((data) => {
                if (typeof data.area === 'number' && data.activity) {
                    totalAreaGlobal += data.area;
                    let index = dataSeries.findIndex(i => i.activity === data.activity);

                    if (index >= 0) {
                        dataSeries[index].area += data.area;
                    } else {
                        dataSeries.push({
                            activity: data.activity,
                            area: data.area,
                        })
                    }
                }
            });

            let response = [];
            dataSeries.forEach((data) => {
                response.push({
                    name: data.activity,
                    y: this.formatValue(data.area / totalAreaGlobal),
                    rawValue: data.area.toFixed(2),
                    rawSymbol: 'ha'
                });
            });
            return response;
        },
        getAtividadeTempo() {
            let dataSeries = [];
            let totalGlobal = 0;

            this.chartsData.forEach((data) => {
                if (data.mode === 1 && data.activity) {
                    totalGlobal += data.time;
                    let index = dataSeries.findIndex(i => i.activity === data.activity);

                    if (index >= 0) {
                        dataSeries[index].time += data.time;
                    } else {
                        dataSeries.push({
                            activity: data.activity,
                            time: data.time,
                        })
                    }
                }
            });

            let response = [];
            dataSeries.forEach((data) => {
                response.push({
                    name: data.activity,
                    y: this.formatValue(data.time / totalGlobal),
                    rawValue: data.time.toFixed(2),
                    rawSymbol: i18n.t('AppAnalysisPieCharts.Horas')
                });
            });
            return response;
        },
        getAtividadeCombustivel() {

            let dataSeries = [];
            let totalGlobal = 0;

            this.chartsData.forEach((data) => {
                if (typeof data.consumption === 'number' && data.activity) {
                    totalGlobal += data.consumption;
                    let index = dataSeries.findIndex(i => i.activity === data.activity);

                    if (index >= 0) {
                        dataSeries[index].consumption += data.consumption;
                    } else {
                        dataSeries.push({
                            activity: data.activity,
                            consumption: data.consumption,
                        })
                    }
                }
            });

            let response = [];
            dataSeries.forEach((data) => {
                if (data.consumption > 0) {
                    response.push({
                        name: data.activity,
                        y: this.formatValue(data.consumption / totalGlobal),
                        rawValue: data.consumption.toFixed(2),
                        rawSymbol: i18n.t('AppAnalysisPieCharts.Litros')
                    });
                }
            });
            return response;
        },
        getAtividadeDistancia() {
            let dataSeries = [];
            let totalGlobal = 0;

            this.chartsData.forEach((data) => {
                if (typeof data.distance === 'number' && data.activity) {
                    totalGlobal += data.distance;
                    let index = dataSeries.findIndex(i => i.activity === data.activity);

                    if (index >= 0) {
                        dataSeries[index].distance += data.distance;
                    } else {
                        dataSeries.push({
                            activity: data.activity,
                            distance: data.distance,
                        })
                    }
                }
            });

            let response = [];
            dataSeries.forEach((data) => {
                if (data.distance > 0) {
                    response.push({
                        name: data.activity,
                        y: this.formatValue(data.distance / totalGlobal),
                        rawValue: data.distance.toFixed(2),
                        rawSymbol: 'km'
                    });
                }
            });
            return response;
        },
        getLocaisArea() {
            let dataSeries = [];
            let totalGlobal = 0

            this.chartsData.forEach((data) => {
                if (typeof data.area === 'number' && typeof data.area === 'number' && data.field) {

                    if (data.area > 0 && data.area > 0) {
                        totalGlobal += data.area;
                        let index = dataSeries.findIndex(i => i.field === data.field);

                        if (index >= 0) {
                            dataSeries[index].area += data.area;
                            dataSeries[index].area += data.area;
                        } else {
                            dataSeries.push({
                                field: data.field,
                                area: data.area,
                            })
                        }
                    }
                }
            });

            let response = [];
            dataSeries.forEach((data) => {
                response.push({
                    name: data.field,
                    y: this.formatValue(data.area / totalGlobal),
                    rawValue: data.area.toFixed(2),
                    rawSymbol: 'ha'
                });
            });
            return response;
        },
        getLocaisTempo() {
            let dataSeries = [];
            let totalGlobal = 0;

            this.chartsData.forEach((data) => {
                if (typeof data.time === 'number' && data.field) {
                    totalGlobal += data.time;
                    let index = dataSeries.findIndex(i => i.field === data.field);

                    if (index >= 0) {
                        dataSeries[index].time += data.time;
                    } else {
                        dataSeries.push({
                            field: data.field,
                            time: data.time,
                        })
                    }
                }
            });

            let response = [];
            dataSeries.forEach((data) => {
                response.push({
                    name: data.field,
                    y: this.formatValue(data.time / totalGlobal),
                    rawValue: data.time.toFixed(2),
                    rawSymbol: i18n.t('AppAnalysisPieCharts.Horas')
                });
            });
            return response;
        },
        locaisCombustivel() {

            let dataSeries = [];
            let totalGlobal = 0;

            this.chartsData.forEach((data) => {
                if (typeof data.consumption === 'number' && data.field) {

                    totalGlobal += data.consumption;
                    let index = dataSeries.findIndex(i => i.field === data.field);

                    if (index >= 0) {
                        dataSeries[index].consumption += data.consumption;
                    } else {
                        dataSeries.push({
                            field: data.field,
                            consumption: data.consumption,
                        })
                    }
                }
            });

            let response = [];
            dataSeries.forEach((data) => {
                if (data.consumption > 0) {
                    response.push({
                        name: data.field,
                        y: this.formatValue(data.consumption / totalGlobal),
                        rawValue: data.consumption.toFixed(2),
                        rawSymbol: i18n.t('AppAnalysisPieCharts.Litros')
                    });
                }
            });
            return response;
        },
        getLocaisDistancia() {


            let dataSeries = [];
            let totalGlobal = 0;

            this.chartsData.forEach((data) => {
                if (typeof data.distance === 'number' && data.field) {

                    totalGlobal += data.distance;
                    let index = dataSeries.findIndex(i => i.field === data.field);

                    if (index >= 0) {
                        dataSeries[index].distance += data.distance;
                    } else {
                        dataSeries.push({
                            field: data.field,
                            distance: data.distance,
                        })
                    }
                }
            });

            let response = [];
            dataSeries.forEach((data) => {
                if (data.distance > 0) {
                    response.push({
                        name: data.field,
                        y: this.formatValue(data.distance / totalGlobal),
                        rawValue: data.distance.toFixed(2),
                        rawSymbol: 'Km'
                    });
                }
            });
            return response;
        },
        maquinaArea() {

            let maquinas = [];
            let total = 0;

            this.chartsData.forEach((data) => {
                if (typeof data.area === 'number') {

                    total += data.area;
                    let index = maquinas.findIndex(i => i.chassis === data.chassis);

                    if (index >= 0) {
                        maquinas[index].area += data.area;
                    } else {
                        maquinas.push({
                            chassis: data.chassis,
                            vehicle_display_id: data.vehicle_display_id,
                            area: data.area
                        })
                    }
                }
            });

            let response = [];
            maquinas.forEach((data) => {
                response.push({
                    name: data.vehicle_display_id,
                    y: this.formatValue(data.area / total),
                    rawValue: data.area.toFixed(2),
                    rawSymbol: 'ha'
                });
            });
            return response;
        },
        getMaquinaTempo() {

            let maquinas = [];
            let total = 0;

            this.chartsData.forEach((data) => {
                if (typeof data.time === 'number') {

                    total += data.time;
                    let index = maquinas.findIndex(i => i.chassis === data.chassis);

                    if (index >= 0) {
                        maquinas[index].time += data.time;
                    } else {
                        maquinas.push({
                            chassis: data.chassis,
                            vehicle_display_id: data.vehicle_display_id,
                            time: data.time
                        })
                    }
                }
            });

            let response = [];
            maquinas.forEach((data) => {
                response.push({
                    name: data.vehicle_display_id,
                    y: this.formatValue(data.time / total),
                    rawValue: data.time.toFixed(2),
                    rawSymbol: i18n.t('AppAnalysisPieCharts.Horas')
                });
            });
            return response;
        },
        getMaquinaCombustivel() {

            let maquinas = [];
            let total = 0;

            this.chartsData.forEach((data) => {
                if (typeof data.consumption === 'number') {

                    total += data.consumption;
                    let index = maquinas.findIndex(i => i.chassis === data.chassis);

                    if (index >= 0) {
                        maquinas[index].consumption += data.consumption;
                    } else {
                        maquinas.push({
                            chassis: data.chassis,
                            vehicle_display_id: data.vehicle_display_id,
                            consumption: data.consumption
                        })
                    }
                }
            });

            let response = [];
            maquinas.forEach((data) => {
                if (data.consumption > 0) {
                    response.push({
                        name: data.vehicle_display_id,
                        y: this.formatValue(data.consumption / total),
                        rawValue: data.consumption.toFixed(2),
                        rawSymbol: i18n.t('AppAnalysisPieCharts.Litros')
                    });
                }
            });
            return response;

        },
        getMaquinaDistancia() {

            let maquinas = [];
            let total = 0;

            this.chartsData.forEach((data) => {
                if (typeof data.distance === 'number') {

                    total += data.distance;
                    let index = maquinas.findIndex(i => i.chassis === data.chassis);

                    if (index >= 0) {
                        maquinas[index].distance += data.distance;
                    } else {
                        maquinas.push({
                            chassis: data.chassis,
                            vehicle_display_id: data.vehicle_display_id,
                            distance: data.distance
                        })
                    }
                }
            });

            let response = [];
            maquinas.forEach((data) => {
                if (data.distance > 0) {
                    response.push({
                        name: data.vehicle_display_id,
                        y: this.formatValue(data.distance / total),
                        rawValue: data.distance.toFixed(2),
                        rawSymbol: 'km'
                    });
                }
            });
            return response;

        },
        getOperadoresArea() {

            let operadores = [];
            let total = 0;

            this.chartsData.forEach((data) => {
                if (typeof data.area === 'number') {

                    total += data.area;
                    let index = operadores.findIndex(i => i.operator_id === data.operator_id);

                    if (index >= 0) {
                        operadores[index].area += data.area;
                    } else {
                        operadores.push({
                            operator_id: data.operator_id,
                            area: data.area
                        })
                    }
                }
            });

            let response = [];
            operadores.forEach((data) => {
                response.push({
                    name: data.operator_id,
                    y: this.formatValue(data.area / total),
                    rawValue: data.area.toFixed(2),
                    rawSymbol: 'ha'
                });
            });
            return response;
        },
        getOperadoresCombustivel() {

            let maquinas = [];
            let total = 0;

            this.chartsData.forEach((data) => {
                if (typeof data.consumption === 'number') {

                    total += data.consumption;
                    let index = maquinas.findIndex(i => i.operator_id === data.operator_id);

                    if (index >= 0) {
                        maquinas[index].consumption += data.consumption;
                    } else {
                        maquinas.push({
                            operator_id: data.operator_id,
                            consumption: data.consumption
                        })
                    }
                }
            });

            let response = [];
            maquinas.forEach((data) => {
                if (data.consumption > 0) {
                    response.push({
                        name: data.operator_id,
                        y: this.formatValue(data.consumption / total),
                        rawValue: data.consumption.toFixed(2),
                        rawSymbol: i18n.t('AppAnalysisPieCharts.Litros')
                    });
                }
            });
            return response;
        },

        getOperadoresDistancia() {

            let maquinas = [];
            let total = 0;

            this.chartsData.forEach((data) => {
                if (typeof data.distance === 'number') {

                    total += data.distance;
                    let index = maquinas.findIndex(i => i.operator_id === data.operator_id);

                    if (index >= 0) {
                        maquinas[index].distance += data.distance;
                    } else {
                        maquinas.push({
                            operator_id: data.operator_id,
                            distance: data.distance
                        })
                    }
                }
            });

            let response = [];
            maquinas.forEach((data) => {
                if (data.distance > 0) {
                    response.push({
                        name: data.operator_id,
                        y: this.formatValue(data.distance / total),
                        rawValue: data.distance.toFixed(2),
                        rawSymbol: 'km'
                    });
                }
            });
            return response;
        },
        getOperadoresTempo() {
            let maquinas = [];
            let total = 0;

            this.chartsData.forEach((data) => {
                if (typeof data.time === 'number') {

                    total += data.time;
                    let index = maquinas.findIndex(i => i.operator_id === data.operator_id);

                    if (index >= 0) {
                        maquinas[index].time += data.time;
                    } else {
                        maquinas.push({
                            operator_id: data.operator_id,
                            time: data.time
                        })
                    }
                }
            });

            let response = [];
            maquinas.forEach((data) => {
                response.push({
                    name: data.operator_id,
                    y: this.formatValue(data.time / total),
                    rawValue: data.time.toFixed(2),
                    rawSymbol: i18n.t('AppAnalysisPieCharts.Horas')
                });
            });
            return response;
        },
        getTurnosArea() {
            let shifts = [];
            let total = 0;

            this.chartsData.forEach((data) => {
                if (typeof data.area === 'number') {

                    total += data.area;
                    let index = shifts.findIndex(i => i.shift === data.shift);

                    if (index >= 0) {
                        shifts[index].area += data.area;
                    } else {
                        shifts.push({
                            shift: data.shift,
                            area: data.area
                        })
                    }
                }
            });

            let response = [];
            shifts.forEach((data) => {
                response.push({
                    name: data.shift,
                    y: this.formatValue(data.area / total),
                    rawValue: data.area.toFixed(2),
                    rawSymbol: 'ha'
                });
            });
            return response;
        },
        getTurnosCombustivel() {
            let shifts = [];
            let total = 0;

            this.chartsData.forEach((data) => {
                if (typeof data.consumption === 'number') {

                    total += data.consumption;
                    let index = shifts.findIndex(i => i.shift === data.shift);

                    if (index >= 0) {
                        shifts[index].consumption += data.consumption;
                    } else {
                        shifts.push({
                            shift: data.shift,
                            consumption: data.consumption
                        })
                    }
                }
            });

            let response = [];
            shifts.forEach((data) => {
                if (data.consumption > 0) {
                    response.push({
                        name: data.shift,
                        y: this.formatValue(data.consumption / total),
                        rawValue: data.consumption.toFixed(2),
                        rawSymbol: i18n.t('AppAnalysisPieCharts.Litros')
                    });
                }
            });
            return response;
        },
        getTurnosDistancia() {
            let shifts = [];
            let total = 0;

            this.chartsData.forEach((data) => {
                if (typeof data.distance === 'number') {

                    total += data.distance;
                    let index = shifts.findIndex(i => i.shift === data.shift);

                    if (index >= 0) {
                        shifts[index].distance += data.distance;
                    } else {
                        shifts.push({
                            shift: data.shift,
                            distance: data.distance
                        })
                    }
                }
            });

            let response = [];
            shifts.forEach((data) => {
                if (data.distance > 0) {
                    response.push({
                        name: data.shift,
                        y: this.formatValue(data.distance / total),
                        rawValue: data.distance.toFixed(2),
                        rawSymbol: 'km'
                    });
                }
            });
            return response;
        },
        getTurnosTempo() {
            let shifts = [];
            let total = 0;

            this.chartsData.forEach((data) => {
                if (typeof data.time === 'number') {

                    total += data.time;
                    let index = shifts.findIndex(i => i.shift === data.shift);

                    if (index >= 0) {
                        shifts[index].time += data.time;
                    } else {
                        shifts.push({
                            shift: data.shift,
                            time: data.time
                        })
                    }
                }
            });

            let response = [];
            shifts.forEach((data) => {
                response.push({
                    name: data.shift,
                    y: this.formatValue(data.time / total),
                    rawValue: data.time.toFixed(2),
                    rawSymbol: i18n.t('AppAnalysisPieCharts.Horas')
                });
            });
            return response;
        },

        formatValue(value) {
            return parseFloat((value * 100).toFixed(2))
        },

        findChartOptionByCode(chartName) {

            let groupCode = this.storedChosenOptions[chartName];
            if (groupCode.includes("_")) {
                groupCode = this.storedChosenOptions[chartName].slice(0, this.storedChosenOptions[chartName].indexOf("_"));
            }
            if (groupCode != '') {
                let groupOptions = optionsPieCharts().find(x => x.code === groupCode)['chart'];
                if (groupOptions.find(x => x.code === this.storedChosenOptions[chartName]) !== undefined) {
                    return groupOptions.find(x => x.code === this.storedChosenOptions[chartName]);
                }
            }
            return {};
        },
        handleCascadeChange(event) {
            let val = {
                name: event.value.cname,
                code: event.value.code,
                sumInGroupMode: false,
                id: this.id
            }

            this.$emit('dropdownChanged', val);
        },
        getDataFromEvents(results) {
            let vm = this;
            if (results.length > 0) {
                let temp = [];
                //como não diferencia as maquinas, joga tudo em um array para facilitar
                results.forEach((singleMachineResult) => {
                    singleMachineResult.forEach((event) => {
                        temp.push(event)
                    })
                });
                vm.chartsData = temp;
                vm.fillCharts();
            }
        },
        dataWatch(filterEventsResults, chartDataFromIdb, filterTime, chartUpdater, chartDataToSideCards) {
            if (chartDataFromIdb) {
                let temp = [];
                //como não diferencia as maquinas, joga tudo em um array para facilitar
                chartDataFromIdb.forEach((cardData) => {
                    if (cardData.events) {
                        cardData.events.forEach((events) => {
                            temp.push(events)
                        })
                    }
                });
                this.chartsData = temp;

                this.fillCharts();
            }

            if (filterTime) {
                this.filteredTime = Math.trunc((filterTime.end_date - filterTime.start_date) / 1000) / 3600;
                this.selectedVehicles = filterTime.selectedVehicles;
            }

            this.chartUpdater = chartUpdater;

            this.getDataFromEvents(filterEventsResults);
        },
        setLocalStorage(count) {
            this.id = count;
        },
        setNoValue() {
            this.chartOneData = [];
        },
        setOption() {
            let temp = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_ANALYSIS));

            temp[this.modelLocalStorage].value.forEach(element => {
                if (element.id === this.id) {
                    this.chartOne = {cname: element.name, code: element.code};
                }
            });
        }
    },
    watch: {
        chartOne: function (val) {
            if (!val.code) {
                return;
            }
            this.chartOneCode = val.code;
            this.chartOneData = this.getChartData(val.code);
        },
        filter_events_results: function (val) {
            this.getDataFromEvents(val);
        },
        filter_time: function (val) {
            if (val) {
                this.filteredTime = Math.trunc((val.end_date - val.start_date) / 1000) / 3600;
                this.selectedVehicles = val.selectedVehicles;
            }
        },
        chart_data_from_idb: function (val) {
            if (val) {
                let temp = [];
                //como não diferencia as maquinas, joga tudo em um array para facilitar
                val.forEach((cardData) => {
                    if (cardData.events) {
                        cardData.events.forEach((events) => {
                            temp.push(events)
                        })
                    }
                });
                this.chartsData = temp;

                this.fillCharts();
            }
        },
        chartsData: {
            handler() {
                let hasArea = this.chartsData.some(data => data.area !== 0);
                if (!hasArea) {
                    let availableOptions = []
                    this.chartsOptions.forEach(option => {
                        let validOption = option.chart.filter(c => !c.code.includes("AREA"));
                        availableOptions.push({
                            name: option.name,
                            chart: validOption
                        })
                    })
                    this.chartsOptions = availableOptions
                }
            }
        }
    },
    computed: {
        isScreenMobile: function () {
            return window.screen.width <= 1024;
        }
    },
    components: {
        CascadeSelect, AppPieChart
    },

}
</script>

<style scoped type="scss">

.containers-charts {
    position: relative;
}

.cascade-select {
    min-width: 200px;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

/* sm >  Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
    .cascade-select {
        min-width: 200px;
    }

    .containers-charts {
        padding-bottom: 5px;
    }
}

/* ms >  Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
}

/* lg > Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
}

/* xl > X-Large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
    .cascade-select {
        min-width: 270px;
    }

}


</style>
