<template>
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__head kt-portlet__head--lg no-border-radius">
                <Dropdown v-model="selectedOrg" :options="organizations" optionLabel="display_name"
                    :placeholder="$t('AppAlertsCoa.Organização')" class="auto-center" :dataKey="'id'" :filter="true"
                    @input="saveChosenOptionsToLocalStorage" style="width: 250px">
                    <template #option="slotProps" style="width: 500px">
                        <div v-if="loadingOrgs"> {{ $t('AppAlertsCoa.Carregando, aguarde') }}</div>
                        <div style="padding-right: 20px" v-else-if="slotProps.option.name === 'ND'">
                            -
                        </div>
                        <div style="padding-right: 20px" v-else>
                            {{ slotProps.option.display_name }}
                        </div>
                    </template>
                </Dropdown>
            </div>
            <div class="kt-portlet__body no-padding" id="tableDiv">
                <div class="card data-table-width">
                    <DataTable :value="gridData" :paginator="true" :rows="50" :rowHover="true" :loading="loading" filterDisplay="row" :filters.sync="filters"
                        :sortOrder="-1" sortField="last_alert_time" stripedRows
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        :rowsPerPageOptions="[10, 25, 50]" responsiveLayout="scroll"
                        :currentPageReportTemplate="$t('AppAlertsCoa.Mostrando {first} até {last} de {totalRecords} Alertas')"
                        class="p-datatable-striped">

                        <template #header>
                            <span style="font-size: 20px; font-weight: 500;">{{$t('AppAlertsCoa.Tela do COA')}}</span>
                        </template>
                        <template #empty>
                            {{$t('AppAlertsCoa.Sem dados para exibir')}}
                        </template>

                        <Column field="criticality_level" :header="$t('AppAlertsCoa.Criticidade')" headerClass="headerClass" :styles="{'min-width': '6rem', 'max-width': '9rem'}"
                            :showFilterMenu="false" :showClearButton="false" bodyClass="bodyClass" :sortable="true">
                            <template #body="slotProps">
                                <span :class="'badge criticalityLevel-' + slotProps.data.criticality_level">
                                    {{ getCriticalityLevel(slotProps.data.criticality_level) }}
                                </span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <MultiSelect v-model="filterModel.value" @input="filterCallback()" :options="optionsCriticalityLevel"
                                            :placeholder="$t('AppAlertsCoa.Selecione')" appendTo="tableDiv"
                                            class="p-column-filter width-multiselect" :showClear="true" optionLabel="name" optionValue="code">
                                    <template #value="slotProps">
                                        <span v-for="singleValue in slotProps.value" :class="'badge criticalityLevel-' + singleValue" style="margin-right: 5px;">
                                            {{ getCriticalityLevel(singleValue) }}
                                        </span>
                                    </template>
                                    <template #option="slotProps">
                                        <span :class="'badge criticalityLevel-' + slotProps.option.code">
                                            {{ getCriticalityLevel(slotProps.option.code) }}
                                        </span>
                                    </template>
                                </MultiSelect>
                            </template>
                        </Column>
                        <Column field="fleet" :header="$t('AppAlertsCoa.Frota')" headerClass="headerClass" :showFilterMenu="false" :styles="{'min-width': '6rem', 'max-width': '9rem'}"
                            :showClearButton="false" bodyClass="bodyClass" :sortable="true">
                            <template #body="slotProps">
                                <span>{{ slotProps.data.fleet }}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <MultiSelect v-model="filterModel.value" @input="filterCallback()" :options="optionsFleet"
                                            :placeholder="$t('AppAlertsCoa.Selecione')" appendTo="tableDiv"
                                            class="p-column-filter width-multiselect" :showClear="true" optionLabel="name" optionValue="name"  />
                            </template>
                        </Column>
                        <Column field="class_name" :header="$t('AppAlertsCoa.Classe')" headerClass="headerClass" :showFilterMenu="false" :styles="{'min-width': '6rem', 'max-width': '9rem'}"
                            :showClearButton="false" bodyClass="bodyClass" :sortable="true">
                            <template #body="slotProps">
                                <span>{{ slotProps.data.class_name }}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <MultiSelect v-model="filterModel.value" @input="filterCallback()" :options="optionsClass"
                                            :placeholder="$t('AppAlertsCoa.Selecione')" appendTo="tableDiv"
                                            class="p-column-filter width-multiselect" :showClear="true" optionLabel="name" optionValue="name" />
                            </template>
                        </Column>
                        <Column field="front" :header="$t('AppAlertsCoa.Frente')" headerClass="headerClass" :showFilterMenu="false" :styles="{'min-width': '6rem', 'max-width': '9rem'}"
                            :showClearButton="false" bodyClass="bodyClass" :sortable="true">
                            <template #body="slotProps">
                                <span>{{ slotProps.data.front }}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <MultiSelect v-model="filterModel.value" @input="filterCallback()" :options="optionsFront"
                                            :placeholder="$t('AppAlertsCoa.Selecione')" appendTo="tableDiv"
                                            class="p-column-filter width-multiselect" :showClear="true" optionLabel="name" optionValue="name" />
                            </template>
                        </Column>
                        <Column field="sensor_name" :header="$t('AppAlertsCoa.Sensor')" headerClass="headerClass" :showFilterMenu="false" :styles="{'min-width': '6rem', 'max-width': '9rem'}"
                            :showClearButton="false" bodyClass="bodyClass" :sortable="true">
                            <template #body="slotProps">
                                <span>{{ slotProps.data.sensor_name }}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <MultiSelect v-model="filterModel.value" @input="filterCallback()" :options="optionsSensorName"
                                            :placeholder="$t('AppAlertsCoa.Selecione')" appendTo="tableDiv"
                                            class="p-column-filter width-multiselect" :showClear="true" optionLabel="name" optionValue="name" />
                            </template>
                        </Column>
                        <Column field="parametric_failures_type" :header="$t('AppAlertsCoa.Tipo Alerta')" headerClass="headerClass" :showFilterMenu="false" :styles="{'max-width': '9rem', 'min-width': '6rem'}"
                            :showClearButton="false" bodyClass="bodyClass" :sortable="true">
                            <template #body="slotProps">
                                <span :class="'badge alertType-' + slotProps.data.parametric_failures_type">
                                    {{ getAlertType(slotProps.data.parametric_failures_type) }}
                                </span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <MultiSelect v-model="filterModel.value" @input="filterCallback()" :options="optionsAlertType"
                                        :placeholder="$t('AppAlertsCoa.Selecione')" appendTo="tableDiv"
                                        class="p-column-filter width-multiselect" :showClear="true" optionLabel="name" optionValue="code">
                                    <template #value="slotProps">
                                        <span v-for="singleValue in slotProps.value" :class="'badge alertType-' + singleValue" style="margin-right: 5px;">
                                            {{ getAlertType(singleValue) }}
                                        </span>
                                    </template>
                                    <template #option="slotProps">
                                        <span :class="'badge alertType-' + slotProps.option.code">
                                            {{ getAlertType(slotProps.option.code) }}
                                        </span>
                                    </template>
                                </MultiSelect>
                            </template>
                        </Column>
                        <Column field="last_measured_value" :header="$t('AppAlertsCoa.Último Valor Medido')" headerClass="headerClass" :styles="{'min-width': '6rem', 'max-width': '9rem'}"
                            :showFilterMenu="false" :showClearButton="false" bodyClass="bodyClass" :sortable="true">
                            <template #body="slotProps">
                                <span>{{ slotProps.data.last_measured_value }}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column field="hour_last_measured_value" :header="$t('AppAlertsCoa.Data/Hora Última Medição')" headerClass="headerClass" :styles="{'min-width': '6rem', 'max-width': '9rem'}"
                            :showFilterMenu="false" :showClearButton="false" bodyClass="bodyClass" :sortable="true">
                            <template #body="slotProps">
                                <span>{{ dateHourFormat(slotProps.data.hour_last_measured_value) }}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column field="value_min" :header="$t('AppAlertsCoa.Min')" headerClass="headerClass" :showFilterMenu="false" :styles="{'min-width': '6rem', 'max-width': '9rem'}"
                            :showClearButton="false" bodyClass="bodyClass" :sortable="true">
                            <template #body="slotProps">
                                <span>{{ slotProps.data.value_min }}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column field="alert_value" :header="$t('AppAlertsCoa.Valor Alerta')" headerClass="headerClass" :showFilterMenu="false" :styles="{'min-width': '6rem', 'max-width': '9rem'}"
                            :showClearButton="false" bodyClass="bodyClass" :sortable="true">
                            <template #body="slotProps">
                                <span>{{ slotProps.data.alert_value }}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column field="value_max" :header="$t('AppAlertsCoa.Máx')" headerClass="headerClass" :showFilterMenu="false" :styles="{'min-width': '6rem', 'max-width': '9rem'}"
                            :showClearButton="false" bodyClass="bodyClass" :sortable="true">
                            <template #body="slotProps">
                                <span>{{ slotProps.data.value_max }}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column field="hour_last_alert_value" :header="$t('AppAlertsCoa.Data/Hora Último Alerta')" headerClass="headerClass" :styles="{'min-width': '6rem', 'max-width': '9rem'}"
                            :showFilterMenu="false" :showClearButton="false" bodyClass="bodyClass" :sortable="true">
                            <template #body="slotProps">
                                <span>{{ dateHourFormat(slotProps.data.hour_last_alert_value) }}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column field="hours_without_communication" :header="$t('AppAlertsCoa.Horas Sem Comunicação')" headerClass="headerClass" :styles="{'min-width': '6rem', 'max-width': '9rem'}"
                            :showFilterMenu="false" :showClearButton="false" bodyClass="bodyClass" :sortable="true">
                            <template #body="slotProps">
                                <span>{{ getTimeFromSecondsDurationFormatMixin(slotProps.data.hours_without_communication)
                                    }}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column field="status" :header="$t('AppAlertsCoa.Status')" headerClass="headerClass" :showFilterMenu="false" :styles="{'min-width': '6rem', 'max-width': '9rem'}"
                            :showClearButton="false" bodyClass="bodyClass" :sortable="true">
                            <template #body="slotProps">
                                <span :class="'badge status-' + slotProps.data.status">
                                    {{ getStatus(slotProps.data.status) }}
                                </span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <MultiSelect v-model="filterModel.value" @input="filterCallback()" :options="optionsStatus"
                                            :placeholder="$t('AppAlertsCoa.Selecione')" appendTo="tableDiv"
                                            class="p-column-filter width-multiselect" :showClear="true" optionLabel="name" optionValue="code">
                                    <template #value="slotProps">
                                        <span v-for="singleValue in slotProps.value" :class="'badge status-' + singleValue" style="margin-right: 5px;">
                                            {{ getStatus(singleValue) }}
                                        </span>
                                    </template>
                                    <template #option="slotProps">
                                        <span :class="'badge status-' + slotProps.option.code">
                                            {{ getStatus(slotProps.option.code) }}
                                        </span>
                                    </template>
                                </MultiSelect>
                            </template>
                        </Column>

                        <Column :header="$t('AppAlertsCoa.Mensagem do Problema')" headerClass="headerClass" bodyClass="bodyClass" :styles="{'min-width': '6rem'}" >
                            <template #body="slotProps">
                                <Button style="margin-right: 5px" @click="handleAnalyse(slotProps.data.message)"
                                    :label="$t('AppAlertsCoa.Analisar')" />
                            </template>
                        </Column>
                        <Column headerClass="headerClass" bodyClass="bodyClass" :styles="{'min-width': '4rem'}" >
                            <template #body="slotProps">
                                <Button icon="pi pi-pencil" class="p-button-rounded p-button-success"
                                    style="margin-right: 5px" @click="handleEdit(slotProps.data)" />
                            </template>
                        </Column>
                    </DataTable>
                </div>

                <Dialog :header="$t('AppAlertsCoa.Mensagem')" :visible.sync="viewMessage" :modal="true" :contentStyle="{ 'max-width': '90vw', 'width': 'auto' }">{{ textMessage }}</Dialog>

                <Dialog :header="$t('AppAlertsCoa.Alerta')" :visible.sync="alertDialog" :modal="true" :contentStyle="{ 'max-width': '90vw', 'width': '886px' }">
                    <div class="container-dialog">
                        <div class="container-dialog-first">
                            <div style="font-size: 16px; font-weight: 500; margin: 7.5px 0 7.5px 0;">{{$t('AppAlertsCoa.Ordem de Serviço')}}</div>

                            <div style="margin-bottom: 6px; font-size: 13px;">{{$t('AppAlertsCoa.Responsável')}}</div>
                            <InputText v-model="form.responsible" style="width: 100%; height: 38px; margin-bottom: 20px;" type="text"
                                :class="{'p-invalid': $v.form.responsible.$error && $v.form.responsible.$dirty}"/>

                            <div style="margin-bottom: 6px; font-size: 13px;">{{$t('AppAlertsCoa.Status')}}</div>
                            <Dropdown v-model="form.status" :options="optionsStatusCoa"
                                    style="width: 100%; height: 38px; margin-bottom: 20px;" :showClear="true" optionLabel="code" optionValue="code"
                                    :class="{'p-invalid': $v.form.status.$error && $v.form.status.$dirty}">
                                <template #value="slotProps">
                                    <span :class="'badge status-' + slotProps.value" style="margin-right: 5px;">
                                        {{ getStatus(slotProps.value) }}
                                    </span>
                                </template>
                                <template #option="slotProps">
                                    <span :class="'badge status-' + slotProps.option.code">
                                        {{ getStatus(slotProps.option.code) }}
                                    </span>
                                </template>
                            </Dropdown>
                            <section v-if="form.status && form.status === 'FINISHED'">
                                <div style="margin-bottom: 6px; font-size: 13px;">{{$t('AppAlertsCoa.Tratativa')}}</div>
                                <Dropdown v-model="form.handling" :options="optionsHandling" :class="{'p-invalid': !form.handling}"
                                        style="width: 100%; height: 38px; margin-bottom: 20px;" :showClear="true" optionLabel="name" optionValue="code" />
                            </section>

                            <div style="margin-bottom: 6px; font-size: 13px;">{{$t('AppAlertsCoa.Comentário')}}</div>
                            <Textarea v-model="form.comment" :autoResize="true" rows="5" style="width: 100%; margin-bottom: 11px;"
                                :class="{'p-invalid': $v.form.comment.$error && $v.form.comment.$dirty}" />

                            <app-button type="primary" @handleClick="saveServiceOrder"
                                :class="{ 'kt-spinner kt-spinner--v2 kt-spinner--right kt-spinner--sm kt-spinner--dark': loagindHandleStatus }">
                                <i class="pi pi-check" />
                                {{$t('AppAlertsCoa.Salvar')}}
                            </app-button>
                        </div>
                        <div class="container-dialog-second">
                            <div style="font-size: 16px; font-weight: 500; margin: 7.5px 0 7.5px 0;">{{$t('AppAlertsCoa.Mapa')}}</div>
                            <div v-if="lat && lng && !loadingVehiclePosition">
                                <AppAlertsCoaGoogleMaps :lat="lat" :lng="lng" :style="{height: getHeightMap}"
                                    style="width: 100%; border-radius: 5px; margin-bottom: 15px;" />

                                <app-button type="primary" @handleClick="copyLinkMaps"
                                    :class="{ 'kt-spinner kt-spinner--v2 kt-spinner--right kt-spinner--sm kt-spinner--dark': loagindHandleStatus }">
                                    <i class="pi pi-share-alt" />
                                    {{$t('AppAlertsCoa.Compartilhar')}}
                                </app-button>
                            </div>
                            <div v-else-if="!lat && !lng && loadingVehiclePosition" :style="{height: getHeightMap}" style="display: flex; flex-direction: column; justify-content: center">
                                <ProgressSpinner/>
                            </div>
                            <div v-else>
                                {{$t('AppAlertsCoa.Nada para apresentar')}}
                            </div>
                        </div>
                    </div>

                    <DataTable :value="gridDataHistoricalAlert" :paginator="true" :rows="4" :rowHover="true"
                        :loading="loadingHistoricalAlert" :sortOrder="-1" sortField="last_alert_time" stripedRows
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        responsiveLayout="scroll" style="margin-top: 15px;"
                        :currentPageReportTemplate="$t('AppAlertsCoa.Mostrando {first} até {last} de {totalRecords} Alertas')"
                        class="p-datatable-striped">

                        <template #header>
                            <span style="font-size: 16px; font-weight: 500;">{{$t('AppAlertsCoa.Histórico do alerta')}}</span>
                        </template>
                        <template #empty>
                            {{$t('AppAlertsCoa.Sem dados para exibir')}}
                        </template>

                        <Column field="user_name" :header="$t('AppAlertsCoa.Usuário')" headerClass="headerClass" bodyClass="bodyClass" :styles="{'min-width': '9rem'}"/>
                        <Column field="assignee" :header="$t('AppAlertsCoa.Responsável')" headerClass="headerClass" bodyClass="bodyClass" :styles="{'min-width': '9rem'}" />
                        <Column field="hour" :header="$t('AppAlertsCoa.Horário')" headerClass="headerClass" bodyClass="bodyClass" :styles="{'min-width': '9rem'}">
                            <template #body="slotProps">
                                <span>{{ dateHourFormat(slotProps.data.hour) }}</span>
                            </template>
                        </Column>
                        <Column field="status" :header="$t('AppAlertsCoa.Status')" headerClass="headerClass" bodyClass="bodyClass" :styles="{'min-width': '9rem'}">
                            <template #body="slotProps">
                                <span :class="'badge status-' + slotProps.data.status">
                                    {{ getStatus(slotProps.data.status) }}
                                </span>
                            </template>
                        </Column>
                        <Column field="comment" :header="$t('AppAlertsCoa.Comentário')" headerClass="headerClass" bodyClass="bodyClass" :styles="{'min-width': '9rem'}">
                            <template #body="slotProps">
                                <div v-if="slotProps.data.comment != null && slotProps.data.comment.length > 20" v-tooltip.top="`${slotProps.data.comment}`">
                                    {{ slotProps.data.comment.slice(0, 20) + ' ...' }}
                                </div>
                                <div v-else>
                                    {{ slotProps.data.comment }}
                                </div>
                            </template>
                        </Column>
                    </DataTable>
                </Dialog>
            </div>
        </div>
    </div>
</template>
<script>
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button/Button';
import Dialog from 'primevue/dialog/Dialog';
import AppButton from '../../../common/AppButton.vue';
import {STATUSES, STATUSES_COA, CRITICALY_LEVELS, HANDLINGS, ALERT_TYPE} from '../AppOptionsCoa';
import AppAlertsCoaGoogleMaps from './AppAlertsCoaGoogleMaps';
import OrganizationsService from "@/services/OrganizationsService";
import Textarea from 'primevue/textarea';
import Tooltip from 'primevue/tooltip';
import ProgressSpinner from 'primevue/progressspinner';
import AlertsCoaService from '../../../../services/AlertsCoaService';
import orderCustomFieldByNaturalOrder from "@/mixins/ArrayMixin";
import dateHourFormat from "@/mixins/DateMixin";
import getTimeFromSecondsDurationFormatMixin from "@/mixins/DateMixin";
import sendWhatsAppAlertServiceOrderMessage from "../../../../mixins/WhatsAppMixin"
import {required, sameAs} from 'vuelidate/lib/validators';
import * as SockJS from 'sockjs-client';
import {Client} from '@stomp/stompjs';
import {mapGetters} from "vuex";
import {FilterMatchMode} from 'primevue/api';
import {FilterService} from 'primevue/api';
const DATE_FILTER = 'DATE FILTER';
const TS_TO_HOURS = 'TS TO HOURS';

export default {
    mixins: [orderCustomFieldByNaturalOrder, dateHourFormat, getTimeFromSecondsDurationFormatMixin, sendWhatsAppAlertServiceOrderMessage],
    created() {
        this.organizationsService = new OrganizationsService();
        this.alertsCoaService = new AlertsCoaService();
    },
    mounted: function () {
        this.getOrganizations();
        this.stompjsClientSubscribeId = this.user.user_email;
        FilterService.register(DATE_FILTER, (value, filter) => {
            if (filter === undefined || filter === null || filter.trim() === '') {
                return true;
            }
            if (value === undefined || value === null) {
                return false;
            }
            return this.dateHourFormat(value).includes(filter);
        });
        FilterService.register(TS_TO_HOURS, (value, filter) => {
            if (filter === undefined || filter === null || filter.trim() === '') {
                return true;
            }
            if (value === undefined || value === null) {
                return false;
            }
            return this.getTimeFromSecondsDurationFormatMixin(value).includes(filter);
        });
    },
    beforeDestroy() {
        this.closeWebSocket();
    },
    data() {
        return {
            loagindHandleStatus: false,
            loading: false,
            organizationsService: null,
            alertsCoaService: null,
            loadingOrgs: false,
            selectedOrg: null,
            organizations: [],
            storedChosenOptions: {},
            gridData: [],
            alertDialog: false,
            textMessage: '',
            viewMessage: false,
            gridDataHistoricalAlert: [],
            loadingHistoricalAlert: false,
            loadingVehiclePosition: false,
            lat: null,
            lng: null,
            optionsFleet: [],
            optionsClass: [],
            optionsFront: [],
            optionsSensorName: [],
            optionsStatus: STATUSES,
            optionsStatusCoa: STATUSES_COA,
            optionsHandling: HANDLINGS,
            optionsCriticalityLevel: CRITICALY_LEVELS,
            optionsAlertType: ALERT_TYPE,
            form: {},
            itemToEdit: null,
            filters: {
                'criticality_level': {value: null, matchMode: FilterMatchMode.IN},
                'fleet': {value: null, matchMode: FilterMatchMode.IN},
                'class_name': {value: null, matchMode: FilterMatchMode.IN},
                'front': {value: null, matchMode: FilterMatchMode.IN},
                'sensor_name': {value: null, matchMode: FilterMatchMode.IN},
                'parametric_failures_type': {value: null, matchMode: FilterMatchMode.IN},
                'last_measured_value': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'hour_last_measured_value': {value: null, matchMode: DATE_FILTER},
                'value_min': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'alert_value': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'value_max': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'hour_last_alert_value': {value: null, matchMode: DATE_FILTER},
                'hours_without_communication': {value: null, matchMode: TS_TO_HOURS},
                'status': {value: null, matchMode: FilterMatchMode.IN}
            },
            stompjsClient: null,
            stompjsClientSubscribeId: null
        }
    },
    methods: {
        formatValue(value) {
            if (typeof value === "number") {
                let formatted = value.toFixed(2).replace(/\.00$/, '');
                return formatted.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            }
            return value;
        },
        getOrganizations() {
            let vm = this;
            this.loadingOrgs = true;
            this.organizationsService.getAllV3('VEHICLES').then((results) => {
                this.populateOrgsList(results);
                this.loadFilteredOptions();
            }).catch((error) => {
                vm.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppAlertsCoa.Operação falhou'),
                    detail: this.$t('AppAlertsCoa.Não foi possível buscar organizações'),
                    life: 5000
                });
                console.log(error)
            }).finally(() => {
                this.loadingOrgs = false;
            })
        },
        populateOrgsList(orgs) {
            this.organizations = this.orderCustomFieldByNaturalOrder(orgs, "name");
        },
        loadFilteredOptions() {
            this.storedChosenOptions = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_BAR_OPTIONS));

            if (!this.storedChosenOptions || !this.storedChosenOptions.clients || this.storedChosenOptions.clients.length === 0) {
                this.storedChosenOptions = {};
                return;
            }

            this.selectedOrg = this.storedChosenOptions['clients'].filter(a => this.organizations.some(b => a.id === b.id))[0];
        },
        saveChosenOptionsToLocalStorage() {
            this.storedChosenOptions['clients'] = [this.selectedOrg];
            this.storedChosenOptions['vehicles'] = [];
            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_BAR_OPTIONS, JSON.stringify(this.storedChosenOptions));
        },
        handleEdit(val) {
            this.alertDialog = true;
            this.$v.form.$reset();
            this.form = {}
            this.itemToEdit = val
            this.lat = val.latitude
            this.lng = val.longitude
            this.getStatusHistoricalAlert(val)
        },
        getStatusHistoricalAlert(val){
            this.loadingHistoricalAlert = true
            this.alertsCoaService.getStatusHistoricalAlert(this.selectedOrg.id, val.id).then((data) => {
                this.gridDataHistoricalAlert = data
            }).catch((error) => {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppAlertsCoa.Operação falhou'),
                    detail: this.$t('AppAlertsCoa.Não foi possível buscar os status'),
                    life: 5000
                });
                console.log(error)
            }).finally(() => {
                this.loadingHistoricalAlert = false;
            })
        },
        handleAnalyse(val) {
            this.textMessage = val
            this.viewMessage = true
        },
        getStatus(status) {
            let result = this.optionsStatus.find(({ code }) => code === status);
            if (result) {
                return result.name;
            }
        },
        getCriticalityLevel(status) {
            let result = this.optionsCriticalityLevel.find(({ code }) => code === status);
            if (result) {
                return result.name;
            }
        },
        getAlertType(alertType) {
            let result = this.optionsAlertType.find(({code}) => code === alertType);
            if (result) {
                return result.name;
            }
        },
        uniquePush(array, name) {
            if (name != null && name != undefined && array.length === 0) {
                array.push({ code: array.length, name });
                return
            }

            if ((name != null && name != undefined) && !array.some(item => item.name === name)) {
                array.push({ code: array.length, name });
            }
        },
        copyLinkMaps(){
            let item = this.gridDataHistoricalAlert[0];
            if(item === -1){
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppAlertsCoa.Por favor crie uma ordem de serviço'),
                    life: 5000
                });
                return
            }

            let form = {
                assignee: item.assignee,
                status: this.getStatus(item.status),
                fleet: this.itemToEdit.fleet,
                sensor_name: this.itemToEdit.sensor_name,
                comment: item.comment,
                hour_last_alert_value: this.itemToEdit.hour_last_alert_value,
                alert_value: this.itemToEdit.alert_value,
                lat: this.lat,
                lng: this.lng
            }
            this.sendWhatsAppAlertServiceOrderMessage(form)
        },
        saveServiceOrder(){
            this.$v.form.$touch();
            if ((!this.$v.form.$invalid && this.form.status != 'FINISHED') || (!this.$v.form.$invalid && this.form.status === 'FINISHED' && this.form.handling != undefined)) {
                let data = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE));
                let params = {
                    status: this.form.status,
                    handling: this.form.handling ? this.form.handling : null,
                    comment: this.form.comment,
                    assignee: this.form.responsible,
                    user_name: data.name,
                    user_id: data.id
                };
                this.alertsCoaService.postStatusHistoricalAlert(this.selectedOrg.id, this.itemToEdit.id, params).then((data) => {
                    this.alertDialog = false
                    this.$toast.add({
                        severity: 'success',
                        summary: this.$t('AppAlertsCoa.Salvo com sucesso'),
                        life: 5000
                    });
                    this.closeWebSocket()
                    this.getAlertsCoa(this.selectedOrg)
                }).catch((error) => {
                    this.gridData = []
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppAlertsCoa.Operação falhou'),
                        life: 5000
                    });
                    console.log(error)
                })
            } else {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppAlertsCoa.Por favor preencha todos os campos obrigatórios'),
                    life: 5000
                });
            }
        },
        getAlertsCoa(val){
            this.loading = true
            this.alertsCoaService.getAlertsCoa(val.id).then((data) => {
                data.forEach(element => {
                    this.uniquePush(this.optionsFleet, element.fleet);
                    this.uniquePush(this.optionsClass, element.class_name);
                    this.uniquePush(this.optionsFront, element.front);
                    this.uniquePush(this.optionsSensorName, element.sensor_name);
                    element.alert_value = this.formatValue(element.alert_value) + ' ' + element.unit
                    element.last_measured_value = this.formatValue(element.last_measured_value) + ' ' + element.unit
                });
                this.gridData = data
                this.connectWebSocket(val.id);
            }).catch((error) => {
                this.gridData = []
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppAlertsCoa.Operação falhou'),
                    detail: this.$t('AppAlertsCoa.Não foi possível buscar os alertas'),
                    life: 5000
                });
                console.log(error)
            }).finally(() => {
                this.loading = false;
            })
        },
        connectWebSocket(orgId) {
            let vm = this;
            this.stompjsClient = new Client({
                reconnectDelay: 10000,
                heartbeatIncoming: 4000,
                heartbeatOutgoing: 4000,
            });

            this.stompjsClient.webSocketFactory = function () {
                return new SockJS(process.env.VUE_APP_BACKEND_ADDRESS + '/parametric-failures-service-ws/?access_token=' + vm.user.access_token)
            };

            this.stompjsClient.onConnect = function (frame) {
                vm.stompjsClient.subscribe(`/topic/alerts/${orgId}`, function (val) {
                    let receivedAlert = JSON.parse(val.body)
                    receivedAlert = vm.transformKeys(receivedAlert);

                    if(receivedAlert.hour_alert_end != null){
                        let updatedGridData = vm.gridData.filter(element => element.id !== receivedAlert.id);
                        vm.gridData = [...updatedGridData];
                        return
                    }

                    vm.uniquePush(vm.optionsFleet, receivedAlert.fleet);
                    vm.uniquePush(vm.optionsClass, receivedAlert.class_name);
                    vm.uniquePush(vm.optionsFront, receivedAlert.front);
                    vm.uniquePush(vm.optionsSensorName, receivedAlert.sensor_name);
                    receivedAlert.alert_value = vm.formatValue(receivedAlert.alert_value) + ' ' + receivedAlert.unit
                    receivedAlert.last_measured_value = vm.formatValue(receivedAlert.last_measured_value) + ' ' + receivedAlert.unit

                    let updatedGridData = vm.gridData.map(element =>
                        element.id === receivedAlert.id ? receivedAlert : element
                    );

                    if (!updatedGridData.some(element => element.id === receivedAlert.id)) {
                        updatedGridData.push(receivedAlert);
                    }

                    vm.gridData = [...updatedGridData];
                }, { id: vm.stompjsClientSubscribeId });
            };

            this.stompjsClient.activate();
        },
        closeWebSocket() {
            if (this.stompjsClient) {
                this.stompjsClient.deactivate();
                this.stompjsClient = null;
            }
        },
        transformKeys(obj) {
            if (Array.isArray(obj)) {
                return obj.map(item => this.transformKeys(item));
            } else if (typeof obj === 'object' && obj !== null) {
                return Object.keys(obj).reduce((acc, key) => {
                    const newKey = key.replace(/([A-Z])/g, '_$1').toLowerCase();
                    acc[newKey] = this.transformKeys(obj[key]);
                    return acc;
                }, {});
            }
            return obj;
        }
    },
    watch: {
        selectedOrg: function (val) {
            this.closeWebSocket()
            this.optionsFleet = []
            this.optionsClass = []
            this.optionsFront = []
            this.optionsSensorName = []
            this.getAlertsCoa(val)
        },
    },
    computed: {
        getHeightMap() {
            if (this.form.status && this.form.status === 'FINISHED') {
                return '397px';
            }
            return '313px';
        },
        ...mapGetters([
            'user'
        ])
    },
    validations: {
        form: {
            responsible: {required},
            status: {required},
            comment: {required}
        }
    },
    components: {
        Dropdown, MultiSelect, DataTable, Column, InputText, Button, Dialog,
        AppButton, Textarea, Tooltip, AppAlertsCoaGoogleMaps, ProgressSpinner
    },
    directives: {
        tooltip: Tooltip
    }
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/primevue";
@import "src/components/views/alerts-coa/style";

.container-dialog {
    display: flex;
    width: 100%;
}

.container-dialog-first {
    width: 60%;
    padding: 0 15px 7.5px 0;
    border-right: 1px solid #D2D2D2;
}

.container-dialog-second {
    width: 40%;
    padding: 0 0 7.5px 15px;
}

@media (max-width: 1024px){
    .data-table-width{
        width: 100vw;
    }
}

@media (max-width: 768px){
    .container-dialog {
        display: block;
    }

    .container-dialog-first {
        width: 100%;
        padding: 0 0 20px 0;
        border: none;
        border-bottom: 1px solid #D2D2D2;
    }

    .container-dialog-second {
        width: 100%;
        padding: 5px 0 15px 0;
        border-bottom: 1px solid #D2D2D2;
    }
}

@media (min-width: 1025px){
    .data-table-width{
        max-width: calc(100vw - 140px);
    }
}
</style>
