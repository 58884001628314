<template>
    <transition name="container-transitions" mode="out-in">
        <div v-if="show">
            <AppTranslator style="position: fixed; right: 20px; top: 15px"/>
            <div class="background kt-grid kt-grid--ver kt-grid--root kt-page">
                <div class="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v4 kt-login--signin" id="kt_login">
                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <div class="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
                            <div class="kt-login__container card-container">
                                <div class="kt-login__logo">
                                    <a href="#">
                                        <img :src="loginImage" class="img-fluid" style="max-height: 182px" alt="logo"
                                             color="black">
                                    </a>
                                </div>
                                <div class="kt-login__signin">
                                    <form class="kt-form" v-on:submit.prevent>
                                        <div class="input-group">
                                            <app-input class="form-control" type="text"
                                                       :placeholder="$t('AppLogin.Email')"
                                                       v-model="email"></app-input>
                                        </div>
                                        <div class="input-group">
                                            <app-input class="form-control" type="password"
                                                       :placeholder="$t('AppLogin.Senha')"
                                                       v-model="password"></app-input>
                                        </div>
                                        <div class="row mt-2">
                                            <div class="col kt-align-right">
                                                <a href="#" @click="goToForgotPassword" id="kt_login_forgot"
                                                   style="font-size: 16px"
                                                   class="kt-login__link">{{ $t('AppLogin.Esqueceu a senha?') }}</a>
                                            </div>
                                        </div>
                                        <div class="kt-login__actions text-center">
                                            <app-button type="primary" size="lg"
                                                        style="width: 100%"
                                                        :class="spinnerClass"
                                                        :disabled="loginLoading || !localStorageAvailable"
                                                        @handleClick="validateForm"> {{ $t('AppLogin.Entrar') }}
                                            </app-button>
                                        </div>
                                        <app-button type="primary" size="lg" class="mt-2"
                                                    style="float: left; width: 100%"
                                                    @handleClick="doLogin">
                                            {{ $t('AppLogin.Login com Azure AD') }} <img class="ml-4"
                                                                                         src="@/assets/images/auth/mssymbol.svg"
                                                                                         alt="MS logo">
                                        </app-button>

                                        <br><br><br><br>
                                        <div class="aceite-termos">
                                            {{ $t('AppLogin.Ao efetuar o login você aceita os') }}
                                            <br>
                                            <a :href="termsOfUse" target="_blank">{{ $t('AppLogin.Termos de Uso') }}</a>
                                            <span>  {{ $t('AppLogin.e') }} </span>
                                            <a :href="privacyPolicy"
                                               target="_blank">{{ $t('AppLogin.Política de Privacidade') }}</a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog header=" " :visible.sync="openModal" :style="{width: width_dialog}" :modal="true"
                    style="text-align: center">
                <span class="pi pi-exclamation-triangle" style="font-size: 7em"></span>
                <br><br>
                <p style="text-align: center; font-size: 1.3em">
                    {{ modalText }}
                </p>
                <template #footer>
                    <Button :label="$t('AppLogin.Fechar')" @click="closeModal" autofocus/>
                </template>
            </Dialog>

            <div style="opacity: 0.1; text-align: center">
                Version: {{ commit }}
            </div>

            <div style="width: 200px;position:absolute; bottom:10px; right:10px;">
                <img :src="getPoweredByLogo()" class="img-fluid">
            </div>
        </div>
    </transition>
</template>
<script>
import AppButton from '../../common/AppButton.vue'
import AppInput from '../../common/AppInput';
import AppTranslator from '../../common/AppTranslator';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';

import {router} from '../../../router';
import axios_global from "axios";

import auth from '../../../services/auth';

import jwt_decode from "jwt-decode";
import UsersService from "@/services/UsersService"

export default {
    created() {
        // this.$store.dispatch('logout');
        this.checkIfLocalStorageIsAvailable();
        this.usersService = new UsersService();
    },
    mounted() {
        this.fade();
        if (this.$route.params.confirmed) {
            if (this.$route.params.confirmed === "account-confirmed") {
                this.$toast.add({
                    severity: 'success',
                    summary: this.$t('AppLogin.Conta confirmada com sucesso'),
                    life: 5000
                });
            } else {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppLogin.Não foi possível confirmar a sua conta'),
                    life: 5000
                });
            }
        }
        this.width_dialog = this.isScreenMobile ? '80vw' : '30vw';
    },
    beforeDestroy() {
        this.fade();
    },
    data() {
        return {
            width_dialog: null,
            openModal: false,
            modalText: false,
            commit: process.env.VUE_APP_GIT_HASH,
            themeActive: process.env.VUE_APP_THEME_ACTIVE,
            loginImage: require("../../../assets/images/brands/" + process.env.VUE_APP_THEME_IMAGE_LOGIN + ".png"),
            show: false,
            email: '',
            password: '',
            localStorageAvailable: true,
            loginLoading: false,
            error: '',
            user: {},
            accessToken: {},
            usersService: null
        }
    },
    watch: {
        // If user changes, make calls to Graph API
        user: async function () {
            this.fetchGraphDetails()
        }
    },
    methods: {
        // Get an access token and call graphGetSelf & graphGetPhoto
        async fetchGraphDetails() {
            if (!this.user) {
                return
            }

            let vm = this;

            try {
                auth.acquireToken().then(function (result) {
                    vm.getUserData(result);
                });
            } catch (err) {
                this.error = err
            }
        },
        async doLogin() {
            try {
                await auth.login()
                this.user = auth.user();
            } catch (err) {
                this.error = err.toString()
            }
        },
        fade() {
            this.show = !this.show;
        },
        validateForm() {
            if (this.email && this.password) {
                this.login();
            } else {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppLogin.Informe o Email e Senha'),
                    life: 5000
                });
            }
        },
        getUserData(tokens) {
            this.loginLoading = true;

            let decodedJWT = jwt_decode(tokens.idToken);

            let expiresIn = decodedJWT.exp - decodedJWT.nbf;

            axios_global.post(process.env.VUE_APP_BACKEND_ADDRESS + '/auth/v1/validate-sso', {
                id_token: tokens.idToken
            }).then((response) => {
                    if (response.status === 200) {
                        this.storeUser(response.data);
                        this.$store.dispatch('storeSSOLogin')
                    } else {
                        this.loginLoading = false;
                        this.showModal(this.$t('AppLogin.Não foi possível efetuar o login'))
                    }
                }
            ).catch((error) => {
                console.log(error)
                this.loginLoading = false;
                if (error.response && error.response.data) {
                    if (error.response.data.error) {
                        if (error.response.data.error === "invalid_grant") {
                            this.showModal(this.$t('AppLogin.Email ou senha incorretos'))
                        } else {
                            this.showModal(this.$t('AppLogin.Não foi possível efetuar o login'))
                        }
                    } else {
                        this.showModal(error.response.data.details)
                    }
                } else {
                    this.showModal(this.$t('AppLogin.Não foi possível efetuar o login'))
                }
            })

        },
        login() {
            let data = {
                username: this.email,
                password: this.password
            };

            let vm = this;

            //Não é SSO. Fix para token expirado via SSO e posteriormente login normal
            localStorage.removeItem(process.env.VUE_APP_SSO_LOGIN);

            vm.loginLoading = true;
            axios_global.post(process.env.VUE_APP_BACKEND_ADDRESS + '/auth/v1/authenticate', data, {}).then(function (response) {
                    if (response.status === 200) {
                        response.data.expiration = Math.floor(Date.now() / 1000) + response.data.expires_in;
                        vm.storeUser(response.data);
                    } else {
                        vm.showModal(this.$t('AppLogin.Não foi possível efetuar o login'))
                        vm.loginLoading = false;
                    }
                }
            ).catch(function (error) {
                vm.loginLoading = false;
                if (error.response && error.response.data) {
                    if (error.response.data.error) {
                        if (error.response.data.error === "invalid_grant") {
                            vm.showModal(this.$t('AppLogin.Email ou senha incorretos'))
                        } else {
                            vm.showModal(this.$t('AppLogin.Não foi possível efetuar o login'))
                        }
                    } else {
                        vm.showModal(error.response.data.details)
                    }
                } else {
                    vm.showModal(this.$t('AppLogin.Não foi possível efetuar o login'))
                }
            })
        },
        storeUser(data) {
            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE, JSON.stringify(data));
            this.$store.dispatch('storeUser', data);
            this.usersService.getOrgFeatureFlags().then((response) => {
                this.$store.dispatch('storeOrganizationFeatureFlags', response);
                router.push({name: 'home'});
            }).catch((error) => {
                this.showModal(this.$t('AppLogin.Não foi possível efetuar o login'));
                this.$store.dispatch('logout');
            }).finally(() => {
                this.loginLoading = false;
            })
        },
        goToForgotPassword() {
            router.push({name: 'forgot-password'});
        },
        checkIfLocalStorageIsAvailable() {
            var test = 'test';
            try {
                localStorage.setItem(test, test);
                localStorage.removeItem(test);
            } catch (e) {
                //se o browser n suporta localStorage desabilita o login
                this.localStorageAvailable = false;
            }
        },
        showModal(message) {
            this.modalText = message;
            this.openModal = true;
        },

        closeModal() {
            this.openModal = false;
            this.modalText = "";
        },
        getPoweredByLogo() {
            if (process.env.VUE_APP_THEME_ACTIVE === 'ORION') {
                return require("../../../assets/images/brands/poweredByIotag.png")
            }
            return "";
        }
    },
    components: {
        AppButton, AppInput, Button, Dialog, AppTranslator
    },
    computed: {
        spinnerClass: function () {
            return this.loginLoading ?
                'kt-spinner kt-spinner--v2 kt-spinner--right kt-spinner--sm kt-spinner--dark' : ''
        },
        isScreenMobile: function () {
            return window.screen.width <= 1024;
        },
        termsOfUse: function () {
            if (this.$i18n.locale === 'pt') {
                return 'https://iotag.com.br/termos/'
            }

            if (this.$i18n.locale === 'es') {
                return 'https://iotag.com.br/terminos/'
            }

            if (this.$i18n.locale === 'en') {
                return 'https://iotag.com.br/terms/'
            }

            return 'https://iotag.com.br/termos/'
        },
        privacyPolicy: function () {
            if (this.$i18n.locale === 'pt') {
                return 'https://iotag.com.br/privacidade/'
            }

            if (this.$i18n.locale === 'es') {
                return 'https://iotag.com.br/privacidad/'
            }

            if (this.$i18n.locale === 'en') {
                return 'https://iotag.com.br/privacy/'
            }

            return 'https://iotag.com.br/privacidade/'
        },
    }
}
</script>

<style scoped>
@import "../../../assets/styles/login-4.css";

.container-transitions-enter-active {
    transition-duration: 2.1s !important;
    transition-property: opacity !important;
    transition-timing-function: ease !important;
}

.container-transitions-enter, .container-transitions-leave-active {
    opacity: 0 !important;
}

.margin-buttons {
    margin-right: 5px;
}

#kt_login_forgot {
    font-size: 1.50rem;
    color: #3b3b3b;
}

#kt_login_signup {
    font-size: 1.50rem;
    color: #3b3b3b;
}

.aceite-termos {
    text-align: center;
    color: #343434;
    font-size: 1.10rem;
}

.aceite-termos a {
    color: #00aff0;
    text-decoration: underline;
}

.card-container {
    background-color: white;
    border-radius: 10px;
    padding: 15px;
    border: 1px solid black;
    box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, .4);
}

</style>
