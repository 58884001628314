import i18n from '../../../i18n';// @formatter:off

export const CROPS_LIST = [
    {name: i18n.t('AppAgricultureV2Options.Soja'), src: require("../../../assets/icons/icone-soy.svg")},
    {name: i18n.t('AppAgricultureV2Options.Milho'), src: require("../../../assets/icons/icone-corn.svg")},
    {name: i18n.t('AppAgricultureV2Options.Trigo'), src: require("../../../assets/icons/icone-wheat.svg")},
    {name: i18n.t('AppAgricultureV2Options.Feijão'), src: require("../../../assets/icons/icone-beans.svg")},
    {name: i18n.t('AppAgricultureV2Options.Arroz'), src: require("../../../assets/icons/icone-rice.svg")},
    {name: i18n.t('AppAgricultureV2Options.Cevada'), src: require("../../../assets/icons/icone-barley.svg")},
    {name: i18n.t('AppAgricultureV2Options.Algodão'), src: require("../../../assets/icons/icone-cottom.svg")},
    {name: i18n.t('AppAgricultureV2Options.Mandioca'), src: require("../../../assets/icons/icone-cassava.svg")},
    {name: i18n.t('AppAgricultureV2Options.Cana'), src: require("../../../assets/icons/icone-sugarcane.svg")},
    {name: i18n.t('AppAgricultureV2Options.Laranja'), src: require("../../../assets/icons/icone-orange.svg")},
    {name: i18n.t('AppAgricultureV2Options.Floresta'), src: require("../../../assets/icons/icone-tree.svg")},
    {name: i18n.t('AppAgricultureV2Options.Café'), src: require("../../../assets/icons/icone-coffee-beans.svg")},
];

export const OPERATIONS_LIST = [
    {name: 'SEEDING', value: 'Seeding', src: require("../../../assets/icons/icone-seeding.svg"), label: i18n.t('AppAgricultureV2Options.Plantio')},
    {name: 'HARVEST', value: 'Harvest', src: require("../../../assets/icons/icone-harvest.svg"), label: i18n.t('AppAgricultureV2Options.Colheita')},
    {name: 'MOWING', value: 'Mowing', src: require("../../../assets/icons/icone-fertilizing.svg"), label: i18n.t('AppAgricultureV2Options.Roçagem')},
    {name: 'TILLAGE', value: 'Tillage', src: require("../../../assets/icons/icone-soil-preparation.svg"), label: i18n.t('AppAgricultureV2Options.Preparo de Solo')},
    {name: 'DRY_APPLICATION', value: 'Application', src: require("../../../assets/icons/icone-fungicide.svg"), label: i18n.t('AppAgricultureV2Options.Aplicação')},
    {name: 'LIQUID_APPLICATION', value: 'Application', src: require("../../../assets/icons/icone-fungicide.svg"), label: i18n.t('AppAgricultureV2Options.Aplicação')}
];


export const MAP_TYPES = Object.freeze(
    {
        TILLAGE: [
            {title: i18n.t('AppAgricultureV2Options.Profundidade'), value: 'actualDepth', symbol: 'cm', ddi: 52, grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Profundidade alvo'), value: 'targetDepth', symbol: 'cm', ddi: 51, grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Velocidade'), value: 'speed', symbol: 'km/h', ddi: 397, grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Rotação'), value: 'engine_speed', symbol: 'RPM', ddi: 484, grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Altitude'), value: 'altitude', symbol: 'm', ddi: 'altitude', grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Veículo'), value: 'vehicle', symbol: '', ddi: 'vehicle', grouped: true},
            {title: i18n.t('AppAgricultureV2Options.Operador'), value: 'operator', symbol: '', ddi: 'operator', grouped: true},
            {title: i18n.t('AppAgricultureV2Options.Dia'), value: 'date', symbol: '', ddi: 'date', grouped: true},
            {title: i18n.t('AppAgricultureV2Options.Combustível por área'), value: 'fuelRateArea', symbol: 'L/ha', ddi: 150, grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Combustível por tempo'), value: 'fuelRateTime', symbol: 'L/h', ddi: 149, grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Sobreposição'), value: 'overlap', symbol: '', ddi: 0, grouped: false}
        ],
        MOWING: [
            {title: i18n.t('AppAgricultureV2Options.Velocidade'), value: 'speed', symbol: 'km/h', ddi: 397, grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Rotação'), value: 'engine_speed', symbol: 'RPM', ddi: 484, grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Altitude'), value: 'altitude', symbol: 'm', ddi: 'altitude', grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Veículo'), value: 'vehicle', symbol: '', ddi: 'vehicle', grouped: true},
            {title: i18n.t('AppAgricultureV2Options.Operador'), value: 'operator', symbol: '', ddi: 'operator', grouped: true},
            {title: i18n.t('AppAgricultureV2Options.Dia'), value: 'date', symbol: '', ddi: 'date', grouped: true},
            {title: i18n.t('AppAgricultureV2Options.Combustível por área'), value: 'fuelRateArea', symbol: 'L/ha', ddi: 150, grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Combustível por tempo'), value: 'fuelRateTime', symbol: 'L/h', ddi: 149, grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Sobreposição'), value: 'overlap', symbol: '', ddi: 0, grouped: false}
        ],
        SEEDING: [
            {title: i18n.t('AppAgricultureV2Options.Taxa de Plantio'), value: 'actualRate', symbol: 'sementes/ha', ddi: 12, grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Taxa alvo'), value: 'targetRate', symbol: 'sementes/ha', ddi: 11, grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Velocidade'), value: 'speed', symbol: 'km/h', ddi: 397, grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Rotação'), value: 'engine_speed', symbol: 'RPM', ddi: 484, grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Altitude'), value: 'altitude', symbol: 'm', ddi: 'altitude', grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Veículo'), value: 'vehicle', symbol: '', ddi: 'vehicle', grouped: true},
            {title: i18n.t('AppAgricultureV2Options.Operador'), value: 'operator', symbol: '', ddi: 'operator', grouped: true},
            {title: i18n.t('AppAgricultureV2Options.Dia'), value: 'date', symbol: '', ddi: 'date', grouped: true},
            {title: i18n.t('AppAgricultureV2Options.Combustível por área'), value: 'fuelRateArea', symbol: 'L/ha', ddi: 150, grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Combustível por tempo'), value: 'fuelRateTime', symbol: 'L/h', ddi: 149, grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Sobreposição'), value: 'overlap', symbol: '', ddi: 0, grouped: false}

        ],
        DRY_APPLICATION: [
            {title: i18n.t('AppAgricultureV2Options.Taxa de Aplicação'), value: 'actualRate', symbol: 't/ha', ddi: 7, grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Taxa alvo'), value: 'targetRate', symbol: 't/ha', ddi: 6, grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Pressão'), value: 'actualPressure', symbol: 'kPa', ddi: 194, grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Pressão alvo'), value: 'targetPressure', symbol: 'kPa', ddi: 193, grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Velocidade'), value: 'speed', symbol: 'km/h', ddi: 397, grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Rotação'), value: 'engine_speed', symbol: 'RPM', ddi: 484, grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Altitude'), value: 'altitude', symbol: 'm', ddi: 'altitude', grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Veículo'), value: 'vehicle', symbol: '', ddi: 'vehicle', grouped: true},
            {title: i18n.t('AppAgricultureV2Options.Operador'), value: 'operator', symbol: '', ddi: 'operator', grouped: true},
            {title: i18n.t('AppAgricultureV2Options.Dia'), value: 'date', symbol: '', ddi: 'date', grouped: true},
            {title: i18n.t('AppAgricultureV2Options.Combustível por área'), value: 'fuelRateArea', symbol: 'L/ha', ddi: 150, grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Combustível por tempo'), value: 'fuelRateTime', symbol: 'L/h', ddi: 149, grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Sobreposição'), value: 'overlap', symbol: '', ddi: 0, grouped: false}
        ],
        LIQUID_APPLICATION: [
            {title: i18n.t('AppAgricultureV2Options.Taxa de Aplicação'), value: 'actualRate', symbol: 'L/ha', ddi: 2, grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Taxa alvo'), value: 'targetRate', symbol: 'L/ha', ddi: 1, grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Pressão'), value: 'actualPressure', symbol: 'kPa', ddi: 194, grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Pressão alvo'), value: 'targetPressure', symbol: 'kPa', ddi: 193, grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Velocidade'), value: 'speed', symbol: 'km/h', ddi: 397, grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Rotação'), value: 'engine_speed', symbol: 'RPM', ddi: 484, grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Altitude'), value: 'altitude', symbol: 'm', ddi: 'altitude', grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Veículo'), value: 'vehicle', symbol: '', ddi: 'vehicle', grouped: true},
            {title: i18n.t('AppAgricultureV2Options.Operador'), value: 'operator', symbol: '', ddi: 'operator', grouped: true},
            {title: i18n.t('AppAgricultureV2Options.Dia'), value: 'date', symbol: '', ddi: 'date', grouped: true},
            {title: i18n.t('AppAgricultureV2Options.Combustível por área'), value: 'fuelRateArea', symbol: 'L/ha', ddi: 150, grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Combustível por tempo'), value: 'fuelRateTime', symbol: 'L/h', ddi: 149, grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Sobreposição'), value: 'overlap', symbol: '', ddi: 0, grouped: false}
        ],
        HARVEST: [
            {title: i18n.t('AppAgricultureV2Options.Produtividade'), value: 'yield', symbol: 't/ha', ddi: 84, grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Redimento (Seco)'), value: 'dryThroughput', symbol: 't/h', ddi: 181, grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Taxa de Colheita'), value: 'wetThroughput', symbol: 't/h', ddi: 0, grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Rendimento bruto'), value: 'wetWeight', symbol: 't/ha', ddi: 0, grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Velocidade'), value: 'speed', symbol: 'km/h', ddi: 397, grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Rotação'), value: 'engine_speed', symbol: 'RPM', ddi: 484, grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Altitude'), value: 'altitude', symbol: 'm', ddi: 'altitude', grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Veículo'), value: 'vehicle', symbol: '', ddi: 'vehicle', grouped: true},
            {title: i18n.t('AppAgricultureV2Options.Operador'), value: 'operator', symbol: '', ddi: 'operator', grouped: true},
            {title: i18n.t('AppAgricultureV2Options.Dia'), value: 'date', symbol: '', ddi: 'date', grouped: true},
            {title: i18n.t('AppAgricultureV2Options.Combustível por área'), value: 'fuelRateArea', symbol: 'L/ha', ddi: 150, grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Combustível por tempo'), value: 'fuelRateTime', symbol: 'L/h', ddi: 149, grouped: false},
            {title: i18n.t('AppAgricultureV2Options.Sobreposição'), value: 'overlap', symbol: '', ddi: 0, grouped: false}
        ]
    });


// @formatter:on
