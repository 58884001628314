<template>
    <div v-click-outside="closeOptions">
        <img src="@/assets/images/maps-type-control/maps-control.png" alt="" @click="showControls = !showControls"
             class="btn-control">
        <div>
            <transition name="fade">
                <div id="controls" v-show="showControls" style="max-height: 350px" class="map-type-control-body">
                    <perfect-scrollbar id="container">
                        <div class="container-fluid">
                            <div class="row header">
                                {{ $t('AppGoogleMapsTypeControl.Tipo de Mapa') }}
                            </div>
                            <div class="row line justify-content-md-center">
                                <div class="item " @click="emitClick('roadmap')">
                                    <img src="@/assets/images/maps-type-control/default.png" alt=""
                                         :class="{ 'menu-item-on': roadmapOn }">
                                    <div> {{ $t('AppGoogleMapsTypeControl.Padrão') }}</div>
                                </div>
                                <div class="item" @click="emitClick('hybrid')">
                                    <img src="@/assets/images/maps-type-control/satellite.png" alt=""
                                         :class="{ 'menu-item-on': hybridOn }">
                                    <div> {{ $t('AppGoogleMapsTypeControl.Satélite') }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="container-fluid" v-if="show_first_row">
                            <div class="row header ">{{ $t('AppGoogleMapsTypeControl.Detalhes do Mapa') }}</div>
                            <div class="row line justify-content-md-center" v-if="show_work_details">
                                <div class="item" @click="emitClick('track')">
                                    <img src="@/assets/images/maps-type-control/track.png" alt=""
                                         :class="{ 'menu-item-on': trackOn }">
                                    <div>{{ $t('AppGoogleMapsTypeControl.Traçado') }}</div>
                                </div>
                                <div class="item" @click="emitClick('application')">
                                    <img src="@/assets/images/maps-type-control/application.png" alt=""
                                         :class="{ 'menu-item-on': applicationOn }">
                                    <div>{{ $t('AppGoogleMapsTypeControl.Aplicação') }}</div>
                                </div>
                            </div>
                            <div class="row line justify-content-md-center" v-if="show_work_details">
                                <div class="item" @click="emitClick('speed')">
                                    <img src="@/assets/images/maps-type-control/speed.png" alt=""
                                         :class="{ 'menu-item-on': speedOn }">
                                    <div>{{ $t('AppGoogleMapsTypeControl.Velocidade') }}</div>
                                </div>
                                <div class="item" @click="emitClick('humidity')">
                                    <img src="@/assets/images/maps-type-control/humidity.png" alt=""
                                         :class="{ 'menu-item-on': humidityOn }">
                                    <div>{{ $t('AppGoogleMapsTypeControl.Umidade') }}</div>
                                </div>
                            </div>
                            <div class="row line">
                                <div class="item" @click="emitClick('fields')">
                                    <img src="@/assets/images/maps-type-control/fields.png" alt=""
                                         :class="{ 'menu-item-on': fieldsOn }">
                                    <div>{{ $t('AppGoogleMapsTypeControl.Locais') }}</div>
                                </div>
                                <div class="item" @click="emitClick('noTrack')" v-if="show_work_details">
                                    <img src="@/assets/images/maps-type-control/noTrack.png" alt=""
                                         :class="{ 'menu-item-on': noTrackOn }">
                                    <div>{{ $t('AppGoogleMapsTypeControl.Sem Rastreio') }}</div>
                                </div>
                            </div>
                            <div class="row line" v-if="show_work_details && showConnectivity">
                                <div class="item" @click="emitClick('connectivity')">
                                    <img src="@/assets/images/maps-type-control/connectivity.png" alt=""
                                         :class="{ 'menu-item-on': connectivityOn }">
                                    <div>{{ $t('AppGoogleMapsTypeControl.Conexão') }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="container-fluid" v-if="show_first_row && show_work_details">
                            <div class="row header">{{ $t('AppGoogleMapsTypeControl.Alertas') }}</div>
                            <div class="row line justify-content-md-center">
                                <div class="item" @click="emitClick('operationalAlerts')">
                                    <img src="@/assets/images/maps-type-control/operational-alert.png" alt=""
                                         :class="{ 'menu-item-on': operationalAlertsOn }">
                                    <div>{{ $t('AppGoogleMapsTypeControl.Operacionais') }}</div>
                                </div>
                                <div class="item" @click="emitClick('machineAlerts')">
                                    <img src="@/assets/images/maps-type-control/fleet-alert.png" alt=""
                                         :class="{ 'menu-item-on': machineAlertsOn }">
                                    <div>{{ $t('AppGoogleMapsTypeControl.Máquina') }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="container-fluid">
                            <div class="row header ">{{ $t('AppGoogleMapsTypeControl.Detalhes Adicionais') }}</div>
                            <div class="row line justify-content-md-center">
                                <div class="item" @click="emitClick(MAPS_OVERLAY_WEATHER_TYPES.PRECIPITATION)">
                                    <img src="@/assets/images/maps-type-control/precipitation.png" alt=""
                                         :class="{ 'menu-item-on': precipitationOn }">
                                    <div>{{ $t('AppGoogleMapsTypeControl.Precipitação') }}</div>
                                </div>
                                <div class="item" @click="emitClick(MAPS_OVERLAY_WEATHER_TYPES.TEMPERATURE)"
                                     v-if="isGeolocationSupported">
                                    <img src="@/assets/images/maps-type-control/temperature.png" alt=""
                                         :class="{ 'menu-item-on': temperatureOn }">
                                    <div>{{ $t('AppGoogleMapsTypeControl.Temperatura') }}</div>
                                </div>
                            </div>
                            <div class="row line ">
                                <div class="item" @click="emitClick(MAPS_OVERLAY_WEATHER_TYPES.CLOUDS)">
                                    <img src="@/assets/images/maps-type-control/clouds.png" alt=""
                                         :class="{ 'menu-item-on': cloudsOn }">
                                    <div>{{ $t('AppGoogleMapsTypeControl.Nuvens') }}</div>
                                </div>
                                <div class="item" @click="emitClick('location')" v-if="isGeolocationSupported">
                                    <img src="@/assets/images/maps-type-control/location.png" alt=""
                                         :class="{ 'menu-item-on': locationOn }">
                                    <div>{{ $t('AppGoogleMapsTypeControl.Local') }}</div>
                                </div>
                            </div>
                        </div>
                    </perfect-scrollbar>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>

import {MAPS_OVERLAY_WORK_TYPES} from "../views/real-time/AppOptions";
import {mapGetters} from "vuex";

export default {
    data() {
        return {
            MAPS_OVERLAY_WEATHER_TYPES: {
                PRECIPITATION: "precipitation",
                CLOUDS: "clouds",
                TEMPERATURE: "temperature",
                REMOVE: 'remove'
            },
            showControls: false,
            roadmapOn: false,
            hybridOn: true,
            fieldsOn: false,
            precipitationOn: false,
            temperatureOn: false,
            cloudsOn: false,
            locationOn: false,
            trackOn: false,
            applicationOn: false,
            operationalAlertsOn: false,
            machineAlertsOn: false,
            speedOn: false,
            humidityOn: false,
            noTrackOn: false,
            connectivityOn: false
        }
    },
    props: {
        show_first_row: {
            type: Boolean,
            default: true
        },
        map_type: {
            type: String
        },
        show_work_details: {
            type: Boolean,
            default: false
        },
    },
    methods: {
        closeOptions() {
            this.showControls = false;
        },
        emitClick(btn) {
            switch (btn) {
                case 'roadmap':
                    this.roadmapOn = true;
                    this.hybridOn = false;
                    break;
                case 'hybrid':
                    this.hybridOn = true;
                    this.roadmapOn = false;
                    break;
                case 'fields':
                    this.fieldsOn = !this.fieldsOn;
                    break;
                case 'noTrack':
                    this.noTrackOn = !this.noTrackOn;
                    break;
                case 'operationalAlerts':
                    this.operationalAlertsOn = !this.operationalAlertsOn;
                    break;
                case 'machineAlerts':
                    this.machineAlertsOn = !this.machineAlertsOn;
                    break;
                case 'connectivity':
                    this.connectivityOn = !this.connectivityOn;
                    break;
                case this.MAPS_OVERLAY_WEATHER_TYPES.PRECIPITATION:
                    if (this.precipitationOn) {
                        btn = this.MAPS_OVERLAY_WEATHER_TYPES.REMOVE;
                    }
                    this.precipitationOn = !this.precipitationOn;
                    this.temperatureOn = false;
                    this.cloudsOn = false;
                    break;
                case this.MAPS_OVERLAY_WEATHER_TYPES.TEMPERATURE:
                    if (this.temperatureOn) {
                        btn = this.MAPS_OVERLAY_WEATHER_TYPES.REMOVE;
                    }
                    this.temperatureOn = !this.temperatureOn;
                    this.precipitationOn = false;
                    this.cloudsOn = false;
                    break;
                case this.MAPS_OVERLAY_WEATHER_TYPES.CLOUDS:
                    if (this.cloudsOn) {
                        btn = this.MAPS_OVERLAY_WEATHER_TYPES.REMOVE;
                    }
                    this.cloudsOn = !this.cloudsOn;
                    this.precipitationOn = false;
                    this.temperatureOn = false;
                    break;
                case 'location':
                    this.locationOn = !this.locationOn;
                    break;
                case MAPS_OVERLAY_WORK_TYPES.TRACK:
                    this.trackOn = !this.trackOn;
                    break;
                case MAPS_OVERLAY_WORK_TYPES.APPLICATION:
                    this.applicationOn = !this.applicationOn;
                    this.speedOn = false;
                    this.humidityOn = false;
                    break;
                case MAPS_OVERLAY_WORK_TYPES.SPEED:
                    this.speedOn = !this.speedOn;
                    this.humidityOn = false;
                    this.applicationOn = false;
                    break;
                case MAPS_OVERLAY_WORK_TYPES.HUMIDITY:
                    this.humidityOn = !this.humidityOn;
                    this.speedOn = false;
                    this.applicationOn = false;
                    break;

            }

            this.$emit('handleMapControlClick', btn);
        },
        resetFieldsButton() {
            this.fieldsOn = false;
        },
        resetAlertsButton() {
            this.operationalAlertsOn = false;
            this.machineAlertsOn = false;
        },
        resetConnectivityButton() {
            this.connectivityOn = false;
        },
    },
    computed: {
        isGeolocationSupported: function () {
            return 'geolocation' in navigator;
        },
        showConnectivity() {
            return this.user.username.includes('@iotag.com.br')
        },
        ...mapGetters([
            'user'
        ])
    },
    watch: {
        show_work_details: function (val) {
            if (val) {
                this.trackOn = true;
                this.applicationOn = true;
                this.humidityOn = false;
                return;
            }

            this.trackOn = false;
            this.applicationOn = false;
            this.humidityOn = false;
        },
        showControls: {
            handler(val) {
                this.$emit('handleControlsShown', val)
            }
        }
    }
}
</script>


<style lang="scss">

.map-type-control-body > .ps > .ps__rail-y > .ps__thumb-y  {
    width: 8px !important;
    border-radius: 4px !important;
    opacity: 1 !important;
    background: gray !important;
    min-height: 40px !important;
}

</style>

<style scoped lang="scss">

.header {
    font-weight: bold;
    padding-left: 10px;
}

.line {
    padding: 5px;
}

.item {
    padding: 5px;
    text-align: center;
    cursor: pointer;
    width: 90px;
}

.item img {
    border-radius: 6px;
}

#controls {
    background: #f2f2f2;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 6px;
    outline: 2px solid #cbcbcb;
    overflow-y: auto;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}

.btn-control {
    margin-bottom: 1rem;
    cursor: pointer;
    background-color: #f2f2f2;
    padding: 5px;
    border-radius: 50px;
    outline: 2px solid #cbcbcb;
}

.menu-item-on {
    border: 2px solid #0f9af0;
}

.ps {
    max-height: 340px;
}
</style>
