export default class CoordMapMbtiles {

    tileSize;
    tilesList;
    grouped;
    colorBounds;
    requestId;

    constructor(tileSize, tilesList, grouped, colorBounds, requestId) {
        this.tileSize = tileSize;
        this.tilesList = tilesList;
        this.grouped = grouped
        this.colorBounds = colorBounds;
        this.requestId = requestId;
    }

    getTile(coord, zoom, ownerDocument) {
        const div = ownerDocument.createElement("div");

        let baseURL = `https://api3.iotag.com.br/track-tiles-service/v1/trackTiles/${this.requestId}`;

        let requestParams = {
            grouped: this.grouped,
            color_bounds: this.colorBounds
        }

        if (this.tilesList && this.tilesList.length > 0) {
            let tileUrl = this.findTilesOnZoom(zoom)
            let zxy = zoom + "/" + coord.x + "/" + coord.y;

            for (const tile of tileUrl) {
                if (zxy.includes(tile)) {
                    let img = document.createElement("img");
                    img.style.position = 'absolute';
                    img.src = `${baseURL}/${zoom}/${tile}.png?params=${btoa(JSON.stringify(requestParams))}`;
                    img.crossOrigin = "anonymous";
                    div.appendChild(img);
                }
            }
        }
        return div;
    }

    findTilesOnZoom(zoom) {
        let tilesOnZoom = this.tilesList.find(tiles => tiles.zoom === zoom)

        if (!tilesOnZoom) {
            return []
        }

        return tilesOnZoom.tiles
    }

    releaseTile(tile) {
        tile.remove();
    }
}
