import moment from "moment";

export default {

    methods: {
        dateFormat(val, timezone = null) {
            if (val) {
                if (!timezone) {
                    return moment(val * 1000).format('DD/MM/YYYY');
                }
                return moment(val * 1000).tz(timezone).format('DD/MM/YYYY');
            }
        },
        dateFormatWithoutYear(val) {
            if (val) {
                return moment(val * 1000).format('DD/MM/YYYY').substring(0, 5);
            }
        },
        dateHourFormat(val) {
            if (val) {
                return moment(val * 1000).format('DD/MM/YY HH:mm:ss')
            }
        },
        /**
         * Recebe um tempo em segundos e devolve no formato HH:MM
         *
         * **/
        getTimeFromSecondsMixin(val) {
            if (!val || val <= 0) {
                return "";
            }
            return new Date(val * 1000).toISOString().substr(11, 5)

        },

        /**
         * Recebe um tempo em segundos e devolve no formato HH:MM
         * Método temporário: deve-se registrar a localização da máquina nos eventos.
         * **/
        getTimeFromSecondsMinusThreeHoursMixin(val) {
            if (!val || val <= 0) {
                return "";
            }
            const THREE_HOURS_IN_SECONDS = 10800;
            val = val - THREE_HOURS_IN_SECONDS;
            return new Date(val * 1000).toISOString().substr(11, 5)

        },

        /**
         * Recebe um tempo em segundos e devolve no formato HHh:MMm:SSs
         *
         * **/
        getTimeFromSecondsDurationFormatMixin(val) {
            if (!val || val <= 0) {
                return "";
            }

            var sec_num = parseInt(val, 10);
            var hours   = Math.floor(sec_num / 3600);
            var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
            var seconds = sec_num - (hours * 3600) - (minutes * 60);

            if (val < 60) {
                return seconds + 's';
            }
            if (val < 3600) {
                if (seconds < 10) { seconds = "0" + seconds; }
                return  minutes + 'm' + seconds + 's';
            }

            if (seconds < 10) { seconds = "0" + seconds; }
            if (minutes < 10) { minutes = "0" + minutes; }
            return hours + 'h' + minutes + 'm' + seconds + 's';
        },

        getTimeFromSecondsDurationFormatMixinShowZero(val) {
            if (val === null || val === undefined || val < 0) {
                return "";
            }

            var sec_num = parseInt(val, 10);
            var hours   = Math.floor(sec_num / 3600);
            var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
            var seconds = sec_num - (hours * 3600) - (minutes * 60);

            if (val < 60) {
                return seconds + 's';
            }
            if (val < 3600) {
                if (seconds < 10) { seconds = "0" + seconds; }
                return  minutes + 'm' + seconds + 's';
            }

            if (seconds < 10) { seconds = "0" + seconds; }
            if (minutes < 10) { minutes = "0" + minutes; }
            return hours + 'h' + minutes + 'm' + seconds + 's';
        },

        getTimeFromHoursDurationFormatMixin(val) {
            if (!val || val <= 0) {
                return "0s";
            }

            let hours = Math.floor(val);
            let minutes = Math.floor((val - hours) * 60);
            let seconds = Math.ceil((((val - hours) * 60) - minutes) * 60);
            
            if (seconds === 60) {
                seconds = 0;
                minutes++;
            }

            if (minutes === 60) {
                minutes = 0;
                hours++;
            }

            if (hours > 0)
                return hours + 'h' + minutes + 'm' + seconds + 's';
            else if (minutes > 0) {
                return minutes + 'm' + seconds + 's';
            }
            return seconds + 's';
        },
        getTimeFromSecondsDurationExcelFormatMixin(val) {
            if (!val || val <= 0) {
                return "";
            }

            var sec_num = parseInt(val, 10);
            var hours = Math.floor(sec_num / 3600);
            var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
            var seconds = sec_num - (hours * 3600) - (minutes * 60);

            hours = hours < 10 ? "0" + hours: hours;
            minutes = minutes < 10 ? "0" + minutes: minutes;
            seconds = seconds < 10 ? "0" + seconds: seconds;

            if (val < 60) {
                return '00:00:' + seconds;
            }
            if (val < 3600) {
                return '00:' + minutes + ':' + seconds;
            }
            return hours + ':' + minutes + ':' + seconds;
        },
    },
};
