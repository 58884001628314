<template>
    <div v-if="rateStepsEdit.length > 0" class="work-options-wrapper expand" :class="{resize: collapseView}">
        <div style="margin-bottom: 15px; display: flex; justify-content: space-between; align-items: center">
            <div class="show" :class="{hide: collapseView}" v-if="!collapseView">
                 {{ $t('AppOperationsWorkOptions.LEGENDA') }} {{getSymbol() }}
            </div>
            <i v-if="!report" :class="[collapseView ? 'pi pi-chevron-circle-right' : 'pi pi-chevron-circle-left']"
               @click="toggleCardView" style="font-size: 18px; cursor: pointer"
               v-tooltip.top="collapseView ? $t('AppOperationsWorkOptions.Exibir legenda') : $t('AppOperationsWorkOptions.Ocultar legenda')"></i>
        </div>
        <div>
            <div v-for="(step, index) in rateStepsEdit" :class="{'mb-2': (grouped || report)}">
                <div class="d-flex justify-content-center">
                    <div style="min-width: 60px; text-align: right"
                         :class="{'global-max': (index === 0 && !grouped && rateStepsEdit.length > 1)}">
                        {{ displayStepValue(step.value) }}
                    </div>
                    <div class="work-color" :class="{workColorDisabled: !step.visible}" style="border-bottom-width: 0"
                         :style="{borderColor: step.color, borderTopWidth: (index === 0 && rateStepsEdit.length > 1) ? '0' : '4px'}"
                         @mouseover="setCurrentStep(index)" @click="colorPickerClicked(index)">
                        <ColorPicker v-model="step.color" disabled @input="change"/>
                    </div>
                    <div style="min-width: 80px; margin-left: 5px; margin-top: 5px; position: relative;
                         overflow: hidden">
                        <div :id="report ? '' : 'slide'" :style="{maxWidth: step.percentage + '%', backgroundColor: 'gray',
                             minHeight: '100%', position: 'relative', overflow: 'visible', borderRadius: '1px'}">
                            &nbsp;
                        </div>
                        <span style="position: absolute; bottom: 0">
                            {{ displayPercentage(step.percentage) }}%
                        </span>
                    </div>
                </div>
            </div>
            <div v-if="!report && !grouped" class="d-flex justify-content-center mt-3">
                <div style="cursor: pointer; border: 1px solid white; border-radius: 5px"
                     class="px-2 py-1" @click="toggleEdit">
                    <i class="pi pi-pencil mr-1"/> {{ $t('AppOperationsWorkOptions.Editar') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ColorPicker from 'primevue/colorpicker';
import Tooltip from "primevue/tooltip";

export default {
    mounted() {
        this.rateStepsEdit = JSON.parse(JSON.stringify(this.rate_steps));
        this.collapseView = this.isScreenMobile;
    },
    data() {
        return {
            rateStepsEdit: [],
            currentStepIndex: 0,
            collapseView: false,
            grouped: false
        }
    },
    props: {
        rate_steps: {
            type: Array
        },
        mapType: {
            type: Object
        },
        report: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        getSymbol() {
            if (!this.mapType || !this.mapType.symbol) {
                return ''
            }

            return '(' + this.mapType.symbol + ')';
        },
        colorPickerClicked(index) {
            this.rateStepsEdit[index].visible = !this.rateStepsEdit[index].visible
            this.$emit('changeVisibility', this.rateStepsEdit)
        },
        displayStepValue(value) {
            if (this.grouped) {
                return value
            }

            if (value === undefined) {
                return '';
            }

            //TODO REMOVER TEMPORÁRIO PARA APRESENTAÇÃO DA AMAGGI
            if (this.mapType.ddi === 1) {
                return (value / 1000).toFixed(2).replace('.', ',');
            }

            return value.toFixed(2).replace('.', ',');
        },
        displayPercentage(percentage) {
            return percentage.replace('.', ',');
        },
        setCurrentStep(stepIndex) {
            this.currentStepIndex = stepIndex;
        },
        change(event) {
            this.rateStepsEdit[this.currentStepIndex].color = "#" + event
        },
        toggleCardView() {
            if (this.collapseView) {
                this.collapseView = false
                return
            }

            this.collapseView = true
        },
        toggleEdit() {
            this.$emit('edit')
        }
    },
    watch: {
        rate_steps: async function (val) {
            if (val.length === 0) {
                this.rateStepsEdit = []
                return
            }
            this.rateStepsEdit = JSON.parse(JSON.stringify(val));
        },
        mapType: function () {
            this.grouped = this.mapType.grouped
        }
    },
    computed: {
        isScreenMobile: function () {
            return window.screen.width <= 1024;
        }
    },
    directives: {
        'tooltip': Tooltip
    },
    components: {
        ColorPicker, Tooltip
    }
}
</script>

<style lang="scss">

.work-color > .p-colorpicker > .p-disabled {
    opacity: 1 !important;
}

.work-color > .p-colorpicker {
    height: 100% !important;
    width: 100% !important;
    padding-left: 3px;
    margin-top: -1px;
}
</style>

<style scoped>

.global-max {
    opacity: 0;
    cursor: default;
    max-width: 60px;
}

.work-color:hover {
    cursor: pointer;
}

.workColorDisabled {
    filter: brightness(30%);
}

.work-color {
    min-height: 80%;
    max-width: 20px;
    margin-top: 0;
    margin-left: 3px;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    overflow: visible;
}

.work-options-wrapper {
    background: rgba(41, 50, 60, .6);
    border: 1px solid white;
    border-radius: 5px;
    padding: 10px;
    color: white;
    overflow: hidden;
    position: relative;
    width: 210px;
}

.show {
    animation: show 1s forwards;
}

.hide {
    animation: hide 1s forwards;
}

.expand {
    animation: expand 1s forwards;
}

.resize {
    animation: resize 1s forwards;
}

@keyframes expand {
    0% {
        width: 42px;
        height: 42px;
        border-radius: 50%;
    }

    30% {
        border-radius: 5px;
    }

    100% {
        width: 210px;
        height: fit-content;
        border-radius: 5px;
    }
}

@keyframes resize {
    0% {
        width: 210px;
        height: fit-content;
        border-radius: 5px;
    }

    70% {
        border-radius: 5px;
    }

    100% {
        width: 42px;
        height: 42px;
        border-radius: 50%;
    }
}

@keyframes hide {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes show {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

#slide {
    left: -100px;
    -webkit-animation: slide 2s forwards;
    -webkit-animation-delay: 0.2s;
    animation: slide 2s forwards;
    animation-delay: 0.2s;
}

@-webkit-keyframes slide {
    100% {
        left: 0;
    }
}

@keyframes slide {
    100% {
        left: 0;
    }
}

</style>
