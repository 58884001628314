<template>
        <vue-html2pdf
                :show-layout="false"
                :float-layout="true"
                :enable-download="true"
                :preview-modal="!show_canva"
                :paginate-elements-by-height="1100"
                :filename="'custo_operação_' + Date.now()"
                :pdf-quality="2"
                :manual-pagination="true"
                pdf-format="a4"
                pdf-orientation="portrait"
                pdf-content-width="800px"
                @hasDownloaded="hasDownloaded($event)"
                ref="html2Pdf">
            <section slot="pdf-content" style="color: black;">
                <section style="width: 100%;margin-left: -10px">
                    <div class="brand-background" >
                        <img alt="Logo" style="height: 80px;width: 150px" :src="headerImage"/>
                    </div>
                    <div class="center default-padding" style="font-size: 20px">
                        {{ $t('AppCostsReport.Análise de Custo Operacional do Maquinário') }}
                    </div>
                    <div class="default-padding mt-3">
                        <b>{{ $t('AppCostsReport.Máquinas') }}:</b> {{vehiclesToShow}} <br/>
                        <b>{{ $t('AppCostsReport.Preço Diesel') }}:</b> {{showPriceFormat(diesel_price)}}<br/>
                        <b>{{ $t('AppCostsReport.Juros/ano') }}:</b> {{interest}}%<br/>
                        <b>{{ $t('AppCostsReport.Mão-de-Obra/hora') }}:</b> {{showPriceFormat(labor_price)}}<br/>
                        <b>{{ $t('AppCostsReport.Manutenção/hora') }}:</b> {{showPriceFormat(maintenance_price)}}<br/>
                        <b>{{ $t('AppCostsReport.Informações coletadas de') }}:</b> {{datesRange}}<br/>
                        <b>{{ $t('AppCostsReport.Gerado em') }}:</b> {{dateHourFormat(Date.now()/1000)}} <br/>
                    </div>
                    <div class="default-padding mt-3">
                        <div class="row no-gutters">
                            <div class="col-md-6 col-sm-12 mt-2 mt-md-0 center">
                                <div class="dashboard-card center"  style="background-color: #d62728;">
                                    <div class="row no-gutters full-width">
                                        <div class="col-12 card-text">
                                            <div class="card-title">
                                                {{ $t('AppCostsReport.Custos Totais') }}
                                            </div>
                                            <div class="card-value">
                                                <p style="font-size: 14px;display: inline;">R$</p>
                                                {{getDisplayValue(total_costs)}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12 mt-2 mt-md-0 center">
                                <div class="dashboard-card center"  style="background-color: #2ca02c;">
                                    <div class="row no-gutters full-width">
                                        <div class="col-11 card-text">
                                            <div class="card-title">
                                                {{ $t('AppCostsReport.Economia Potencial') }}
                                            </div>
                                            <div class="card-value">
                                                <p style="font-size: 14px;display: inline;">R$</p>
                                                {{getDisplayValue(potential_savings)}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row no-gutters mt-5">
                            <div class="col-6 mt-3">
                                <h4 class="center">{{ $t('AppCostsReport.Combustível (Litros)') }}</h4>
                                <app-costs-report-chart-pie :chartData="gasto_combustivel_series" :tooltipSuffix="$t('AppCostsReport.litros')" />
                            </div>
                            <div class="col-6 mt-3">
                                <h4 class="center">{{ $t('AppCostsReport.Tempo (Horas)') }}</h4>
                                <app-costs-report-chart-pie :chartData="tempo_series" :tooltipSuffix="$t('AppCostsReport.horas')"/>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="html2pdf__page-break"/>
                <section style="width: 100%;margin-left: -10px">
                    <app-costs-column-chart :chart_data="column_chart_data" class="mt-4"
                                            :chart_title="$t('AppCostsReport.Custos Totais x Economia Potencial x Máquina')"/>

                    <DataTable :value="table_costs" style="text-align: center" dataKey="field_id"
                               class="p-datatable-striped mt-4">
                        <template #header>
                            <div class="table-header">
                                {{ $t('AppCostsReport.Custos Totais') }}
                            </div>
                        </template>
                        <Column headerStyle="width: 110px" headerClass="headerClass" bodyClass="bodyClass"
                                field="operation" :header="$t('AppCostsReport.Operação')"
                                :bodyStyle="{textAlign: 'center'}" >
                            <template #body="slotProps">
                                <div>
                                    {{slotProps.data.operation}}
                                </div>
                            </template>
                        </Column>
                        <Column headerStyle="width: 75px" headerClass="headerClass" bodyClass="bodyClass"
                                field="time_total" :header="$t('AppCostsReport.Tempo (h)')"
                                :bodyStyle="{textAlign: 'center'}">
                            <template #body="slotProps">
                                <div>
                                    {{getDisplayValue(slotProps.data.time_total)}}
                                </div>
                            </template>
                        </Column>
                        <Column headerStyle="width: 95px" headerClass="headerClass" bodyClass="bodyClass"
                                field="time_percentage" :header="$t('AppCostsReport.Outros (R$)')"
                                :bodyStyle="{textAlign: 'center'}">
                            <template #body="slotProps">
                                <div>
                                    {{getCurrentModeDisplayValue(slotProps.data.fixed_costs)}}
                                </div>
                            </template>
                        </Column>
                        <Column headerStyle="width: 120px" headerClass="headerClass" field="labor_cost"
                                :header="$t('AppCostsReport.Mão de Obra (R$)')" :bodyStyle="{textAlign: 'center'}">
                            <template #body="slotProps">
                                <div>
                                    {{getCurrentModeDisplayValue(slotProps.data.labor_cost)}}
                                </div>
                            </template>
                        </Column>
                        <Column headerStyle="width: 100px" headerClass="headerClass" field="maintenance_cost"
                                :header="$t('AppCostsReport.Manutenção (R$)')" :bodyStyle="{textAlign: 'center'}">
                            <template #body="slotProps">
                                <div>
                                    {{getCurrentModeDisplayValue(slotProps.data.maintenance_cost)}}
                                </div>
                            </template>
                        </Column>
                        <Column headerStyle="width: 100px" headerClass="headerClass" field="fuel_cost"
                                :header="$t('AppCostsReport.Combustível (R$)')" :bodyStyle="{textAlign: 'center'}">
                            <template #body="slotProps">
                                <div>
                                    {{getCurrentModeDisplayValue(slotProps.data.fuel_cost)}}
                                </div>
                            </template>
                        </Column>
                        <Column headerStyle="width: 100px" headerClass="headerClass" field="loss"
                                :header="$t('AppCostsReport.Custo (R$)')" :bodyStyle="{textAlign: 'center'}">
                            <template #body="slotProps">
                                <div>
                                    {{getCurrentModeDisplayValue(slotProps.data.loss)}}
                                </div>
                            </template>
                        </Column>
                        <Column headerStyle="width: 100px" headerClass="headerClass" field="potential_savings"
                                :header="$t('AppCostsReport.Economia Potencial (R$)')"
                                :bodyStyle="{textAlign: 'center'}">
                            <template #body="slotProps">
                                <div>
                                    {{getCurrentModeDisplayValue(slotProps.data.potential_savings)}}
                                </div>
                            </template>
                        </Column>
                        <ColumnGroup type="footer">
                            <Row>
                                <Column :footer="$t('AppCostsReport.Total') + ':'" :colspan="6" footerStyle="text-align:right"/>
                                <Column :footer="getCurrentModeDisplayValue(total_costs)"
                                        footerStyle="text-align:center"/>
                                <Column :footer="getCurrentModeDisplayValue(potential_savings)"
                                        footerStyle="text-align:center"/>
                            </Row>
                        </ColumnGroup>
                    </DataTable>
                </section>
            </section>
        </vue-html2pdf>
</template>

<script>

import VueHtml2pdf from 'vue-html2pdf'
import getTimeFromSecondsDurationFormatMixin from "@/mixins/DateMixin";
import dateHourFormat from "@/mixins/DateMixin";
import AppCostsReportChartPie from "@/components/views/costs/AppCostsReportChartPie";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Row from 'primevue/row';
import AppCostsColumnChart from "./AppCostsColumnChart";

export default {
    mixins: [getTimeFromSecondsDurationFormatMixin, dateHourFormat],
    data() {
        return {
            headerImage: require("../../../assets/images/brands/" + process.env.VUE_APP_THEME_IMAGE_HEADER + ".png"),
            vehiclesToShow: ''
        }
    },
    props: {
        generate_report: {
            type: Boolean
        },
        table_costs: {
            type: Array
        },
        tempo_series: {
            type: Array
        },
        gasto_combustivel_series: {
            type: Array
        },
        total_costs: {
            type: Number
        },
        potential_savings: {
            type: Number
        },
        diesel_price: {
            type: Number
        },
        labor_price: {
            type: Number
        },
        interest: {
            type: Number
        },
        maintenance_price: {
            type: Number
        },
        selected_vehicles: {
            type: Array
        },
        selected_dates_to_show: {
            type: String
        },
        column_chart_data: {
            type: Object
        }
    },
    methods: {
        hasDownloaded(event) {
            this.$emit('reportHasBeenDownloaded');
        },
        getDisplayValue(value) {
            if (!value || isNaN(value) || value < 0) {
                return 0;
            }
            return value.toFixed(2);
        },
        showPriceFormat(value) {
            if (!value || value < 0) {
                return 'N/A'
            }

            return 'R$' + value.toFixed(2);
        },
        getCurrentModeDisplayValue(value) {
            return 'R$' + this.getDisplayValue(value);
        },
    },
    watch: {
        generate_report: function () {
            this.$refs.html2Pdf.generatePdf();
        },
        selected_vehicles: {
            deep: true,
            handler(val) {
                let vehiclesToShowText = '';
                val.forEach(vehicle => {
                    if (vehiclesToShowText === '') {
                        vehiclesToShowText += vehicle.display_id;
                        return
                    }
                    vehiclesToShowText += ', ' + vehicle.display_id;
                })
                this.vehiclesToShow = vehiclesToShowText;
            }
        },
    },
    computed: {
        datesRange: function () {
            return this.selected_dates_to_show.length > 0 ? this.selected_dates_to_show.substring(2) : '';
        },
        show_canva() {
            return window.screen.width <= 1024;
        }
    },
    components: {
        VueHtml2pdf, AppCostsReportChartPie, DataTable, Column, Row, ColumnGroup, AppCostsColumnChart
    },
}
</script>

<style scoped lang="scss">

@import "src/assets/styles/constants";

.center {
    display:flex;
    justify-content:center;
    align-items:center;
}

.dashboard-card {
    width: 95%;
    height: 70px;
    border-radius: 20px;
}

.card-text {
    color: white;
}

.card-title {
    font-size: 14px;
    margin: 0;
    padding-left: 10px;
}

.card-value {
    font-size: 22px;
    padding-left: 10px;
}

.full-width {
    width: 100%;
}

.default-padding {
    padding: 10px 50px 5px 40px;
}

.brand-background {
    display: flex;
    justify-content: center;
    align-self: center;
    width: 100%;
    background-color: $aside-menu-item-active;
}

</style>
