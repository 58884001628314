<template>
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__head kt-portlet__head--lg no-border-radius">
                <div class="kt-portlet__head-label">
                        <span class="kt-portlet__head-icon">
                            <Dropdown v-model="selectedOrg" :options="organizations"
                                    optionLabel="display_name" :placeholder="$t('AppUsers.Organização')"
                                    class="auto-center" :dataKey="'id'"
                                    :filter="true"
                                    style="width: 250px"
                                    @input="saveChosenOptionsToLocalStorage">
                                <template #option="slotProps" style="width: 500px">
                                    <div v-if="loadingOrgs">{{ $t('AppUsers.Carregando, aguarde') }}</div>
                                    <div style="padding-right: 20px" v-else-if="slotProps.option.name === 'ND'">
                                        -
                                    </div>
                                    <div style="padding-right: 20px" v-else>
                                        {{ slotProps.option.display_name }}
                                    </div>
                                </template>
                            </Dropdown>
                        </span>
                </div>
                <div class="kt-portlet__head-toolbar">
                    <div class="kt-portlet__head-wrapper">
                        <app-button type="primary" @handleClick="add" :class="{'margin-25-right': !is_col_12, 'width-button': is_col_12}" :disabled="!selectedOrg">
                            <i class="glyphicons glyphicons-plus"></i><span v-if="!is_col_12">{{ $t('AppUsers.Adicionar') }}</span>
                        </app-button>
                        <app-button type="secondary" @handleClick="back" :class="{'margin-25-right': !is_col_12, 'width-button': is_col_12}">
                            <i class="glyphicons glyphicons-arrow-left"></i><span v-if="!is_col_12">{{ $t('AppUsers.Voltar') }}</span>
                        </app-button>
                    </div>
                </div>
            </div>
            <div class="kt-portlet__body no-padding" id="tableDiv">
                <div class="card data-table-width">
                    <DataTable :value="gridData" :paginator="true" :rowHover="true" :rows="10" :loading="loading"
                            sortField="username"
                            removableSort
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            :rowsPerPageOptions="[10,25,50]" @row-select="onRowSelect" selectionMode="single"
                            dataKey="id"
                            :currentPageReportTemplate="$t('AppUsers.currentPageReportTemplate')"
                            class="p-datatable-striped" :sortOrder="1" :filters.sync="filters"
                            filterDisplay="row" responsiveLayout="scroll">
                        <template #empty>
                            {{ $t('AppUsers.Sem dados para exibir') }}
                        </template>
                        <template #loading>
                            {{ $t('AppUsers.Carregando usuários, por favor aguarde') }}

                        </template>
                        <Column field="username" :header="$t('AppUsers.Nome')" headerClass="headerClass"
                                bodyClass="bodyClass" :sortable="true" filterMatchMode="contains"
                                :showFilterMenu="false" :showClearButton="false"
                                :styles="{'min-width': '150px'}">
                            <template #filter class="headerClass">
                                <InputText type="text" v-model="filters['username']" class="p-column-filter"/>
                            </template>
                            <template #body="slotProps">
                                <i v-tooltip.top="$t('AppUsers.Convite pendente')" v-if="!slotProps.data.invitation_accepted"
                                class="pi pi-exclamation-circle"
                                style="color: red; font-weight: bold"></i>
                                {{ slotProps.data.username }}
                            </template>
                        </Column>

                        <Column field="equipment" :header="$t('AppUsers.Equipamento')" headerClass="headerClass"
                                bodyClass="bodyClass" :sortable="false" filterMatchMode="in"
                                :showFilterMenu="false" :showClearButton="false">
                            <template #filter class="headerClass">
                                <MultiSelect v-model="filters['equipment']" :options="permissionStatus" appendTo="tableDiv"
                                            style="width: 100%" :placeholder="$t('AppUsers.Selecione')" class="p-column-filter"
                                            :showClear="true" optionLabel="name" optionValue="code">
                                    <template #option="slotProps">
                                            <span :class="'badge user-permission-' + slotProps.option.code">
                                                {{ slotProps.option.name }}
                                            </span>
                                    </template>
                                </MultiSelect>
                            </template>
                            <template #body="slotProps">
                                    <span :class="'badge user-permission-' + slotProps.data.equipment">
                                    {{ getPermissionStatus(slotProps.data.equipment) }}
                                    </span>
                            </template>
                        </Column>

                        <Column field="locations" :header="$t('AppUsers.Locais') " headerClass="headerClass"
                                bodyClass="bodyClass" :sortable="false" filterMatchMode="in"
                                :showFilterMenu="false" :showClearButton="false">
                            <template #filter class="headerClass">
                                <MultiSelect v-model="filters['locations']" :options="permissionStatus"
                                            style="width: 100%" :placeholder="$t('AppUsers.Selecione')" class="p-column-filter"
                                            :showClear="true" appendTo="tableDiv"
                                            optionLabel="name" optionValue="code">
                                    <template #option="slotProps">
                                            <span :class="'badge user-permission-' + slotProps.option.code">
                                                {{ slotProps.option.name }}
                                            </span>
                                    </template>
                                </MultiSelect>
                            </template>
                            <template #body="slotProps">
                                    <span :class="'badge user-permission-' + slotProps.data.locations">
                                    {{ getPermissionStatus(slotProps.data.locations) }}
                                    </span>
                            </template>
                        </Column>

                        <Column field="management" :header="$t('AppUsers.Gerenciamento')" headerClass="headerClass"
                                bodyClass="bodyClass" :sortable="false" filterMatchMode="in"
                                :showFilterMenu="false" :showClearButton="false">
                            <template #filter class="headerClass">
                                <MultiSelect v-model="filters['management']" :options="permissionStatus"
                                            style="width: 100%" :placeholder="$t('AppUsers.Selecione')" class="p-column-filter"
                                            :showClear="true" appendTo="tableDiv"
                                            optionLabel="name" optionValue="code">
                                    <template #option="slotProps">
                                            <span :class="'badge user-permission-' + slotProps.option.code">
                                                {{ slotProps.option.name }}
                                            </span>
                                    </template>
                                </MultiSelect>
                            </template>
                            <template #body="slotProps">
                                    <span :class="'badge user-permission-' + slotProps.data.management">
                                    {{ getPermissionStatus(slotProps.data.management) }}
                                    </span>
                            </template>
                        </Column>

                    </DataTable>
                </div>
            </div>
        </div>

        <app-modal-add-user :displayDialogAddUser="showDialogManageUser" :editUserPermissions="editing"
                            :selectedUserPermissions="userPermissions" :selectedUser="selectedUser"
                            :current-organization="selectedOrg" :organizations="organizations"
                            @handleClose="closeDialog" @handleRemoveUser="removeUser" @handleSave="save"/>
    </div>
</template>

<script>
    import {router} from '../../../router';
    import AppTable from "../../common/AppTable";
    import maskMacAddress from "../../../mixins/StringsMaskMixin";
    import maskCpfCnpj from "../../../mixins/StringsMaskMixin";
    import dateHourFormat from "../../../mixins/DateMixin";
    import dateFormat from "../../../mixins/DateMixin";
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';
    import InputText from 'primevue/inputtext';
    import MultiSelect from 'primevue/multiselect';
    import Calendar from 'primevue/calendar';
    import Dropdown from 'primevue/dropdown';
    import ProgressBar from 'primevue/progressbar';
    import Button from 'primevue/button'
    import AppButton from "../../common/AppButton";
    import SplitButton from 'primevue/splitbutton';
    import getTimeFromSecondsMixin from "../../../mixins/DateMixin";
    import displayToast from "../../../mixins/ToastMixin";
    import AppModalAddUser from "@/components/views/users/AppModalAddUser";
    import {FilterMatchMode} from 'primevue/api';

    import UsersService from "../../../services/UsersService";
    import {PERMISSIONS} from "./AppOptions";

    import Tooltip from 'primevue/tooltip';
    import Dialog from 'primevue/dialog';
    import AppInput from "@/components/common/AppInput";
    import OrganizationsService from "@/services/OrganizationsService";
    import orderCustomFieldByNaturalOrder from "@/mixins/ArrayMixin";

export default {
    mixins: [displayToast, orderCustomFieldByNaturalOrder],
    mounted() {
        this.usersService = new UsersService();
        this.organizationsService = new OrganizationsService();
        this.getOrganizations();
        this.is_col_12 = this.isScreenMobile;
    },
    data() {
        return {
            is_col_12: false,
            usersService: null,
            filters: {},
            loading: false,
            gridData: [],
            originalResponse: [],
            permissionStatus: PERMISSIONS(),
            showDialogManageUser: false,
            editing: false,
            selectedUser: null,
            userPermissions: {
                locations: 'NOT_AUTHORIZED',
                equipment: 'NOT_AUTHORIZED',
                management: 'NOT_AUTHORIZED',
            },
            organizations: [],
            organizationsService: null,
            loadingOrgs: false,
            selectedOrg: null,
            storedChosenOptions: {}
        }
    },
    components: {
        AppTable,
        DataTable,
        Column,
        InputText,
        MultiSelect,
        Calendar,
        Dropdown,
        ProgressBar,
        Button,
        AppButton,
        SplitButton,
        AppModalAddUser
    },
    validations: {},
    directives: {
        'tooltip': Tooltip
    },
    methods: {
        back() {
            router.go(-1);
        },
        add() {
            this.selectedUser = null
            this.userPermissions = {
                "locations": 'NOT_AUTHORIZED',
                "equipment": 'NOT_AUTHORIZED',
                "management": 'NOT_AUTHORIZED'
            };
            this.showDialogManageUser = true
            this.editing = false
        },
        onRowSelect(user) {
            this.selectedUser = user.data
            this.userPermissions = {
                "locations": user.data.locations,
                "equipment": user.data.equipment,
                "management": user.data.management
            };
            this.showDialogManageUser = true
            this.editing = true
        },
        getUsers(org) {
            this.gridData = []
            this.usersService.getUsers(org.id)
                .then((response) => {
                    this.originalResponse = response;
                    this.gridData = response;
                }).catch((error) => {
                if (!error.response || error.response.status !== 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppUsers.Não foi possivel completar a operação'),
                        life: 5000
                    });
                    return;
                }
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppUsers.Você não tem acesso a esta funcionalidade'),
                    life: 5000
                });
            }).finally(() => {
                this.loading = false;
            })
        },
        getPermissionStatus(status) {
            let result = this.permissionStatus.find(({code}) => code === status);
            if (result) {
                return result.name;
            }
        },
        closeDialog() {
            this.userPermissions = null;
            this.showDialogManageUser = false;
        },
        save(user, organizationIds) {
            if (this.selectedUser) {
                this.updateUser(user);
                return;
            }

            if (organizationIds.length === 0) {
                this.createUser(user);
                return;
            }

            this.creatUserOnMultipleOrganizations(user, organizationIds);
            this.closeDialog();
        },
        updateUser(user) {
            this.usersService.updateUser(this.selectedUser.id, user)
                .then(() => {
                    this.$toast.add({
                        severity: 'success',
                        summary: this.$t('AppUsers.Permissões atualizadas com sucesso!'),
                        life: 3000
                    });
                }).catch((error) => {
                    if (!error.response || error.response.status !== 403) {
                        this.$toast.add({
                            severity: 'error',
                            summary: error.response.data.details,
                            life: 5000
                        });
                        return;
                    }
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppUsers.Você não tem acesso a esta funcionalidade'),
                        life: 5000
                    });
            }).finally(() => {
                this.getUsers(this.selectedOrg);
                this.closeDialog();
            })
        },
        createUser(user) {
            this.usersService.createUser(this.selectedOrg.id, user)
                .then(() => {
                    this.$toast.add({
                        severity: 'success',
                        summary: this.$t('AppUsers.Usuário adicionado com sucesso!'),
                        life: 3000
                    });
                }).catch((error) => {
                    if (!error.response || error.response.status !== 403) {
                        this.$toast.add({
                            severity: 'error',
                            summary: error.response.data.details,
                            life: 5000
                        });
                        return;
                    }
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppUsers.Você não tem acesso a esta funcionalidade'),
                        life: 5000
                    });
            }).finally(() => {
                this.getUsers(this.selectedOrg);
                this.closeDialog();
            })
        },
        creatUserOnMultipleOrganizations(user, organizationIds) {
            organizationIds.push(this.selectedOrg.id)
            let data = {
                user: user,
                organizations: organizationIds
            }
            this.usersService.createUserOnMultipleOrganizations(data)
                .then(() => {
                    this.$toast.add({
                        severity: 'success',
                        summary: this.$t('AppUsers.Usuário adicionado com sucesso!'),
                        life: 3000
                    });
                }).catch((error) => {
                if (!error.response || error.response.status !== 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary: error.response.data.details,
                        life: 5000
                    });
                    return;
                }
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppUsers.Você não tem acesso a esta funcionalidade'),
                    life: 5000
                });
            }).finally(() => {
                this.getUsers(this.selectedOrg)
            })
        },
        removeUser() {
            this.usersService.removeUser(this.selectedUser.id)
                .then(() => {
                    this.$toast.add({
                        severity: 'success',
                        summary: this.$t('AppUsers.Usuário removido com sucesso!'),
                        life: 3000
                    });
                }).catch((error) => {
                    if (!error.response || error.response.status !== 403) {
                        this.$toast.add({
                            severity: 'error',
                            summary: error.response.data.details,
                            life: 5000
                        });
                        return;
                    }
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppUsers.Você não tem acesso a esta funcionalidade'),
                        life: 5000
                    });
            }).finally(() => {
                this.getUsers(this.selectedOrg)
            })
            this.closeDialog();
        },
        getOrganizations() {
            let vm = this;
            this.loadingOrgs = true;
            this.organizationsService.getAllV3('USERS').then((results) => {
                this.populateOrgsList(results);
                this.loadFilteredOptions();
            })
                .catch((error) => {
                    vm.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppUsers.Operação falhou'),
                        detail: this.$t('AppUsers.Não foi possível buscar organizações'),
                        life: 5000
                    });
                    console.log(error)
                }).finally(() => {
                this.loadingOrgs = false;
            })
        },
        populateOrgsList(orgs) {
            this.organizations = this.orderCustomFieldByNaturalOrder(orgs, "name");
        },
        loadFilteredOptions() {
            this.storedChosenOptions = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_BAR_OPTIONS));

            if (!this.storedChosenOptions || !this.storedChosenOptions.clients || this.storedChosenOptions.clients.length === 0) {
                this.storedChosenOptions = {};
                return;
            }

            this.selectedOrg = this.storedChosenOptions['clients'].filter(a => this.organizations.some(b => a.id === b.id))[0];
        },
        saveChosenOptionsToLocalStorage() {
            this.storedChosenOptions['clients'] = [this.selectedOrg];
            this.storedChosenOptions['vehicles'] = [];
            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_BAR_OPTIONS, JSON.stringify(this.storedChosenOptions));
        },
        normalizeText(text){
            let textNomalize = text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            return textNomalize.toLowerCase();
        },
        filterData() {
            let newGridData = [];
            this.originalResponse.forEach((user) => {

                if(this.filters.username != null && !this.normalizeText(user.username).includes(this.normalizeText(this.filters.username))){
                    return;
                }

                if (this.filters.equipment != null && this.filters.equipment.length > 0 && !this.filters.equipment.includes(user.equipment)) {
                    return;
                }

                if (this.filters.locations != null && this.filters.locations.length > 0 && !this.filters.locations.includes(user.locations)) {
                    return;
                }

                if (this.filters.management != null && this.filters.management.length > 0 && !this.filters.management.includes(user.management)) {
                    return;
                }

                newGridData.push(user)
            })

            this.gridData = newGridData;
        }
    },
    computed:{
        isScreenMobile: function() {
            return window.screen.width <= 1024;
        }
    },
    watch: {
        selectedOrg: function (val) {
            this.gridData = [];
            if (!val) {
                return;
            }
            this.getUsers(val)
        },
        filters: {
            deep: true,
            handler() {
                this.filterData();
            }
        }
    },
}

</script>

<style scoped lang="scss">
@import "src/assets/styles/primevue";
@import "src/components/views/users/style";

    .width-button{
        max-width: 55px;
        margin: 5px;
    }

    @media (max-width: 1024px){
        .data-table-width{
            width: 100vw;
        }
    }

    @media (min-width: 1025px){
        .data-table-width{
            max-width: calc(100vw - 140px);
        }
    }
</style>
