<template>
    <Dialog :visible.sync="display" modal @hide="closeDialog()">
        <template #header>
            <h3>{{ $t('AppOperationsReportDialog.Relatório') }}</h3>
        </template>

        <div style="width: 35vw">
            <h4 class="text-center mb-3">{{ $t('AppOperationsReportDialog.Ajuste a área a ser exposta no relatório') }}</h4>
            <app-google-maps-operations v-if="selected_map_type" ref="googleMapsOperationsReport" :loading="false"
                                        :google_maps_operations_id="'analyze-operation-maps-report'" :focused="true"
                                        :position_center="position_center" :selected_field="selected_field"
                                        :tiles-data="tilesDataReport" id="google-maps" style="height: 50vh"/>
        </div>

        <template #footer>
            <Button :label="$t('AppOperationsReportDialog.Cancelar')" icon="pi pi-times" class="p-button-text" @click="closeDialog"/>
            <Button :label="$t('AppOperationsReportDialog.Gerar relatório')" icon="pi pi-download" @click="generateReport"/>
        </template>
    </Dialog>
</template>

<script>

import Dialog from "primevue/dialog";
import Button from "primevue/button";
import * as htmlToImage from 'html-to-image';
import AppGoogleMapsOperations from "@/components/views/agricultureV2/operations/AppGoogleMapsOperations.vue";

export default {
    data() {
        return {
            display: false,
            tilesDataReport: {
                requestId: null,
                zooms: [],
                grouped: false,
                color_bounds: [],
                center_tile: false,
                center: {},
                bounds: []
            },
            mapCanvas: null
        }
    },
    methods: {
        closeDialog() {
            this.display = false;
            this.$emit('hideDialog');
        },
        generateReport() {
            htmlToImage
                .toCanvas(document.getElementById('google-maps'),
                    { skipFonts: true, preferredFontFormat: 'woff2', backgroundColor: 'white', includeQueryParams: true })
                .then(canvas => {
                    this.mapCanvas = canvas
                    this.generateCanvas(canvas)
                })
        },
        generateCanvas() {
            let div = document.getElementById('google-maps-operation-report');
            div.innerHTML = "";
            let image = new Image();
            image.id = "pic";
            image.src = this.mapCanvas.toDataURL();
            image.style.width = '100%';
            image.style.height = 'auto';
            div.appendChild(image);
            this.$emit('generateReport');
        }
    },
    props: {
        showDialog: {
            type: Boolean
        },
        operation: {
            type: Object
        },
        selected_map_type: {
            type: Object
        },
        operationMapCenter: {
            type: Object
        },
        selected_field: {
            type: Object
        },
        tilesData: {
            type: Object
        }
    },
    computed: {
        position_center: function () {
            return {lat: this.operationMapCenter.lat, lng: this.operationMapCenter.lon ? this.operationMapCenter.lon : this.operationMapCenter.lng};
        }
    },
    watch: {
        showDialog: function (val) {
            this.display = val;
            if (val) {
                setTimeout(() => {
                    this.tilesDataReport = JSON.parse(JSON.stringify(this.tilesData));
                }, 100)
            }
        }
    },
    components: {
        AppGoogleMapsOperations, Dialog, Button
    }
}
</script>

<style scoped>
</style>
