<template>
    <div style="height: 100%; position: relative">
        <div :id="google_maps_operations_id" style="height: 100%"></div>
        <ProgressSpinner style="position: absolute;top: 40%; left: 45%;z-index: 9999" v-show="loading"/>
    </div>
</template>

<script>

import gmapsInit from '../../../../utils/gmaps';
import {mapGetters} from 'vuex';
import jstsWithHolesToGoogleMaps from "@/mixins/GeometryMixin";
import {GoogleMapsOverlay as DeckOverlay} from '@deck.gl/google-maps';
import * as jsts from 'jsts';
import dateHourFormat from "@/mixins/DateMixin";
import ProgressSpinner from "primevue/progressspinner";

import OperationsService from "../../../../services/OperationsService";
import CoordMapMbtiles from "@/classes/CoordMapMbtiles";

export default {
    mixins: [dateHourFormat, jstsWithHolesToGoogleMaps],
    beforeMount() {
        this.operationsService = new OperationsService();
    },
    async mounted() {
        let mapType = 'hybrid';

        if (this.isUserDemo) {
            mapType = 'satellite'
        }

        let vm = this;
        try {
            const google = await gmapsInit();
            vm.google_maps_reference = google;
            vm.infoWindow = new google.maps.InfoWindow();
            let mapElement = document.getElementById(vm.google_maps_operations_id);
            this.map = new google.maps.Map(mapElement, {
                minZoom: 4,
                zoom: 16,
                center: vm.position_center,
                pan: true,
                mapTypeId: mapType,
                rotateControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                tilt: 0,
                disableDefaultUI: true
            });

            this.heatmap = new this.google_maps_reference.maps.visualization.HeatmapLayer({
                map: vm.map,
            });

            this.overlay = new DeckOverlay();
            this.overlay.setMap(this.map);

            this.drawSelectedField()

            this.map.addListener('click', event => {

                let infoWindowField = new google.maps.InfoWindow();

                if (!this.overlay || !event.pixel) {
                    return;
                }

                const deckPicked = this.overlay._deck.pickObject({
                    x: event.pixel.x,
                    y: event.pixel.y,
                    radius: 4
                });

                if (!deckPicked) {
                    infoWindowField.close();
                    return;
                }

                if (vm.getInfoWindowByIndex(deckPicked.index) >= 0) {
                    return;
                }

                this.google_maps_reference.maps.event.addListener(infoWindowField, 'closeclick', function () {
                    vm.handleCloseClickInfoWindow(this);
                });

            });

            this.map.addListener('bounds_changed', () => this.handleBoundsChanged())
        } catch (error) {
            console.error(error);
        }
    },
    data() {
        return {
            map: null,
            heatMap: {},
            google_maps_reference: {},
            INDEX_OVERLAY_WEATHER: 1,
            INDEX_OVERLAY_TILES_FIELDS: 2,
            INDEX_OVERLAY_TILES: 10,
            overlay: null,
            fieldInfoWindows: [],
            operationsService: null,
            operationsLoading: false
        }
    },
    props: {
        position_center: {
            required: true,
        },
        google_maps_operations_id: {
            type: String
        },
        selected_field: {
            type: Object
        },
        loading: {
            type: Boolean
        },
        tilesData: {
            type: Object
        },
        focused: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        drawSelectedField() {
            let vm = this;

            if (!this.selected_field || !this.selected_field.coordinates) {
                return;
            }

            let reader = new jsts.io.WKTReader();
            let jstsGeom = reader.read(this.selected_field.coordinates);

            let newPolygon = new this.google_maps_reference.maps.Polygon({
                paths: this.jstsWithHolesToGoogleMaps(jstsGeom, this.google_maps_reference.maps),
                strokeColor: '#000000',
                strokeOpacity: 0.8,
                strokeWeight: 3,
                fillColor: '#8f8f8f',
                fillOpacity: 0.1,
                editable: false,
                clickable: false
            });

            newPolygon.setMap(this.map);
            newPolygon.setOptions({zIndex: 0});

            this.fitMapOnScreen(jstsGeom)
        },
        fitMapOnScreen(jstsGeom) {
            let bounds = new this.google_maps_reference.maps.LatLngBounds();
            jstsGeom._shell._points._coordinates.forEach(coords => {
                bounds.extend(new this.google_maps_reference.maps.LatLng(coords.y, coords.x));
            })

            this.map.fitBounds(bounds, 0);
        },
        centerMapOnOperation() {
            let operationBounds = new this.google_maps_reference.maps.LatLngBounds();
            operationBounds.extend(new this.google_maps_reference.maps.LatLng(this.tilesData.bounds[1], this.tilesData.bounds[0]))
            operationBounds.extend(new this.google_maps_reference.maps.LatLng(this.tilesData.bounds[3], this.tilesData.bounds[2]))
            this.map.fitBounds(operationBounds);
        },
        setupWeatherOverlay(overlay) {
            let vm = this;

            const imageMapType = new vm.google_maps_reference.maps.ImageMapType({
                name: 'openweathermap',
                getTileUrl: function (coord, zoom) {
                    return "https://tile.openweathermap.org/map/" + overlay + "/" + zoom + "/" + coord.x +
                        "/" + coord.y + ".png?appid=" + process.env.VUE_APP_OPEN_WEATHER_MAP_KEY
                },
                tileSize: new vm.google_maps_reference.maps.Size(256, 256),
            });
            this.map.overlayMapTypes.setAt(this.INDEX_OVERLAY_WEATHER, imageMapType);
        },
        setUpMapType(type) {
            if (type === 'roadmap') {
                this.map.setMapTypeId(this.google_maps_reference.maps.MapTypeId.ROADMAP);
                return
            }

            this.map.setMapTypeId(this.google_maps_reference.maps.MapTypeId.HYBRID);
        },
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        getInfoWindowByIndex(index) {
            if (!this.fieldInfoWindows || this.fieldInfoWindows.length === 0) {
                return -1;
            }

            for (let i = 0; i < this.fieldInfoWindows.length; i++) {
                if (this.fieldInfoWindows[i].index === index) {
                    return i;
                }
            }
            return -1;
        },
        handleCloseClickInfoWindow(InfoWindow) {
            let index = this.getInfoWindowByIndex(InfoWindow.index);

            if (index >= 0) {
                this.fieldInfoWindows.splice(index, 1)
            }
        },
        clearMap() {
            if (!this.map) {
                return;
            }

            this.map.overlayMapTypes.setAt(this.INDEX_OVERLAY_TILES, null);
            this.map.setOptions({minZoom: null});
            this.map.setOptions({maxZoom: null});
        },
        handleTilesData(data) {
            this.map.overlayMapTypes.setAt(
                this.INDEX_OVERLAY_TILES,
                new CoordMapMbtiles(new this.google_maps_reference.maps.Size(256, 256), data.zooms, data.grouped, data.color_bounds, data.requestId)
            );
        },
        configureMapOptionsByTilesData(data) {
            let minZoom = this.findMinZoomWithTiles(data.zooms);
            let maxZoom = this.findMaxZoomWithTiles(data.zooms);

            if (minZoom && minZoom < 30) {
                this.map.setOptions({minZoom: minZoom});
            }

            if (maxZoom && maxZoom > -1) {
                this.map.setOptions({maxZoom: maxZoom});
            }
        },
        findMinZoomWithTiles(tilesData) {
            let min = 30;

            for (const i in tilesData) {
                if (tilesData[i].tiles.length > 0) {
                    if (min > tilesData[i].zoom) {
                        min = tilesData[i].zoom;
                        break;
                    }
                }
            }
            return min;
        },
        findMaxZoomWithTiles(tilesData) {
            let max = -1;

            for (let i = tilesData.length - 1; i >= 0; i--) {
                if (tilesData[i].tiles.length > 0) {
                    if (max < tilesData[i].zoom) {
                        max = tilesData[i].zoom;
                        break;
                    }
                }
            }
            return max;
        },
        handleBoundsChanged() {
            if (this.focused) {
                this.$emit('boundsChanged', {zoom: this.map.getZoom(), center: this.map.getCenter()});
            }
        },
        updatePosition(zoom, center) {
            this.map.setZoom(zoom)
            this.map.setCenter(center)
        }
    },
    computed: {
        ...mapGetters([
            'isUserDemo'
        ])
    },
    watch: {
        position_center: {
            handler: function (val) {
                if (val && this.map) {
                    if (!this.tilesData.center_tile) {
                        return;
                    }
                    this.map.setCenter(new this.google_maps_reference.maps.LatLng(val.lat, val.lon))
                }
            },
            deep: true
        },
        tilesData: {
            deep: true,
            handler(val) {
                this.clearMap()
                if (!val.zooms || val.zooms.length <= 0) {
                    return
                }
                this.handleTilesData(val);
                this.configureMapOptionsByTilesData(val);
                if (this.tilesData.center_tile) {
                    this.centerMapOnOperation();
                }
            }
        }
    },
    components: {
        ProgressSpinner
    }
}
</script>

<style>
</style>
