<template>
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__head kt-portlet__head--lg no-border-radius">
                <Dropdown v-model="organizationSelected" :options="orgsList"
                          optionLabel="display_name" :placeholder="$t('AppChecklists.Selecione a organização')"
                          class="auto-center dropdown-width" :dataKey="'id'"
                          :filter="true"
                          style="width: 250px">
                    <template #option="slotProps" style="width: 500px">
                        <div v-if="loadingOrgs"> {{ $t('AppChecklists.Carregando, aguarde') }}</div>
                        <div style="padding-right: 20px" v-else-if="slotProps.option.display_name === 'ND'">
                            -
                        </div>
                        <div style="padding-right: 20px;" v-else>
                            {{ slotProps.option.display_name }}
                        </div>
                    </template>
                </Dropdown>

                <i class="pi pi-stopwatch" style="font-size: 2rem"></i>

                <div class="kt-portlet__head-label">
                    <span class="kt-portlet__head-icon">
                    </span>
                </div>
                <div class="kt-portlet__head-toolbar">
                    <div class="kt-portlet__head-wrapper">
                        <app-button type="secondary" @handleClick="back" class="auto-center" :class="{'margin-10-right':
                                    !is_col_12, 'width-button': is_col_12}">
                            <i class="glyphicons glyphicons-arrow-left"></i>
                            <span v-if="!is_col_12">{{ $t('AppChecklists.Voltar') }}</span>
                        </app-button>
                        <app-button type="secondary" class="remove-checklist-button" :class="{'width-button': is_col_12}"
                                    @handleClick="handleRemoveChecklistFromVehicle">
                            <i class="glyphicons glyphicons-erase"></i>
                            <span v-if="!is_col_12">{{ $t('AppChecklists.Limpar Checklist') }}</span>
                        </app-button>
                        <app-button type="secondary" class="checklist-answers-button" :class="{'width-button': is_col_12}"
                                    @handleClick="handleChecklistAnswers">
                            <i class="glyphicons glyphicons-comments"></i>
                            <span v-if="!is_col_12">{{ $t('AppChecklists.Respostas de Checklists') }}</span>
                        </app-button>
                        <app-button type="primary" @handleClick="updateDialog" :class="{'width-button': is_col_12}">
                            <i class="glyphicons glyphicons-plus"></i>
                            <span v-if="!is_col_12">{{ $t('AppChecklists.Novo Checklist') }}</span>
                        </app-button>
                    </div>
                </div>
            </div>
            <div class="card data-table-width">
                <div v-if="organizationSelected">
                    <DataTable :value="checklistsList" :paginator="true" :rows="50" :rowHover="true"
                        :loading="checklistsLoading" :sortOrder="-1" sortField="created_date"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        :rowsPerPageOptions="[10, 25, 50]" dataKey="checklist"
                        :currentPageReportTemplate="$t('AppChecklists.currentPageReportTemplate')"
                        :filters="filters" class="p-datatable-striped" :autoLayout="true" responsiveLayout="scroll">
                        <template #empty>
                            {{ $t('AppChecklists.Nenhum checklist localizado') }}
                        </template>
                        <template #loading>
                            {{ $t('AppChecklists.Carregando checklists, por favor aguarde') }}
                        </template>
                        <Column field="title" :header="$t('AppChecklists.Título')" headerClass="headerClass" bodyClass="bodyClass" :sortable="true"
                            filterMatchMode="contains" :styles="{ 'min-width': '100px' }">
                            <template #body="slotProps">
                                <span>{{ slotProps.data.title }}</span>
                            </template>
                        </Column>
                        <Column field="description" :header="$t('AppChecklists.Descrição')" headerClass="headerClass" bodyClass="bodyClass" :sortable="true"
                            filterMatchMode="contains" :styles="{ 'min-width': '100px' }">
                            <template #body="slotProps">
                                <span>{{ slotProps.data.description }}</span>
                            </template>
                        </Column>
                        <Column field="created_date" :header="$t('AppChecklists.Data de Criação')" headerClass="headerClass" bodyClass="bodyClass" :sortable="true"
                            filterMatchMode="contains" :styles="{ 'min-width': '150px' }">
                            <template #body="slotProps">
                                <span>{{ dateFormat(slotProps.data.created_date)
                                }}</span>
                            </template>
                        </Column>
                        <Column :header="$t('AppChecklists.Ações')" headerClass="headerClass" bodyClass="bodyClass" :styles="{ 'min-width': '80px' }">
                            <template #body="slotProps">
                                <div style="min-width: 50px">
                                    <Button icon="pi pi-upload" @click="handleAssociationButton(slotProps.data)"
                                            class="p-button-rounded upload-btn" v-tooltip.bottom="$t('AppChecklists.Associar veículo')"/>
                                    <Button icon="pi pi-user-edit" @click="editChecklist(slotProps.data.id)"
                                            class="p-button-rounded actions" v-tooltip.bottom="$t('AppChecklists.Editar')"/>
                                    <Button :disabled="deleteConfirmation" icon="pi pi-times"
                                            @click="preDeleteChecklist(slotProps.data.id)"
                                            class="p-button-rounded actions delete" v-tooltip.bottom="$t('AppChecklists.Excluir')"/>
                                </div>
                            </template>
                        </Column>
                    </DataTable>
                </div>

                <div v-else class="initial-text">
                    <h3>{{ $t('AppChecklists.Selecione uma organização') }}</h3>
                </div>
            </div>

            <!--DIALOGO::: ASSOCIAR VEÍCULO-->
            <app-checklists-association-dialog :loading-send-checklist="sendChecklistLoading"
                                               :loading-vehicles="vehiclesChecklistsLoading"
                                               :vehicles-checklists="vehiclesChecklists"
                                               :show-dialog="showAssociateDialog"
                                               :checklist-id="associateChecklistId"
                                               :checklist-title="associateChecklistTitle"
                                               :send-checklist="sendChecklistDialogType"
                                               @closeDialog="closeAssociationDialog()"
                                               @handleSendChecklistToVehicles="sendChecklistToVehicles"
                                               @handleSendEmptyChecklistToVehicles="sendEmptyChecklistToVehicles"
                                               @getVehiclesChecklists="getVehiclesChecklistsByOrg"/>

            <!---DIALOGO::: CONFIRMAR ASSOCIAÇÃO-->
            <Dialog :visible="vehicleHasChecklistFlag" :modal="true" :closable="false">
                <div v-if="!sendChecklistLoading" class="d-flex flex-column align-items-center" style="width: 25vw">
                    <h3>{{ $t('AppChecklists.Atenção!') }}</h3>
                    {{ $t('AppChecklists.Dentre os veículos escolhidos há dispositivos que já possuem um checklist, deseja continuar?') }}
                    <p><b>{{ $t('AppChecklists.Obs, os checklists já existentes serão sobrescritos no veículo selecionado') }}</b></p>
                </div>
                <div v-else class="d-flex flex-column align-items-center" style="width: 25vw">
                    <ProgressSpinner/>
                    <p>{{ $t('AppChecklists.Enviando ...') }}</p>
                </div>
                <template #footer>
                    <Button :label="$t('AppChecklists.Cancelar')" icon="pi pi-times" class="p-button-text" :disabled="disableButton"
                            @click='closeWarningDialog()'/>
                    <Button :disabled="disableButton" :label="$t('AppChecklists.Confirmar')" icon="pi pi-check"
                            @click="sendChecklistToVehicles(checklistToSend)"/>
                </template>
            </Dialog>


            <!--DIALOGO::: NOVO CHECKLIST-->
            <app-checklists-new-dialog :organizations="orgsList" :show-dialog="getNewChecklist"
                                       :checklists="dialogCopyChecklists"
                                       :loading-checklists="dialogCopyChecklistsLoading"
                                       @closeDialog="closeNewChecklistDialog"
                                       @newChecklistClicked="handleNewChecklistClicked"
                                       @getOrganizationChecklists="handleDialogGetChecklists"
                                       @copyChecklistClicked="newPreExistentChecklist"/>

            <!--DIALOGO::: CONFIRMAR EXCLUSÃO-->
            <Dialog :header="$t('AppChecklists.Deseja realmente excluir')" :visible="deleteConfirmation" :modal="true" :closable="false">
                <div class="dialog-delete">
                    <app-button style="margin-right: 1rem; background-color:#ff0000" type="primary"
                                @handleClick="cancelDelete()">{{ $t('AppChecklists.Cancelar') }}
                    </app-button>
                    <app-button :class="spinnerClass" :disabled="yes" type="primary" @handleClick="deleteChecklist()">
                        {{ $t('AppChecklists.Sim') }}
                    </app-button>
                </div>
            </Dialog>

            <app-checklists-answers-dialog :show-dialog="showAnswersDialog" :organization="this.organizationSelected"
                                           @closeDialog="handleCloseAnswersDialog"/>

        </div>
    </div>
</template>

<script>
import { router } from '../../../router';
import AppHeader from "../../layout/AppHeader";
import AppButton from "../../common/AppButton";
import dateFormat from "../../../mixins/DateMixin";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dropdown from 'primevue/dropdown';
import Dialog from 'primevue/dialog';
import SelectButton from 'primevue/selectbutton';
import Button from 'primevue/button';
import ChecklistService from "../../../services/ChecklistService";
import OrganizationsService from "../../../services/OrganizationsService";
import Tooltip from 'primevue/tooltip';
import ProgressSpinner from 'primevue/progressspinner';
import AppChecklistsNewDialog from "@/components/views/checklist/AppChecklistsNewDialog";
import AppChecklistsAssociationDialog from "@/components/views/checklist/AppChecklistsAssociationDialog";
import orderCustomFieldByNaturalOrder from "@/mixins/ArrayMixin";
import AppChecklistsAnswersDialog from "@/components/views/checklist/AppChecklistsAnswersDialog.vue";

export default {
    mixins: [dateFormat, orderCustomFieldByNaturalOrder],
    beforeMount() {
        this.checklistService = new ChecklistService();
        this.organizationsService = new OrganizationsService();
        this.getOrganizations();
    },
    mounted(){
        this.updateDialog_col_12 = this.isScreenMobile ? '85vw' : '30vw';
        this.is_col_12 = this.isScreenMobile;
    },
    data() {
        return {
            is_col_12: false,
            updateDialog_col_12: '',
            deleteConfirmation: false,
            yes: false,
            orgDeleteID: null,
            initialOrg: null,
            //organizations
            organizationsService: null,
            orgsList: null,
            organizationSelected: null,
            loadingOrgs: true,
            //checklists
            checklistService: null,
            checklistsList: null,
            checklistsLoading: true,
            filters: {},
            //new-checklist
            getNewChecklist: false,
            dialogCopyChecklists: [],
            dialogCopyChecklistsLoading: false,
            //associate vehicle
            showAssociateDialog: false,
            vehiclesChecklistsLoading: false,
            vehiclesChecklists: null,
            associateChecklistId: null,
            associateChecklistTitle: null,
            sendChecklistLoading: false,
            vehicleHasChecklistFlag: false,
            disableButton: false,
            checklistToSend: null,
            //Type Dialog
            sendChecklistDialogType: true,
            showAnswersDialog: false,
        }
    },
    computed: {
        spinnerClass: function () {
            return this.yes ?
                'kt-spinner kt-spinner--v2 kt-spinner--right kt-spinner--sm kt-spinner--dark' : ''
        },
        isScreenMobile: function() {
            return window.screen.width <= 1024;
        }
    },
    methods: {
        getOrganizations() {
            this.loadingOrgs = true;
            this.organizationsService.getAllV3('APPCONFIGS').then((results) => {
                this.populateOrgsList(results);
                this.loadFilteredOptions();
            }).catch((error) => {
                if (!error.response || error.response.status !== 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary:this.$t('AppChecklists.Não foi possível completar a operação'),
                        life: 5000
                    });
                    return;
                }
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppChecklists.Você não tem acesso a esta funcionalidade'),
                    life: 5000
                });
                console.log(error)
            }).finally(() => {
                this.loadingOrgs = false;
            })
        },
        populateOrgsList(orgs) {
            this.orgsList = this.orderCustomFieldByNaturalOrder(orgs, "name");
        },
        loadFilteredOptions() {
            this.storedChosenOptions = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_BAR_OPTIONS));

            if (!this.storedChosenOptions || !this.storedChosenOptions.clients || this.storedChosenOptions.clients.length === 0) {
                this.storedChosenOptions = {};
                return;
            }

            this.organizationSelected = this.storedChosenOptions['clients'].filter(a => this.orgsList.some(b => a.id === b.id))[0];
        },
        getChecklists(organization) {
            this.checklistsList = [];
            this.checklistService.getChecklistsByOrg(organization.id)
                .then((response) => {
                    this.checklistsList = response;
                    this.getVehiclesChecklistsByOrg();
                }).catch((error) => {
                if (!error.response || error.response.status !== 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppChecklists.Não foi possível completar a operação'),
                        life: 5000
                    });
                    return;
                }
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppChecklists.Você não tem acesso a esta funcionalidade'),
                    life: 5000
                });
            }).finally(() => {
                this.checklistsLoading = false;
            })
        },
        updateDialog() {
            if (this.organizationSelected) {
                this.getNewChecklist = true;
            } else {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppChecklists.Selecione uma organização'),
                    life: 5000
                })
            }
        },
        editChecklist(checklist_id) {
            router.push({
                name: 'new-checklist',
                query: {org_id: this.organizationSelected.id, checklist_id: checklist_id}
            });
        },
        preDeleteChecklist(org_id) {
            this.orgDeleteID = org_id;
            this.deleteConfirmation = true;
        },
        cancelDelete() {
            this.orgDeleteID = null;
            this.deleteConfirmation = false;
        },
        deleteChecklist() {
            this.yes = true;
            this.checklistService.deleteChecklist(this.orgDeleteID)
                .then((response) => {
                    this.checklistsList = null;
                    this.$toast.add({
                        severity: 'success',
                        summary: this.$t('AppChecklists.Checklist deletado com sucesso!'),
                        life: 5000
                    })
                    this.getChecklists(this.organizationSelected);
                }).catch((error) => {
                if (!error.response || error.response.status !== 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppChecklists.Não foi possível completar a operação'),
                        life: 5000
                    });
                    return;
                }
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppChecklists.Você não tem acesso a esta funcionalidade'),
                    life: 5000
                });
            }).finally(() => {
                this.deleteConfirmation = false;
                this.orgDeleteID = null;
                this.yes = false;
            })
        },
        closeNewChecklistDialog() {
            this.getNewChecklist = false;
        },
        handleNewChecklistClicked() {
            router.push({name: 'new-checklist', query: {org_id: this.organizationSelected.id}});
        },
        handleDialogGetChecklists(organization) {
            this.dialogCopyChecklistsLoading = true;
            this.checklistService.getChecklistsByOrg(organization.id)
                .then((response) => {
                    this.dialogCopyChecklists = response;
                }).catch((error) => {
                if (!error.response || error.response.status !== 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppChecklists.Não foi possível completar a operação'),
                        life: 5000
                    });
                    return;
                }
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppChecklists.Você não tem acesso a esta funcionalidade'),
                    life: 5000
                });
            }).finally(() => {
                this.dialogCopyChecklistsLoading = false;
            })
        },
        newPreExistentChecklist(organization, checklist) {
            router.push({
                name: 'new-checklist',
                query: {org_id: this.organizationSelected.id, org_checklist_id: checklist.id}
            })
        },
        handleAssociationButton(checklist) {
            this.sendChecklistDialogType = true;
            this.associateChecklistId = checklist.id;
            this.associateChecklistTitle = checklist.title;
            this.showAssociateDialog = true;
        },
        closeAssociationDialog() {
            this.showAssociateDialog = false;
            this.associateChecklistId = null;
            this.associateChecklistTitle = null;
        },
        getVehiclesChecklistsByOrg() {
            this.vehiclesChecklistsLoading = true;
            this.checklistService.getVehiclesChecklists(this.organizationSelected.id)
                .then((response) => {
                    this.vehiclesChecklists = response;
                }).catch((error) => {
                if (!error.response || error.response.status !== 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppChecklists.Não foi possível completar a operação'),
                        life: 5000
                    });
                    return;
                }
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppChecklists.Você não tem acesso a esta funcionalidade'),
                    life: 5000
                });
            }).finally(() => {
                this.vehiclesChecklistsLoading = false;
            });
        },
        closeWarningDialog() {
            this.vehicleHasChecklistFlag = false;
            this.disableButton = false;
        },
        sendChecklistToVehicles(data) {
            this.checklistToSend = data;
            data.vehicles.forEach(vehicle => {
                if (vehicle.status) {
                    this.vehicleHasChecklistFlag = true;
                }
            })

            if (this.vehicleHasChecklistFlag) {
                return
            }

            this.sendChecklistLoading = true;
            this.disableButton = true;
            this.checklistService.sendChecklistToVehicles(this.associateChecklistId, data)
                .then(() => {
                    this.$toast.add({
                        severity: 'success',
                        summary: this.$t('Sucesso'),
                        detail: this.$t('AppChecklists.Checklist enviado'),
                        life: 5000
                    });
                    this.hideSendChecklistDialog();
                    this.closeWarningDialog();
                }).catch((error) => {
                if (!error.response || error.response.status !== 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppChecklists.Não foi possível completar a operação'),
                        life: 5000
                    });
                    return;
                }
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppChecklists.Você não tem acesso a esta funcionalidade'),
                    life: 5000
                });
            }).finally(() => {
                this.sendChecklistLoading = false;
                this.getVehiclesChecklistsByOrg(this.organizationSelected);
            })
        },
        sendEmptyChecklistToVehicles(data) {
            this.checklistToSend = data;
            data.vehicles.forEach(vehicle => {
                if (vehicle.status) {
                    this.vehicleHasChecklistFlag = true;
                }
            })

            if (this.vehicleHasChecklistFlag) {
                return
            }

            this.sendChecklistLoading = true;
            this.disableButton = true;
            this.checklistService.sendEmptyChecklistToVehicles(this.organizationSelected.id, data)
                .then(() => {
                    this.$toast.add({
                        severity: 'success',
                        summary: this.$t('Sucesso'),
                        detail: this.$t('AppChecklists.Checklist enviado'),
                        life: 5000
                    });
                    this.hideSendChecklistDialog();
                    this.closeWarningDialog();
                }).catch((error) => {
                if (!error.response || error.response.status !== 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppChecklists.Não foi possível completar a operação'),
                        life: 5000
                    });
                    return;
                }
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppChecklists.Você não tem acesso a esta funcionalidade'),
                    life: 5000
                });
            }).finally(() => {
                this.sendChecklistLoading = false;
                this.getVehiclesChecklistsByOrg(this.organizationSelected);
            })
        },
        hideSendChecklistDialog() {
            this.showAssociateDialog = false;
            this.associateChecklistTitle = null;
            this.associateChecklistId = null;
        },
        handleRemoveChecklistFromVehicle() {
            this.sendChecklistDialogType = false;
            this.showAssociateDialog = true;
        },
        back() {
            router.go(-1);
        },
        handleOrganizationChange() {
            if(this.organizationSelected) {
                this.getChecklists(this.organizationSelected);
                this.saveChosenOptionsToLocalStorage();
            }
        },
        saveChosenOptionsToLocalStorage() {
            if (!this.storedChosenOptions) {
                this.storedChosenOptions = {}
            }

            this.storedChosenOptions['clients'] = [this.organizationSelected];
            this.storedChosenOptions['vehicles'] = [];
            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_BAR_OPTIONS, JSON.stringify(this.storedChosenOptions));
        },
        handleChecklistAnswers() {
            this.showAnswersDialog = true
        },
        handleCloseAnswersDialog() {
            this.showAnswersDialog = false
        }
    },
    watch: {
        organizationSelected: function (val) {
            this.handleOrganizationChange();
        },
    },
    directives: {
        tooltip: Tooltip
    },
    components: {
        AppHeader,
        AppButton,
        ChecklistService,
        OrganizationsService,
        DataTable,
        Column,
        Dropdown,
        SelectButton,
        Dialog,
        Button,
        ProgressSpinner,
        AppChecklistsNewDialog,
        AppChecklistsAssociationDialog,
        AppChecklistsAnswersDialog
    }
}

</script>

<style scoped lang="scss">
@import "src/assets/styles/primevue";

.existent-list {
    width: 300px;
}

.dialog-select {
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.dialog-list {
    max-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.dialog-buttons {
    margin-top: 2rem;
}

.dialog-delete {
    display: flex;
    align-items: center;
    justify-content: right;
    height: 50px;
    width: 250px;
}

.initial-text {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
}

.checklist-answers-button {
    margin-right: 0.5rem;
    background-color: #9355b5;
    color: white;
}

.checklist-answers-button:enabled:hover {
    background-color: #59346e;
    color: white;
}

.delete {
    margin-left: 5px;
    background-color: #FF3030;
    border: none;
}

.delete:enabled:hover {
    background-color: #e01414;
}

.dropdown-width{
    width: 250px;
}

.upload-btn {
    margin-right: 5px;
    border: none;
    background-color: #3bbeb4;
}

.upload-btn:enabled:hover {
    background-color: #25736d;
}

.remove-checklist-button {
    margin-right: 0.5rem;
    background-color: #2196f3;
    color: white;
}

.remove-checklist-button:enabled:hover {
    background-color: #2779b5;
    color: white;
}

.width-button{
    max-width: 55px;
    margin: 5px;
}

.margin-10-right{
    margin-right: 10px;
}

@media (min-width: 1025px) {
    .data-table-width {
        max-width: calc(100vw - 140px);
    }
}

@media (max-width: 1024px) {
    .data-table-width {
        width: 100vw;
    }

    .dropdown-width{
        max-width: 180px;
    }
}
</style>
