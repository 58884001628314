import axios from '../axios/axios-auth'

export default class AlertsCoaService {

    getAlertsCoa(orgId) {
        return axios.get(`/parametric-failures-service/v1/failuresCoa/organization/${orgId}`).then(response => response.data)
    }

    getHistoricalAlert(orgId, startDate, endDate) {
        return axios.get(`/parametric-failures-service/v1/failuresCoa/date/${startDate}/${endDate}/organization/${orgId}`).then(response => response.data)
    }

    getStatusHistoricalAlert(orgId, failuresId) {
        return axios.get(`/parametric-failures-service/v1/failureStatus/${failuresId}/organization/${orgId}`).then(response => response.data)
    }

    postStatusHistoricalAlert(orgId, failuresId, params) {
        return axios.post(`/parametric-failures-service/v1/failureStatus/${failuresId}/organization/${orgId}`, params).then(response => response.data)
    }

    getAlerts(orgId) {
        return axios.get(`/parametric-failures-service/v1/failures/organization/${orgId}`).then(response => response.data)
    }

    createAlert(orgId, params){
        return axios.post(`/parametric-failures-service/v1/organization/${orgId}/failure`, params).then(response => response.data)
    }

    deleteAlert(orgId, failureId){
        return axios.delete(`/parametric-failures-service/v1/organization/${orgId}/failure/${failureId}`).then(response => response.data)
    }

    editAlert(orgId, params, failureId){
        return axios.patch(`/parametric-failures-service/v1/organization/${orgId}/failure/${failureId}`, params).then(response => response.data)
    }

    getSensorsByOrg(orgId) {
        return axios.get(`/parametric-failures-service/v1/sensors/organization/${orgId}`).then(response => response.data)
    }

    createSensor(orgId, params){
        return axios.post(`/parametric-failures-service/v1/organization/${orgId}/sensor`, params).then(response => response.data)
    }

    deleteSensor(orgId, sensorId){
        return axios.delete(`/parametric-failures-service/v1/organization/${orgId}/sensor/${sensorId}`).then(response => response.data)
    }

    editSensor(orgId, params, sensorId){
        return axios.patch(`/parametric-failures-service/v1/organization/${orgId}/sensor/${sensorId}`, params).then(response => response.data)
    }

    getSPNlist(orgId){
        return axios.get(`/parametric-failures-service/v1/dictionarySpn/organization/${orgId}`).then(response => response.data)
    }

}
