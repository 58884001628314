<template>
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__head kt-portlet__head--lg no-border-radius">
                <div class="row center justify-content-between" style="width: 100%">
                    <div>
                        <div v-if="operationAnalyze" key="map-type">
                            <Dropdown v-model="selectedOperation" :options="operations" optionLabel="name" dataKey="id"
                                      :placeholder="$t('AppOperations.Operação')" class="dropdown-width-org dropdown-250" filter>
                                <template #value="slotProps">
                                    <div v-if="slotProps.value">
                                        {{ slotProps.value.name }} -
                                        {{ dateFormat(slotProps.value.start.valueOf() / 1000, 'GMT') }} {{ $t('AppOperations.a') }}
                                        {{ dateFormat(slotProps.value.end.valueOf() / 1000, 'GMT') }}
                                    </div>
                                </template>
                                <template #option="slotProps">
                                    {{ slotProps.option.name }} -
                                    {{ dateFormat(slotProps.option.start.valueOf() / 1000, 'GMT') }} {{ $t('AppOperations.a') }}
                                    {{ dateFormat(slotProps.option.end.valueOf() / 1000, 'GMT') }}
                                </template>
                            </Dropdown>
                            <Dropdown v-model="selectedMapType" :options="mapTypes" optionLabel="title" dataKey="value"
                                      :placeholder="$t('AppOperations.Tipo de mapa')" class="dropdown-ml dropdown-250" filter>
                            </Dropdown>
                        </div>
                        <div v-else-if="comparingOperations" key="operation-compare" class="d-flex">
                            <Dropdown v-model="compareOperationOne" :options="operations" optionLabel="name" filter
                                      :placeholder="$t('AppOperations.Operação')" class="dropdown-width-org dropdown-250" dataKey="id">
                                <template #value="slotProps">
                                    <div v-if="slotProps.value">
                                        {{ slotProps.value.name }} -
                                        {{ dateFormat(slotProps.value.start.valueOf() / 1000, 'GMT') }} {{ $t('AppOperations.a') }}
                                        {{ dateFormat(slotProps.value.end.valueOf() / 1000, 'GMT') }}
                                    </div>
                                </template>
                                <template #option="slotProps" style="width: 500px">
                                        {{ slotProps.option.name }} -
                                        {{ dateFormat(slotProps.option.start.valueOf() / 1000, 'GMT') }} {{ $t('AppOperations.a') }}
                                        {{ dateFormat(slotProps.option.end.valueOf() / 1000, 'GMT') }}
                                </template>
                            </Dropdown>
                            <Dropdown v-model="selectedMapTypeOne" :options="mapTypesOne" optionLabel="title" filter
                                      dataKey="value" :placeholder="$t('AppOperations.Tipo de mapa')" class="dropdown-ml dropdown-250">
                            </Dropdown>
                            <Dropdown v-model="compareOperationTwo" :options="operations" optionLabel="name"
                                      :placeholder="$t('AppOperations.Operação')" class="dropdown-ml dropdown-250" dataKey="id" filter>
                                <template #value="slotProps">
                                    <div v-if="slotProps.value">
                                        {{ slotProps.value.name }} -
                                        {{ dateFormat(slotProps.value.start.valueOf() / 1000, 'GMT') }} {{ $t('AppOperations.a') }}
                                        {{ dateFormat(slotProps.value.end.valueOf() / 1000, 'GMT') }}
                                    </div>
                                </template>
                                <template #option="slotProps" style="width: 500px">
                                        {{ slotProps.option.name }} -
                                        {{ dateFormat(slotProps.option.start.valueOf() / 1000, 'GMT') }} {{ $t('AppOperations.a') }}
                                        {{ dateFormat(slotProps.option.end.valueOf() / 1000, 'GMT') }}
                                </template>
                            </Dropdown>
                            <Dropdown v-model="selectedMapTypeTwo" :options="mapTypesTwo" optionLabel="title" filter
                                      dataKey="value" :placeholder="$t('AppOperations.Tipo de mapa')" class="dropdown-ml dropdown-250">
                            </Dropdown>
                        </div>
                        <div v-else key="operation-type">
                            <Dropdown v-model="selectedOrg" :options="orgsList" optionLabel="display_name" filter
                                      :placeholder="$t('AppOperations.Organização')" class="dropdown-width-org dropdown-250" dataKey="id">
                                <template #option="slotProps" style="width: 500px">
                                    <div v-if="loadingClients">{{ $t('AppOperations.Carregando, aguarde') }}</div>
                                    <div v-else>
                                        {{ slotProps.option.display_name }}
                                    </div>
                                </template>
                            </Dropdown>
                            <Dropdown v-model="selectedField" :options="fieldsList" optionLabel="name" dataKey="id"
                                      :placeholder="$t('AppOperations.Talhão')" class="dropdown-ml dropdown-250" filter ref="fieldsDropDown">
                                <template #option="slotProps">
                                    <div v-if="loadingFields">{{ $t('AppOperations.Carregando, aguarde') }}</div>
                                    <div v-else>
                                        {{ slotProps.option.name }}
                                    </div>
                                </template>
                            </Dropdown>
                        </div>
                    </div>

                    <div v-show="show_back" class="button-back">
                        <app-button v-show="!comparingOperations" type="info" @handleClick="openReportDialog"
                                    class="mr-2">
                            <i class="glyphicons glyphicons-file"></i> <span v-show="!is_col_12">
                            {{ $t('AppOperations.Exportar') }}
                        </span>
                        </app-button>
                        <app-button v-show="!comparingOperations" type="primary" @handleClick="compareOperations"
                                    class="mr-2">
                            <i class="glyphicons glyphicons-map"></i> <span v-show="!is_col_12">
                            {{ $t('AppOperations.Comparar') }}
                        </span>
                        </app-button>
                        <app-button type="secondary" @handleClick="backToOperationsList">
                            <i class="glyphicons glyphicons-arrow-left"></i> <span v-show="!is_col_12">
                            {{ $t('AppOperations.Voltar') }}
                        </span>
                        </app-button>
                    </div>
                </div>
            </div>
            <div class="kt-portlet__body no-padding">
                <div class="agro-body operations" v-show="selectedField && !loadingOperations && !comparingOperations"
                     :class="timelinePaneSize < 1 ? $t('AppOperations.panel-show') : $t('AppOperations.panel-hide')">
                    <splitpanes class="default-theme" horizontal @resize="redrawTimeline($event[1].size)"
                                @splitter-click="togglePane">
                        <pane :size="100 - timelinePaneSize">
                            <app-operations-list v-if="!operationAnalyze" ref="operationsList"
                                                 :operations="operationsToShow" :selected_field="selectedField"
                                                 @allClicked="allClicked" @scheduledClicked="scheduledClicked"
                                                 @doneClicked="doneClicked" @hoverOperation="hoverOperation"
                                                 @operationClickedFromList="operationClickedFromList"
                                                 @leaveOperation="leaveOperation"/>

                            <app-operations-analyze v-else class="operations-analyze" :operations="operations"
                                                    :selected_org="selectedOrg" :selected_field="selectedField"
                                                    :selected_operation="selectedOperation" :field_center="fieldCenter"
                                                    :selected_map_type="selectedMapType" :map_types="mapTypes"
                                                    ref="operations_analyze"/>
                        </pane>
                        <pane :size="timelinePaneSize" style="z-index: 1">
                            <div class="timeline-body">
                                <app-timeline ref="timeline" class="analyze-operations-timeline" :items="itemsToShow"
                                              @handleItemOver="handleItemOver" @handleItemOut="handleItemOut"
                                              @handleSelect="handleSelect"/>
                            </div>
                        </pane>
                    </splitpanes>
                </div>
                <div v-show="!selectedField">
                    <div class="row no-gutters text-center">
                        <div class="col-12">
                            <h3>
                                {{ $t('AppOperations.Selecione a organização e um talhão para analisar as operações') }}
                            </h3>
                        </div>
                    </div>
                </div>
                <div v-show="loadingOperations">
                    <ProgressSpinner style="display: flex;align-items: center;justify-content: center;height: 40px"/>
                </div>
                <div v-if="comparingOperations">
                    <app-operations-compare class="operations-compare" :operation_one="compareOperationOne"
                                            :map_type_one="selectedMapTypeOne" :operation_two="compareOperationTwo"
                                            :map_type_two="selectedMapTypeTwo" :operations="operations"
                                            :map_types_one="mapTypesOne" :map_types_two="mapTypesTwo"
                                            :selected_field="selectedField" :field_center="fieldCenter"/>
                </div>
            </div>
        </div>

        <Dialog :visible.sync="showCompareDialog" :style="{width: '400px'}" header="Comparar" modal>
            <div>
                <perfect-scrollbar id="container">
                    <div v-for="operation in operations" :id="operation.name" class="operation-compare-item"
                         @click="handleClickCompareOperation(operation)">
                        {{ operation.name }} - {{ dateFormat(operation.start.valueOf() / 1000, 'GMT') }} {{ $t('AppOperations.a') }}
                        {{ dateFormat(operation.end.valueOf() / 1000, 'GMT') }}
                    </div>
                </perfect-scrollbar>
            </div>
        </Dialog>
    </div>
</template>

<script>
import OperationsService from "@/services/OperationsService";
import FieldsService from "@/services/FieldsService";
import OrganizationsService from "@/services/OrganizationsService";

import AppButton from "@/components/common/AppButton";
import AppTimeline from "@/components/views/agricultureV2/operations/AppOperationsTimeline";
import AppOperationsAnalyze from "@/components/views/agricultureV2/operations/AppOperationsAnalyze";
import AppOperationsList from "@/components/views/agricultureV2/operations/AppOperationsList";
import AppOperationsCompare from "@/components/views/agricultureV2/operations/AppOperationsCompare";
import {MAP_TYPES, OPERATIONS_LIST} from "@/components/views/agricultureV2/AppAgricultureV2Options";

import 'splitpanes/dist/splitpanes.css'
import {Pane, Splitpanes} from 'splitpanes'

import Dialog from "primevue/dialog";
import Dropdown from 'primevue/dropdown';
import ProgressSpinner from "primevue/progressspinner";

import * as jsts from 'jsts';
import gmapsInit from "@/utils/gmaps";
import dateFormat from "@/mixins/DateMixin";
import jstsWithoutHolesToGoogleMaps from "@/mixins/GeometryMixin";

export default {
    mixins: [dateFormat, jstsWithoutHolesToGoogleMaps],
    async mounted() {
        this.google_maps_reference = await gmapsInit();
        this.fieldsService = new FieldsService();
        this.operationsService = new OperationsService();
        this.organizationsService = new OrganizationsService();
        this.getOrgs();
        this.is_col_12 = this.isScreenMobile;
    },
    data() {
        return {
            google_maps_reference: null,
            is_col_12: false,
            operations: [],
            show_back: false,
            operationsToShow: [],
            orgsList: null,
            selectedOrg: null,
            loadingClients: true,
            fieldsService: null,
            operationsService: null,
            fieldsList: [],
            loadingFields: false,
            selectedField: null,
            loadingOperations: false,
            operationAnalyze: false,
            selectedOperation: null,
            selectedMapType: null,
            mapTypes: [],
            mapTypesOne: [],
            mapTypesTwo: [],
            comparingOperations: false,
            compareOperationOne: null,
            selectedMapTypeOne: null,
            compareOperationTwo: null,
            selectedMapTypeTwo: null,
            items: [],
            itemsToShow: [],
            timeline: null,
            fieldCenter: {lat: -25.407688, lng: -49.253990},
            changedOrg: false,
            timelinePaneSize: 20,
            showCompareDialog: false
        }
    },
    methods: {
        allClicked() {
            this.operationsToShow = this.items
            this.initializeItemsToShowFromOperations(this.operationsToShow);
        },
        scheduledClicked() {
            let operationsTemp = [];
            this.items.forEach(item => {
                if (item.status === 'scheduled') {
                    operationsTemp.push(item)
                }
            })
            this.operationsToShow = operationsTemp;
            this.initializeItemsToShowFromOperations(this.operationsToShow);
        },
        doneClicked() {
            let operationsTemp = [];
            this.items.forEach(item => {
                if (item.status === 'done') {
                    operationsTemp.push(item)
                }
            })
            this.operationsToShow = operationsTemp;
            this.initializeItemsToShowFromOperations(this.operationsToShow);
        },
        handleSelect(event) {
            this.selectOperationFromTimeline(event.items[0])
        },
        handleItemOver(event) {
            if (!this.selectedOperation) {
                this.$refs.operationsList.timelineHover(event.item);
            }
        },
        handleItemOut(event) {
            if (!this.selectedOperation) {
                this.$refs.operationsList.timelineHoverOut(event.item);
            }
        },
        compareOperations() {
            this.showCompareDialog = true;
            this.compareOperationOne = this.selectedOperation
        },
        openReportDialog() {
            this.$refs.operations_analyze.openReportDialog();
        },
        backToOperationsList() {
            this.show_back = false;
            this.operationAnalyze = false;
            this.comparingOperations = false;
            this.compareOperationOne = null;
            this.compareOperationTwo = null;
            this.selectedOperation = null;
            this.selectedMapType = null;
            this.allClicked();
        },
        handleClickCompareOperation(operationToCompare) {
            this.compareOperationTwo = operationToCompare;
            this.showCompareDialog = false;
            this.comparingOperations = true;
            this.operationAnalyze = false;
        },
        async operationClickedFromList(operation) {
            this.allClicked();
            await this.sleep(1);

            this.selectedOperation = operation;
            this.timeline.unselectAll();
            this.timeline.clearHover(operation);
            this.timeline.setSelection(operation.id);
            this.timeline.setFocusItem(operation);
            this.goToAnalyzeOperation();
        },
        selectOperationFromTimeline(itemId) {
            let operation = this.operations.find(obj => {
                return obj.id === itemId
            })

            if (!operation) {
                return;
            }

            this.operationClickedFromList(operation);
        },
        goToAnalyzeOperation() {
            this.show_back = true;
            this.operationAnalyze = true;
        },
        hoverOperation(operation) {
            this.timeline.setFocusItem(operation);
            this.timeline.setHover(operation);
        },
        leaveOperation(operation) {
            this.timeline.clearHover(operation);
        },
        redrawTimeline(panelSize) {
            this.timelinePaneSize = panelSize
            this.timeline.redraw();
        },
        togglePane() {
            if (this.timelinePaneSize > 1) {
                this.timelinePaneSize = 0
                this.timeline.zoomOut(1);
                return
            }

            this.timelinePaneSize = 20
            this.timeline.zoomIn(1);
        },
        getOrgs() {
            this.organizationsService.getAllV3('AGRICULTURE')
                .then((response) => {
                    this.orgsList = response.sort((a, b) => a.name.localeCompare(b.name));
                    this.selectSavedOrg();
                }).catch((error) => {
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppOperations.Não foi possivel buscar as organizações'),
                    life: 5000
                });
            }).finally(() => {
                this.loadingClients = false;
            })
        },
        selectSavedOrg() {
            if (this.orgsList.length === 1) {
                this.selectedOrg = this.orgsList[0];
                return;
            }

            let storedChosenOptions = {};
            storedChosenOptions = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_AGRO_FILTER_OPTIONS));

            if (storedChosenOptions && storedChosenOptions.selectedOrg && this.orgsList.find(obj => {
                return obj.id === storedChosenOptions.selectedOrg.id
            })) {
                this.selectedOrg = storedChosenOptions.selectedOrg;
            }
        },
        getFieldsFromOrg(val) {
            this.loadingFields = true;
            this.fieldsList = [];
            let fieldSelected = false
            this.fieldsService.getFieldsSummaryV3(val.id).then((response) => {
                this.fieldsList = response.fields.sort((a, b) => a.name.localeCompare(b.name));

                this.fieldsList.forEach(field => {
                    field.area = this.calculateArea(field);
                })

                if (!this.changedOrg) {
                    fieldSelected = this.selectSavedField();
                }
            }).catch((error) => {
                if (!error.response || error.response.status !== 403) {
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppOperations.Não foi possivel completar a operação, Tente novamente'),
                        life: 5000
                    });
                    return;
                }
                this.$toast.add({
                    severity: 'error',
                    summary: this.$t('AppOperations.Você não tem acesso a esta funcionalidade'),
                    life: 5000
                });
            }).finally(() => {
                this.loadingFields = false;
                if (!fieldSelected) {
                    this.$refs.fieldsDropDown.show();
                }
                this.changedOrg = true;
            })
        },
        selectSavedField() {
            if (this.fieldsList.length === 1) {
                this.selectedField = this.fieldsList[0];
                return true;
            }

            let storedChosenOptions = {};
            storedChosenOptions = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_AGRO_FILTER_OPTIONS));

            if (storedChosenOptions && storedChosenOptions.selectedField && this.fieldsList.find(obj => {
                return obj.id === storedChosenOptions.selectedField.id
            })) {
                this.selectedField = storedChosenOptions.selectedField;
                return true;
            }

            return false;
        },
        getOperations() {
            this.loadingOperations = true;

            this.operationsService.getOperations(this.selectedField.id)
                .then(async (result) => {
                    this.initOperationsFromDatabase(result);
                    await this.initTimeline()
                    this.timeline.refresh()
                })
                .catch(error => {
                    this.$toast.add({
                        severity: 'error',
                        summary: this.$t('AppOperations.Não foi possível buscar as operações associadas a este talhão'),
                        life: 5000
                    });
                }).finally(() => {
                this.loadingOperations = false;
            })
        },
        getContentIcon(name) {
            for (const element of OPERATIONS_LIST) {
                if (element.name === name) {
                    return element.src;
                }
            }
            return require("../../../../assets/icons/icone-task.svg");
        },
        createTimelineContent(name) {
            let wrapper = document.createElement("div");
            let content = document.createElement("div");

            content.textContent = name;
            wrapper.appendChild(content);

            let wrapperImg = document.createElement("div");

            let img = document.createElement("IMG");
            img.src = this.getContentIcon(name);
            img.width = "30";
            img.color = "white";
            wrapperImg.appendChild(img);

            wrapper.appendChild(wrapperImg);

            return wrapper;
        },
        initOperationsFromDatabase(response) {
            let itemsTemp = [];

            response.forEach(operation => {
                itemsTemp.push({
                    id: operation.id,
                    type: 'range',
                    operation_type: operation.operation_type,
                    name: this.getOperationLabel(operation.operation_type),
                    content: this.createTimelineContent(this.getOperationLabel(operation.operation_type)),
                    className: 'operation-analyze',
                    group: 'operation',
                    start: new Date(operation.start_date * 1000),
                    end: new Date(operation.end_date * 1000),
                    length: operation.end_date - operation.start_date,
                    status: this.getOperationStatus(operation.end_date)
                })
            })

            this.operations = itemsTemp;
            this.operationsToShow = this.operations;

            itemsTemp = itemsTemp.sort((a, b) => b.length - a.length);
            this.items = itemsTemp;
            this.itemsToShow = itemsTemp;

            setTimeout(() => {
                this.initializeItemsToShowFromOperations(this.operationsToShow)
            }, 50)
        },
        getOperationLabel(operationType) {
            for (const element of OPERATIONS_LIST) {
                if (element.name === operationType) {
                    return element.label;
                }
            }
            return '---';
        },
        getOperationStatus(endDate) {
            if (new Date(endDate * 1000) < new Date()) {
                return 'done'
            }
            return 'scheduled'
        },
        async initTimeline() {
            await this.sleep(1);
            this.timeline = this.$refs.timeline;
            this.timeline.redraw();
        },
        initializeItemsToShowFromOperations(operationsToShow) {
            let itemsTemp = [];
            this.items.forEach(item => {
                let operationItemToShow = operationsToShow.find((obj) => obj.id === item.id)

                if (operationItemToShow) {
                    itemsTemp.push(item)
                }
            })

            this.itemsToShow = itemsTemp;
        },
        clearData() {
            this.items = [];
            this.operations = [];
            this.operationsToShow = [];
            this.itemsToShow = [];
            this.selectedField = null;
            this.selectedOperation = null;
            this.compareOperationOne = null;
            this.compareOperationTwo = null;
            this.operationAnalyze = false;
            this.show_back = false;
        },
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        calculateArea(field) {
            if (!field.coordinates) {
                return 0;
            }
            return (this.google_maps_reference.maps.geometry.spherical.computeArea(
                this.jstsWithoutHolesToGoogleMaps(this.buildPolygonFromCoordinates(field.coordinates),
                    this.google_maps_reference.maps)[0]) / 10000).toFixed(3);
        },
        buildPolygonFromCoordinates(wtkString) {
            let reader = new jsts.io.WKTReader();
            return reader.read(wtkString);
        },
        calculateFieldCenter(val) {
            if (!val.coordinates) {
                return;
            }

            let fieldPolygon = this.buildPolygonFromCoordinates(val.coordinates);
            let envelopeCoordinates = (fieldPolygon.getEnvelope().getCoordinates())

            let maxLat = -90;
            let maxLng = -180;
            let minLat = 90;
            let minLng = 180;

            envelopeCoordinates.forEach(coordinate => {
                if (coordinate.y > maxLat) {
                    maxLat = coordinate.y;
                }
                if (coordinate.y < minLat) {
                    minLat = coordinate.y;
                }
                if (coordinate.x > maxLng) {
                    maxLng = coordinate.x;
                }
                if (coordinate.x < minLng) {
                    minLng = coordinate.x;
                }
            })

            this.fieldCenter = {lat: (maxLat + minLat) / 2, lng: (maxLng + minLng) / 2};
        },
        saveFilterOptions() {
            let storedChosenOptions = {}
            storedChosenOptions["selectedOrg"] = this.selectedOrg;
            storedChosenOptions["selectedField"] = this.selectedField;
            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_AGRO_FILTER_OPTIONS, JSON.stringify(storedChosenOptions));
        },
        loadMapTypes(operationType) {
            switch (operationType) {
                case 'SEEDING':
                    return MAP_TYPES.SEEDING
                case 'HARVEST':
                    return MAP_TYPES.HARVEST
                case 'MOWING':
                    return MAP_TYPES.MOWING
                case 'TILLAGE':
                    return MAP_TYPES.TILLAGE
                case 'DRY_APPLICATION':
                    return MAP_TYPES.DRY_APPLICATION
                case 'LIQUID_APPLICATION':
                    return MAP_TYPES.LIQUID_APPLICATION
            }
        }
    },
    components: {
        Pane,
        Dialog,
        Dropdown,
        Splitpanes,
        ProgressSpinner,
        AppButton,
        AppTimeline,
        AppOperationsList,
        AppOperationsAnalyze,
        AppOperationsCompare
    },
    watch: {
        selectedOrg: function (val) {
            this.clearData();
            this.getFieldsFromOrg(val);
        },
        selectedField: function (val) {
            if (val) {
                this.saveFilterOptions();
                this.selectedOperation = null;
                this.operationAnalyze = false;
                this.show_back = false;
                this.calculateFieldCenter(val);
                this.getOperations();
            }
        },
        selectedOperation: function () {
            if (!this.selectedOperation) {
                return
            }

            this.mapTypes = this.loadMapTypes(this.selectedOperation.operation_type)
            setTimeout(() => {
                this.selectedMapType = this.mapTypes[0];
            }, 5)

            this.operationClickedFromList(this.selectedOperation)
        },
        compareOperationOne: function () {
            if (!this.compareOperationOne) {
                this.selectedMapTypeOne = null
                return
            }

            this.mapTypesOne = this.loadMapTypes(this.compareOperationOne.operation_type)
            setTimeout(() => {
                this.selectedMapTypeOne = this.mapTypesOne[0];
            }, 5)
        },
        compareOperationTwo: function () {
            if (!this.compareOperationTwo) {
                this.selectedMapTypeTwo = null
                return
            }

            this.mapTypesTwo = this.loadMapTypes(this.compareOperationTwo.operation_type)
            setTimeout(() => {
                if (!this.selectedMapTypeTwo) {
                    this.selectedMapTypeOne = JSON.parse(JSON.stringify(this.mapTypesOne[0]));
                }
                this.selectedMapTypeTwo = this.mapTypesTwo[0];
            }, 5)
        }
    },
    computed: {
        isScreenMobile: function () {
            return window.screen.width <= 1024;
        }
    }
}
</script>

<style scoped lang="scss">
.splitpanes.default-theme .splitpanes__pane {
    background-color: #fff;
}

.agro-body {
    position: relative;
    height: calc(100vh - 81px) !important;
}

.operations-compare {
    height: calc(100vh - 81px) !important;
}

.operations-analyze {
    height: 100% !important;
}

.timeline-body {
    width: 100%;
    height: 100%;
}

.button-back {
    margin-left: 10px;
    justify-content: end;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dropdown-250 {
    width: 250px;
}

.dropdown-ml {
    margin-left: 10px;
}

.operation-compare-item {
    border-bottom: 1px solid #e0e0e0;
    height: 50px;
    display: flex;
    align-items: center;
    color: #393939;
    padding-left: 10px;
    cursor: pointer;
}

@media(max-width: 1024px) {
    .glyphicons {
        margin: 0;
        padding: 0;
    }
}

@media(max-width: 519px) {
    .dropdown-width-org {
        margin: 10px 10px 10px 10px !important;
    }

    .dropdown-width-field {
        margin: 0px 10px 10px 10px !important;
    }

    .button-back {
        margin-bottom: 8px;
    }
}
</style>
